import {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {isEmpty} from 'lodash'
import * as utils from 'f-utils'
import getFeaturedDetails from './getFeaturedDetails'

export default function useFoodlyRestaurants({initialFilterType = 'Nearest'} = {intialFilterType: 'Nearest'}) {
  const dispatch = useDispatch()
  const locationsData = useSelector(dispatch.restaurants.getLocations)
  const [restaurantFilterKeyword, setRestaurantFilterKeyword] = useState(null)
  const isSearching = restaurantFilterKeyword != null
  const userLocation = useSelector(dispatch.user.getUserLocation) ?? {latitude: 0, longitude: 0}
  const isCartEmpty = useSelector(dispatch.user.getIsCartEmpty)
  const cartCount = useSelector(dispatch.user.getCartCount)
  const cartTotal = useSelector(dispatch.user.getCartTotal)
  const [selectedTags, setSelectedTags] = useState({})
  const [selectedFilterType, setSelectedFilterType] = useState(initialFilterType)
  const orderType = useSelector(dispatch.user.getOrderType)
  const tagGroups = useSelector(dispatch.public.getPublicTagGroups)
  const rewards = useSelector(dispatch.restaurants.getAllRewards)

  // 1. Go through all locations and calculate distance and open_status
  const locationsList = Object.entries(locationsData).map(([locationId, locationData]) => {
    const {latitude: lat1, longitude: lng1} = userLocation
    const {lat: lat2, lng: lng2} = locationData.restaurantLatLng ?? {lat: 0, lng: 0}
    const distance = utils.latlngDistance(lat1, lng1, lat2, lng2)
    const orderOpenDetails = dispatch.restaurants.getOrderOpenDetails({locationId})
    const restaurantNameLowercase = locationData.restaurantName.toLowerCase()
    if (!locationData.rewards && rewards[locationId]) {
      locationData.rewards = rewards[locationId]
    }
    return {...locationData, restaurantNameLowercase, orderOpenDetails, distance}
  })

  // 2. Filter out any restaurant that falls outside of our query
  // a. By Order Type
  // b. Delivery AND outside of delivery radius
  // c. Search keyword OR tags
  // d. when selectedFilterType === 'Deals'
  const rewardData = {}
  const filteredLocationList = []
  const filterLowerCaseKeyword = restaurantFilterKeyword?.toLowerCase() ?? null
  for (const locationData of Object.values(locationsList)) {
    if (!locationData.availableOrderTypes[orderType]) {
      continue
    }
    if (orderType === 'Delivery') {
      if (locationData.deliveryDistance < locationData.distance) {
        continue
      }
    }
    if (filterLowerCaseKeyword?.length > 0) {
      if (!locationData.restaurantNameLowercase.includes(filterLowerCaseKeyword)) {
        continue
      }
    }
    if (!isEmpty(selectedTags)) {
      if (!Object.keys(selectedTags).every((t) => locationData.tags?.includes(t))) {
        continue
      }
    }
    if (selectedFilterType === 'Deals') {
      if ('rewards' in locationData) {
        const {rewardName, rewardType, deliveryFreeName, condition} = getFeaturedDetails({
          orderType,
          locationData,
        })
        if (rewardName) {
          rewardData[locationData.locationId] = {
            rewardName,
            rewardType,
            deliveryFreeName,
            condition,
          }
          filteredLocationList.push(locationData)
        }
      }
    } else {
      filteredLocationList.push(locationData)
    }
  }

  // 3. Sort (fitlered) restaurants in following order.
  // a. orderOpen -> 0 orderClosed -> nextOpeningTime
  // b. within 10km radius
  // c. If Fastest, waitTime + deliveryTime (if delivery)
  //    If Nearest, distance

  const sortedLocationList = filteredLocationList.sort((a, b) => {
    const openDetailsA = a.orderOpenDetails
    const openDetailsB = b.orderOpenDetails
    const locationDistA = a.distance
    const locationDistB = b.distance

    // Check isOpen
    if (openDetailsA.isOpen && openDetailsB.isOpen) {
      // Check within 10km
      if ((locationDistA < 10 && locationDistB < 10) || (locationDistA >= 10 && locationDistB >= 10)) {
        if (selectedFilterType === 'Fastest') {
          if (orderType === 'Delivery') {
            return a.waitTime + a.deliveryTime - (b.waitTime + b.deliveryTime)
          } else {
            return a.waitTime - b.waitTime
          }
        } else if (selectedFilterType === 'Nearest') {
          return a.distance - b.distance
        } else {
          // Deals sorting?
          return a.distance - b.distance
        }
      } else {
        return locationDistA >= 10 ? 1 : -1
      }
    } else if (!openDetailsA.isOpen && !openDetailsB.isOpen) {
      if (openDetailsA.openMoment && openDetailsB.openMoment) {
        if (openDetailsB.openMoment.isAfter(openDetailsA.openMoment)) {
          return -1
        } else {
          return 1
        }
      } else if (openDetailsA.openMoment) {
        return -1
      } else {
        return 1
      }
    } else {
      return openDetailsA.isOpen ? -1 : 1
    }
  })

  return {
    cartTotal,
    isCartEmpty,
    cartCount,
    isSearching,
    orderType,
    restaurantFilterKeyword,
    selectedFilterType,
    selectedTags,
    setRestaurantFilterKeyword,
    handleFilterKeywordClear: () => setRestaurantFilterKeyword(null),
    setSelectedFilterType,
    setSelectedTags,
    sortedLocationList,
    tagGroups,
    rewardData,
  }
}
