import React from 'react'
import {useMediaMinSM} from 'f-web/src/hooks'
import {Dialog} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {FButton, FView} from 'components'

export default function FDialog({cancelable = true, children, onClose, ...props}) {
  const isMinSM = useMediaMinSM()
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={!isMinSM}
      disableBackdropClick={!cancelable}
      disableEscapeKeyDown={!cancelable}
      transitionDuration={0}
      onClose={onClose}
      disableScrollLock={isMinSM}
      {...props}>
      {cancelable && (
        <FView absolute right={24} top={24} zIndex={24}>
          <FButton alignCenter onClick={onClose}>
            <FView p={4} br="50%" bg="rgba(255,255,255, 0.75)">
              <Close />
            </FView>
          </FButton>
        </FView>
      )}
      {children}
    </Dialog>
  )
}
