import React from 'react'
import {FText, FView, FButton} from 'components'
import {Radio, Checkbox, useTheme} from '@material-ui/core'
import {Block} from '@material-ui/icons'

const ModifierGroupView = ({
  isRequired,
  maxSelection,
  modifierGroupDetails,
  modifierGroupId,
  modifierItems,
  selectedModifiers,
  setSelectedModifiers,
  title,
  onClickModifier,
  error = null,
}) => {
  const theme = useTheme()
  const errorColor = theme.palette.error.main
  return (
    <FView>
      <FView mh={24}>
        <FText bold h6>
          {title}
        </FText>

        <FView row alignCenter justifyBetween>
          <FText color={error ? errorColor : theme.palette.grey[600]}>
            {maxSelection >= 99 ? '(Choose as many)' : `(Choose up to ${maxSelection})`}
          </FText>
          {isRequired && (
            <FText color={error ? errorColor : theme.palette.primary.main}>
              {error ? error.message : '(Required)'}
            </FText>
          )}
        </FView>
      </FView>
      {Object.keys(modifierItems)
        .sort()
        .map((modifierItemId) => {
          const {name, price, activeAfter} = modifierItems[modifierItemId]
          const selected = !!(selectedModifiers[modifierGroupId] && selectedModifiers[modifierGroupId][modifierItemId])
          const inStock = !activeAfter || activeAfter.toDate() < new Date()
          return (
            <FView key={modifierItemId} ml={13} mr={24}>
              <FButton
                disabled={!inStock}
                hoverHighlight
                onClick={() => {
                  setSelectedModifiers(
                    calculateNewSelectedModifiers({
                      selectedModifiers,
                      modifierGroupId,
                      modifierGroupDetails,
                      modifierItemId,
                    }),
                  )
                  onClickModifier?.()
                }}>
                <FView fill row alignCenter>
                  <FView>
                    {!inStock ? (
                      <FView size={42} center>
                        <Block color={'action'} />
                      </FView>
                    ) : maxSelection === 1 && isRequired ? (
                      <Radio checked={selected} name={name} color="primary" />
                    ) : (
                      <Checkbox checked={selected} name={name} color="primary" />
                    )}
                  </FView>
                  <FText color={error ? errorColor : 'black'}>{name}</FText>
                  <FView fill />

                  <FView w={8} />
                  {inStock ? (
                    <FText semiBold color={error ? errorColor : theme.palette.grey[600]}>
                      {price > 0 ? `+$${price.toFixed(2)}` : price < 0 ? `-$${(-price).toFixed(2)}` : ''}
                    </FText>
                  ) : (
                    <FText grey600 semiBold>
                      SOLD OUT
                    </FText>
                  )}
                </FView>
              </FButton>
            </FView>
          )
        })}
    </FView>
  )
}

const calculateNewSelectedModifiers = ({selectedModifiers, modifierGroupId, modifierGroupDetails, modifierItemId}) => {
  const newModifiers = {...selectedModifiers}
  if (!selectedModifiers[modifierGroupId]) {
    newModifiers[modifierGroupId] = {[modifierItemId]: true}
    return newModifiers
  }
  const {isRequired, maxSelection} = modifierGroupDetails

  // Radio Button
  if (isRequired && maxSelection === 1) {
    if (!selectedModifiers[modifierGroupId][modifierItemId]) {
      newModifiers[modifierGroupId] = {[modifierItemId]: true}
      return newModifiers
    }
    return selectedModifiers
  }

  // 1. Count how many existing items are selected
  const groupSelectionCount = Object.values(selectedModifiers[modifierGroupId]).reduce((prev, cur) => {
    return prev + (cur === true ? 1 : 0)
  }, 0)

  // 2. Is it a selection?
  if (!newModifiers[modifierGroupId][modifierItemId]) {
    newModifiers[modifierGroupId] = {...selectedModifiers[modifierGroupId]}
    // 3. If it is a selection and there is no more room, uncheck one
    if (groupSelectionCount >= maxSelection) {
      for (const itemId of Object.keys(newModifiers[modifierGroupId])) {
        const isSelected = newModifiers[modifierGroupId][itemId]
        if (isSelected) {
          newModifiers[modifierGroupId][itemId] = false
          break
        }
      }
    }
    // 4. Simply select the option
    newModifiers[modifierGroupId][modifierItemId] = true
    return newModifiers
  } else {
    // 5. If the modifier is required, and selectionCount is 1, don't do anything
    if (isRequired && groupSelectionCount === 1) {
      return selectedModifiers
    }
    // 6. Uncheck the item
    newModifiers[modifierGroupId] = {...selectedModifiers[modifierGroupId]}
    newModifiers[modifierGroupId][modifierItemId] = false
    return newModifiers
  }
}

export default ModifierGroupView
