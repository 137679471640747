import React, {useEffect} from 'react'
import {Footer, FText, FView, NavBarV2} from 'components'
import {Divider} from '@material-ui/core'

const Heading = ({children}) => (
  <FText bold gutterBottom>
    {children}
  </FText>
)
const Content = ({children}) => (
  <FText body1 paragraph gutterBottom>
    {children}
  </FText>
)

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <FView alignCenter>
      <NavBarV2 />
      <FView selfCenter p={25} maxWidth={1000}>
        <Heading>1. How We Collect Your Information</Heading>
        <Content>
          To ensure that we can improve and provide the best service, personal information will be collected from you
          when you use our services; such as partnering with us or using our application(s), website(s) and services. We
          collect information: - when you create an account with us or update your account information settings on our
          Foodly Technologies Inc services platform; this includes both mobile applications, websites, electronic
          devices - when you place an online order through our affiliated services and applications. - when you browse
          and visit our sites and applications - from thirty party sites such as advertising platforms to understand you
          better
        </Content>
        <Heading>2. Security</Heading>
        <Content>
          Our team will make our best efforts to ensure the personal information that you have entrusted to us are
          suitably protected. We take safety measures to protect your information from unauthorized access. As a user of
          Foodly Technologies Inc, you are also responsible for the security of your own password. We advise that you do
          not share your password with anyone.
        </Content>
        <Content>
          Unfortunately, there are instances where the transmission of information via the internet, mobile phones are
          not completely secure. Although we will take measures to protect your information, we cannot guarantee the
          security of your data transmitted to us or through third party vendors; any transmission is at your own risk.
          Once we have received your information, we will use strict procedures and security features to try to prevent
          unauthorised access.
        </Content>
        <Heading>3. Information Usage</Heading>
        <Content>
          In order to provide you with quality services, we use your information: - To enable us to collect payments
          from you through our provided services - To conduct Know Your Customer( “KYC”) due diligence so that we could
          understand you better - To provide assistance to you where necessary concerning our services - To contact you
          for conducting business marketing purposes - To improve the quality of service in the future by analyzing your
          record of activities - To customize content that we or our third party restaurant or advertising partners
          display to you; To showcase which advertising is suitable for you ;based on characteristics determined by our
          data analysis - To enforce our contractual terms with you and any other agreement,if applicable - For the
          purpose of defence of legal claims and to protect the rights of Foodly Technologies Inc, restaurant partners,
          or others.
        </Content>
        <Heading>4. Marketing</Heading>
        <Content>
          To provide premium quality services, our team will analyze the data about your use of our services from your
          location data and personal data to create profiles relating to you and for you. For example, we may send you
          marketing communications restaurant promotions, updates on our services, and advertisement that we think you
          may be interested in. Here, the definition of marketing communication can comprised of both physical and
          electronic communications, whichever that is suitable.
        </Content>
        <Heading>5. Disclosure Of Your Information</Heading>
        <Content>
          The information we collect about you will be on our servers and our third party service providers. Sharing
          your information internally: We share your information within Foodly Technologies and its affiliated partners
          only where necessary for the purposes set out above. Sharing your information with third parties: We may share
          your information with third party service providers. The types of third party service providers whom we share
          your information with includes: Payment providers (including online payment providers/vendors): for the
          purposes of providing services to us, for example when they process transactions such as online payments; this
          includes debit card and credit card transactions IT service providers (including cloud providers): for the
          purposes of data storage and analysis; Restaurant partners: that you have placed your order with so that they
          can fulfil your order, be made aware of any food allergies you have volunteered to tell them about, resolve
          issues, or improve their services;
        </Content>
        <Heading>
          6. Authorization for Release of Transaction Processing Information to Third Party Payment Processor(s)
        </Heading>
        <Content>
          By using our service(s), you hereby authorize Foodly Technologies Inc. (“Foodly”) to release to Foodly’s third
          party payment processor(s) (“Payment Processor(s)”): (a) historical and future dollar amounts of sale
          transactions processed by our Payment Processor(s) less any refunds and adjustments for each of your debit,
          Mastercard, Visa, Amex transactions, (b) Your contact and identifying information including name, mailing
          address, telephone number and email address (collectively, the “Data”) to permit Foodly and our Payment
          Processor(s) to evaluate your eligibility for payment solutions. The Data will be released to our Payment
          Processor(s) following your payment of any Foodly’s service(s) and at subsequent intervals to be determined by
          Foodly in its sole discretion. You may withdraw your consent to have the information described in this
          Authorization released to Payment Processor(s) by providing written notice to Foodly via email to
          support@foodly.ca. Following the release, the Data will be treated in accordance with our Payment
          Processor(s)’ privacy policy, which may be amended from time to time. You hereby acknowledge and agree that
          neither Foodly nor any parent, affiliate, or subsidiary of Foodly, nor Member, shall have any liability
          whatsoever to you arising from or related to the provision of the Data to any third party payment
          processor(s), including without limitation for any failure by our third party payment processor(s) to collect,
          process, or store the Data in compliance with payment network requirements related to cardholder and
          transaction information, confidentiality and security, including by not limited to the PCI Data Security
          Standard.
        </Content>
        <Content>
          Further, you hereby release and shall at all times indemnify Foodly and its respective officers, directors,
          employees, agents, in respect of, and hold Foodly harmless from and against, any and all claims, demands,
          causes of action, actions, proceedings, judgments, debts, obligations, liabilities, damages, fines, fees,
          penalties, interest obligations, taxes, deficiencies, loss, costs or expenses (including amounts paid to
          enforce this provision and amounts paid in settlement, interest, court costs, costs of investigation, fees and
          expenses of attorneys, accountants, financial advisors and other experts) suffered or incurred, directly or
          indirectly, by Foodly and its Payment Processor(s) arising out of, resulting from your payment transactions
          with Foodly and its Payment Processor(s)’ actions pursuant to this policy.
        </Content>
        <Heading>7. Complaints</Heading>
        <Content>
          To ensure we continue to provide quality services to our valuable customers, we are committed to listen to any
          inquiries, complaints that you may have with our services. However, If you’re not satisfied with our response
          to any complaint or believe our processing of your information does not comply with the applicable law, you
          can file a complaint to the Office of the Privacy Commissioner of Canada (OPC)
        </Content>
        <Content>Office of the Privacy Commissioner of Canada 30, Victoria Street Gatineau, Quebec K1A 1H3</Content>
        <Content>Toll-free: 1-800-282-1376 Phone: (819) 994-5444 TTY: (819) 994-659</Content>
        <Content>Website: https://www.priv.gc.ca/en</Content>
        <Heading>8. Retention Of Record</Heading>
        <Content>
          Information that we collect will be retained for as long as needed to fulfil the purposes set forth above.
        </Content>
        <Content>
          When determining the appropriate retention period, (1) we will consider whether there is a continued need to
          carry out legitimate business objectives; (2) legal obligation(s) under applicable law to retain data for a
          certain period of time; (3) guidelines issued by relevant data protection authorities.
        </Content>
        <Content>
          We will securely remove your information when we no longer require your information for the purposes
          collected.
        </Content>
        <Heading>9. Changes To Our Privacy Policy</Heading>
        <Content>Any updates on our privacy policy will be posted on our website, where appropriate</Content>
        <Heading>10. Contacting Us</Heading>
        <Content>
          If you have any further queries or requests concerning this privacy policy or your personal data, please
          contact us by: By contacting our general customer services team at: hello@foodly.ca
        </Content>
      </FView>
      <Divider />
      <FView size={50} />
      <Footer />
    </FView>
  )
}
