import * as components from './src/components'
import * as styles from './src/styles'

export {components, styles}

export const foodlyTheme = {
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#f5f5f5',
      },
    },
    MuiTab: {
      root: {
        maxWidth: 200,
      },
      wrapper: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    MuiDialog: {
      paper: {
        margin: 0,
        padding: 0,
        borderRadius: 24,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '48px 32px 32px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0px 32px 0',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '32px 32px 32px',
      },
    },
  },

  breakpoints: {
    values: {
      xs: 375,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },

  typography: {
    htmlFontSize: 10,
    fontFamily: 'Open Sans, sans-serif',
    h1: {fontWeight: 400},
    h2: {fontWeight: 400},
    h3: {},
    h4: {},
    h5: {},
    h6: {fontWeight: 400},
    subtitle1: {},
    subtitle2: {fontWeight: 400},
    body1: {},
    body2: {},
    button: {},
    caption: {},
    overline: {},
  },
  palette: {
    common: {
      white75: 'rgba(255, 255, 255, 0.75)',
      white50: 'rgba(255, 255, 255, 0.50)',
      white25: 'rgba(255, 255, 255, 0.25)',
      white10: 'rgba(255, 255, 255, 0.10)',
      black75: 'rgba(0, 0, 0, 0.75)',
      black50: 'rgba(0, 0, 0, 0.50)',
      black25: 'rgba(0, 0, 0, 0.25)',
      black10: 'rgba(0, 0, 0, 0.10)',
      background: 'rgba(247, 247, 245, 1)',
    },
    primary: {
      main: '#F75C23',
      light: '#FE7B4C',
    },
    secondary: {
      main: '#FE7B4C',
    },
  },
}
