const settingsModel = {
  state: {
    isViewingCart: false,
    authModalType: null,
  },
  reducers: {
    setIsViewingCart: (state, isViewingCart) => ({
      ...state,
      isViewingCart,
    }),
    setAuthModalType: (state, authModalType) => ({
      ...state,
      authModalType,
    }),
  },
  actions: ({dispatch, getState}) => ({
    getIsViewingCart() {
      const isCartEmpty = dispatch.user.getIsCartEmpty()
      return !isCartEmpty && !!getState().settings.isViewingCart
    },
    getAuthModalType() {
      return getState().settings.authModalType
    },
  }),
}

export default settingsModel
