import React, {useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FButton, FView, FText} from 'components'
import {useTheme} from '@material-ui/core/styles'
import {Home, Add} from '@material-ui/icons'
import {MenuItem, Popper, ClickAwayListener, Backdrop, makeStyles} from '@material-ui/core'
import DeliveryModal from './DeliveryModal'
import useQueryDialog from './useQueryDialog'

export default function AddressSelector() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const selectedAddress = useSelector(dispatch.user.getAddressData)
  const selectedAddressIndex = useSelector(dispatch.user.getSelectedAddressIndex)
  const addresses = useSelector(dispatch.user.getAddresses)
  const [popOpen, setPopOpen] = useState(false)
  const anchorRef = useRef()
  const {dialogOpen, openDialog, closeDialog} = useQueryDialog()

  return (
    <>
      <DeliveryModal isOpen={dialogOpen === 'deliveryInfo'} onRequestClose={closeDialog} />
      <Backdrop open={popOpen} classes={classes} transitionDuration={100} />
      <ClickAwayListener onClickAway={() => setPopOpen(false)}>
        <FView zIndex={popOpen ? 666 : 420}>
          <FButton
            alignCenter
            onClick={() => {
              selectedAddress ? setPopOpen(!popOpen) : openDialog('deliveryInfo')
            }}>
            <FView ref={anchorRef} w={180} row alignCenter bg={theme.palette.grey[100]} ph={16} pv={8} br={8}>
              <Home fontSize="small" />
              <FView w={8} />
              <FText semiBold noWrap overflow="hidden" textOverflow="ellipsis">
                {(selectedAddress && selectedAddress.name) || 'Add New Address'}
              </FText>
            </FView>
          </FButton>
          <Popper
            disablePortal
            anchorEl={anchorRef.current}
            open={popOpen}
            onClose={() => setPopOpen(false)}
            placement={'bottom-start'}>
            <FView bg="white" mt={8} pv={8} br="5%">
              {addresses.map((address, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    dispatch.user.setSelectedAddressIndex(index)
                    dispatch.user.setUserLocation({
                      latitude: address.containerDeliveryAddressLatLng.lat,
                      longitude: address.containerDeliveryAddressLatLng.lng,
                      city: null,
                      region: null,
                      country: null,
                    })
                    setPopOpen(false)
                  }}>
                  <FView pr={16}>
                    <FText semiBold secondary={index === selectedAddressIndex}>
                      {address.name ?? address.containerDeliveryAddress}
                    </FText>
                    <FText
                      body2
                      grey700={index !== selectedAddressIndex}
                      secondary={index === selectedAddressIndex}
                      semiBold>
                      {address.city + ', ' + address.region + ' ' + address.postalCode}
                    </FText>
                  </FView>
                </MenuItem>
              ))}
              <MenuItem onClick={() => openDialog('deliveryInfo')}>
                <FView row alignCenter minHeight={42}>
                  <Add />
                  <FView w={8} />
                  <FText semiBold>Add New Address</FText>
                </FView>
              </MenuItem>
            </FView>
          </Popper>
        </FView>
      </ClickAwayListener>
    </>
  )
}

const useStyles = makeStyles({
  root: {
    zIndex: 609,
  },
})
