import React from 'react'
import {FView} from 'components'
import './loading.css'
export default function Loading() {
  return (
    <FView w={'100vw'} h={'100vh'} overflow="hidden" center bg={'#eee'}>
      <div className="loading-container">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </FView>
  )
}
