import React from 'react'
import {FText, FView, FDialog} from 'components'
import {DialogContent, DialogTitle, useTheme, Link} from '@material-ui/core'
import {Place, Phone, AccessTime, DriveEta} from '@material-ui/icons'
import moment from 'moment'
import * as utils from 'f-utils'

export default function InfoDialog({open, onClose, locationData}) {
  const theme = useTheme()
  const params = new URLSearchParams({q: locationData.address, key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY})
  return (
    <FDialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <FText h6 bold>
          Restaurant Info
        </FText>
      </DialogTitle>
      <DialogContent>
        <iframe
          title="location on google maps"
          src={`https://www.google.com/maps/embed/v1/place?${params.toString()}`}
          frameBorder="0"
          loading="lazy"
          style={{height: 240, width: '100%', border: 0, backgroundColor: '#eee'}}
        />
        <FView h={16} />
        <FView row alignCenter>
          <FView w={200} row alignCenter maxWidth="40%">
            <Place color="primary" />
            <FView w={16} />
            <FText bold> Address</FText>
          </FView>
          <FText color={theme.palette.primary.main}>{locationData.address}</FText>
        </FView>
        <FView h={32} />
        <FView row alignCenter>
          <FView w={200} row alignCenter maxWidth="40%">
            <Phone color="primary" />
            <FView w={16} />
            <FText bold> Phone</FText>
          </FView>
          <Link href={`tel:+1${locationData.phoneNumber}`} color="primary">
            <FText>{utils.formatPhoneNumber(locationData.phoneNumber)}</FText>
          </Link>
        </FView>
        <FView h={32} />

        <FView row alignStart>
          <FView w={200} row alignCenter maxWidth="40%">
            <AccessTime color="primary" />
            <FView w={16} />
            <FText bold> Ordering Hours</FText>
          </FView>
          <AvailableHoursView hoursLT={locationData?.hoursLT} label="Ordering Hours" />
        </FView>
        {locationData.deliveryHoursLT && (
          <FView row alignStart mt={32}>
            <FView w={200} row alignCenter maxWidth="40%">
              <DriveEta color="primary" />
              <FView w={16} />
              <FText bold> Delivery Hours</FText>
            </FView>
            <AvailableHoursView hoursLT={locationData?.deliveryHoursLT} label="Delivery Hours" orderType={'Delivery'} />
          </FView>
        )}
        <FView h={64} />
      </DialogContent>
    </FDialog>
  )
}
const AvailableHoursView = ({hoursLT}) => {
  return (
    <FView>
      {[1, 2, 3, 4, 5, 6, 0].map((day) => {
        return (hoursLT?.[day] ?? [{openLT: null, closeLT: null}]).map((openCloseLT, index) => {
          return (
            <FView row justifyBetween key={String(day) + index} mb={index === 0 ? 10 : 4}>
              <FView w={56}>
                {index === 0 && <FText bold>{`${moment().weekday(day).format('ddd').toUpperCase()}`}</FText>}
              </FView>

              <FView>
                <FText>
                  {!openCloseLT.openLT || !openCloseLT.closeLT
                    ? 'Ordering Closed'
                    : `${openCloseLT.openLT} - ${openCloseLT.closeLT}`}
                </FText>
              </FView>
            </FView>
          )
        })
      })}
    </FView>
  )
}
