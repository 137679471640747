import React from 'react'
import {FText, FView, FButton} from 'components'
import {Facebook} from '@material-ui/icons'

export default function FacebookButton({text, onClick}) {
  return (
    <FButton onClick={onClick}>
      <FView round bg="#4267B2" ph={32} pv={16} row center>
        <Facebook style={{color: 'white'}} />
        <FView w={16} />
        <FText white semiBold>
          {text}
        </FText>
      </FView>
    </FButton>
  )
}
