import momentTZ from 'moment-timezone'
import {get, sum, flatten, memoize, isEmpty} from 'lodash'

export * from './container'

export function calculateDeliveryFee({
  customerCoords,
  deliveryFee,
  deliveryFeeDiscount,
  deliveryFeeDiscountMinimumSubTotal,
  deliveryFeeMinimum = 0,
  deliveryFeePerDistance,
  deliveryFreeMinimumSubTotal,
  isDeliveryFeeVariable,
  orderType,
  restaurantCoords,
  subTotalBeforeDiscount,
}) {
  deliveryFeeDiscount = deliveryFeeDiscount || 0
  if (orderType === 'Delivery') {
    let totalDeliveryFee = 0
    if (isDeliveryFeeVariable) {
      const {lat: lat1, lng: lng1} = customerCoords
      const {lat: lat2, lng: lng2} = restaurantCoords
      const distance = latlngDistance(lat1, lng1, lat2, lng2)
      totalDeliveryFee = currencyRounding(deliveryFee + deliveryFeePerDistance * distance)
      if (totalDeliveryFee < deliveryFeeMinimum) {
        totalDeliveryFee = deliveryFeeMinimum
      }
    } else {
      totalDeliveryFee = deliveryFee
    }

    if (deliveryFreeMinimumSubTotal && subTotalBeforeDiscount >= deliveryFreeMinimumSubTotal) {
      totalDeliveryFee = 0
    } else if (deliveryFeeDiscountMinimumSubTotal && subTotalBeforeDiscount >= deliveryFeeDiscountMinimumSubTotal) {
      totalDeliveryFee = currencyRounding(totalDeliveryFee - deliveryFeeDiscount)
    }
    totalDeliveryFee = Math.max(totalDeliveryFee, 0)

    return totalDeliveryFee
  }

  return 0
}

export const parseCurrency = (amount) => {
  let amountNumber

  if (amount == null) {
    return 0
  }

  if (typeof amount === 'string') {
    amountNumber = Number(amount)
  } else {
    amountNumber = amount
  }

  if (isNaN(amountNumber)) {
    //
    return 0
  }

  return Math.round(amountNumber * Math.pow(10, 2)) / Math.pow(10, 2)
}

export const removeNonNumericString = (str) => {
  if (!str) {
    return ''
  }

  return str.replace(/\D+/g, '')
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }

  const phoneNumberStr = phoneNumber + ''

  return removeNonNumericString(phoneNumberStr).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3')
}

// Checks if given reward is currently valid
export const getIsRewardValid = ({
  isLoggedIn = false,
  rewardDetails,
  isFirstOrder = false,
  productsDetails,
  cartSubTotal,
  redeemedRewardCount = 0,
  userPointsWithPromoApplied = 0,
  orderType,
  productIdToCount,
  alreadyRedeemedCount = 0,
}) => {
  const ERROR = {
    INVALID_REWARD: {valid: false, message: 'Reward is invalid'},
    NOT_LOGGED_IN: {valid: false, message: 'Invalid Reward: Please log in before redeeming rewards'},
    NOT_ENOUGH_POINTS: {valid: false, message: 'Invalid Reward: Not Enough Points'},
    FREE_REWARD_EMPTY_CART: {valid: false, message: 'Invalid Reward: Free Reward cannot be redeemed with empty cart'},
    DISCOUNT_EMPTY_CART: {valid: false, message: 'Invalid Reward: Discount cannot be redeemed with empty cart'},
    INVALID_PRODUCT: {valid: false, message: 'Invalid Reward: Product is invalid'},
    INVALID_ORDER_TYPE: {valid: false, message: 'Invalid Reward: Order Type is invalid'},
    PRODUCT_SOLDOUT: {valid: false, message: 'Invalid Reward: Product is temporarily Out Of Stock'},
    MAXIMUM_COUNT: {valid: false, message: 'Invalid Reward: You cannot redeem any more of this reward'},
    INVALID_FIELD: {valid: false, message: 'Invalid Reward: This Reward cannot be redeemed'},
    INVALID_COUNT: {valid: false, message: 'Invalid Reward: You must redeem 1 or more'},
    INVALID_SUBTOTAL: {valid: false, message: 'Invalid Reward: Subtotal cannot be negative'},
    PRODUCT_NOT_IN_CART: {valid: false, message: 'Discounted product not found in cart'},
    NOT_MET_MIN_SUBTOTAL: {valid: false, message: 'Invalid Reward: Subtotal is too low'},
    QTY_LESS_THAN_ONE: {valid: false, message: ' Reward not currently available'},
  }

  if (!rewardDetails) {
    return ERROR.INVALID_REWARD
  }

  if (rewardDetails.requiresLogin && !isLoggedIn) {
    return ERROR.NOT_LOGGED_IN
  }

  if (redeemedRewardCount > rewardDetails.maximumCount) {
    return ERROR.MAXIMUM_COUNT
  }
  if (redeemedRewardCount + alreadyRedeemedCount > rewardDetails.qtyLimitPerUser) {
    return {valid: false, message: 'You have used up all your uses for this reward'}
  }
  if (rewardDetails.qty <= 0) {
    return ERROR.QTY_LESS_THAN_ONE
  }
  if (redeemedRewardCount <= 0) {
    return ERROR.INVALID_COUNT
  }

  if (cartSubTotal < rewardDetails.minimumSubTotal) {
    return ERROR.NOT_MET_MIN_SUBTOTAL
  }

  if (cartSubTotal < 0) {
    return ERROR.INVALID_SUBTOTAL
  }

  if (!isFirstOrder && rewardDetails.freeOnFirstOrder) {
    return {valid: false, message: 'This reward can only be used on first order'}
  }

  if (isFirstOrder && rewardDetails.requiredPoints === 0 && cartSubTotal <= 0) {
    return ERROR.FREE_REWARD_EMPTY_CART
  }

  if (rewardDetails.discountSubtotal > 0 && cartSubTotal <= 0) {
    return ERROR.DISCOUNT_EMPTY_CART
  }

  if (rewardDetails.productId && !productsDetails[rewardDetails.productId]) {
    return ERROR.INVALID_PRODUCT
  }

  if (rewardDetails.productId && !getIsProductActive(productsDetails[rewardDetails.productId])) {
    return ERROR.PRODUCT_SOLDOUT
  }

  if (
    (rewardDetails.version === 2 || rewardDetails.version === 1) &&
    rewardDetails.redeemType === 'ProductDiscount' &&
    !productIdToCount[rewardDetails.productId]
  ) {
    return ERROR.PRODUCT_NOT_IN_CART
  }

  if (
    rewardDetails.version === 3 &&
    !isEmpty(rewardDetails.productIds) &&
    rewardDetails.discountType !== 'free' &&
    Object.keys(rewardDetails.productIds).every((productId) => !productIdToCount[productId])
  ) {
    return {valid: false, message: 'None of the discounted products found in cart'}
  }

  if (userPointsWithPromoApplied < 0) {
    return ERROR.NOT_ENOUGH_POINTS
  }

  if (rewardDetails.requiredOrderType && rewardDetails.requiredOrderType !== orderType) {
    return ERROR.INVALID_ORDER_TYPE
  }

  return {valid: true}
}

// Checks if given reward is redeemable.
export const getIsRewardRedeemable = ({
  isLoggedIn = false,
  rewardDetails,
  isFirstOrder = false,
  productsDetails,
  cartSubTotal,
  redeemedRewardCount = 0,
  userPointsWithPromoApplied = 0,
  orderType,
}) => {
  if (!rewardDetails) {
    return {valid: false, message: 'Unsupported Reward'}
  }
  return getIsRewardValid({
    isLoggedIn,
    rewardDetails,
    isFirstOrder,
    productsDetails,
    cartSubTotal,
    redeemedRewardCount: redeemedRewardCount + 1,
    userPointsWithPromoApplied: userPointsWithPromoApplied - rewardDetails.requiredPoints,
    orderType,
  })
}

// Parses reward info
export const parseRewardInfo = (props) => {
  const {rewardInfo, isFirstOrder = false, products = {}, cartSubTotal, cartItems, cartItemIdToPrice = {}} = props
  const {
    type, // deprecated
    value, // deprecated
    points, // deprecated
    imageUrl,
    minimumSubTotal = 0,
    appliedDefault = false,
    requiresLogin = true,
    rewardName,
    requiredPoints = 0,
    freeOnFirstOrder = false,
    requiredFPoints = 0,
    maximumCount = 100,
    subtotalDiscountAmount = 0,
    subtotalDiscountPercent = 0,
    totalDiscountAmount = 0,
    totalDiscountPercent = 0,
    productId = null,
    requiredOrderType = null,
    version = 1,
    discountType,
    discountValue,
    productIds,
    qtyLimit,
    qtyLimitPerUser,
    qty,
    rewardId,
  } = rewardInfo
  const count = version === 2 || version === 3 ? 1 : props.count

  const [rewardWith, rewardFor, rewardCondition = 'Free'] = type.split('/')

  const rewardDetails = {
    // Descriptions
    discountType: discountType || 'undef',
    discountValue: discountValue || 0,
    productIds: productIds || {},
    qtyLimit: qtyLimit == null ? 999999999 : qtyLimit,
    qty: qty == null ? 99999999999999 : qty,
    qtyLimitPerUser: qtyLimitPerUser == null ? 99999999999999 : qtyLimitPerUser,
    rewardId: rewardId == null ? 'no reward id because it is not version 3' : rewardId,
    rewardName,
    imageUrl,
    maximumCount,
    appliedDefault,
    requiresLogin,
    freeOnFirstOrder,
    version,
    requiredPoints,
    totalRequiredPoints: requiredPoints * count,
    requiredFPoints,
    subtotalDiscountAmount, // Applies discount before applying tax
    subtotalDiscountPercent,
    totalDiscountAmount,
    totalDiscountPercent,
    productId,
    redeemType: rewardFor,
    // == Deprecated ==
    name: null, // Deprecated, use rewardName instead
    discountSubtotal: null,
    price: null,
    rewardValue: null,
    totalRewardValue: null,
    totalDiscountSubtotal: null,

    minimumSubTotal,
    requiredOrderType,
    count,
  }

  if (version === 2 || version === 3) {
    if (freeOnFirstOrder && isFirstOrder) {
      rewardDetails.requiredPoints = 0
      rewardDetails.totalRequiredPoints = 0
      rewardDetails.requiredFPoints = 0
      rewardDetails.totalRequiredFPoints = 0
    } else {
      rewardDetails.totalRequiredPoints = requiredPoints * count
    }
    rewardDetails.name = rewardName

    let totalDiscount = 0

    if (version === 2) {
      totalDiscount += subtotalDiscountPercent * cartSubTotal * count
      totalDiscount += subtotalDiscountAmount * count
    }

    if (version === 3) {
      if (discountType === 'flat' || 'percent') {
        if (isEmpty(productIds)) {
          if (discountType === 'flat') {
            totalDiscount = discountValue
          }
          if (discountType === 'percent') {
            totalDiscount = discountValue * cartSubTotal
          }
        } else {
          // sort cartItemIds by price with modifiers
          // for every promo, map cartItemId to the number of times the promo applies to it store this in applyTo
          // according to applyLimit's restrictions
          rewardDetails.count = 0
          rewardDetails.applyTo = {}
          rewardDetails.cartItemIdToDiscount = {}
          // cartItem -> (discount, count)
          const sorted = Object.entries(cartItems).sort((a, b) => {
            const priceA = cartItemIdToPrice[a[0]] || 0
            const priceB = cartItemIdToPrice[b[0]] || 0
            const idealDiscA = discountType === 'flat' ? discountValue : discountValue * priceA
            const idealDiscB = discountType === 'flat' ? discountValue : discountValue * priceB
            const actualDiscA = Math.min(idealDiscA, priceA)
            const actualDiscB = Math.min(idealDiscB, priceB)
            const diff = actualDiscB - actualDiscA
            return diff ? diff : a[0] > b[0] ? 1 : -1
          })

          sorted.forEach(([cartItemId, cartItem]) => {
            if (productIds[cartItem.productId]) {
              const numAvailableToApply = Math.max(
                Math.min(rewardDetails.qtyLimit, rewardDetails.qty, rewardDetails.qtyLimitPerUser) -
                  rewardDetails.count,
                0,
              )
              const numToApply = Math.min(cartItem.count, numAvailableToApply)
              if (numToApply <= 0) {
                return
              }
              rewardDetails.count += numToApply
              rewardDetails.applyTo[cartItemId] = numToApply
              const price = cartItemIdToPrice[cartItemId]
              let discountAmount = 0
              if (discountType === 'flat') {
                discountAmount = discountValue * numToApply
              }
              if (discountType === 'percent') {
                discountAmount = discountValue * price * numToApply
              }
              discountAmount = Math.min(discountAmount, price * numToApply)
              rewardDetails.cartItemIdToDiscount[cartItemId] = discountAmount
              totalDiscount += discountAmount
            }
          })
        }
      }
      if (discountType === 'free') {
        //
      }
    }
    rewardDetails.totalDiscountSubtotal = currencyRounding(Math.min(totalDiscount, cartSubTotal))

    return rewardDetails
  }

  // Type Follows With/For/Condition pattern
  // What are you redeeming WITH, what are you redeeming FOR, and in what CONDITION can you redeem it.
  // For instance, Free/Product/Birthday will indicate free product on birthday

  switch (rewardWith) {
    case 'Free':
      rewardDetails.maximumCount = 1
      rewardDetails.requiredPoints = 0
      rewardDetails.totalRequiredPoints = 0
      break
    case 'Points':
      rewardDetails.requiredPoints = points
      rewardDetails.totalRequiredPoints = points * count
      break
    case 'FreeFirstOrderOrPoints':
      if (isFirstOrder) {
        rewardDetails.requiredPoints = 0
        rewardDetails.totalRequiredPoints = 0
        rewardDetails.maximumCount = 1
      } else {
        rewardDetails.requiredPoints = points
        rewardDetails.totalRequiredPoints = points * count
      }
      break
    default:
      return null
  }

  switch (rewardFor) {
    case 'Product':
      const productDetails = products[value]
      if (productDetails) {
        rewardDetails.productId = productDetails.id
        rewardDetails.name = rewardName || productDetails.name
        rewardDetails.price = productDetails.price
        rewardDetails.rewardValue = productDetails.price
        rewardDetails.totalRewardValue = currencyRounding(productDetails.price * count)
      }
      break
    case 'ProductDiscount':
      break

    case 'DiscountPercent':
      if (minimumSubTotal == null || cartSubTotal == null) {
        return null
      }

      rewardDetails.name = rewardName || `${(subtotalDiscountPercent || value) * 100}% OFF`
      const discountPercent = parseFloat(subtotalDiscountPercent || value) || 0
      rewardDetails.maximumCount = 1
      rewardDetails.requiredPoints = 0
      rewardDetails.totalRequiredPoints = 0

      rewardDetails.subtotalDiscountPercent = discountPercent
      rewardDetails.discountSubtotal = currencyRounding(discountPercent * cartSubTotal)
      rewardDetails.totalDiscountSubtotal = rewardDetails.discountSubtotal
      rewardDetails.rewardValue = rewardDetails.discountSubtotal
      rewardDetails.totalRewardValue = rewardDetails.discountSubtotal
      break
    case 'Discount':
      rewardDetails.name = rewardName || `$${subtotalDiscountAmount || value} OFF`
      const discount = currencyRounding(parseFloat(subtotalDiscountAmount || value) || 0)
      rewardDetails.subtotalDiscountAmount = discount
      rewardDetails.discountSubtotal = discount
      rewardDetails.totalDiscountSubtotal = currencyRounding(discount * count)
      rewardDetails.rewardValue = discount
      rewardDetails.totalRewardValue = rewardDetails.totalDiscountSubtotal
      break
    default:
      return null
  }
  switch (rewardCondition) {
    case 'Pickup':
    case 'Delivery':
    case 'DineIn':
      rewardDetails.requiredOrderType = rewardCondition
      break
    case 'MinSubTotal':
      if (minimumSubTotal == null || cartSubTotal == null) {
        return null
      }
      break
    case 'Free':
      break
    default:
      return null
  }
  if (!rewardDetails.rewardName) {
    rewardDetails.rewardName = rewardDetails.name
  }

  return rewardDetails
}

export const parseFReward = ({fRewardId, subtotal}) => {
  switch (fRewardId) {
    case 'fPoints2500Discount':
      return {totalDiscountAmount: Math.min(2.5, subtotal), fPointsCost: 2500}
    case 'fPoints5000Discount':
      return {totalDiscountAmount: Math.min(5, subtotal), fPointsCost: 5000}
    case 'fPoints10000Discount':
      return {totalDiscountAmount: Math.min(10, subtotal), fPointsCost: 10000}
    case 'fPoints15000Discount':
      return {totalDiscountAmount: Math.min(15, subtotal), fPointsCost: 15000}
    default:
      return {totalDiscountAmount: 0, fPointsCost: 0}
  }
}

export const moment = (...params) => momentTZ.tz(...params, 'America/Vancouver')

export const isOrderOpenDetailsEqual = (left, right) => {
  if (!left || !right) {
    return false
  }

  return (
    left.isOpen === right.isOpen &&
    ((left.openMoment == null && right.openMoment == null) || left.openMoment.isSame(right.openMoment))
  )
}

const getOrderStatusDetailsTimestampResolver = ({hoursLT, targetTimestamp, orderOpenAfter, timezone}) => {
  const myTargetTimestamp = momentTZ(targetTimestamp).startOf('minute').valueOf()
  return (
    myTargetTimestamp +
    flatten(Object.values(hoursLT))
      .map((e) => (e.openLT || 'null') + (e.closeLT || 'null'))
      .join('') +
    timezone +
    orderOpenAfter
  )
}

const _getOrderStatusDetailsTimestamp = ({hoursLT, targetTimestamp, orderOpenAfter, timezone}) => {
  const targetTimeMoment = momentTZ.tz(targetTimestamp, timezone)
  const orderOpenAfterMoment = momentTZ.tz(orderOpenAfter, timezone)
  return getOrderStatusDetails({hoursLT, targetTimeMoment, orderOpenAfterMoment, timezone})
}

export const getOrderStatusDetailsTimestamp = memoize(
  _getOrderStatusDetailsTimestamp,
  getOrderStatusDetailsTimestampResolver,
)

// Given targetTimeMoment, find current open status, and next open, next close time.
export const getOrderStatusDetails = ({
  hoursLT,
  targetTimeMoment,
  orderOpenAfterMoment,
  timezone = 'America/Vancouver',
}) => {
  const myTargetTimeMoment = targetTimeMoment || momentTZ().tz(timezone)
  const myOrderOpenAfterMoment = orderOpenAfterMoment || momentTZ.tz(0, timezone)

  if (!hoursLT) {
    throw new Error('Missing hoursLT')
  }
  if (!myTargetTimeMoment) {
    throw new Error('Missing targetTimeMoment')
  }
  if (!myOrderOpenAfterMoment) {
    throw new Error('Missing orderOpenAfterMoment')
  }

  // 1. Check if targetTime is before the orderOpenAfterMoment
  if (myTargetTimeMoment.isBefore(myOrderOpenAfterMoment)) {
    // 2. Ordering is closed, find next opening hour
    const isOpenWithDetails = getIsOpenWithDetails({hoursLT, targetTimeMoment: myOrderOpenAfterMoment, timezone})
    if (isOpenWithDetails.isOpen) {
      // 3. Order is closed and reopens at myOrderOpenAfterMoment
      return {
        isOpen: false,
        openMoment: myOrderOpenAfterMoment,
      }
    } else {
      // 4. Order is closed and reopens at isOpenWithDetails.openMoment
      return {
        isOpen: false,
        openMoment: isOpenWithDetails.openMoment,
      }
    }
  }

  // 5. Find current and next hour block at targetTimeMoment
  return getIsOpenWithDetails({hoursLT, targetTimeMoment, timezone})
}

// Return arrays of open close timestamps periods up to array that includes targetTimestamp or upcoming (range: -1 to max +7 days)
// For includes example [[111, 222], [333, 555]] for targetTimestamp of 444
// For upcoming example [[111, 222], [444, 555]] for targetTimestamp of 333
export function getOrderOpenPeriods({
  hoursLT,
  targetTimestamp = new Date().valueOf(),
  timezone = 'America/Vancouver',
  fullRange = false,
}) {
  if (!hoursLT) {
    throw new Error('Missing hoursLT')
  }
  if (targetTimestamp == null) {
    throw new Error('Missing targetTimestamp')
  }

  const availablePeriods = []

  // 1. Get week and weekday of targetTimestamp in given timezone
  const targetMoment = momentTZ(targetTimestamp).tz(timezone)
  const targetWeekday = targetMoment.weekday()
  const targetWeek = targetMoment.week()
  const targetWeekYear = targetMoment.weekYear()

  // 3. Loop over hoursLT from targetWeekday - 1 + targetWeekday + 7
  for (let weekDayDeltaIndex = -1; weekDayDeltaIndex < 8; weekDayDeltaIndex++) {
    let myWeekYear = targetWeekYear
    let myWeek = targetWeek
    // moment day start from Sunday and ends on Saturday
    let myWeekday = targetWeekday + weekDayDeltaIndex

    // when myWeekday is negative, we need to go back in a week and fix the myWeekday value
    if (myWeekday <= -1) {
      myWeekday += 7
      myWeek -= 1
    } else if (myWeekday >= 7) {
      myWeekday -= 7
      myWeek += 1
    }

    // if myWeek is negative, subtrack 1 year and reset the myWeek value
    if (myWeek < 1) {
      myWeekYear -= 1
      myWeek = moment().year(myWeekYear).weeksInYear()
    } else if (myWeek > targetMoment.weeksInYear()) {
      myWeek = 1
      myWeekYear += 1
    }

    // 4. Loop over dayHoursLT
    const myDayHoursLT = hoursLT[myWeekday]
    if (myDayHoursLT && myDayHoursLT.length > 0) {
      for (const myDayHourLT of myDayHoursLT) {
        const {openLT, closeLT} = myDayHourLT
        if (openLT && closeLT) {
          // 5. parsing the time with 'YYYY w e LT' format
          // which translates to `weekyear() week() weekday() format('LT')`
          const momentParseFormat = 'YYYY w e LT'
          const momentYMD = myWeekYear + ' ' + myWeek + ' ' + myWeekday + ' '
          const openMoment = momentTZ.tz(momentYMD + openLT, momentParseFormat, timezone)
          const closeMoment = momentTZ.tz(momentYMD + closeLT, momentParseFormat, timezone)

          // 6. Ensure closeMoment is after openMoment
          if (closeMoment.isSameOrBefore(openMoment)) {
            closeMoment.add(1, 'days')
          }

          const openTimestamp = openMoment.valueOf()
          const closeTimestamp = closeMoment.valueOf()

          // 7. Add Open Period, assuming dayHoursLT is sorted and does not overlap each other
          availablePeriods.push([openTimestamp, closeTimestamp])

          // 8. If fullRange is false, end as soon as end block is reached for optimization (default)
          if (!fullRange && closeTimestamp > targetTimestamp) {
            return availablePeriods
          }
        }
      }
    }
  }
  return availablePeriods
}

function getIsOpenWithDetails({hoursLT, targetTimeMoment, timezone = 'America/Vancouver'}) {
  const myTargetTimeMoment = targetTimeMoment || momentTZ().tz(timezone)

  if (!hoursLT) {
    throw new Error('Missing hoursLT')
  }
  if (!myTargetTimeMoment) {
    throw new Error('Missing targetTimeMoment')
  }
  const targetTimestamp = myTargetTimeMoment.valueOf()
  const orderOpenPeriods = getOrderOpenPeriods({hoursLT, targetTimestamp, timezone})
  // 1. Loop through order open periods
  for (const orderOpenPeriod of orderOpenPeriods) {
    const [openTimestamp, closeTimestamp] = orderOpenPeriod

    // 2. Order is not open but we know when it will open again (within 6 days)
    if (targetTimestamp < openTimestamp) {
      return {
        isOpen: false,
        openMoment: momentTZ(openTimestamp).tz(timezone),
      }
    }

    // 3. Order is open, also return when it will close again (may not be accurate for 24 hrs)
    if (targetTimestamp >= openTimestamp && targetTimestamp < closeTimestamp) {
      return {
        isOpen: true,
        closeMoment: momentTZ(closeTimestamp).tz(timezone),
      }
    }
  }
  return {
    isOpen: false,
    openMoment: null,
  }
}

export const getIsOrderOpenHoursLT = ({hoursLT, targetTimeMoment, timezone = 'America/Vancouver'}) => {
  const myTargetTimeMoment = targetTimeMoment || momentTZ().tz(timezone)

  if (!hoursLT || !myTargetTimeMoment) {
    return false
  }

  const targetTimestamp = myTargetTimeMoment.valueOf()
  const orderOpenPeriods = getOrderOpenPeriods({hoursLT, targetTimestamp, timezone})

  for (const orderOpenPeriod of orderOpenPeriods) {
    const [openTimestamp, closeTimestamp] = orderOpenPeriod

    if (targetTimestamp >= openTimestamp && targetTimestamp < closeTimestamp) {
      return true
    }
  }
  return false
}

// Deprecated in favour of getIsOrderOpenHoursLT
export const getIsOpenForGivenTime = ({hours, targetDate, time, waitTime}) => {
  // Still being used in menu hours
  //
  if (!hours || !targetDate || !time) {
    return false
  }

  const weekday = targetDate.weekday()

  // get time from hours
  const open = get(hours[weekday], 'open')
  const close = get(hours[weekday], 'close')

  if (!open || !close) {
    return false
  }

  const openMoment = momentTZ.tz(open, 'America/Vancouver')
  const closeMoment = momentTZ.tz(close, 'America/Vancouver')

  const openTime = momentTZ
    .tz(targetDate, 'America/Vancouver')
    .hours(openMoment.hours())
    .minutes(openMoment.minutes())
    .seconds(0)
  const closeTime = momentTZ
    .tz(targetDate, 'America/Vancouver')
    .hours(closeMoment.hours())
    .minutes(closeMoment.minutes())
    .seconds(0)

  const orderCloseMoment = momentTZ.tz(closeTime, 'America/Vancouver').subtract(waitTime, 'minutes')

  if (openTime.isSame(closeTime)) {
    return false
  }

  let todayOrderClose

  if (openTime.isBefore(closeTime)) {
    todayOrderClose = orderCloseMoment
  } else {
    todayOrderClose = momentTZ.tz(orderCloseMoment, 'America/Vancouver').add(1, 'days')
  }

  return time.isBetween(openTime, todayOrderClose, '[]')
}

export const getIsProductActive = (productDetails) => {
  if (!productDetails) {
    // Product doens't exist
    return false
  }

  const qty = productDetails.qty
  if (typeof qty === 'number' && qty < 1) {
    return false
  }

  // Since Dec 16 2018, active property is deprecated in favor of activeAfter
  const activeAfter = get(productDetails, 'activeAfter')
  if (activeAfter) {
    return new Date().valueOf() > activeAfter
  }

  const active = get(productDetails, 'active')
  // If active property doesn't exist, this means product is active
  if (active === undefined || active === true) {
    return true
  }
  if (active === false) {
    return false
  }

  // As a fallback
  return true
}

export const isMobileBrowser = () => {
  var check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export function currencyRounding(amount) {
  return Math.round(amount * Math.pow(10, 2)) / Math.pow(10, 2)
}

export function calculatePointsEarned(subTotal, pointsInterval, pointsPerInterval) {
  return Math.max(Math.floor(Math.floor(subTotal / pointsInterval) * pointsPerInterval), 0)
}

// Return approx distance between two points in km
export function latlngDistance(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295 // Math.PI / 180
  var c = Math.cos
  var a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2

  return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
}

export function getCartItemTotalWithDefaultModifiers({price, count, selectedModifierGroupsWithDetails}) {
  if (!selectedModifierGroupsWithDetails) {
    return Math.max(price * count, 0)
  }

  const modifierTotalPrice = selectedModifierGroupsWithDetails.reduce((prev, modifierGroupDetails) => {
    if (!modifierGroupDetails || !modifierGroupDetails.selectedModifierItemsObj) {
      return prev
    }
    return (
      prev +
      Object.entries(modifierGroupDetails.selectedModifierItemsObj).reduce((prev, [modifierItemId]) => {
        if (modifierGroupDetails.modifierItems[modifierItemId].defaultValue) {
          const modifier_price = modifierGroupDetails.modifierItems[modifierItemId].price
          prev += modifier_price
        }
        return prev
      }, 0)
    )
  }, 0)

  return Math.max((price + modifierTotalPrice) * count, 0)
}

export function getCartItemTotal({price, count, selectedModifierGroupsWithDetails}) {
  if (!selectedModifierGroupsWithDetails) {
    return Math.max(price * count, 0)
  }

  const modifierTotalPrice = selectedModifierGroupsWithDetails.reduce((prev, modifierGroupDetails) => {
    if (!modifierGroupDetails || !modifierGroupDetails.selectedModifierItemsObj) {
      return prev
    }
    return (
      prev +
      Object.entries(modifierGroupDetails.selectedModifierItemsObj).reduce((prev, [modifierItemId, isSelected]) => {
        if (!modifierGroupDetails.modifierItems[modifierItemId]) {
          return prev
        }

        return prev + (isSelected ? modifierGroupDetails.modifierItems[modifierItemId].price : 0)
      }, 0)
    )
  }, 0)

  return Math.max((price + modifierTotalPrice) * count, 0)
}

export function getSelectedModifierGroupsWithDetails({selectedModifiers, modifierGroups}) {
  return Object.entries(selectedModifiers).reduce((prev, [modifierGroupId, selectedModifierItemsObj]) => {
    if (modifierGroups[modifierGroupId]) {
      prev.push({
        ...modifierGroups[modifierGroupId],
        selectedModifierItemsObj,
      })
    }
    return prev
  }, [])
}

function getStringMemorySize(_string) {
  var codePoint,
    accum = 0
  for (var stringIndex = 0, endOfString = _string.length; stringIndex < endOfString; stringIndex++) {
    codePoint = _string.charCodeAt(stringIndex)

    if (codePoint < 0x100) {
      accum += 1
      continue
    }

    if (codePoint < 0x10000) {
      accum += 1.5
      continue
    }

    if (codePoint < 0x1000000) {
      accum += 3
    } else {
      accum += 4
    }
  }

  return accum * 2
}

function getStringLength(s) {
  return Math.ceil(getStringMemorySize(s) / 2)
}

export function getRasterizedText({
  addedCharges,
  orderTimeType,
  scheduledOrderTimestamp,
  completionTime,
  createdAt,
  locationAddress,
  locationPhoneNumber,
  name,
  email,
  deliveryFee,
  deliveryAddress,
  deliveryUnit,
  deliveryInstructions,
  notes,
  orderCartItems,
  orderNumber,
  orderType,
  paymentMethod,
  paymentStatus,
  phoneNumber,
  restaurantName,
  rewards,
  subTotal,
  taxAmount,
  taxAmounts,
  tipAmount,
  total,
  timezone,
}) {
  const myMoment = (...params) => momentTZ.tz(...params, timezone || 'America/Vancouver')
  const prepareByTimestamp = orderTimeType === 'Scheduled' ? scheduledOrderTimestamp : completionTime

  function getTaxString() {
    if (taxAmounts) {
      if (isEmpty(taxAmounts)) {
        return `                  Tax${textEnd('$0.00', 21)}`
      }
      let taxString = ''
      for (const tax of Object.values(taxAmounts)) {
        taxString += `                  ${tax.name}${textEnd('$' + tax.taxAmount.toFixed(2), 18 + tax.name.length)}\n`
      }
      return taxString
    } else {
      return `                  Tax${textEnd('$' + taxAmount.toFixed(2), 21)}`
    }
  }

  return `${textCenter(restaurantName)}
${textCenter(locationAddress)}
${textCenter(locationPhoneNumber)}
--------------------------------------
${textCenter(orderType + ' Order: #' + orderNumber)}
${textCenter('Payment Method: ' + paymentMethod)}
${textCenter('Payment Status: ' + paymentStatus)}
${textCenter('Created At: ' + myMoment(createdAt).format('MM/DD/YYYY HH:mm'))}
${textCenter('Order Time Type: ' + orderTimeType)}
${textCenter(
  'Prepare By: ' +
    (prepareByTimestamp ? myMoment(prepareByTimestamp).format('MM/DD/YYYY HH:mm') : 'Not Confirmed Yet...'),
)}
--------------------------------------
${Object.values(orderCartItems).reduce((prev, cur) => {
  let productStr = ''
  const countAndProductName = `${cur.count} ${cur.name}`.substring(0, 32)
  productStr +=
    countAndProductName +
    textEnd(
      '$' +
        getCartItemTotal({
          price: cur.price,
          count: cur.count,
          selectedModifierGroupsWithDetails: cur.selectedModifierGroupsWithDetails,
        }).toFixed(2),
      getStringLength(countAndProductName),
    ) +
    '\n'

  const selectedModifierNames = getSelectedModifierNames(cur.selectedModifierGroupsWithDetails, cur.count)
  for (const modifierName of selectedModifierNames) {
    productStr += modifierName + '\n'
  }
  return prev + productStr
}, '')}${getAddedChargesNames(addedCharges).reduce((prev, addedChargeNamePrice) => {
    return prev + addedChargeNamePrice + '\n'
  }, '')}${Object.values(rewards).reduce((prev, cur) => {
    const countAndRewardName = `${cur.count} (R)${cur.name}`.substring(0, 32)
    return (
      prev +
      countAndRewardName +
      textEnd(
        cur.totalDiscountSubtotal ? '-$' + cur.totalDiscountSubtotal.toFixed(2) : 'FREE',
        getStringLength(countAndRewardName),
      ) +
      '\n'
    )
  }, '')}                              --------
                  Subtotal${textEnd('$' + subTotal.toFixed(2), 26)}
                  Delivery Fee${textEnd('$' + deliveryFee.toFixed(2), 30)}
${getTaxString()}
                  Tip${textEnd('$' + tipAmount.toFixed(2), 21)}
                              --------
                  Total${textEnd('$' + total.toFixed(2), 23)}

${textCenter(notes)}
${textCenter(`${name}, ${formatPhoneNumber(phoneNumber)}`)}
${textCenter(email || '')}
${textCenter(deliveryAddress || '')}
${deliveryUnit ? textCenter('Unit: ' + deliveryUnit) : ''}
${textCenter(deliveryInstructions || '')}

${textCenter('Thank you')}
${textCenter('Powered By Foodly.ca')}
`
}

function textCenter(text, startIndex = 0) {
  const textLength = getStringLength(text)
  // Assume UTF8
  if (textLength > 38 - startIndex) {
    return text
    // get overflow text
    // const firstText = text.substring(0, text.length - startIndex - (text.length % 38))
    // const overflow = text.substr(-(text.length % 38) - startIndex)
    //
    //
    //
    // return firstText + ' '.repeat((38 - startIndex - overflow.length) / 2) + overflow
  }
  return ' '.repeat((38 - startIndex - textLength) / 2) + text
}

function textEnd(text, startIndex = 0) {
  const textLength = getStringLength(text)
  // cannot exceed 38
  if (textLength >= 38 - startIndex) {
    return text
  }

  return ' '.repeat(38 - textLength - startIndex) + text
}

const getAddedChargesNames = (addedCharges) => {
  if (!addedCharges) {
    return []
  }
  return addedCharges.map((charge) => {
    if (!charge) {
      return ''
    }
    const addedChargeName = charge.name || 'No Name Charge'
    const addedChargePriceStr = (charge.price || 0).toFixed(2)

    const addedChargeNamePrice = addedChargeName + textEnd(addedChargePriceStr, getStringLength(addedChargeName))
    return addedChargeNamePrice
  })
}

export const getSelectedModifierNamesOnly = (selectedModifierGroupsWithDetails) => {
  const names = []
  selectedModifierGroupsWithDetails.forEach((modifierGroupDetails) => {
    Object.entries(modifierGroupDetails.selectedModifierItemsObj).forEach(([itemId, isSelected]) => {
      if (!modifierGroupDetails.modifierItems || !modifierGroupDetails.modifierItems[itemId]) {
        return
      }
      const {name: optionName, defaultValue} = modifierGroupDetails.modifierItems[itemId]
      if (defaultValue === isSelected) {
        return
      }
      const modifierName = ` ${!isSelected ? 'No ' : ''}${optionName}`
      names.push(modifierName)
    })
  })
  return names
}

const getSelectedModifierNames = (selectedModifierGroupsWithDetails, count) => {
  return selectedModifierGroupsWithDetails.reduce((prev, modifierGroupDetails) => {
    Object.entries(modifierGroupDetails.selectedModifierItemsObj).forEach(([itemId, isSelected]) => {
      if (!modifierGroupDetails.modifierItems || !modifierGroupDetails.modifierItems[itemId]) {
        return prev
      }
      const {name: optionName, price: optionPrice, defaultValue} = modifierGroupDetails.modifierItems[itemId]
      if (defaultValue === isSelected) {
        return prev
      }
      let isNegative = optionPrice < 0
      if (!isSelected) {
        isNegative = !isNegative
      }
      const modifierName = ` • ${!isSelected ? 'No ' : ''}${optionName}`
      const modifierItemPriceStr =
        optionPrice === 0 ? '' : `${isNegative ? '-' : ''}$${Math.abs(optionPrice * count).toFixed(2)}`
      const modifierNamePrice = modifierName + textEnd(modifierItemPriceStr, getStringLength(modifierName))
      prev.push(modifierNamePrice)
    })
    return prev
  }, [])
}

export const getOrderReportDetails = ({orderData}) => {
  let total = 0
  let inpersonTotal = 0
  let onlineTotal = 0
  let fPointsDiscount = 0
  let foodSubTotal = 0
  let deliveryFee = 0
  let taxAmount = 0
  let taxes = {}
  let pickupTip = 0
  let inpersonPickupTip = 0
  let courierTip = 0
  let totalCommission = 0
  let networkAccessFee = 0
  let subsidizedDeliveryFee = 0
  let foodDelayContribution = 0
  let commissionTax = 0
  let otherAdjustments = 0
  let cashSubTotal = 0
  let cashDeliveryFee = 0
  let cashTax = 0
  let onlineSubTotal = 0
  let onlineDeliveryFee = 0
  let onlineTax = 0
  let onlinePickupTip = 0
  let onlineCourierTip = 0
  let inpersonCourierTip = 0
  let stripeTransfer = 0

  const otherAdjustmentsAmount = sum(Object.values(orderData.otherAdjustments || {}))
  stripeTransfer += otherAdjustmentsAmount
  otherAdjustments += otherAdjustmentsAmount

  // Do not include any cancelled order in the data
  if (orderData.status !== 'Cancelled') {
    total += orderData.total
    foodSubTotal += orderData.subTotal
    fPointsDiscount += orderData.fPointsDiscount || 0

    totalCommission +=
      (orderData.commissionAmount || 0) +
      (orderData.restaurantDeliveryFeeSubs || 0) +
      (orderData.foodDelayContribution || 0) +
      (orderData.commissionTax || 0)
    networkAccessFee += orderData.commissionAmount || 0
    foodDelayContribution += orderData.foodDelayContribution || 0
    commissionTax += orderData.commissionTax || 0
    stripeTransfer += orderData.connectedAccountDestinationAmount

    if (orderData.paymentMethod && orderData.paymentMethod.startsWith('inperson')) {
      inpersonTotal += orderData.total
      cashSubTotal += orderData.subTotal
      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'restaurant') {
        cashDeliveryFee += orderData.deliveryFee || 0
        inpersonCourierTip += orderData.inpersonTipAmount || 0
      }
      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'foodly') {
        // deliveryFeeTax collected by foodly courier
        cashTax += orderData.subTotalTax
      } else {
        // deliveryFeeTax collected by restasurant
        cashTax += orderData.taxAmount
        inpersonPickupTip += orderData.inpersonTipAmount || 0
      }
    } else {
      onlineTotal += orderData.total
      onlineSubTotal += orderData.subTotal
      if (orderData.orderType === 'Pickup' || orderData.orderType === 'DineIn') {
        onlineTax += orderData.taxAmount
        onlinePickupTip += orderData.tipAmount || 0
      }
      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'restaurant') {
        onlineDeliveryFee += orderData.deliveryFee || 0
        onlineTax += orderData.taxAmount
        onlineCourierTip += orderData.tipAmount || 0
      }
      if (orderData.orderType === 'Delivery' && orderData.deliveryType === 'foodly') {
        onlineTax += orderData.subTotalTax
      }
    }

    if (orderData.orderType === 'Delivery') {
      if (orderData.deliveryType === 'restaurant') {
        // Restaurant does delivery
        deliveryFee += orderData.deliveryFee || 0
        taxAmount += orderData.taxAmount
        if (orderData.taxes) {
          taxes = {...orderData.taxes}
        } else {
          taxes = null
        }
        courierTip += orderData.tipAmount || 0
      } else {
        // Foodly does delivery
        taxAmount += orderData.subTotalTax
        if (orderData.subtotalTaxes) {
          taxes = {...orderData.subtotalTaxes}
        } else {
          taxes = null
        }
        subsidizedDeliveryFee += orderData.restaurantDeliveryFeeSubs || 0
      }
    } else {
      // Pickup order
      taxAmount += orderData.taxAmount
      if (orderData.taxes) {
        taxes = {...orderData.taxes}
      } else {
        taxes = null
      }
      pickupTip += orderData.tipAmount || 0
    }
  }

  const foodTotal = orderData.subTotal + orderData.subTotalTax

  const grossEarning =
    foodSubTotal + deliveryFee + taxAmount + pickupTip + courierTip + inpersonPickupTip + inpersonCourierTip
  const cashRevenue = cashSubTotal + cashDeliveryFee + cashTax + inpersonPickupTip + inpersonCourierTip
  const netDirectDeposit =
    otherAdjustments +
    onlineSubTotal +
    onlineDeliveryFee +
    onlineTax +
    onlinePickupTip +
    onlineCourierTip -
    totalCommission
  const revenue = cashRevenue + netDirectDeposit

  return {
    total: currencyRounding(total),
    inpersonTotal: currencyRounding(inpersonTotal),
    onlineTotal: currencyRounding(onlineTotal),
    fPointsDiscount: currencyRounding(fPointsDiscount),
    foodSubTotal: currencyRounding(foodSubTotal),
    foodTotal: currencyRounding(foodTotal),
    deliveryFee: currencyRounding(deliveryFee),
    taxAmount: currencyRounding(taxAmount),
    taxes,
    pickupTip: currencyRounding(pickupTip),
    inpersonPickupTip: currencyRounding(inpersonPickupTip),
    courierTip: currencyRounding(courierTip),
    totalCommission: currencyRounding(totalCommission),
    networkAccessFee: currencyRounding(networkAccessFee),
    subsidizedDeliveryFee: currencyRounding(subsidizedDeliveryFee),
    foodDelayContribution: currencyRounding(foodDelayContribution),
    commissionTax: currencyRounding(commissionTax),
    otherAdjustments: currencyRounding(otherAdjustments),
    cashSubTotal: currencyRounding(cashSubTotal),
    cashDeliveryFee: currencyRounding(cashDeliveryFee),
    cashTax: currencyRounding(cashTax),
    onlineSubTotal: currencyRounding(onlineSubTotal),
    onlineDeliveryFee: currencyRounding(onlineDeliveryFee),
    onlineTax: currencyRounding(onlineTax),
    onlinePickupTip: currencyRounding(onlinePickupTip),
    onlineCourierTip: currencyRounding(onlineCourierTip),
    inpersonCourierTip: currencyRounding(inpersonCourierTip),
    stripeTransfer: currencyRounding(stripeTransfer),
    grossEarning: currencyRounding(grossEarning),
    cashRevenue: currencyRounding(cashRevenue),
    netDirectDeposit: currencyRounding(netDirectDeposit),
    revenue: currencyRounding(revenue),
  }
}

export const getPromosWithDetails = ({
  rewards,
  products,
  orderType,
  subtotalBeforeDiscount,
  isLoggedIn,
  isFirstOrder,
  cartItems = {},
  modifierGroups = {},
  rewardIdToTimesUsed,
}) => {
  const promoWithDetails = {}

  const productIdToCount = {}

  Object.values(cartItems).forEach((item) => {
    productIdToCount[item.productId] = (productIdToCount[item.productId] || 0) + item.count
  })

  const cartItemIdToPrice = {}
  Object.entries(cartItems).forEach(([cartItemId, cartItem]) => {
    cartItemIdToPrice[cartItemId] = getCartItemTotal({
      count: 1,
      price: products[cartItem.productId] ? products[cartItem.productId].price : 0,
      selectedModifierGroupsWithDetails: getSelectedModifierGroupsWithDetails({
        selectedModifiers: cartItem.selectedModifiers || {},
        modifierGroups,
      }),
    })
  })
  Object.entries(rewards).forEach(([rewardId, rewardInfo]) => {
    let rewardDetails
    let {valid, message} = {}
    const alreadyRedeemedCount = rewardIdToTimesUsed[rewardInfo.rewardId || '']
    rewardDetails = parseRewardInfo({
      rewardInfo,
      isFirstOrder,
      cartSubTotal: subtotalBeforeDiscount,
      products,
      cartItems,
      cartItemIdToPrice,
    })
    const resp = rewardDetails
      ? getIsRewardValid({
          isLoggedIn,
          alreadyRedeemedCount,
          rewardDetails,
          isFirstOrder,
          productsDetails: products,
          cartSubTotal: subtotalBeforeDiscount,
          redeemedRewardCount: rewardDetails.count,
          userPointsWithPromoApplied: -(rewardDetails.totalRequiredPoints || 0),
          productIdToCount,
          orderType,
        })
      : {valid: false}
    valid = resp.valid
    message = resp.message
    promoWithDetails[rewardId] = {...rewardDetails, id: rewardId, valid, message}
    if (!valid) {
      promoWithDetails[rewardId].count = 0
    }
  })
  return promoWithDetails
}

export const getBeforeTaxDiscount = ({promosWithDetails, subtotalBeforeDiscount}) => {
  let totalDiscount = 0
  for (const promoData of Object.values(promosWithDetails)) {
    if (!promoData) {
      return
    }

    if (promoData.version === 1 || promoData.version === 2) {
      const subtotalDiscountAmount = (promoData.subtotalDiscountAmount || 0) * (promoData.count || 1)
      const subtotalDiscountPercent = (promoData.subtotalDiscountPercent || 0) * (promoData.count || 1)
      totalDiscount +=
        currencyRounding(subtotalDiscountPercent * subtotalBeforeDiscount) + currencyRounding(subtotalDiscountAmount)
    }
    if (promoData.version === 3) {
      totalDiscount += promoData.totalDiscountSubtotal
    }
  }
  return currencyRounding(totalDiscount)
}

export const getAfterTaxDiscount = ({subtotalBeforeDiscount, fRewardId}) => {
  const {totalDiscountAmount} = parseFReward({fRewardId, subtotal: subtotalBeforeDiscount})
  return currencyRounding(totalDiscountAmount)
}

export function getAddedChargeTaxes({amount, publicTaxes, taxIds}) {
  const getTaxData = (taxId) => {
    return (publicTaxes && taxId && publicTaxes[taxId]) || null
  }
  const taxes = {}
  for (const taxId of taxIds) {
    const taxData = getTaxData(taxId)
    if (taxData) {
      if (!taxes[taxId]) {
        taxes[taxId] = {...taxData, subtotal: 0, taxAmount: 0}
      }
      taxes[taxId].subtotal += amount
    }
  }
  for (const taxData of Object.values(taxes)) {
    taxData.subtotal = currencyRounding(taxData.subtotal)
    taxData.taxAmount = currencyRounding(taxData.subtotal * taxData.rate)
  }

  return taxes
}

export const getCartTaxes = ({
  products,
  cartItems,
  beforeTaxDiscountAmount,
  orderType,
  deliveryFee,
  deliveryFeeTaxType,
  publicTaxes,
  modifierGroups = {},
}) => {
  const getTaxData = (taxId) => {
    return (publicTaxes && taxId && publicTaxes[taxId]) || null
  }

  const productsWithDetails = Object.entries(cartItems).reduce((prev, [cartItemId, cartItem]) => {
    prev[cartItemId] = {
      ...cartItems[cartItemId],
      ...products[cartItem.productId],
    }
    return prev
  }, {})

  const taxes = {}

  let subtotalBeforeDeliveryFee = 0
  for (const cartItemWithProductDetails of Object.values(productsWithDetails)) {
    const {count, selectedModifiers = {}, price, taxIds = ['cad_gst']} = cartItemWithProductDetails
    const selectedModifierGroupsWithDetails = getSelectedModifierGroupsWithDetails({selectedModifiers, modifierGroups})
    const cartItemPrice = getCartItemTotal({selectedModifierGroupsWithDetails, count, price})
    for (const taxId of taxIds) {
      const taxData = getTaxData(taxId)
      if (!taxes[taxId]) {
        if (taxData) {
          taxes[taxId] = {...taxData, subtotal: 0, taxAmount: 0}
        }
      }
      taxes[taxId].subtotal += cartItemPrice
    }
    subtotalBeforeDeliveryFee += cartItemPrice
  }

  if (beforeTaxDiscountAmount > 0) {
    for (const taxData of Object.values(taxes)) {
      if (taxData.subtotal >= beforeTaxDiscountAmount) {
        taxData.subtotal -= beforeTaxDiscountAmount
        subtotalBeforeDeliveryFee -= beforeTaxDiscountAmount
      } else {
        taxData.subtotal = 0
      }
    }
  }

  if (orderType === 'Delivery') {
    if (deliveryFee > 0) {
      // deliveryFeeTaxType can be either 'prorated', taxId, or array of taxIds
      // Check if deliveryFee should be taxed equally distributed based on currently applied taxes or a specific tax.
      if (subtotalBeforeDeliveryFee && (deliveryFeeTaxType === 'prorated' || !deliveryFeeTaxType)) {
        for (const taxData of Object.values(taxes)) {
          const ratio = taxData.subtotal / subtotalBeforeDeliveryFee
          taxData.subtotal += ratio * deliveryFee
        }
      } else {
        if (typeof deliveryFeeTaxType === 'string') {
          const taxData = getTaxData(deliveryFeeTaxType)
          if (taxData) {
            if (!taxes[deliveryFeeTaxType]) {
              taxes[deliveryFeeTaxType] = {...taxData, subtotal: 0, taxAmount: 0}
            }
            taxes[deliveryFeeTaxType].subtotal += deliveryFee
          }
        } else {
          for (const taxId of deliveryFeeTaxType) {
            const taxData = getTaxData(taxId)
            if (taxData) {
              if (!taxes[taxId]) {
                taxes[taxId] = {...taxData, subtotal: 0, taxAmount: 0}
              }
              taxes[taxId].subtotal += deliveryFee
            }
          }
        }
      }
    }
  }

  for (const taxData of Object.values(taxes)) {
    taxData.subtotal = currencyRounding(taxData.subtotal)
    taxData.taxAmount = currencyRounding(taxData.subtotal * taxData.rate)
  }

  return taxes
}

export const getCartTax = ({taxes}) => {
  let totalTax = 0
  for (const tax of Object.values(taxes)) {
    totalTax += tax.taxAmount
  }
  return currencyRounding(totalTax)
}

export function getOrderCartItems({cartItems, products, modifierGroups = {}}) {
  return Object.entries(cartItems).reduce((prev, [cartItemId, cartItem]) => {
    const count = cartItem.count
    const selectedModifiers = cartItem.selectedModifiers || {}
    if (!products[cartItem.productId]) {
      return prev
    }
    if (products[cartItem.productId].activeAfter && products[cartItem.productId].activeAfter > moment().valueOf()) {
      return prev
    }
    prev[cartItemId] = {
      ...products[cartItem.productId],
      productId: cartItem.productId,
      count,
      notes: cartItem.notes || '',
      selectedModifierGroupsWithDetails: Object.entries(selectedModifiers).reduce(
        (_prev, [modifierGroupId, selectedModifierItemsObj]) => {
          if (!modifierGroups[modifierGroupId]) {
            return _prev
          }
          _prev.push({
            id: modifierGroupId,
            ...modifierGroups[modifierGroupId],
            selectedModifierItemsObj,
          })
          return _prev
        },
        [],
      ),
    }
    return prev
  }, {})
}

export function getSubtotalBeforeDiscount({orderCartItems}) {
  if (!orderCartItems) {
    return 0
  }
  let subtotalBeforeDiscount = 0

  for (const cartItemWithProductDetails of Object.values(orderCartItems)) {
    let modifierTotalPrice = 0
    if (cartItemWithProductDetails.selectedModifierGroupsWithDetails) {
      cartItemWithProductDetails.selectedModifierGroupsWithDetails.forEach((modifierGroupDetails) => {
        if (modifierGroupDetails) {
          for (const [modifierItemId, isSelected] of Object.entries(modifierGroupDetails.selectedModifierItemsObj)) {
            if (modifierGroupDetails.modifierItems[modifierItemId] && isSelected) {
              modifierTotalPrice += modifierGroupDetails.modifierItems[modifierItemId].price
            }
          }
        }
      })
    }

    subtotalBeforeDiscount += Math.max(
      (cartItemWithProductDetails.price + modifierTotalPrice) * cartItemWithProductDetails.count,
      0,
    )
  }
  return currencyRounding(subtotalBeforeDiscount)
}

export function getOrderCartItemsWithTaxes({cartItems, products, modifierGroups, publicTaxes}) {
  const orderCartItems = getOrderCartItems({cartItems, products, modifierGroups})

  for (const cartItemWithProductDetails of Object.values(orderCartItems)) {
    let modifierTotalPrice = 0
    if (cartItemWithProductDetails.selectedModifierGroupsWithDetails) {
      cartItemWithProductDetails.selectedModifierGroupsWithDetails.forEach((modifierGroupDetails) => {
        if (modifierGroupDetails) {
          for (const [modifierItemId, isSelected] of Object.entries(modifierGroupDetails.selectedModifierItemsObj)) {
            if (modifierGroupDetails.modifierItems[modifierItemId] && isSelected) {
              modifierTotalPrice += modifierGroupDetails.modifierItems[modifierItemId].price
            }
          }
        }
      })
    }

    const cartItemSubtotal = Math.max(
      (cartItemWithProductDetails.price + modifierTotalPrice) * cartItemWithProductDetails.count,
      0,
    )

    const taxes = {}
    const taxIds = get(cartItemWithProductDetails, 'taxIds', ['cad_gst'])
    for (const taxId of taxIds) {
      const rate = publicTaxes[taxId].rate
      taxes[taxId] = {
        ...publicTaxes[taxId],
        taxAmount: currencyRounding(cartItemSubtotal * rate),
        subtotal: cartItemSubtotal,
      }
    }

    cartItemWithProductDetails.taxes = taxes
  }
  return orderCartItems
}

export function getProductIdToDiscount(products, rewards, rewardIdToTimesUsed = {}) {
  const productIdToDiscount = {}
  Object.values(rewards || {}).forEach((rewardItem) => {
    if (
      !rewardItem.productIds ||
      rewardItem.discountType === 'free' ||
      rewardItem.count >= rewardItem.qtyLimit ||
      rewardItem.count >= rewardItem.qty ||
      rewardItem.count + (rewardIdToTimesUsed[rewardItem.rewardId] || 0) >= rewardItem.qtyLimitPerUser
    ) {
      return
    }
    Object.keys(rewardItem.productIds).forEach((productId) => {
      let discountAmount = 0
      const productPrice = (products[productId] && products[productId].price) || 0
      if (rewardItem.discountType === 'flat') {
        discountAmount = rewardItem.discountValue
      }
      if (rewardItem.discountType === 'percent') {
        discountAmount = rewardItem.discountValue * productPrice
      }
      discountAmount = Math.min(discountAmount, productPrice)
      productIdToDiscount[productId] = (productIdToDiscount[productId] || 0) + discountAmount
    })
  })
  return productIdToDiscount
}
