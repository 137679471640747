import {getDocument} from 'f-core/src/config/firebase'

const DEFAULT_STATE = {
  publicLoaded: false,
  promotions: [],
  taxes: {},
  supportOptions: [],
}

const publicModel = {
  state: DEFAULT_STATE,
  reducers: {
    setPublicData: (state, publicData) => {
      return {...publicData, publicLoaded: true}
    },
    setPublicLoaded: (state, publicLoaded) => {
      return {...state, publicLoaded: publicLoaded}
    },
  },
  actions: ({dispatch, getState}) => ({
    getPublicTaxes() {
      return getState().public.taxes
    },
    getTaxData(taxId) {
      return getState().public.taxes?.[taxId] ?? null
    },
    getSupportNumber() {
      return getState().public.supportNumber || '7787262345'
    },
    getIsPublicLoaded() {
      return getState().public.publicLoaded
    },
    getPublicPromotionsV2() {
      return getState().public.promotionsV2 ?? []
    },
    getPublicPromotions() {
      return getState().public.promotions ?? []
    },
    getPublicTagGroups() {
      return getState().public.tasteTags ?? []
    },
    getPublicProductTags() {
      return getState().public.productTags ?? {}
    },
    getPublicSupportOptions() {
      return getState().public.supportOptions ?? []
    },
    subscribePublic() {
      dispatch.public.setPublicLoaded(false)
      return getDocument('public').onSnapshot((snapshot) => {
        dispatch.public.setPublicData(snapshot.data())
      })
    },
  }),
}

export default publicModel
