import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Check, AttachMoney, Add} from '@material-ui/icons'
import {FView, FText, FButton} from 'components'

import PaymentItem from '../Account/PaymentItem'
import AddPaymentDialog from '../Account/AddPaymentDialog'

export default function PaymentInformation({directOrder}) {
  const dispatch = useDispatch()

  const [showAddPayment, setShowAddPayment] = useState(false)

  const cartLocationId = useSelector(dispatch.user.getCartLocationId)
  const orderType = useSelector(dispatch.user.getOrderType)

  const paymentMethod = useSelector(dispatch.user.getPaymentMethod)
  const payments = useSelector(dispatch.user.getPayments)
  const selectedPaymentIndex = useSelector(dispatch.user.getSelectedPaymentIndex)

  const isOnlineCCEnabled = useSelector(() => dispatch.restaurants.getIsOnlineCCEnabled({locationId: cartLocationId}))
  const isInpersonCashEnabled = useSelector(() =>
    dispatch.restaurants.getIsInpersonCashEnabled({locationId: cartLocationId}),
  )
  const isCashDeliveryEnabled = useSelector(() =>
    dispatch.restaurants.getIsCashDeliveryEnabled({locationId: cartLocationId}),
  )
  let isCashEnabled = false
  if (orderType === 'Delivery') {
    isCashEnabled = isCashDeliveryEnabled && isInpersonCashEnabled
  } else {
    isCashEnabled = isInpersonCashEnabled
  }

  useEffect(() => {
    // If paymentMethod is selected as disabled method, deselect the paymentMethod
    if (!isOnlineCCEnabled && !isInpersonCashEnabled) {
      dispatch.user.setPaymentMethod({paymentMethod: null})
    } else if (!isOnlineCCEnabled && paymentMethod === 'online-creditcard') {
      dispatch.user.setPaymentMethod({paymentMethod: null})
    } else if (!isInpersonCashEnabled && paymentMethod === 'inperson-cash') {
      dispatch.user.setPaymentMethod({paymentMethod: null})
    } else if (
      orderType === 'Delivery' &&
      (!isCashDeliveryEnabled || !isInpersonCashEnabled) &&
      paymentMethod === 'inperson-cash'
    ) {
      dispatch.user.setPaymentMethod({paymentMethod: null})
    }
  }, [isOnlineCCEnabled, isInpersonCashEnabled, paymentMethod, dispatch.user, orderType, isCashDeliveryEnabled])

  return (
    <FView>
      {isOnlineCCEnabled && (
        <FView>
          <FView>
            <FText bold>Pay Online</FText>
          </FView>
          <FView fill>
            {payments.map((payment, index) => {
              return (
                <PaymentItem
                  key={index}
                  index={index}
                  isSelected={paymentMethod === 'online-creditcard' && selectedPaymentIndex === index}
                  setExpandedIndex={(i) => {}}
                  last4={payment.maskedPan.slice(-4)}
                  expdate={payment.expdate}
                  onPress={() => {
                    dispatch.user.setPaymentMethod({paymentMethod: 'online-creditcard'})
                    dispatch.user.setSelectedPaymentIndex(index)
                  }}
                />
              )
            })}
            <FView>
              <FButton onClick={() => setShowAddPayment(true)}>
                <FView row>
                  <Add />
                  <FView w={4} />
                  <FText bold>Add Credit/Debit Card</FText>
                </FView>
              </FButton>
            </FView>
          </FView>
        </FView>
      )}
      {isCashEnabled && (
        <FView mt={32}>
          <FView mb={8}>
            <FText bold>Pay In-Person</FText>
          </FView>
          <FView fill>
            <FButton
              onClick={() => {
                dispatch.user.setPaymentMethod({paymentMethod: 'inperson-cash'})
              }}>
              <FView row justifyBetween>
                <FView row alignCenter>
                  <FText grey700={paymentMethod !== 'inperson-cash'} primary noLineHeight>
                    <AttachMoney />
                  </FText>
                  <FView size={5} />
                  <FText body1 grey700={paymentMethod !== 'inperson-cash'} primary>
                    Cash
                  </FText>
                </FView>
                <FView row justifyRight alignCenter size={24} mr={16}>
                  {paymentMethod === 'inperson-cash' && <Check color="primary" />}
                </FView>
              </FView>
            </FButton>
          </FView>
        </FView>
      )}

      <AddPaymentDialog open={showAddPayment} handleClose={() => setShowAddPayment(false)} />
    </FView>
  )
}
