import React, {useState, useRef, useEffect} from 'react'
import {css} from 'emotion'
import {colors} from 'styles'
import {useDispatch} from 'react-redux'
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete'
import {Cancel, RadioButtonUnchecked, RadioButtonChecked, Home} from '@material-ui/icons'
import {Divider, DialogTitle, DialogActions, DialogContent, useTheme} from '@material-ui/core'
import {FView, FButton, FText, FInput, ButtonFillView, FDialog} from 'components'

export default function DeliveryModal({selectedAddressIndex, isOpen, onRequestClose, editMode = false}) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const inputRef = useRef()
  const deliveryInstructionsRef = React.createRef()
  const aptSuiteRef = React.createRef()
  const [address, setAddress] = useState('')
  const [updating, setUpdating] = useState(false)
  const addresses = dispatch.user.getAddresses()

  // GENERATED DATA
  const addressData = useRef(addresses?.[selectedAddressIndex] ?? null)

  // INITIAL FROM REDUX
  const defaultFormattedAddress = addresses?.[selectedAddressIndex]?.containerDeliveryAddress ?? ''
  const defaultContactlessDeliveryType = addresses?.[selectedAddressIndex]?.contactlessDeliveryType ?? null
  const defaultContactlessDeliveryLocation = addresses?.[selectedAddressIndex]?.contactlessDeliveryLocation ?? ''
  const defaultDeliveryAddressLatLng = addresses?.[selectedAddressIndex]?.containerDeliveryAddressLatLng ?? {
    lat: 0,
    lng: 0,
  }
  const defaultDeliveryUnit = addresses?.[selectedAddressIndex]?.containerDeliveryUnit ?? ''
  const defaultDeliveryInstructions = addresses?.[selectedAddressIndex]?.containerDeliveryInstructions ?? ''
  const [isAddressSelected, setIsAddressSelected] = useState(!!defaultFormattedAddress)

  // TEMPORARY FORM STATES
  const [formattedAddress, setFormattedAddress] = useState(defaultFormattedAddress)
  const [deliveryAddressLatLng, setDeliveryAddressLatLng] = useState(defaultDeliveryAddressLatLng)
  const [deliveryUnit, setDeliveryUnit] = useState(defaultDeliveryUnit)
  const [contactlessDeliveryType, setContactlessDeliveryType] = useState(defaultContactlessDeliveryType)
  const [contactlessDeliveryLocation, setContactlessDeliveryLocation] = useState(defaultContactlessDeliveryLocation)
  const [deliveryInstructions, setDeliveryInstructions] = useState(defaultDeliveryInstructions)

  useEffect(() => {
    if (selectedAddressIndex === null) {
      setAddress('')
      setIsAddressSelected(false)
      setFormattedAddress('')
      setDeliveryAddressLatLng({lat: 0, lng: 0})
      setDeliveryUnit('')
      setDeliveryInstructions('')
    } else {
      const defaultFormattedAddress = addresses?.[selectedAddressIndex]?.containerDeliveryAddress ?? ''
      const defaultDeliveryAddressLatLng = addresses?.[selectedAddressIndex]?.containerDeliveryAddressLatLng ?? {
        lat: 0,
        lng: 0,
      }
      const defaultDeliveryUnit = addresses?.[selectedAddressIndex]?.containerDeliveryUnit ?? ''
      const defaultDeliveryInstructions = addresses?.[selectedAddressIndex]?.containerDeliveryInstructions ?? ''

      setIsAddressSelected(!!defaultFormattedAddress)
      setFormattedAddress(defaultFormattedAddress)
      setDeliveryAddressLatLng(defaultDeliveryAddressLatLng)
      setDeliveryUnit(defaultDeliveryUnit)
      setDeliveryInstructions(defaultDeliveryInstructions)
    }
  }, [selectedAddressIndex, addresses])

  return (
    <FDialog
      disableScrollLock={true}
      open={isOpen}
      onClose={() => {
        if (updating) {
          return
        }
        onRequestClose()
      }}>
      <DialogTitle>
        <FText h4 bold>
          {editMode ? 'Edit' : 'Enter'} Address
        </FText>
      </DialogTitle>
      <DialogContent>
        <FView h={'90vh'}>
          {!editMode && (
            <PlacesAutocomplete
              value={address}
              onChange={(address) => {
                setAddress(address)
                setIsAddressSelected(false)
              }}
              onSelect={(address, ...rest) => {
                if (address) {
                  geocodeByAddress(address).then(async (results) => {
                    try {
                      const {formatted_address, types, address_components} = results[0]

                      if (types.includes('route')) {
                        throw new Error(
                          'Invalid Address, we can only deliver to a specific street address. For example: 1234 Friendly Street, Coquitlam.',
                        )
                      }
                      const addressComponents = {
                        street_number: null,
                        route: null, // Street Name
                        locality: null, // City
                        administrative_area_level_1: null, // Province
                        country: null,
                        postal_code: null,
                      }

                      for (const component of address_components) {
                        for (const type of component.types) {
                          if (addressComponents.hasOwnProperty(type)) {
                            addressComponents[type] = component.short_name
                            break
                          }
                        }
                      }
                      if (addressComponents.postal_code?.length < 4) {
                        throw new Error(
                          'Invalid Address, we can only deliver to a specific street address. For example: 1234 Friendly Street, Coquitlam.',
                        )
                      }

                      const name = `${addressComponents.street_number} ${addressComponents.route}`

                      addressData.current = {
                        ...(addressData.current ?? {}),
                        city: addressComponents.locality,
                        country: addressComponents.country,
                        name,
                        postalCode: addressComponents.postal_code,
                        region: addressComponents.administrative_area_level_1,
                        streetName: addressComponents.route,
                        streetNumber: addressComponents.street_number,
                      }

                      const {lat, lng} = await getLatLng(results[0])

                      setAddress(name)
                      setIsAddressSelected(true)
                      setFormattedAddress(formatted_address)
                      setDeliveryAddressLatLng({lat, lng})
                      setDeliveryUnit('')
                      setDeliveryInstructions('')

                      inputRef.current && inputRef.current.blur()
                    } catch (e) {
                      alert(e)
                    }
                  })
                }
              }}
              searchOptions={{
                componentRestrictions: {country: ['ca']},
                types: ['address'],
              }}>
              {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <div className={classes.container}>
                  <FView fill br={4} bg={theme.palette.grey[100]} row alignCenter>
                    <FView w={8} />

                    <FView size={24} center>
                      <Home />
                    </FView>
                    <FView fill>
                      <FInput
                        ref={inputRef}
                        autoFocus
                        className={classes.addressInput}
                        {...getInputProps()}
                        placeholder="1234 Foodly Street, Coquitlam"
                        onKeyPress={(e) => {
                          e.target.keyCode === 13 && e.preventDefault()
                        }}
                      />
                    </FView>
                    {!!address && (
                      <FButton
                        onClick={() => {
                          setAddress('')
                          setIsAddressSelected(false)
                          setFormattedAddress('')
                          setDeliveryAddressLatLng({lat: 0, lng: 0})
                          setDeliveryUnit('')
                          setDeliveryInstructions('')
                        }}>
                        <FView size={48} center>
                          <Cancel color={'action'} />
                        </FView>
                      </FButton>
                    )}
                  </FView>
                  <FView h={16} />

                  {suggestions.map((suggestion) => (
                    <div className={classes.placeItem} {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                      <FView pv={8} ph={40}>
                        <FText body1 bold>
                          {suggestion.formattedSuggestion.mainText}
                        </FText>
                        <FText body2 bold color={theme.palette.grey[500]}>
                          {suggestion.formattedSuggestion.secondaryText}
                        </FText>
                      </FView>
                    </div>
                  ))}
                </div>
              )}
            </PlacesAutocomplete>
          )}
          <FView fill overflowY="auto">
            <FView>
              {isAddressSelected && (
                <>
                  <FView bg={colors.white} pt={16}>
                    <FText body1 bold grey900>
                      {formattedAddress}
                    </FText>
                  </FView>
                  <FView bg={colors.white}>
                    <FView mt={15} mb={6}>
                      <FText bold caption>
                        Unit/Apt/Suite:
                      </FText>
                    </FView>
                    <FView w="100%">
                      <FInput
                        ref={aptSuiteRef}
                        value={deliveryUnit}
                        onChange={(e) => setDeliveryUnit(e.target.value)}
                        placeholder="Optional"
                        maxLength={10}
                        className={classes.textInput}
                      />
                    </FView>
                    <FView size={15} />
                  </FView>

                  <FView bg={colors.white}>
                    <FButton hoverHighlight onClick={() => setContactlessDeliveryType(null)}>
                      <FView w="100%" h={60} row alignCenter justifyBetween>
                        <FText body1 bold grey900={!!contactlessDeliveryType} primary>
                          Deliver to my door
                        </FText>
                        {contactlessDeliveryType ? (
                          <RadioButtonUnchecked color={'action'} />
                        ) : (
                          <RadioButtonChecked color={'primary'} />
                        )}
                      </FView>
                    </FButton>
                    <Divider />
                    <FButton hoverHighlight onClick={() => setContactlessDeliveryType('MEET_COURIER_OUTSIDE')}>
                      <FView w="100%" h={60} row alignCenter justifyBetween>
                        <FText body1 bold grey900={contactlessDeliveryType !== 'MEET_COURIER_OUTSIDE'} primary>
                          Meet courier outside
                        </FText>
                        {contactlessDeliveryType === 'MEET_COURIER_OUTSIDE' ? (
                          <RadioButtonChecked color={'primary'} />
                        ) : (
                          <RadioButtonUnchecked color={'action'} />
                        )}
                      </FView>
                    </FButton>
                    <Divider />
                    <FButton hoverHighlight onClick={() => setContactlessDeliveryType('LEAVE_AT_LOCATION')}>
                      <FView w="100%" h={60} row alignCenter justifyBetween>
                        <FText body1 bold grey900={contactlessDeliveryType !== 'LEAVE_AT_LOCATION'} primary>
                          Leave at specified location
                        </FText>
                        {contactlessDeliveryType === 'LEAVE_AT_LOCATION' ? (
                          <RadioButtonChecked color={'primary'} />
                        ) : (
                          <RadioButtonUnchecked color={'action'} />
                        )}
                      </FView>
                    </FButton>
                  </FView>
                  {contactlessDeliveryType === 'LEAVE_AT_LOCATION' && (
                    <FView mb={15} bg={colors.white}>
                      <FView mb={6}>
                        <FText bold caption>
                          Location:
                        </FText>
                      </FView>
                      <FView w="100%" maxWidth={345}>
                        <FInput
                          value={contactlessDeliveryLocation}
                          onChange={(e) => setContactlessDeliveryLocation(e.target.value)}
                          placeholder="Required"
                          maxLength={50}
                          className={classes.textInput}
                        />
                      </FView>
                    </FView>
                  )}
                  <FView mb={25} bg={colors.white}>
                    <FView mt={15} mb={6}>
                      <FText bold>Instructions:</FText>
                    </FView>
                    <FView w="100%">
                      <FInput
                        ref={deliveryInstructionsRef}
                        value={deliveryInstructions}
                        onChange={(e) => setDeliveryInstructions(e.target.value)}
                        placeholder="Optional"
                        maxLength={100}
                        className={classes.textInput}
                      />
                    </FView>
                  </FView>
                </>
              )}
            </FView>
          </FView>
        </FView>
      </DialogContent>
      <DialogActions>
        {!isAddressSelected ? (
          <FButton fill onClick={() => onRequestClose()}>
            <ButtonFillView round>
              <FText bold body1 primaryContrast>
                Cancel
              </FText>
            </ButtonFillView>
          </FButton>
        ) : (
          <FView row alignCenter fill>
            {editMode && (
              <>
                <FButton
                  fill
                  onClick={() => {
                    dispatch.user.deleteAdddressAtIndex(selectedAddressIndex)
                    onRequestClose()
                  }}>
                  <FView bc={colors.primary} bw={1} p={16} round center>
                    <FText bold body1 primary>
                      Delete
                    </FText>
                  </FView>
                </FButton>
                <FView w={16} />
              </>
            )}

            <FButton
              fill
              onClick={() => {
                if (updating) {
                  return
                }
                if (isAddressSelected) {
                  if (contactlessDeliveryType === 'LEAVE_AT_LOCATION' && !contactlessDeliveryLocation) {
                    alert('You must enter a location the courier should leave your food at.')
                    return
                  }
                  setUpdating(true)
                  dispatch.user.setUserLocation({
                    latitude: deliveryAddressLatLng.lat,
                    longitude: deliveryAddressLatLng.lng,
                    city: null,
                    region: null,
                    country: null,
                  })

                  if (selectedAddressIndex != null) {
                    dispatch.user.setSelectedAddressIndex(selectedAddressIndex)
                    dispatch.user
                      .updateAddress(
                        {
                          containerDeliveryAddress: formattedAddress,
                          containerDeliveryAddressLatLng: deliveryAddressLatLng,
                          containerDeliveryUnit: aptSuiteRef.current.value,
                          contactlessDeliveryType,
                          contactlessDeliveryLocation,
                          containerDeliveryInstructions: deliveryInstructionsRef.current.value,
                        },
                        selectedAddressIndex,
                      )
                      .then(() => {
                        onRequestClose()
                      })
                      .catch((e) => {
                        alert(e.message)
                      })
                      .finally(() => {
                        setUpdating(false)
                      })
                  } else {
                    const {streetNumber, streetName, city, region, country, postalCode, name} = addressData.current

                    dispatch.user
                      .addAddress({
                        name,
                        streetNumber,
                        streetName,
                        city,
                        region,
                        country,
                        postalCode,
                        containerDeliveryAddress: formattedAddress,
                        containerDeliveryAddressLatLng: deliveryAddressLatLng,
                        containerDeliveryInstructions: deliveryInstructionsRef.current.value,
                        containerDeliveryUnit: aptSuiteRef.current.value,
                        contactlessDeliveryType,
                        contactlessDeliveryLocation,
                      })
                      .then((addressIndex) => {
                        dispatch.user.setSelectedAddressIndex(addressIndex)
                        setAddress('')
                        setIsAddressSelected(false)
                        setFormattedAddress('')
                        setDeliveryAddressLatLng({lat: 0, lng: 0})
                        setDeliveryUnit('')
                        setDeliveryInstructions('')
                        onRequestClose()
                      })
                      .catch((e) => {
                        alert(e.message)
                      })
                      .finally(() => {
                        setUpdating(false)
                      })
                  }
                }
              }}>
              <ButtonFillView round>
                <FText white bold>
                  Save
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        )}
      </DialogActions>
    </FDialog>
  )
}

const classes = {
  placeItem: css({cursor: 'pointer'}),
  addressInput: css({height: 48, background: 'transparent'}),
  textInput: css({
    boxShadow: 'none',
    border: '1px solid ' + colors.grey50,
    height: '4rem',
  }),
}
