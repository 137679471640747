import React, {useState, useEffect} from 'react'
import {FText, FView, FButton, FDialog} from 'components'
import {ShoppingCartOutlined} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'
import Cart from './Cart'
import {useFoodlyAppCart} from 'f-app-models/hooks'

import {useParams} from 'react-router-dom'

const useStylesIcon = makeStyles({
  root: {
    width: 30,
    height: 30,
    color: 'white',
  },
})

export default function FloatingCart({directOrder}) {
  const dispatch = useDispatch()
  const iconClasses = useStylesIcon()
  const {locationId} = useParams()
  const {locationId: cartLocationId} = useFoodlyAppCart()

  const [cartOpen, setCartOpen] = useState(false)
  const isCartEmpty = useSelector(dispatch.user.getIsCartEmpty)
  const cartCount = useSelector(dispatch.user.getCartCount)

  const handleClose = () => setCartOpen(false)
  useEffect(() => {
    if (isCartEmpty) {
      setCartOpen(false)
    }
  }, [isCartEmpty])

  return (
    !isCartEmpty &&
    cartLocationId === locationId && (
      <FView fixed bottom={24} right={24} zIndex={666}>
        <FButton onClick={() => setCartOpen(true)}>
          <FView br="50%" bg="green" size={60} center relative>
            <FView absolute top={8} right={8} bg="white" br="50%" size={22} center>
              <FText color="green" bold>
                {cartCount}
              </FText>
            </FView>
            <ShoppingCartOutlined classes={iconClasses} color="secondary" />
          </FView>
        </FButton>
        <FDialog open={cartOpen} onClose={handleClose}>
          <FView p={8} pt={24}>
            <Cart directOrder={directOrder} />
          </FView>
        </FDialog>
      </FView>
    )
  )
}
