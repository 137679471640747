import React, {useEffect} from 'react'
import * as images from 'images'
import {useMediaMinMD, useMediaMinLG} from 'f-web/src/hooks'
import {FText, FView, ButtonFillView, NavBarV2, Footer, FButton} from 'components'
import {Divider} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import * as deliverImages from './images'
import HubspotForm from 'react-hubspot-form'

export default function DeliverWithUs() {
  const theme = useTheme()
  const isMinMD = useMediaMinMD()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <FView>
      <NavBarV2 />
      <FView center h={isMinMD ? 'calc(100vh - 80px)' : 250}>
        <img
          src={images.courierHeroBG}
          style={{position: 'fixed', width: '100vw', objectFit: 'cover', zIndex: -1}}
          alt={'courierHeroBG'}
        />
        <FView row justifyCenter>
          <FView column center w={isMinMD ? '40%' : '80%'}>
            <FText bold h3 gutterBottom alignCenter={!isMinMD}>
              Deliver on your own schedule.
            </FText>
            <FText h6 alignCenter={!isMinMD}>
              Get some extra money by deliverying with Foodly, all on your own schedule.
            </FText>
            <FView h={isMinMD ? 50 : 0} />
          </FView>
          {isMinMD && <SignUpFormView />}
        </FView>
      </FView>
      <FView zIndex={1} bg={theme.palette.common.white}>
        {!isMinMD && <SignUpFormView />}
        <Section title="Why Deliver with Foodly?">
          <ImageCard
            src={deliverImages.why1}
            title="Earn extra money"
            description="Earn money for each delivery completed and get paid weekly."
          />
          <ImageCard
            src={deliverImages.why2}
            title="Your own schedule"
            description="Deliver on your own schedule, whenever you want."
          />
          <ImageCard
            src={deliverImages.why3}
            title="Easy to use"
            description="Use the Foodly Riders app to complete deliveries and track your earnings."
          />
        </Section>

        <FView size={isMinMD ? 50 : 25} />
        <FView bg={theme.palette.primary.main} pv={isMinMD ? 50 : 25} alignCenter>
          <FView row={isMinMD} maxWidth={1000} alignCenter ph={24}>
            <FView>
              <FText h4 bold primaryContrast gutterBottom alignCenter={!isMinMD}>
                Foodly lets you be your own boss
              </FText>
              <FText h6 primaryContrast alignCenter={!isMinMD}>
                Delivering with Foodly is very easy and convenient. After completing the application process, you’ll be
                able to deliver whenever you want by logging in on the Riders app and be notified of available
                deliveries in your area.
              </FText>
            </FView>
            <FView size={25} />
            <img src={deliverImages.ownBoss} alt="be your own boss" />
          </FView>
        </FView>

        <Section title="How It Works">
          <ImageCard
            src={deliverImages.how1}
            title="Browse Orders"
            description="Browse delivery orders available in your area and accept the ones that you like to be assigned to them."
          />
          <ImageCard
            src={deliverImages.how2}
            title="Complete Delivery"
            description="Receive instructions and notifications assisting you in successfully completing your delivery."
          />
          <ImageCard
            src={deliverImages.how3}
            title="Track Earnings"
            description="Track your earnings directly in the Riders app. Your earnings are deposited automatically every week."
          />
        </Section>

        <FView size={isMinMD ? 50 : 25} />
        <Divider />

        <Section title="Requirements">
          <img src={deliverImages.requirements} alt="requirements" />
          <FView size={25} />
          <FView selfCenter>
            <FText h5 bold gutterBottom>
              You will need:
            </FText>
            <FView size={15} />
            <FText body1>• Be at least 19 years old</FText>
            <FText body1>• A valid driver license</FText>
            <FText body1>• Proof of Canadian work eligibility</FText>
          </FView>
        </Section>
        <FView size={isMinMD ? 50 : 25} />
        <Divider />
        <FView size={isMinMD ? 50 : 25} />
        <FView alignCenter>
          <FText h3 bold>
            Get Started
          </FText>
          <FView size={15} />
          <FButton
            onClick={() => {
              window.scrollTo(0, 0)
            }}>
            <ButtonFillView rounded w={250}>
              <FText body1 bold primaryContrast>
                Apply Now
              </FText>
            </ButtonFillView>
          </FButton>
        </FView>
        <FView size={isMinMD ? 50 : 25} />
        <Divider />
        <FView size={isMinMD ? 50 : 25} />
        <Footer />
      </FView>
    </FView>
  )
}

function Section({title, children}) {
  const isMinMD = useMediaMinMD()
  return (
    <>
      <FView size={isMinMD ? 50 : 25} />
      <FText h3 bold alignCenter>
        {title}
      </FText>
      <FView size={15} />
      <FView row={isMinMD} selfCenter>
        {children}
      </FView>
    </>
  )
}

function ImageCard({src, title, description}) {
  return (
    <FView p={10} alignCenter maxWidth={325}>
      <img src={src} alt={title} />
      <FView size={15} />
      <FText h5 bold gutterBottom alignCenter>
        {title}
      </FText>
      <FView size={15} />
      <FText body1 alignCenter>
        {description}
      </FText>
    </FView>
  )
}

function SignUpFormView() {
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  return (
    <FView
      rounded
      bg={'rgba(255,255,255,0.75)'}
      w={isMinMD ? '38%' : '100%'}
      pt={25}
      ph={25}
      maxWidth={450}
      ml={isMinMD ? 25 : 15}
      mt={isMinMD ? 50 : 25}
      mr={isMinMD ? 0 : 15}
      relative
      selfCenter={!isMinLG}>
      <HubspotForm
        region="na1"
        portalId="9318101"
        formId="9e274de0-ef14-4d1e-b800-e30edd9ab282"
        // onSubmit={() => console.log('Submit!')}
        // onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading Form...</div>}
      />
    </FView>
  )
}
