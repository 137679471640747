import React, {useState, useEffect} from 'react'
import {FView} from 'components'
import * as images from 'images/orderStatus'
import {css} from 'emotion'

export default function OrderStatusView({status, orderType, deliveryStatus, isFoodReady}) {
  let imageSourceOutline = images.orderStatusOutline
  let imageSourceFill = images.orderStatusFill
  if (orderType === 'Delivery') {
    imageSourceOutline = images.deliveryOrderStatusOutline
    imageSourceFill = images.deliveryOrderStatusFill
  }
  const [fillRatio, setFillRatio] = useState(0)
  useEffect(() => {
    if (orderType === 'Delivery') {
      switch (status) {
        case 'Cancelled':
          setFillRatio(0)
          break
        case 'New':
          setFillRatio(0.2)
          break
        case 'Preparing':
        case 'Done':
        default:
          switch (deliveryStatus) {
            case 'HeadingToRestaurant':
            case 'ArrivedAtRestaurant':
            default:
              setFillRatio(0.465)
              break
            case 'HeadingToCustomer':
              setFillRatio(0.73)
              break
            case 'Delivered':
              setFillRatio(1)
          }
      }
    } else {
      switch (status) {
        case 'Cancelled':
          setFillRatio(0)
          break
        case 'New':
        default:
          setFillRatio(0.2)
          break
        case 'Preparing':
          if (isFoodReady) {
            setFillRatio(0.73)
          } else {
            setFillRatio(0.465)
          }
          break
        case 'Done':
          setFillRatio(1)
          break
      }
    }
  }, [status, orderType, deliveryStatus, isFoodReady])

  return (
    <FView relative w={300} h={85}>
      <img src={imageSourceOutline} alt="" className={classes.imgOutline} />
      <FView w={fillRatio * 100 + '%'} className={classes.imgFillContainer}>
        <img src={imageSourceFill} alt="" className={classes.imgFill} />
      </FView>
    </FView>
  )
}

const classes = {
  imgFillContainer: css({transition: 'width 2s ease-out', overflow: 'hidden', height: 85, zIndex: 1}),
  imgOutline: css({position: 'absolute', maxWidth: '100%', height: 'auto', objectFit: 'contain'}),
  imgFill: css({width: 300, height: 'auto', objectFit: 'contain'}),
}
