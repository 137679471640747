import React, {useState, useMemo} from 'react'
import {FView, FButton, RestaurantListItemView, NavBarV2} from 'components'
import {colors} from 'styles'
import {useDispatch} from 'react-redux'
import ReactMapboxGl, {Marker} from 'react-mapbox-gl'
import * as images from 'images'
import {css} from 'emotion'
import {kebabCase} from 'lodash'
import {useNavigate} from 'react-router-dom'
import {useFoodlyRestaurants} from 'f-app-models/hooks'

const MapBox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
})
const PIN_MAP = {
  Korean: images.mapPinKorean,
  Chinese: images.mapPinChinese,
  Japanese: images.mapPinJapanese,
  Chicken: images.mapPinChicken,
  Cafe: images.mapPinCafe,
  Italian: images.mapPinItalian,
  Indian: images.mapPinIndian,
  Thai: images.mapPinThai,
  Pub: images.mapPinPub,
  Breakfast: images.mapPinBreakfast,
}
const PIN_MAP_SELECTED = {
  Korean: images.mapPinKoreanSelected,
  Chinese: images.mapPinChineseSelected,
  Japanese: images.mapPinJapaneseSelected,
  Chicken: images.mapPinChickenSelected,
  Cafe: images.mapPinCafeSelected,
  Italian: images.mapPinItalianSelected,
  Indian: images.mapPinIndianSelected,
  Thai: images.mapPinThaiSelected,
  Pub: images.mapPinPubSelected,
  Breakfast: images.mapPinBreakfastSelected,
}
const INITIAL_ZOOM = [13]
const MAPBOX_STYLE = 'mapbox://styles/mapbox/streets-v9'
export default function Map() {
  const dispatch = useDispatch()
  const [selectedLocationId, setSelectedLocationId] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const userLocation = dispatch.user.getUserLocation()
  const mapCenter = useMemo(
    () => [userLocation.longitude, userLocation.latitude],
    [userLocation.longitude, userLocation.latitude],
  )
  const {sortedLocationList, restaurantFilterKeyword, setRestaurantFilterKeyword} = useFoodlyRestaurants()
  const locations = sortedLocationList
  const navigate = useNavigate()
  const checkMapPin = (isStoreOpen, selectedLocationId, locationId, resType) => {
    if (isStoreOpen) {
      if (selectedLocationId != null && selectedLocationId === locationId) {
        if (resType && PIN_MAP_SELECTED[resType]) {
          return PIN_MAP_SELECTED[resType]
        } else {
          return images.mapPinSelected
        }
      } else {
        if (resType && PIN_MAP[resType]) {
          return PIN_MAP[resType]
        } else {
          return images.mapPin
        }
      }
    } else {
      if (selectedLocationId != null && selectedLocationId === locationId) {
        return images.mapPinClosedSelected
      } else {
        return images.mapPinClosed
      }
    }
  }

  return (
    <FView minHeight={'100vh'} overflowY="auto" overflowX="hidden">
      <FView alignCenter>
        <NavBarV2 override={{sortedLocationList, restaurantFilterKeyword, setRestaurantFilterKeyword}} />
      </FView>
      <MapBox
        onClick={() => {
          setSelectedLocationId(null)
          setSelectedLocation(null)
        }}
        zoom={INITIAL_ZOOM}
        center={mapCenter}
        // eslint-disable-next-line react/style-prop-object
        style={MAPBOX_STYLE}
        containerStyle={styles.mapboxContainerStyle}>
        {locations.map((location) => {
          const locationId = location.locationId
          if (!locationId) {
            return null
          }
          const isStoreOpen = dispatch.restaurants.getOrderOpenDetails({locationId}).isOpen
          const resType = dispatch.restaurants.getRestaurantType({locationId})
          return location.restaurantLatLng ? (
            <Marker
              key={locationId}
              onClick={(ref) => {
                setSelectedLocationId(locationId)
                setSelectedLocation(location)
              }}
              coordinates={[location.restaurantLatLng.lng, location.restaurantLatLng.lat]}
              anchor="bottom"
              className={classes.mapPin}>
              <img src={checkMapPin(isStoreOpen, selectedLocationId, locationId, resType)} alt="map pin" />
            </Marker>
          ) : null
        })}
      </MapBox>
      {!!selectedLocation && (
        <FView absolute bottom={25} zIndex={5} selfCenter alignCenter bg={colors.white} shadow25 rounded>
          <FButton
            onClick={() => {
              navigate(
                `/${kebabCase(selectedLocation.restaurantName)}/restaurant/${selectedLocation.restaurantId}/location/${
                  selectedLocation.locationId
                }`,
              )
            }}>
            <RestaurantListItemView item={selectedLocation} locationId={selectedLocationId} />
          </FButton>
        </FView>
      )}
    </FView>
  )
}

const styles = {
  mapboxContainerStyle: {
    width: '100vw',
    height: 'calc(100vh - 80px)',
  },
}

const classes = {
  mapPin: css({
    cursor: 'pointer',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
  // restaurantIconStyle: css({width: 85, height: 85, resizeMode: 'contain'}),
}
