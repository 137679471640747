import React from 'react'
import {useNavigate} from 'react-router-dom'
import {colors} from 'styles'
import {kebabCase} from 'lodash'
import {useMediaMinMD, useMediaMinLG} from 'f-web/src/hooks'
import {useSelector, useDispatch} from 'react-redux'
import {FText, FView, NavBarV2, FButton} from 'components'
import ItemView from './ItemView'

export default function Restaurants() {
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const promotions = useSelector(dispatch.restaurants.getLocationsWithPromotions)

  return (
    <FView bg={colors.background} minHeight={'100vh'} overflowY="auto" overflowX="hidden">
      <NavBarV2 />
      <FView size={isMinMD ? 50 : 10} />
      <FView selfCenter w={1024} maxWidth="100%" ph={isMinLG ? 0 : 15}>
        <FText bold h4>
          Promotions
        </FText>
        <FView size={isMinMD ? 25 : 15} />
        <FView maxWidth={!isMinLG && 700} selfCenter={!isMinLG} row={isMinMD} justifyBetween wrap>
          {promotions.map((promotion) => {
            return (
              <FButton
                key={promotion.locationId}
                onClick={() => {
                  navigate(
                    `/${kebabCase(promotion.restaurantName)}/restaurant/${promotion.restaurantId}/location/${
                      promotion.locationId
                    }`,
                  )
                }}
                mb={25}>
                <ItemView
                  imageUrl={promotion.imageUrl}
                  restaurantName={promotion.restaurantName}
                  restaurantType={promotion.restaurantType}
                  name={promotion.name}
                />
              </FButton>
            )
          })}
        </FView>
      </FView>
      <FView size={100} />
    </FView>
  )
}
