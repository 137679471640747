import React from 'react'
import {NavLink} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {ButtonBase, Button, IconButton} from '@material-ui/core'
import clsx from 'clsx'
import {css} from 'emotion'

export default function FButton(props) {
  const classes = useStyles()
  const passedProps = {}
  const classNames = [classes.baseStyle]

  if (!props.ripple) {
    passedProps.disableRipple = true
  }
  if (props.target === '_blank') {
    passedProps.rel = 'noopener noreferrer'
  }
  if (props.onPress) {
    passedProps.onClick = props.onPress
  }

  const keys = Object.keys(props)
  for (const key of keys) {
    if (key === 'w') {
      classNames.push(css({width: props[key]}))
    } else if (key === 'h') {
      classNames.push(css({height: props[key]}))
    } else if (key === 'p') {
      classNames.push(css({padding: props[key]}))
    } else if (key === 'm') {
      classNames.push(css({margin: props[key]}))
    } else if (key === 'size') {
      classNames.push(css({width: props[key], height: props[key]}))
    } else if (props[key] === true && classes[key]) {
      classNames.push(classes[key])
    } else if (!notPassedProps[key]) {
      switch (key) {
        case 'bottom':
        case 'top':
        case 'left':
        case 'right':
        case 'zIndex':
          classNames.push(css({[key]: props[key]}))
          break
        default:
          passedProps[key] = props[key]
          break
      }
    }
  }
  if (props.to) {
    passedProps.component = NavLink
    if (props.activeLinkColor === 'primary') {
      passedProps.activeClassName = classes.primary
    } else if (props.activeLinkColor === 'secondary') {
      passedProps.activeClassName = classes.secondary
    } else if (props.activeLinkDisplayNone === true) {
      passedProps.activeClassName = classes.none
    }
  }
  if (props.hoverShadow) {
    classNames.push(classes.relative)
  }
  classNames.push(props.className)

  const BaseComponent = props.Button ? Button : props.IconButton ? IconButton : ButtonBase

  return (
    <BaseComponent className={clsx(classNames)} {...passedProps}>
      {props.children}
      {props.hoverShadow && <div className={clsx(classes.hoverShadowStyle, props.rounded ? classes.rounded : null)} />}
    </BaseComponent>
  )
}

const useStyles = makeStyles((theme) => ({
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 40,
    minWidth: 40,
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  primary: {color: theme.palette.primary.main},
  backgroundPrimary: {backgroundColor: theme.palette.primary.main},
  secondary: {color: theme.palette.secondary.main},
  padding: {padding: 8},
  round: {borderRadius: 8},
  rounded: {borderRadius: theme.shape.borderRadius},
  flex: {display: 'flex'},
  none: {display: 'none'},
  block: {display: 'block'},
  inline: {display: 'inline'},
  inlineBlock: {display: 'inline-block'},
  row: {flexDirection: 'row'},
  rowReverse: {flexDirection: 'row-reverse'},
  column: {flexDirection: 'column'},
  columnReverse: {flexDirection: 'column-reverse'},
  fill: {flex: 1},
  flexShrink: {flexShrink: 1},
  flexGrow: {flexGrow: 1},
  wrap: {flexWrap: 'wrap'},
  absoluteFill: {position: 'absolute', left: 0, right: 0, top: 0, bottom: 0},
  relative: {position: 'relative'},
  absolute: {position: 'absolute'},
  fixed: {position: 'fixed'},
  selfCenter: {alignSelf: 'center'},
  selfStart: {alignSelf: 'flex-start'},
  selfEnd: {alignSelf: 'flex-end'},
  selfStretch: {alignSelf: 'stretch'},
  center: {alignItems: 'center', justifyContent: 'center'},
  alignCenter: {alignItems: 'center'},
  alignStart: {alignItems: 'flex-start'},
  alignEnd: {alignItems: 'flex-end'},
  alignStretch: {alignItems: 'stretch'},
  justifyCenter: {justifyContent: 'center'},
  justifyBetween: {justifyContent: 'space-between'},
  justifyAround: {justifyContent: 'space-around'},
  justifyEvenly: {justifyContent: 'space-evenly'},
  justifyEnd: {justifyContent: 'flex-end'},
  hoverShadowStyle: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 8px 0px, rgba(23, 32, 38, 0.06) 0px 2px 4px 0px',
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      opacity: 1,
      // Don't transition in mobile (touch) screen
      '@media (hover: none)': {
        opacity: 0,
      },
    },
  },
  hoverHighlight: {
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  hoverLighten: {
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(236,	85,	31, 0.8)',
    },
  },
}))

const notPassedProps = {
  IconButton: true,
  Button: true,
  children: true,
  hoverShadow: true,
  activeLinkColor: true,
  onPress: true,
  activeLinkDisplayNone: true,
}
