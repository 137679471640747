import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {parseCurrency} from 'f-utils'
import {useTheme} from '@material-ui/core/styles'
import {DialogTitle, DialogContent, DialogActions, TextField} from '@material-ui/core'
import {InputAdornment} from '@material-ui/core'
import {FView, FText, FButton, FDialog} from 'components'

export default React.memo(function TipsView({
  tipSelectionIndex,
  tipAmountForTipSelectionIndexes,
  totalBeforeTip,
  orderType,
}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [customTipOpen, setCustomTipOpen] = useState(false)
  const minimumTipAmount = useSelector(dispatch.user.getMinimumTipAmount)
  const [customTipAmount, setCustomTipAmount] = useState('')
  const applyCustomTip = () => {
    const tipAmountNumber = parseCurrency(customTipAmount)
    if (tipAmountNumber < 0) {
      alert('Invalid Tip Amount: Tip cannot be negative')
      return
    }
    if (tipAmountNumber < minimumTipAmount) {
      alert('Tip Adjusted: Tip must be greater or equal to $' + minimumTipAmount.toFixed(2))
    }

    dispatch.user.setCustomTipAmount(Math.max(tipAmountNumber, minimumTipAmount))
    dispatch.user.setTipSelectionIndex(3)
    setCustomTipOpen(false)
  }
  return (
    <>
      <FDialog open={customTipOpen} onClose={() => setCustomTipOpen(false)} maxWidth="sm">
        <DialogTitle>
          <FText bold h4>
            Custom Tip
          </FText>
        </DialogTitle>
        <DialogContent>
          <FView>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
              value={customTipAmount}
              onChange={(e) => setCustomTipAmount(e.target.value)}
              variant="filled"
            />
            <FText color={theme.palette.grey[700]}>
              100% of the tip goes to the {orderType === 'Delivery' ? 'driver' : 'restaurant'}
            </FText>
          </FView>
        </DialogContent>
        <DialogActions>
          <FView fill>
            <FButton onClick={() => applyCustomTip()}>
              <FView fill justifyCenter bg={theme.palette.primary.main} rounded>
                <FText color="white" bold>
                  Done
                </FText>
              </FView>
            </FButton>
          </FView>
        </DialogActions>
      </FDialog>
      <FView row justifyAround alignCenter>
        <TipButton
          isSelected={tipSelectionIndex === 0}
          label="12%"
          onPress={() => dispatch.user.setTipSelectionIndex(0)}
          tipAmount={tipAmountForTipSelectionIndexes[0]}
          totalBeforeTip={totalBeforeTip}
        />
        <TipButton
          isSelected={tipSelectionIndex === 1}
          label="15%"
          onPress={() => dispatch.user.setTipSelectionIndex(1)}
          tipAmount={tipAmountForTipSelectionIndexes[1]}
          totalBeforeTip={totalBeforeTip}
        />
        <TipButton
          isSelected={tipSelectionIndex === 2}
          label="18%"
          onPress={() => dispatch.user.setTipSelectionIndex(2)}
          tipAmount={tipAmountForTipSelectionIndexes[2]}
          totalBeforeTip={totalBeforeTip}
        />
        <FButton onPress={() => setCustomTipOpen(true)} size="lg" variant="outlined">
          <FView
            ph={8}
            w={70}
            minHeight={56}
            center
            br={8}
            bc={tipSelectionIndex === 3 ? theme.palette.primary.main : theme.palette.grey[500]}
            bw={1}>
            <FText bold color={tipSelectionIndex === 3 ? theme.palette.primary.main : theme.palette.grey[500]}>
              Other
            </FText>
            {tipSelectionIndex === 3 && <FText primary>${tipAmountForTipSelectionIndexes[3].toFixed(2)}</FText>}
          </FView>
        </FButton>
      </FView>
    </>
  )
})

const TipButton = ({onPress, isSelected, tipAmount, totalBeforeTip, label}) => {
  const theme = useTheme()
  return (
    <FButton onPress={onPress} variant="outlined" size="lg">
      <FView
        ph={8}
        minHeight={56}
        w={70}
        center
        br={8}
        bg={isSelected ? theme.palette.primary.main : 'none'}
        bc={isSelected ? theme.palette.primary.main : theme.palette.grey[500]}
        bw={1}>
        {totalBeforeTip >= 5 && (
          <FText bold color={isSelected ? 'white' : theme.palette.grey[500]} primary={isSelected}>
            {label}
          </FText>
        )}
        <FText
          color={isSelected ? 'white' : theme.palette.grey[500]}
          primary={isSelected}
          body2={totalBeforeTip >= 5}
          bold={totalBeforeTip < 5}>
          ${tipAmount?.toFixed(2)}
        </FText>
      </FView>
    </FButton>
  )
}
