import React from 'react'

import {FButton, FView} from 'components'
import {useMediaMinMD} from 'f-web/src/hooks'
import {foodlyLogo} from 'images'
import AddressSelector from './AddressSelector'
import LocationsSearch from './LocationsSearch'
import OrderTypeSelector from './OrderTypeSelector'
import NavBarMenuButtonGroup from './NavBarMenuButtonGroup'
import {useParams} from 'react-router'
import {useSelector, useDispatch} from 'react-redux'

const NavBarV2 = ({
  override,
  hasSearchBar = true,
  hasAddressSelector = true,
  hasOrderTypeSelector = true,
  hasMenuButtonGroup = true,
  directOrder,
}) => {
  const dispatch = useDispatch()
  const isMinMD = useMediaMinMD()
  const MdPadder = () => <FView w={isMinMD ? 16 : 0} />
  const {restaurantName, restaurantId, locationId} = useParams()
  const location = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  hasSearchBar = hasSearchBar && !directOrder
  return (
    <FView bg="white" w="100vw" alignCenter>
      <FView row justifyBetween alignCenter w={1440} maxWidth={'100vw'} pt={16} pb={8} ph={'3.33vw'} wrap>
        <FView order={-2} w={directOrder ? 44 : 168}>
          <FButton alignCenter to={directOrder ? `/direct/order/${restaurantName}/${restaurantId}/${locationId}` : '/'}>
            <img
              draggable={false}
              src={directOrder ? location.restaurantIconUrl : foodlyLogo}
              style={{objectFit: 'contain', height: 44}}
              alt="foodly logo"
            />
          </FButton>
        </FView>
        {hasAddressSelector && (
          <>
            <MdPadder />
            <AddressSelector />
          </>
        )}
        {hasOrderTypeSelector && (
          <>
            <MdPadder />
            <OrderTypeSelector locationId={locationId} />
          </>
        )}
        {hasSearchBar ? (
          <>
            <MdPadder />
            <FView pv={8} order={!isMinMD ? 2 : 0} fill>
              <LocationsSearch override={override} />
            </FView>
          </>
        ) : (
          <FView fill h={61} />
        )}
        {hasMenuButtonGroup && (
          <>
            <MdPadder />
            <NavBarMenuButtonGroup directOrder={directOrder} />
          </>
        )}
      </FView>
    </FView>
  )
}

export default NavBarV2
