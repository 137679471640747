import React from 'react'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import {ExpandMore} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

export default function ExpandMoreRotate({expanded, ...props}) {
  const classes = useStyles()
  return (
    <ExpandMore
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      {...props}
    />
  )
}
