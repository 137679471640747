import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FText, FView, FButton} from 'components'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {Lock} from '@material-ui/icons'
import {Radio} from '@material-ui/core'

const useStylesIcon = makeStyles({
  root: {
    width: 20,
    height: 20,
    // color: 'white',
  },
})
export default React.memo(function RedeemFPointsView() {
  const fRewardIdToDiscount = {
    fPoints2500Discount: 2.5,
    fPoints5000Discount: 5,
    fPoints10000Discount: 10,
    fPoints15000Discount: 15,
  }
  const classes = useStylesIcon()
  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedFRewardId = useSelector(dispatch.user.getSelectedFRewardId)
  const isLoggedIn = useSelector(dispatch.user.getIsUserLoggedIn)
  const fPoints = useSelector(dispatch.user.getFPoints)
  const cartSubTotal = useSelector(dispatch.user.getCartSubTotalBeforeDiscount)
  useEffect(() => {
    const {fPointsCost} = dispatch.user.getFRewardDetails(selectedFRewardId)
    if (fPoints < fPointsCost) {
      dispatch.user.setSelectedFRewardId(null)
    }
  }, [dispatch.user, selectedFRewardId, fPoints])

  return (
    <>
      <FView>
        <FView row justifyBetween mb={16}>
          <FView fill>
            <FText bold>Redeem your points</FText>
          </FView>
          <FText>{fPoints.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} points</FText>
        </FView>
        <FView row justifyAround alignCenter>
          {['fPoints2500Discount', 'fPoints5000Discount', 'fPoints10000Discount', 'fPoints15000Discount'].map(
            (fRewardId) => {
              const {fPointsCost} = dispatch.user.getFRewardDetails(fRewardId)
              const isSelected = selectedFRewardId === fRewardId
              const isDisabled = fPoints < fPointsCost || fRewardIdToDiscount[fRewardId] > cartSubTotal || !isLoggedIn
              return (
                <FButton
                  key={fRewardId}
                  disabled={isDisabled}
                  onPress={() => dispatch.user.setSelectedFRewardId(fRewardId)}
                  size="lg"
                  variant="outlined">
                  <FView
                    ph={8}
                    minHeight={56}
                    minWidth={64}
                    center
                    br={8}
                    bg={isSelected ? theme.palette.primary.main : 'none'}
                    bc={isSelected ? theme.palette.primary.main : theme.palette.grey[500]}
                    bw={1}>
                    <FView row alignCenter>
                      {isDisabled && <Lock classes={classes} color="disabled" />}
                      <FView w={2} />
                      <FText bold color={isSelected ? 'white' : theme.palette.grey[500]}>
                        ${(fPointsCost / 1000).toFixed(2)}
                      </FText>
                    </FView>
                    <FText color={isSelected ? 'white' : theme.palette.grey[500]} body2>
                      {fPointsCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} pts
                    </FText>
                  </FView>
                </FButton>
              )
            },
          )}
        </FView>
        {!isLoggedIn && (
          <FView mt={8}>
            <FText color={theme.palette.grey[600]}>* Login or signup to redeem your points!</FText>
          </FView>
        )}
      </FView>
      {isLoggedIn && (
        <>
          <FView size={8} />
          <FButton
            variant="clear"
            shape="rectangular"
            onPress={() => dispatch.user.setSelectedFRewardId(null)}
            color="tertiary">
            <FView row justifyBetween alignCenter>
              <FView>
                <FText semiBold grey700={selectedFRewardId !== null} primary={selectedFRewardId === null}>
                  Don't redeem points
                </FText>
              </FView>
              <Radio color="primary" checked={!selectedFRewardId} />
            </FView>
          </FButton>
          <FView h={8} />
        </>
      )}
    </>
  )
})
