import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useTheme} from '@material-ui/core/styles'

import {FText, FView, FButton} from 'components'

export default function OrderTypeSelector({locationId}) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isPickupAvailable = useSelector(() => dispatch.restaurants.getIsPickupAvailable({locationId}))
  const isDeliveryAvailable = useSelector(() => dispatch.restaurants.getIsDeliveryAvailable({locationId}))

  return (
    <FView row wrap alignCenter bg={theme.palette.grey[100]} p={4} br={32}>
      {(!locationId || isDeliveryAvailable) && <SelectButton buttonOrderType={'Delivery'} />}
      {(!locationId || isPickupAvailable) && <SelectButton buttonOrderType={'Pickup'} />}
    </FView>
  )
}

const SelectButton = ({buttonOrderType}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const orderType = useSelector(dispatch.user.getOrderType)
  const isSelected = orderType === buttonOrderType
  return (
    <FButton onClick={() => dispatch.user.setOrderType({orderType: buttonOrderType})}>
      <FView p={12} bg={isSelected ? theme.palette.primary.main : theme.palette.grey[100]} br={24}>
        <FText bold grey800={!isSelected} white={isSelected}>
          {buttonOrderType}
        </FText>
      </FView>
    </FButton>
  )
}
