import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import {FText, FView} from 'components'
import {useMediaMinLG} from 'f-web/src/hooks'
import Cart from './Cart'
import FloatingCart from './FloatingCart'
import ProductCard from './ProductCard'
import {isEqual} from 'lodash'
import {getProductIdToDiscount} from 'f-utils'

const CategoriesList = React.forwardRef(
  (
    {
      activeCategoryOrder,
      locationId,
      setSelectedProductId,
      setOrderTypeDialogOpen,
      jumpRef,
      setSelectedCategory,
      directOrder,
    },
    ref,
  ) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const isMinLG = useMediaMinLG()
    const publicProductTags = useSelector(dispatch.public.getPublicProductTags)

    const promoItems = useSelector(() => dispatch.user.getAllPromosWithDetails(locationId), isEqual)
    const products = useSelector(() => dispatch.restaurants.getProducts({locationId}))
    const rewardIdToTimesUsed = useSelector(() => dispatch.user.getRewardIdToTimesUsed())
    const productIdToDiscount = getProductIdToDiscount(products, promoItems, rewardIdToTimesUsed)
    return (
      <FView row justifyBetween>
        <FView fill>
          {activeCategoryOrder.map((categoryId, index) => {
            const categoryData = dispatch.restaurants.getCategory({locationId, categoryId})
            const observer = new IntersectionObserver(
              ([entry]) => {
                if (!jumpRef.current) {
                  if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
                    setSelectedCategory(index + 1)
                  }
                  if (entry.isIntersecting && entry.boundingClientRect.top < 0) {
                    setSelectedCategory(index)
                  }
                }
              },
              {rootMargin: '-80px 0px 0px 0px'},
            )
            return (
              <FView
                key={categoryId}
                ref={(r) => {
                  ref.current[categoryId] = r

                  if (!r) {
                    observer.disconnect()
                    return
                  }

                  observer.observe(r)
                }}>
                <FView mt={30} mb={20}>
                  <FText h6 bold>
                    {categoryData.name}
                  </FText>
                  {!!categoryData.description && <FText grey700>{categoryData.description}</FText>}
                </FView>
                <Grid container spacing={2}>
                  {categoryData?.productOrder?.map((productId) => (
                    <ProductCard
                      key={productId}
                      locationId={locationId}
                      productId={productId}
                      publicProductTags={publicProductTags}
                      onClick={() => {
                        const orderType = dispatch.user.getOrderType()
                        if (!orderType || (orderType === 'Delivery' && !dispatch.user.getAddressData())) {
                          setOrderTypeDialogOpen('orderType')
                          return
                        }
                        setSelectedProductId(productId)
                      }}
                      discount={productIdToDiscount[productId]}
                    />
                  ))}
                </Grid>
              </FView>
            )
          })}
        </FView>
        {isMinLG ? (
          <FView>
            <FView mt={82} ml={16} top={72} sticky bc={theme.palette.grey[300]} bw={1}>
              <Cart directOrder={directOrder} />
            </FView>
          </FView>
        ) : (
          <FloatingCart directOrder={directOrder} />
        )}
      </FView>
    )
  },
)

export default React.memo(CategoriesList)
