import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'
import {RiMoneyDollarBoxLine} from 'react-icons/ri'

export default function OrderItemAddedCharges({addedCharges}) {
  return addedCharges.map((charge, index) => (
    <FView row alignCenter minHeight={30} mt={10} key={index}>
      <FView size={25} bg={colors.grey} center rounded>
        <FText bold caption primaryContrast>
          x1
        </FText>
      </FView>
      <FView size={6} />
      <RiMoneyDollarBoxLine size={20} color={colors.grey} />
      <FView size={6} />
      <FView fill>
        <FText bold body2>
          {`${charge?.name}`}
        </FText>
      </FView>
      <FView w={60}>
        <FText bold body2 alignRight>
          {`$${charge?.price?.toFixed(2)}`}
        </FText>
      </FView>
    </FView>
  ))
}
