import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {FView, DeliveryAddressDialog, NavBarV2} from 'components'

import {useMediaMinMD} from 'f-web/src/hooks'
import CheckoutInfo from './CheckoutInfo'
import OrderSummary from './OrderSummary'
import ContactInfoDialog from 'components/ContactInfoDialog'
import PaymentInfoDialog from 'components/PaymentInfoDialog'
import {firebaseAnalytics} from 'f-core/src/config/firebase'
import {useNavigate, useParams} from 'react-router'
import RemoveInvalidCartItemsModal from 'components/RemoveInvalidCartItemsModal'
import OrderTypeDialog from 'pages/Location/componentsV2/OrderTypeDialog'
import useQueryDialog from 'components/useQueryDialog'

const Checkout = ({directOrder}) => {
  const dispatch = useDispatch()
  const {locationId: directLocationId, restaurantName, restaurantId: directRestaurantId} = useParams()
  const isMinMD = useMediaMinMD()
  const navigate = useNavigate()
  const {dialogOpen, openDialog, closeDialog} = useQueryDialog()
  const locationId = useSelector(dispatch.user.getCartLocationId)
  const restaurantId = useSelector(dispatch.user.getCartRestaurantId)
  //Delivery
  const orderType = useSelector(dispatch.user.getOrderType)
  const isDeliveryAvailable = useSelector(() => dispatch.restaurants.getIsDeliveryAvailable({locationId}))
  const isDeliveryAddressEmpty = useSelector(dispatch.user.getIsDeliveryAddressEmpty)

  //Payment
  const paymentMethod = useSelector(dispatch.user.getPaymentMethod)
  const payments = useSelector(dispatch.user.getPayments)
  const selectedPaymentIndex = useSelector(dispatch.user.getSelectedPaymentIndex)
  const hasPayment = !!payments[selectedPaymentIndex]
  const isCashPaymentEnabled = useSelector(() => dispatch.restaurants.getIsInpersonCashEnabled({locationId}))
  const isCashPaymentEnabledForDelivery = useSelector(() => dispatch.restaurants.getIsCashDeliveryEnabled({locationId}))
  const paymentComplete =
    paymentMethod === 'inperson-cash' || (paymentMethod === 'online-creditcard' && payments[selectedPaymentIndex])

  const isCartValid = useSelector(dispatch.user.getIsCartValid)

  //Contact
  const name = useSelector(dispatch.user.getName)
  const phoneNumber = useSelector(dispatch.user.getPhoneNumber)
  const isPhoneNumberVerified = useSelector(() => dispatch.user.getIsPhoneNumberVerified({phoneNumber}))
  const contactComplete = name && isPhoneNumberVerified

  const orderTimeType = useSelector(dispatch.user.getOrderTimeType)
  const scheduledOrderTimestamp = useSelector(dispatch.user.getScheduledOrderTimestamp)

  useEffect(() => {
    if (locationId && restaurantId) {
      dispatch.user.subscribeRewardsWithCode({restaurantId, locationId})
    }
  }, [dispatch.user, locationId, restaurantId])

  useEffect(() => {
    if (dispatch.user && !locationId) {
      if (directOrder) {
        navigate(`/direct/order/${restaurantName}/${directRestaurantId}/${directLocationId}`)
      } else {
        navigate('/')
      }
    }
  }, [directLocationId, directOrder, dispatch.user, locationId, navigate, directRestaurantId, restaurantName])

  useEffect(() => {
    if (paymentMethod === null && hasPayment) {
      dispatch.user.setPaymentMethod({paymentMethod: 'online-creditcard'})
    }
  }, [dispatch.user, hasPayment, paymentMethod, payments])

  useEffect(() => {
    if (
      paymentMethod === 'inperson-cash' &&
      (!isCashPaymentEnabled || (!isCashPaymentEnabledForDelivery && orderType === 'Delivery'))
    ) {
      dispatch.user.setPaymentMethod({paymentMethod: null})
    }
  }, [dispatch.user, isCashPaymentEnabled, isCashPaymentEnabledForDelivery, orderType, paymentMethod])

  useEffect(() => {
    if (paymentComplete) {
      firebaseAnalytics.logEvent('add_payment_info')
    }
    if (contactComplete) {
      firebaseAnalytics.logEvent('add_contact_info')
    }
    if (isPhoneNumberVerified) {
      firebaseAnalytics.logEvent('verified_phone_number')
    }
  }, [paymentComplete, contactComplete, isPhoneNumberVerified, paymentMethod, dispatch.user])

  useEffect(() => {
    if (orderTimeType === 'Scheduled') {
      dispatch.restaurants.updateActiveCategoryOrder({locationId, targetDateTime: scheduledOrderTimestamp})
      dispatch.restaurants.updateActiveProducts({locationId})
    }
  }, [dispatch.restaurants, locationId, orderTimeType, scheduledOrderTimestamp])

  const notReady = []
  if (!isCartValid) {
    notReady.push('invalidCart')
  }
  if (isDeliveryAvailable && orderType === 'Delivery' && isDeliveryAddressEmpty) {
    notReady.push('delivery')
  }
  if (!contactComplete) {
    notReady.push('contact')
  }
  if (!paymentComplete) {
    notReady.push('payment')
  }

  const hasNext = notReady.length > (dialogOpen === notReady[0] ? 1 : 0)
  const onNext = () => {
    openDialog(dialogOpen === notReady[0] ? notReady[1] : notReady[0])
  }

  return (
    <FView alignCenter>
      <OrderTypeDialog />
      <DeliveryAddressDialog open={dialogOpen === 'delivery'} onClose={closeDialog} hasNext={hasNext} onNext={onNext} />
      <ContactInfoDialog open={dialogOpen === 'contact'} onClose={closeDialog} onNext={onNext} hasNext={hasNext} />
      <PaymentInfoDialog open={dialogOpen === 'payment'} hasNext={hasNext} onClose={closeDialog} onNext={onNext} />
      <RemoveInvalidCartItemsModal
        open={dialogOpen === 'invalidCart'}
        onClose={closeDialog}
        onNext={onNext}
        hasNext={hasNext}
        locationId={locationId}
      />
      <NavBarV2
        hasAddressSelector={false}
        hasSearchBar={false}
        hasOrderTypeSelector={false}
        directOrder={directOrder}
        locationId={locationId}
      />
      <FView
        flexDirection={isMinMD ? 'row' : 'column'}
        justifyEvenly
        alignCenter={!isMinMD}
        w={1440}
        maxWidth={'100vw'}
        mt={isMinMD ? 32 : 4}>
        <CheckoutInfo directOrder={directOrder} dialogOpen={dialogOpen} setDialogOpen={openDialog} />
        {isMinMD ? <FView w={'3vw'} /> : <FView h={'3vh'} />}
        <OrderSummary notReady={notReady} setDialogOpen={openDialog} directOrder={directOrder} />
      </FView>
    </FView>
  )
}

export default Checkout
