import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router'

import {useTheme} from '@material-ui/core/styles'
import {ArrowForward} from '@material-ui/icons'

import * as images from 'images'
import {useMediaMinSM, useMediaMinMD, useMediaMinLG} from 'f-web/src/hooks'
import {FView, FText, NavBarV2, ButtonFillView, FButton, Footer} from 'components'
import FindRestaurantsInput from './FindRestaurantsInput'
import WorkWithUsItemView from './WorkWithUsItemView'

const Home = () => {
  const theme = useTheme()
  const isMinSM = useMediaMinSM()
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const addressData = useSelector(dispatch.user.getAddressData)

  const orderType = useSelector(dispatch.user.getOrderType)

  useEffect(() => {
    if ((addressData && orderType === 'Delivery') || orderType === 'Pickup') {
      navigate('/restaurants')
    }
  }, [addressData, navigate, orderType])

  return (
    <FView w={'100vw'}>
      <NavBarV2 />
      <FView h={'calc(15.5vw + 200px)'} minHeight={250} maxHeight={415} bg={'#FFC3B0'} relative center>
        <FView absoluteFill justifyBetween row pt={15} zIndex={1}>
          <img
            draggable={false}
            src={isMinSM ? images.heroFood1 : images.heroFood1Mobile}
            style={{objectFit: 'contain', height: '100%'}}
            alt={'hero food one'}
          />
          <img
            src={isMinSM ? images.heroFood2 : images.heroFood2Mobile}
            style={{objectFit: 'contain', height: '100%'}}
            alt={'hero food two'}
          />
        </FView>
        <FView zIndex={2}>
          <FText bold h3 alignCenter primaryContrast textShadowLight>
            Foodly
            <br />
            Food-Friendly
          </FText>
          {isMinMD && (
            <>
              <FView size={50} />
              <FindRestaurantsInput />
            </>
          )}
        </FView>
      </FView>

      <FView size={25} />
      {!isMinMD && (
        <FView mh={15}>
          <FindRestaurantsInput />
        </FView>
      )}
      <FView size={25} />

      {/* ========================================= */}

      <FView
        selfCenter
        maxWidth={1024}
        w={'100%'}
        columnReverse
        row={isMinLG}
        justifyBetween
        alignCenter
        ph={isMinLG ? 0 : 15}>
        <FView w={500} maxWidth="100%">
          <FText bold h3 gutterBottom>
            Order Food You Love
          </FText>
          <FText body1 gutterBottom>
            Foodly allows you to order ahead of time & skip the line when picking up your food. We also offer delivery
            for selected participating restaurants. Our platform currently covers the Greater Vancouver area in British
            Columbia, Canada.
          </FText>
          <FView>
            <FButton
              onClick={() =>
                window.scroll({
                  top: 0,
                  behavior: 'smooth',
                })
              }>
              <FView row>
                <FText body1 bold primary>
                  Find Restaurants
                </FText>
                <FView size={5} />
                <ArrowForward color="primary" />
              </FView>
            </FButton>
          </FView>
        </FView>
        <img
          src={images.promoImage4}
          style={{objectFit: 'contain', width: 450, marginBottom: 15, maxWidth: '100%'}}
          alt={'promotion'}
        />
      </FView>

      <FView mv={isMinMD ? 50 : 25} h={1} bg={theme.palette.grey['300']} selfCenter maxWidth={1024} w={'100%'} />

      <FView selfCenter maxWidth={1024} w={'100%'} row={isMinLG} justifyBetween alignCenter ph={isMinLG ? 0 : 15}>
        <img
          src={images.phoneMockup}
          style={{objectFit: 'contain', width: 578, marginBottom: 15, maxWidth: '100%'}}
          alt={'phone mockup'}
        />
        <FView w={375} maxWidth="100%">
          <FText bold h3 gutterBottom>
            Get The App
          </FText>
          <FText body1>
            Download our app and start ordering from anywhere. See restaurants near you and order with a single click.
          </FText>
          <FView size={25} />
          <FView>
            <FButton href="https://foodly.ca/links/foodlymobileapp" target="_blank">
              <ButtonFillView w={250} rounded>
                <FText body1 bold primaryContrast>
                  Download The App
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </FView>

      <FView mv={isMinMD ? 50 : 25} h={1} bg={theme.palette.grey['300']} selfCenter maxWidth={1024} w={'100%'} />

      <FView selfCenter maxWidth={1024} w={'100%'} row={isMinLG} alignCenter justifyAround ph={isMinLG ? 0 : 15}>
        <WorkWithUsItemView
          imgSrc={images.becomeAPartner}
          title={'Become a Partner'}
          description={
            'Would you like to list your restaurant in our app? We also offer a great deal of services to our restaurant partners.'
          }
          LearnMoreElement={
            <FButton to="/restaurant-owners">
              <FView row>
                <FText body1 bold primary>
                  Learn More
                </FText>
                <FView size={5} />
                <ArrowForward color="primary" />
              </FView>
            </FButton>
          }
        />
        {!isMinLG && <FView h={1} bg={theme.palette.grey['300']} mv={25} selfStretch />}
        <WorkWithUsItemView
          imgSrc={images.deliverWithUs}
          title={'Deliver With Us'}
          description={
            'Looking to make extra money? Become a courier for Foodly and start delivering on your own schedule.'
          }
          LearnMoreElement={
            <FButton to="/deliverWithUs">
              <FView row>
                <FText body1 bold primary>
                  Learn More
                </FText>
                <FView size={5} />
                <ArrowForward color="primary" />
              </FView>
            </FButton>
          }
        />
      </FView>

      <FView mv={isMinMD ? 50 : 25} h={1} bg={theme.palette.grey['300']} selfCenter maxWidth="100%" w={1024} />

      <FView selfCenter alignCenter maxWidth={1024} w={'100%'}>
        <FText bold h3>
          Hungry?
        </FText>
        <FView size={15} />
        <FButton
          onClick={() =>
            window.scroll({
              top: 0,
              behavior: 'smooth',
            })
          }>
          <ButtonFillView w={250} rounded>
            <FText body1 bold primaryContrast>
              Find Restaurants
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>

      <FView mv={isMinMD ? 50 : 25} h={1} bg={theme.palette.grey['300']} />

      <Footer />
    </FView>
  )
}

export default Home
