import React from 'react'
import {DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import {FButton, FText, ButtonFillView, FDialog} from 'components'
import AddressPicker from './AddressPicker'
import {useDispatch, useSelector} from 'react-redux'

export default function DeliveryAddressDialog({open, onClose, hasNext, onNext}) {
  const dispatch = useDispatch()
  const isDeliveryAddressEmpty = useSelector(dispatch.user.getIsDeliveryAddressEmpty)

  return (
    <>
      <FDialog open={open} onClose={onClose}>
        <DialogTitle>
          <FText h4 bold>
            Deliver to
          </FText>
        </DialogTitle>
        <DialogContent>
          <AddressPicker />
        </DialogContent>
        <DialogActions>
          <FButton fill disabled={isDeliveryAddressEmpty} onClick={hasNext ? onNext : onClose}>
            <ButtonFillView disabled={isDeliveryAddressEmpty} round>
              <FText white bold>
                {hasNext ? 'Next' : 'Done'}
              </FText>
            </ButtonFillView>
          </FButton>
        </DialogActions>
      </FDialog>
    </>
  )
}
