import React from 'react'
import {FView} from 'components'
import {css} from 'emotion'

export default function FButtonA({label, animateOnHover, onClick, onPress, disabled, ...props}) {
  return (
    <FView
      className={classes.noselectFloat}
      cursor="pointer"
      minHeight={50}
      center
      onClick={disabled ? null : onClick || onPress}
      {...props}
    />
  )
}

const classes = {
  noselectFloat: css({
    ':hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
}
