import React from 'react'
import {FText} from 'components'

const FooterItemView = ({label}) => {
  return (
    <FText body1 bold>
      {label}
    </FText>
  )
}

export default FooterItemView
