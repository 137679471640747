import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useMediaQueryUp = (key) => {
  const theme = useTheme()
  const defaultState = window ? window.innerWidth >= theme.breakpoints.values[key] : false
  return useMediaQuery(theme.breakpoints.up(key), {
    defaultMatches: defaultState,
  })
}

export const useMediaQueryDown = (key) => {
  const theme = useTheme()
  const defaultState = window ? window.innerWidth <= theme.breakpoints.values[key] : false
  return useMediaQuery(theme.breakpoints.down(key), {
    defaultMatches: defaultState,
  })
}

export const useMediaQueryBetween = (start, end) => {
  const theme = useTheme()
  const defaultState = window
    ? window.innerWidth >= theme.breakpoints.values[start] && window.innerWidth <= theme.breakpoints.values[end]
    : false
  return useMediaQuery(theme.breakpoints.between(start, end), {
    defaultMatches: defaultState,
  })
}

export const useMediaQueryOnly = (key) => {
  const theme = useTheme()
  const defaultState = window ? window.innerWidth === theme.breakpoints.values[key] : false
  return useMediaQuery(theme.breakpoints.only(key), {
    defaultMatches: defaultState,
  })
}

export {default as useIsTop} from './useIsTop'
export {default as useMediaMinXXS} from './useMediaMinXXS'
export {default as useMediaMinXS} from './useMediaMinXS'
export {default as useMediaMinSM} from './useMediaMinSM'
export {default as useMediaMinMD} from './useMediaMinMD'
export {default as useMediaMinLG} from './useMediaMinLG'
export {default as useMediaMinXL} from './useMediaMinXL'
export {default as useMediaMinXXL} from './useMediaMinXXL'
export {default as usePrevious} from './usePrevious'
