import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router'
/**
 * @returns {dialogOpen: string , editCartDialog: string, openDialog : function, closeDialog: function}
 * dialogOpen is a string identifying the currently opened dialog. If it is null no dialog is open
 * editCartDialog is a string indicating that the dialog is editing an existing cart item
 * openDialog opens/overrides a dialog by setting dialogOpen to some string identifying the dialog
 * closeDialog closes all dialogs
 */
export default function useQueryDialog() {
  const {search} = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(search)

  const dialogOpen = queryParams.get('dialog')

  const editCartDialog = queryParams.get('edit')

  const closeDialog = useCallback(() => {
    navigate('')
  }, [navigate])

  const openDialog = useCallback(
    (dialogOpen, editCartDialog) => {
      if (!!editCartDialog) {
        navigate(`?dialog=${dialogOpen}&edit=${editCartDialog}`)
      } else {
        navigate(`?dialog=${dialogOpen}`)
      }
    },
    [navigate],
  )

  return {dialogOpen, editCartDialog, openDialog, closeDialog}
}
