import {useEffect} from 'react'
import {firebaseAnalytics} from 'f-core/src/config/firebase'

import {useLocation} from 'react-router-dom'

const Analytics = () => {
  const location = useLocation()

  useEffect(() => {
    firebaseAnalytics.setCurrentScreen(location.pathname)
  }, [location])

  return null
}

export default Analytics
