"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateFoodlyPointsEarnedSubTotal = void 0;

var calculateFoodlyPointsEarnedSubTotal = function calculateFoodlyPointsEarnedSubTotal(_ref) {
  var subTotal = _ref.subTotal;
  // Points per dollar == 10
  return Math.floor(Math.max(subTotal, 0)) * 10;
};

exports.calculateFoodlyPointsEarnedSubTotal = calculateFoodlyPointsEarnedSubTotal;