import React from 'react'
import {FText, FView, FButton} from 'components'
import {googleIcon} from 'images'

export default function GoogleButton({text, onClick}) {
  return (
    <FButton onClick={onClick}>
      <FView round bc="black" bw={1} ph={32} pv={16} row center>
        <img alt="google icon" src={googleIcon} width={16} height={16} />
        <FView w={16} />
        <FText grey800 semiBold>
          {text}
        </FText>
      </FView>
    </FButton>
  )
}
