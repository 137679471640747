import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import {kebabCase} from 'lodash'

import {useTheme} from '@material-ui/core/styles'

import {FButton, FText, FView} from 'components'
import {useMediaMinLG, useMediaMinMD} from 'f-web/src/hooks'
import * as utils from 'f-utils'

const extractNameFromRewards = (rewards) => {
  for (const reward of Object.values(rewards ?? {})) {
    if (reward.rewardName) {
      return reward.rewardName
    }
  }

  return ''
}

const LocationPreview = ({location, fixed = false}) => {
  const theme = useTheme()
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  const dispatch = useDispatch()
  const locationId = location.locationId
  const orderType = useSelector(dispatch.user.getOrderType)
  const deliveryFee = useSelector(() => dispatch.restaurants.getEstimatedDeliveryFee({locationId}))
  const currentOrderOpenDetails = useSelector(() => {
    let orderOpenDetails = dispatch.restaurants.getOrderOpenDetails({locationId})
    if (orderOpenDetails.isOpen && orderType === 'Delivery') {
      orderOpenDetails = dispatch.restaurants.getDeliveryOrderOpenDetails({locationId})
    }
    return orderOpenDetails
  }, utils.isOrderOpenDetailsEqual)
  const p = 8
  const rewardName = extractNameFromRewards(location.rewards)
  return (
    <FView
      mv={16}
      w={!fixed ? '100%' : isMinMD ? (isMinLG ? 450 : 500) : 999}
      maxWidth={
        !fixed
          ? '100%'
          : isMinMD
          ? isMinLG
            ? `calc(33.333333% - ${p}px)`
            : `calc(50% - ${p}px)`
          : `calc(100% -${p}px)`
      }>
      <FButton
        to={`/${kebabCase(location.restaurantName)}/restaurant/${location.restaurantId}/location/${
          location.locationId
        }`}>
        <FView>
          {rewardName && (
            <FView zIndex={5} bg={theme.palette.primary.main} pv={8} ph={16} absolute left={0} top={0}>
              <FText white bold>
                {rewardName}
              </FText>
            </FView>
          )}
          <FView>
            <FView relative>
              <img
                draggable={false}
                src={location.imageUrl ?? location.promoUrl}
                alt="preview"
                style={{
                  objectFit: 'cover',
                  height: 230,
                  width: '100%',
                }}
              />
              {!currentOrderOpenDetails?.isOpen && (
                <FView zIndex={6}>
                  <FView bg={'rgba(0,0,0,0.5)'} absoluteFill />
                  <FView absoluteFill center mh={'10%'}>
                    <FText h6 bold white>
                      Ordering Closed
                    </FText>
                    {currentOrderOpenDetails?.openMoment && (
                      <FText bold white>
                        {(orderType ?? '') + ' Opens ' + currentOrderOpenDetails.openMoment.calendar()}
                      </FText>
                    )}
                  </FView>
                </FView>
              )}
            </FView>
            <FView h={4} />
            <FText bold>{location.restaurantName}</FText>
            <FText color={theme.palette.grey[700]}>
              {(orderType === 'Delivery'
                ? `$${deliveryFee} Delivery Fee`
                : `${Math.round(location.distance)} km away`) + ` • ${location.waitTime}-${location.waitTime + 10}min`}
            </FText>
          </FView>
        </FView>
      </FButton>
    </FView>
  )
}
export default LocationPreview
