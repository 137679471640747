import {shallowEqual} from 'react-redux'

const locationResolver = async (dispatch) => {
  const efficientSetUserLocation = async (location) => {
    const userLocation = dispatch.user.getUserLocation() ?? {}
    if (!shallowEqual(location, userLocation)) {
      return await dispatch.user.setUserLocation(location)
    }
  }

  // const tryGeolocation = async () => {
  //   try {
  //     const geolocationPermissionPromise = navigator.permissions.query({name: 'geolocation'})
  //     const timer = new Promise((res, _) => setTimeout(() => res('timeout', 1000)))
  //     if (geolocationPermissionPromise) {
  //       // Browser supports navigator.permissions.query
  //       const data = await geolocationPermissionPromise

  //       if (data) {
  //         if (data.state === 'granted') {
  //           const position = await Promise.race([
  //             new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)),
  //             new Promise((res, _) => setTimeout(() => res('timeout'), 1000)),
  //           ])
  //           if (position === 'timeout') {
  //             throw new Error('timeout')
  //           }
  //           const res = await Promise.race([
  //             timer,
  //             efficientSetUserLocation({...position.coords, method: 'geolocation'}),
  //           ])
  //           if (res === 'timeout') {
  //             throw new Error('timeout')
  //           }
  //         } else {
  //           throw new Error('not granted')
  //         }
  //       } else {
  //         throw new Error('no data')
  //       }
  //     } else {
  //       throw new Error('no geolocationPermissionPromise')
  //     }
  //   } catch (e) {
  //     throw new Error('Failed to locate user Geolocation ' + e.message)
  //   }
  // }

  const tryIP = async () => {
    try {
      const {latitude, longitude, city, region, country} = await Promise.race([
        new Promise((_, rej) => setTimeout(() => rej(new Error('timeout')), 5000)),
        dispatch.user.getUserLocationByIP(),
      ])
      const location = {latitude, longitude, city, region, country, method: 'ip'}
      return efficientSetUserLocation(location)
    } catch (e) {
      // Backup location is Foodly Headquarter
      throw new Error('Failed to locate user IP: ' + e.message)
    }
  }

  const tryPreviouslyStored = async () => {
    const userLocation = dispatch.user.getUserLocation()
    if (userLocation) {
      return efficientSetUserLocation({...userLocation, method: 'previouslyStored'})
    } else {
      throw new Error('No user location previously stored')
    }
  }

  const tryHQ = async () => {
    try {
      return await efficientSetUserLocation({
        latitude: 49.252162663696105,
        longitude: -122.89456835857399,
        city: 'Burnaby',
        region: 'BC',
        country: 'CA',
        method: 'fake',
      })
    } catch (e) {
      throw new Error('userlocation not set' + e.message)
    }
  }

  const tryAddress = async () => {
    const addressData = dispatch.user.getAddressData()
    if (addressData) {
      const {city, region, country, containerDeliveryAddressLatLng} = addressData
      // Set user's current location with delivery address
      return efficientSetUserLocation({
        latitude: containerDeliveryAddressLatLng.lat,
        longitude: containerDeliveryAddressLatLng.lng,
        city: city ?? null,
        region: region ?? null,
        country: country ?? null,
        method: 'address',
      })
    } else {
      throw new Error('Address Data is empty')
    }
  }

  const strategies = [tryAddress, tryPreviouslyStored, tryIP, tryHQ]

  for (const strategy of strategies) {
    try {
      return await strategy()
    } catch (e) {
      console.warn(e.message)
    }
  }

  throw new Error('Ran out of strategies, could not set userLocation')
}

export default locationResolver
