import React, {useEffect} from 'react'
import {FButton, FText, FView, NavBarV2, TagSelector} from 'components'
import {useFoodlyRestaurants} from 'f-app-models/hooks'
import {useLocation} from 'react-router-dom'
import {useTheme} from '@material-ui/core/styles'
import LocationPreview from 'components/LocationPreview'

export default function Restaurants() {
  const {state} = useLocation()
  const {
    restaurantFilterKeyword,
    selectedFilterType,
    setSelectedFilterType,
    setRestaurantFilterKeyword,
    sortedLocationList,
    selectedTags,
    setSelectedTags,
  } = useFoodlyRestaurants()
  const theme = useTheme()

  const filterTypes = ['Nearest', 'Fastest', 'Deals']

  useEffect(() => {
    if (state?.selectedFilterType) {
      setSelectedFilterType(state.selectedFilterType)
    }
    if (state?.selectedTags) {
      setSelectedTags(state.selectedTags)
    }
  }, [setSelectedFilterType, setSelectedTags, state])

  return (
    <FView alignCenter>
      <NavBarV2 override={{sortedLocationList, restaurantFilterKeyword, setRestaurantFilterKeyword}} />
      <FView h={32} />
      <TagSelector selectedTags={selectedTags} />
      <FView h={24} />
      <FView w={1440} maxWidth="100vw" ph="3vw">
        <FText h5 bold>
          {selectedFilterType} {Object.keys(selectedTags ?? {})[0]} Restaurants
        </FText>
        <FText h6 bold color={theme.palette.grey[700]}>
          {sortedLocationList.length} Restaurants
        </FText>
        <FView row alignCenter>
          {filterTypes.map((filterType) => (
            <FButton key={filterType} onClick={() => setSelectedFilterType(filterType)}>
              <FView
                br={32}
                bg={selectedFilterType === filterType ? theme.palette.primary.main : theme.palette.grey[100]}
                ph={12}
                pv={4}
                mh={8}
                justifyCenter>
                <FText h6 bold color={selectedFilterType === filterType ? 'white' : 'black'}>
                  {filterType}
                </FText>
              </FView>
            </FButton>
          ))}
        </FView>
      </FView>

      <FView w={1440} maxWidth={'100vw'} row wrap ph="3vw">
        {sortedLocationList.map((location) => (
          <React.Fragment key={location.locationId}>
            <LocationPreview fixed location={location} />
            <FView w={8} />
          </React.Fragment>
        ))}
      </FView>
    </FView>
  )
}
