import React, {useEffect} from 'react'
import {Footer, FText, FView, NavBarV2} from 'components'
import {Divider} from '@material-ui/core'

const Heading = ({children, ...props}) => (
  <FText bold gutterBottom {...props}>
    {children}
  </FText>
)
const Content = ({children}) => (
  <FText body1 paragraph gutterBottom>
    {children}
  </FText>
)

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <FView overflowX="auto">
      <NavBarV2 />
      <FView selfCenter p={25} maxWidth={1000}>
        <Heading h5 alignCenter paragraph>
          Terms Of Use For Website And Applications
        </Heading>
        <Content>
          This page (together with the documents referred to on it) tells you the terms of use on which you may make use
          of our website foodly.ca (our "Site") or any application we make available via an app store or otherwise (our
          "Service"), whether as a guest or a registered user. Please read these terms of use carefully before you start
          to use or Site or our Service. By accessing our Site or by using our Service, you indicate that you accept
          these terms of use and that you agree to abide by them. If you do not agree to these terms of use, do not use
          access our Site or use our Service.
        </Content>
        <Heading>1. Information About Us</Heading>
        <Content>
          Foodly.ca is a website operated by Foodly Technologies Inc("we", "us" or "Foodly"), incorporated and
          registered in British Columbia, Canada. Foodly Technologies Inc is a business where the food is prepared by
          independent restaurants (our "Partner Restaurants") and delivered by us.
        </Content>
        <Heading>2. Accessing Our Service Or Our Services</Heading>
        <Content>
          Access to our Site and to our Service is permitted on a temporary basis, and we reserve the right to withdraw
          or amend access to our Site or our Service without notice (see below). We will not be liable if, for any
          reason, our Site or our Service is unavailable at any time or for any period. From time to time, we may
          restrict access to some parts of our Site or our Service, or our entire Site or Service to users who have
          registered with us. You are responsible for maintaining the confidentiality of your login details and any
          activities that occur under your account. If you have any concerns about your login details or think they have
          been misused, you should contact hello@foodly.ca straight away to let us know. We can deactivate your account
          at any time.
        </Content>
        <Heading>3. Acceptable Use</Heading>
        <Content>
          You may use our Service only for lawful purposes. You may not use our Site or our Service in any way that
          breaches any applicable local, national or international law or regulation or to send, knowingly receive,
          upload, download, use or re-use any material which does not comply with our content standards in clause 5
          below. You also agree not to access without authority, interfere with, damage or disrupt any part of our Site
          or our Service or any network or equipment used in the provision of our Service.
        </Content>
        <Heading>4. Interactive Features Of Our Site</Heading>
        <Content>
          We may from time to time provide certain features which allow you to interact through our Site or our Service
          such as chat rooms. Generally, we do not moderate any interactive service we provide, although we may remove
          content in contravention of these Terms of Use as set out in section 6. If we do decide to moderate an
          interactive service, we will make this clear before you use the service and normally provide you with a means
          of contacting the moderator, should a concern or difficulty arise.
        </Content>
        <Heading>5. Intellectual Property Rights</Heading>
        <Content>
          We are the owner of or the licensee of all intellectual property rights in our Site and our Service, and in
          the material published on it (excluding your Contributions). Those works are protected by copyright laws and
          treaties around the world. All such rights are reserved. You may not copy, reproduce, republish, download,
          post, broadcast, transmit, make available to the public, or otherwise use any content on our site in any way
          except for your own personal, non-commercial use.
        </Content>
        <Heading>6. Reliance On Information Posted</Heading>
        <Content>
          Commentary and other materials posted on our Service are not intended to amount to advice on which reliance
          should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on
          such materials by any visitor to our Service, or by anyone who may be informed of any of its contents.
        </Content>
        <Heading>7. Our Site And Our Service Change Regularly</Heading>
        <Content>
          We aim to update our Site and our Service regularly, and may change the content at any time. If the need
          arises, we may suspend access to our Site and our Service, or close them indefinitely. Any of the material on
          our Site or our Service may be out of date at any given time, and we are under no obligation to update such
          material.
        </Content>
        <Heading>8. Our Liability</Heading>
        <Content>
          We have taken cautions in the preparation of our Site and our Service. However, we will not be responsible for
          any errors or omissions in relation to such content or for any technical difficulties you may experience with
          our Site or our Service. If we are informed of any inaccuracies on our Site or in our Service we will attempt
          to correct this as soon as we reasonably can. To the extent permitted by law, we exclude all liability
          (whether arising in contract, negligence or otherwise) for loss or damage which you or any third party may
          incur in connection with our Site, our Service, and any website linked to our Site and any materials posted on
          it.
        </Content>
        <Heading>9. Information About You And Your Visits To Our Site And Use Of Our Service</Heading>
        <Content>
          We collect certain data about you as a result of you using our Service. This is described in more detail in
          our privacy policy.
        </Content>
        <Heading>10. Links From Our Site</Heading>
        <Content>
          Where our Site contains links to other sites and resources provided by third parties, these links are provided
          for your information only. We have no control over the contents of those sites or resources, and accept no
          responsibility for them or for any loss or damage that may arise from your use of them.
        </Content>
        <Heading>11. Jurisdiction And Applicable Law</Heading>
        <Content>
          Terms of use and any dispute or claim arising out of or in connection with them or their subject matter or
          formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with
          the law of British Columbia, Canada
        </Content>
        <Heading>12. Variations</Heading>
        <Content>
          We may revise these terms of use at any time by amending this page. You are expected to check this page from
          time to time to take notice of any changes we make, as they are binding on you.
        </Content>
      </FView>
      <Divider />
      <FView size={50} />
      <Footer />
    </FView>
  )
}
