import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Info} from '@material-ui/icons'
import {Divider, useTheme, Tooltip, withStyles} from '@material-ui/core'
import {FButton, FView, FText} from 'components'
import {useFoodlyAppCart} from 'f-app-models/hooks'
import TipsView from './TipsView'
import _, {isEmpty} from 'lodash'
import {useNavigate} from 'react-router-dom'
import {useMediaMinMD, useMediaMinXL} from 'f-web/src/hooks'
import RedeemFPointsView from './RedeemFPointsView'
import CartItems from 'pages/Location/componentsV2/CartItems'
import EditNotesDialog from 'pages/Location/componentsV2/EditNotesDialog'
import ProductDialog from 'pages/Location/componentsV2/ProductDialog'
import useQueryDialog from 'components/useQueryDialog'

function OrderSummary({notReady, setDialogOpen, directOrder}) {
  const theme = useTheme()
  const isMinMD = useMediaMinMD()
  const isMinXL = useMediaMinXL()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {openDialog} = useQueryDialog()
  const readyToOrder = notReady.length === 0

  const phoneNumber = useSelector(dispatch.user.getPhoneNumber)
  const isPhoneNumberVerified = useSelector(() => dispatch.user.getIsPhoneNumberVerified({phoneNumber}))

  const restaurantId = useSelector(dispatch.user.getCartRestaurantId)
  const {
    cartAfterTaxDiscount,
    cartItems,
    cartSubTotal,
    cartTotal,
    deliveryFee,
    fPointsEarned,
    currentOrderOpenDetails,
    locationId,
    orderType,
    isCartEmpty,
    promoItems,
    taxes,
    tipAmount,
    tipAmountForTipSelectionIndexes,
    tipSelectionIndex,
    totalBeforeTip,
    deliveryMinimumSubTotal,
  } = useFoodlyAppCart()
  const hasMinimumSubtotalForDelivery = orderType !== 'Delivery' || cartSubTotal >= deliveryMinimumSubTotal

  const products = useSelector(() => dispatch.restaurants.getProducts({locationId}))
  const modifierGroups = useSelector(() => dispatch.restaurants.getModifierGroups({locationId}))
  const taxTotal = _.sumBy(Object.values(taxes), 'taxAmount')
  const [placingOrder, setPlacingOrder] = useState(false)

  const [cartNotesId, setCartNotesId] = useState('')
  const disabled = placingOrder || isCartEmpty || !currentOrderOpenDetails.isOpen || !hasMinimumSubtotalForDelivery

  let text = 'Place Order'

  if (!readyToOrder) {
    if (notReady[0] === 'contact' && !isPhoneNumberVerified) {
      text = 'Verify Phone Number'
    } else {
      text = `Add ${_.capitalize(notReady[0])}`
    }
  }
  if (notReady[0] === 'delivery') {
    text = 'Add a Delivery Address'
  }
  if (isCartEmpty) {
    text = 'Empty Cart'
  }
  if (!hasMinimumSubtotalForDelivery) {
    text = `Subtotal must be at least $${deliveryMinimumSubTotal} for delivery`
  }
  if (notReady[0] === 'invalidCart') {
    text = 'Next'
  }
  if (!currentOrderOpenDetails.isOpen) {
    text = 'Ordering Closed'
    if (currentOrderOpenDetails?.openMoment) {
      text += '\n' + (orderType ?? '') + ' Opens ' + currentOrderOpenDetails.openMoment.calendar()
    }
  }

  if (placingOrder) {
    text = 'Placing Order...'
  }

  return (
    <FView w={isMinXL ? 425 : isMinMD ? 375 : 425} maxWidth={'100vw'} bg={theme.palette.grey[50]} pv={16} ph={16}>
      <FText h5 bold>
        Your Order
      </FText>
      <FView h={16} />
      {!isEmpty(products) && (
        <CartItems
          cartItems={cartItems}
          products={products}
          modifierGroups={modifierGroups}
          restaurantId={restaurantId}
          locationId={locationId}
          promoItems={promoItems}
          setProductDialogId={openDialog}
        />
      )}

      <ProductDialog locationId={locationId} restaurantId={restaurantId} />
      {!!cartNotesId && (
        <EditNotesDialog
          cartItemId={cartNotesId}
          cartItems={cartItems}
          products={products}
          onClose={() => {
            setCartNotesId('')
          }}
        />
      )}
      <FView mt={16} mb={16}>
        <Divider />
      </FView>
      <RedeemFPointsView />
      <Divider />
      <FView h={16} />
      <FView row justifyBetween>
        <FText semiBold>Subtotal</FText>
        <FText semiBold>${cartSubTotal.toFixed(2)}</FText>
      </FView>
      <FView row justifyBetween>
        <FText semiBold>Delivery Fee</FText>
        <FText semiBold>${deliveryFee.toFixed(2)}</FText>
      </FView>
      <FView row justifyBetween>
        <FView row alignCenter>
          <FText semiBold>Taxes</FText>
          <FView w={4} />
          <FTooltip
            leaveTouchDelay={10000}
            enterTouchDelay={0}
            title={
              Object.values(taxes).reduce(
                (s, tax, index) =>
                  s + tax.name + ' $' + tax.taxAmount + (Object.values(taxes).length - 1 !== index ? ' + ' : ''),
                '',
              ) +
              ' = $' +
              taxTotal.toFixed(2)
            }
            arrow>
            <Info style={{color: theme.palette.grey[500]}} fontSize="small" />
          </FTooltip>
        </FView>
        <FText semiBold> ${taxTotal.toFixed(2)}</FText>
      </FView>
      <FView row justifyBetween>
        <FText semiBold>Tip</FText>
        <FText semiBold>${tipAmount.toFixed(2)}</FText>
      </FView>
      {cartAfterTaxDiscount > 0 && (
        <FView row justifyBetween>
          <FText semiBold>Discount</FText>
          <FText semiBold>-${cartAfterTaxDiscount.toFixed(2)}</FText>
        </FView>
      )}

      <FView mv={16}>
        <Divider />
      </FView>
      <FView row justifyBetween>
        <FText semiBold>Total</FText>
        <FText bold secondary>
          ${cartTotal.toFixed(2)}
        </FText>
      </FView>
      <FView mv={16}>
        <Divider />
      </FView>
      <FView row justifyBetween>
        <FText semiBold>Earned Rewards</FText>
        <FText semiBold>{fPointsEarned} points</FText>
      </FView>
      <FView mv={16}>
        <Divider />
      </FView>
      <FView row justifyBetween mb={16}>
        <FText bold>Add Tips</FText>
      </FView>
      <TipsView
        tipSelectionIndex={tipSelectionIndex}
        tipAmountForTipSelectionIndexes={tipAmountForTipSelectionIndexes}
        totalBeforeTip={totalBeforeTip.toFixed(2)}
        orderType={orderType}
      />
      <FView h={32} />
      <FButton
        rounded
        hoverLighten
        disabled={disabled}
        onClick={async () => {
          if (readyToOrder) {
            try {
              setPlacingOrder(true)
              const orderId = await dispatch.user.placeOrder({directOrder})
              const locationData = dispatch.restaurants.getLocation({locationId})

              if (directOrder) {
                navigate(
                  `/direct/orders/${
                    _.kebabCase(locationData?.restaurantName) || 'restaurant'
                  }/${restaurantId}/${locationId}/${orderId}`,
                )
              } else {
                navigate('/orders/' + orderId)
              }
              setPlacingOrder(false)
            } catch (e) {
              setPlacingOrder(false)
              if (e.statusCode === 402) {
                alert('Payment Error ' + e.message)
              } else {
                alert('Place order failed. ' + e.message)
              }
            }
          } else {
            setDialogOpen(notReady[0])
          }
        }}>
        <FView round p={10} bg={disabled ? theme.palette.grey[500] : theme.palette.primary.main}>
          <FText white h6 bold style={{whiteSpace: 'pre-line'}}>
            {text}
          </FText>
        </FView>
      </FButton>
    </FView>
  )
}

const FTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[500],
    color: 'white',
    fontSize: '14px',
    fontWeight: '600',
    border: '1px solid #dadde9',
  },
}))(Tooltip)

export default OrderSummary
