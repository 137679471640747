import React from 'react'
import {FText, FView, FButton} from 'components'
import {Apple} from '@material-ui/icons'

export default function AppleButton({text, onClick}) {
  return (
    <FButton onClick={onClick}>
      <FView round bc="black" bw={1} ph={32} pv={16} row center>
        <Apple />
        <FView w={16} />
        <FText semiBold>{text}</FText>
      </FView>
    </FButton>
  )
}
