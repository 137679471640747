import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReactMapboxGl, {Marker} from 'react-mapbox-gl'
import {css} from 'emotion'
import * as images from 'images'
import {useMediaMinMD} from 'f-web/src/hooks'
import {FView} from 'components'

const MapBox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
})

const generateFitBounds = (l1, l2) => {
  const z = 0.01
  const n1lng = l1[0] + (l1[0] < l2[0] ? -z : z)
  const n2lng = l2[0] + (l2[0] < l1[0] ? -z : z)
  const n1lat = l1[1] + (l1[1] < l2[1] ? -z : z)
  const n2lat = l2[1] + (l2[1] < l1[1] ? -z : z)

  return [
    [n1lng, n1lat],
    [n2lng, n2lat],
  ]
}

const MAPBOX_STYLE = 'mapbox://styles/mapbox/streets-v9'
export default function CheckoutMap() {
  const dispatch = useDispatch()
  const isMinMD = useMediaMinMD()
  const userLocation = dispatch.user.getUserLocation()
  const orderType = useSelector(dispatch.user.getOrderType)
  const locationId = useSelector(dispatch.user.getCartLocationId) ?? ''
  const location = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const fitBounds = generateFitBounds(
    [userLocation.longitude, userLocation.latitude],
    [location?.restaurantLatLng?.lng ?? 0, location?.restaurantLatLng?.lat ?? 0],
  )
  return (
    <FView overflow="hidden" fill br={isMinMD ? 16 : 0}>
      <MapBox
        fitBounds={fitBounds}
        // eslint-disable-next-line react/style-prop-object
        style={MAPBOX_STYLE}
        containerStyle={styles.mapboxContainerStyle}>
        {locationId && location?.restaurantLatLng && (
          <Marker
            key={'restaurant'}
            coordinates={[location.restaurantLatLng.lng, location.restaurantLatLng.lat]}
            anchor="center"
            className={classes.mapPin}>
            <img src={images.restaurantPin} alt="map pin" />
          </Marker>
        )}
        <Marker
          key={'user'}
          coordinates={[userLocation.longitude, userLocation.latitude]}
          anchor="center"
          className={classes.mapPin}>
          <img src={orderType === 'Delivery' ? images.homePin : images.personPin} alt="map pin" />
        </Marker>
      </MapBox>
    </FView>
  )
}

const styles = {
  mapboxContainerStyle: {
    width: '100%',
    height: '100%',
  },
}

const classes = {
  mapPin: css({
    cursor: 'pointer',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
  // restaurantIconStyle: css({width: 85, height: 85, resizeMode: 'contain'}),
}
