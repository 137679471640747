import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import _ from 'lodash'
import {LinearProgress} from '@material-ui/core'
import {FView, NavBarV2, FText, FButton, ButtonFillView} from 'components'
import CategoriesList from './componentsV2/CategoriesList'
import CategoryHorizontalList from './componentsV2/CategoryHorizontalList'
import OrderTypeDialog from './componentsV2/OrderTypeDialog'
import ProductDialog from './componentsV2/ProductDialog'
import RestaurantHeader from './componentsV2/RestaurantHeader'
import {Helmet} from 'react-helmet'
import useQueryDialog from 'components/useQueryDialog'

export default function Location({directOrder}) {
  const dispatch = useDispatch()
  const {restaurantId, locationId} = useParams()
  const {openDialog} = useQueryDialog()
  const location = useLocation()
  const navigate = useNavigate()
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const activeCategoryOrder = useSelector(() => dispatch.restaurants.getActiveCategoryOrder({locationId}), shallowEqual)
  const isLoading = useSelector(() => dispatch.restaurants.getIsFoodLoading({locationId}))

  useEffect(() => {
    if (locationId && restaurantId) {
      dispatch.user.subscribeRewardsWithCode({restaurantId, locationId})
      return dispatch.restaurants.subscribeLocation({restaurantId, locationId})
    }
  }, [dispatch.restaurants, restaurantId, locationId, dispatch.user])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const URLOrderType = queryParams.get('orderType')
    const couponCode = queryParams.get('couponCode')
    let hasParams = false
    if (URLOrderType === 'Delivery' || URLOrderType === 'Pickup') {
      dispatch.user.setOrderType({orderType: URLOrderType})
      queryParams.delete('orderType')
      hasParams = true
    }
    if (couponCode) {
      dispatch.user.addPromo({code: couponCode, restaurantId, locationId}).catch((_) => {})
      queryParams.delete('couponCode')
      hasParams = true
    }
    hasParams && navigate(queryParams.toString(), {replace: true})
  }, [location.search, dispatch.restaurants, dispatch.user, locationId, navigate, restaurantId])

  // Update Favicon to Restaurant Icon for Direct Order
  useEffect(() => {
    if (directOrder && locationData.restaurantIconUrl) {
      document.querySelector("link[rel*='icon']").href = locationData.restaurantIconUrl
      document.querySelector("link[rel*='apple-touch-icon']").href = locationData.restaurantIconUrl
    }
  }, [directOrder, locationData.restaurantIconUrl])

  if (!locationData) {
    return (
      <FView p={24} center>
        <NavBarV2 hasOrderTypeSelector={!directOrder} locationId={locationId} directOrder={directOrder} />
        <FView size={60} />
        <FText h6>Nothing to eat here...</FText>
        <FButton to="/restaurants">
          <ButtonFillView rounded p={16}>
            <FText body1 white bold>
              Discover Other Food
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>
    )
  }

  return (
    <>
      <Helmet>
        {!!locationData.title && <title>{locationData.title}</title>}
        {!!locationData.title && <meta name="title" content={locationData.title} />}
        {!!locationData.description && <meta name="description" content={locationData.description} />}
        {!!locationData.keywords && <meta name="keywords" content={locationData.keywords} />}
      </Helmet>

      <FView alignCenter>
        <NavBarV2 hasOrderTypeSelector={!directOrder} locationId={locationId} directOrder={directOrder} />
        <FView bg="white" fill alignCenter minHeight={'100vh'}>
          <FView ph={'3.33vw'} w={1440} maxWidth={'100vw'} relative>
            <RestaurantHeader
              directOrder={directOrder}
              address={locationData.address}
              promoUrl={locationData.promoUrl}
              restaurantName={locationData.restaurantName}
              restaurantType={locationData.restaurantType}
              tags={locationData.tags}
              waitTime={locationData.waitTime}
              locationData={locationData}
            />
            <LoadingComponent locationId={locationId} activeCategoryOrder={activeCategoryOrder} />
            {!isLoading && !_.isEmpty(activeCategoryOrder) && (
              <Menu
                activeCategoryOrder={activeCategoryOrder}
                locationId={locationId}
                directOrder={directOrder}
                setOrderTypeDialogOpen={openDialog}
                setSelectedProductId={openDialog}
              />
            )}
          </FView>
        </FView>
        <ProductDialog locationId={locationId} restaurantId={restaurantId} />

        <OrderTypeDialog locationId={locationId} />
      </FView>
    </>
  )
}

const LoadingComponent = ({locationId, activeCategoryOrder}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(() => dispatch.restaurants.getIsFoodLoading({locationId}))
  return (
    <>
      {isLoading && _.isEmpty(activeCategoryOrder) && (
        <>
          <FView h={64} w={'100vw'} />
          <LinearProgress />
        </>
      )}
    </>
  )
}
const Menu = ({activeCategoryOrder, locationId, directOrder, setOrderTypeDialogOpen, setSelectedProductId}) => {
  const [selectedCategory, setSelectedCategory] = useState(0)
  const jumpRef = useRef(false)
  const categoryItemRefs = useRef({})

  return (
    <>
      <CategoryHorizontalList
        activeCategoryOrder={activeCategoryOrder}
        categoryItemRefs={categoryItemRefs}
        locationId={locationId}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        jumpRef={jumpRef}
      />
      <CategoriesList
        directOrder={directOrder}
        activeCategoryOrder={activeCategoryOrder}
        locationId={locationId}
        ref={categoryItemRefs}
        setSelectedProductId={setSelectedProductId}
        setSelectedCategory={setSelectedCategory}
        jumpRef={jumpRef}
        setOrderTypeDialogOpen={setOrderTypeDialogOpen}
      />
    </>
  )
}
