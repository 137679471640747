import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FText, FView, FButton} from 'components'
import {useTheme} from '@material-ui/core/styles'
import * as images from 'images'
import {useFoodlyAppCart} from 'f-app-models/hooks'
import {useNavigate, useParams} from 'react-router-dom'
import CartItems from './CartItems'
import {useMediaMinLG, useMediaMinXL} from 'f-web/src/hooks'
import useQueryDialog from 'components/useQueryDialog'

const Cart = ({directOrder}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {restaurantName, restaurantId, locationId} = useParams()
  const {openDialog} = useQueryDialog()
  const isMinLG = useMediaMinLG()
  const isMinXL = useMediaMinXL()
  const {
    cartItems,
    cartSubTotal,
    cartSubTotalBeforeDiscount,
    currentOrderOpenDetails,
    deliveryAddressDistance,
    deliveryDistance,
    deliveryMinimumSubTotal,
    isCartEmpty,
    isDeliveryAddressEmpty,
    locationId: cartLocationId,
    orderType,
    promoItems,
  } = useFoodlyAppCart()

  const restaurantType = useSelector(() => dispatch.restaurants.getRestaurantType({locationId}))
  const products = useSelector(() => dispatch.restaurants.getProducts({locationId}))
  const modifierGroups = useSelector(() => dispatch.restaurants.getModifierGroups({locationId}))

  let buttonText = `Go to checkout - $${cartSubTotal?.toFixed(2)}`
  let disabled = false

  if (orderType === 'Delivery' && cartSubTotal < deliveryMinimumSubTotal) {
    buttonText = `Subtotal must be at least $${deliveryMinimumSubTotal} for delivery`
    disabled = true
  }

  if (!currentOrderOpenDetails.isOpen) {
    buttonText = `Ordering Closed`
    disabled = true
  }

  if (orderType === 'Delivery') {
    if (deliveryAddressDistance > deliveryDistance) {
      if (isDeliveryAddressEmpty) {
        buttonText = 'Enter a delivery address in the nav bar up top to check availability or choose pickup'
      } else {
        buttonText = `We can only deliver within ${deliveryDistance}km radius from the restaurant.`
      }
      disabled = true
    }
    if (cartSubTotalBeforeDiscount < deliveryMinimumSubTotal) {
      buttonText = `Subtotal must be at least $${deliveryMinimumSubTotal} for delivery`
      disabled = true
    }
  }

  return (
    <FView>
      <FView w={isMinXL ? 425 : isMinLG ? 375 : 425} maxWidth={'100%'}>
        {isCartEmpty || cartLocationId !== locationId ? (
          <FView mt={32} mb={48}>
            <FView mb={8}>
              <img
                src={restaurantType === 'Cafe' ? images.noItemsCafe : images.noItems}
                style={{objectFit: 'contain', height: 200}}
                alt="no items"
              />
            </FView>
            <FText color={theme.palette.grey[700]} bold body1 alignCenter>
              No items in your cart
            </FText>
          </FView>
        ) : (
          <FView maxHeight={'calc(100vh - 100px)'}>
            <FView p={16}>
              <FText h5 bold>
                Your Order
              </FText>
            </FView>

            <FView overflowY="auto" ph={16} pb={8}>
              <CartItems
                cartItems={cartItems}
                products={products}
                modifierGroups={modifierGroups}
                restaurantId={restaurantId}
                locationId={locationId}
                promoItems={promoItems}
                setProductDialogId={openDialog}
              />
            </FView>

            <FView p={16}>
              <FButton
                round
                hoverLighten
                disabled={disabled}
                onClick={() => {
                  navigate(
                    directOrder ? `/direct/checkout/${restaurantName}/${restaurantId}/${locationId}` : `/checkout`,
                  )
                }}>
                <FView
                  h={32}
                  br={4}
                  p={24}
                  justifyCenter
                  bg={disabled ? theme.palette.grey[400] : theme.palette.primary.main}>
                  <FText white bold body1>
                    {buttonText}
                  </FText>
                </FView>
              </FButton>
            </FView>
          </FView>
        )}
      </FView>
    </FView>
  )
}
export default Cart
