import React from 'react'
import {FText, FView, FButton} from 'components'
import {getIsProductActive} from 'f-utils/src'
import {useDispatch, useSelector} from 'react-redux'
import {Grid, makeStyles} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import {useMediaMinSM} from 'f-web/src/hooks'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
})

const TagView = ({tagName, bg}) => (
  <FView br={20} ph={8} pv={2} mt={4} mr={4} bg={bg}>
    <FText caption>{tagName}</FText>
  </FView>
)

export default function ProductCard({locationId, productId, onClick, publicProductTags, discount}) {
  const dispatch = useDispatch()
  const theme = useTheme()

  const productData = useSelector(() => dispatch.restaurants.getProductDetails({locationId, productId}))
  const inStock = getIsProductActive(productData)
  const classes = useStyles()
  const isMinSM = useMediaMinSM()
  return (
    <Grid container item sm={12} md={6} classes={classes}>
      <FView bc={theme.palette.grey[300]} bw={1} fill minHeight={isMinSM ? 196 : 128}>
        <FButton fill hoverHighlight disabled={!inStock} onClick={onClick}>
          <FView fill row justifyBetween>
            <FView justifyCenter alignStart pv={16} ph={24}>
              <FText alignLeft bold style={{wordBreak: 'normal'}}>
                {productData.name}
              </FText>
              <FView row wrap mt={-4}>
                {typeof productData.qty === 'number' && productData.qty > 0 && (
                  <TagView tagName={`Only ${productData.qty} Remaining`} bg={theme.palette.grey[300]} />
                )}
                {typeof productData.qtyLimit === 'number' && productData.qtyLimit > 0 && (
                  <TagView tagName={`Limit ${productData.qtyLimit} per Order`} bg={theme.palette.grey[300]} />
                )}
                {productData.tags?.map((tagName) => {
                  if (publicProductTags[tagName]) {
                    return <TagView tagName={tagName} key={tagName} bg={publicProductTags[tagName]} />
                  } else {
                    return null
                  }
                })}
              </FView>
              <FView mv={4}>
                <FText alignLeft body2 color={theme.palette.grey[700]} style={{wordBreak: 'normal'}}>
                  {productData.description}
                </FText>
              </FView>
              <FView relative inlineBlock>
                {discount > 0 && inStock && <FView absolute top={'50%'} right={0} h={2} w={'100%'} bg="black" />}
                <FText bold error={!inStock || discount > 0}>
                  {inStock ? productData.price?.toFixed(2) : 'SOLD OUT'}
                </FText>
              </FView>
              {discount > 0 && inStock && (
                <FText bold alignLeft>
                  {Math.max(productData.price - discount, 0)
                    ? Math.max(productData.price - discount, 0)?.toFixed(2)
                    : 'FREE'}
                </FText>
              )}
            </FView>
            {productData.imageUrl && (
              <img
                src={productData.imageUrl}
                style={{
                  objectFit: 'cover',
                  width: 220,
                  maxWidth: '40%',
                  minHeight: isMinSM ? 196 : 128,
                  maxHeight: 300,
                }}
                alt="product"
              />
            )}
          </FView>
        </FButton>
      </FView>
    </Grid>
  )
}
