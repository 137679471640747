import React, {useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import * as pages from 'pages'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import locationResolver from 'f-app-models/locationResolver'
import ScrollToTop from 'ScrollToTop'
import Analytics from 'Analytics'
import {AuthModal} from 'components'

const UserLoadingRoute = ({element, screen, ...rest}) => {
  const dispatch = useDispatch()

  const isLocationsLoaded = useSelector(dispatch.restaurants.getIsLocationsLoaded)
  const isPublicLoaded = useSelector(dispatch.public.getIsPublicLoaded)
  const isUserLoaded = useSelector(dispatch.user.getIsUserLoaded)
  const loaded = isLocationsLoaded && isPublicLoaded && isUserLoaded
  const hasUserLocation = !!useSelector(dispatch.user.getUserLocation)
  const ready = loaded && hasUserLocation

  useEffect(() => {
    dispatch.user.subscribeUser()
    dispatch.public.subscribePublic()
    return dispatch.restaurants.subscribeLocations()
  }, [dispatch.user, dispatch.public, dispatch.restaurants])

  useEffect(() => {
    if (isUserLoaded) {
      locationResolver(dispatch)
    }
  }, [dispatch, isUserLoaded])

  return <Route {...rest} element={ready ? element : <pages.Loading.Loading />} />
}

const DirectOrderLoadingRoute = ({element, screen, ...rest}) => {
  const dispatch = useDispatch()

  const isDirectOrderLoaded = useSelector(dispatch.restaurants.getIsDirectOrderLoaded)
  const isPublicLoaded = useSelector(dispatch.public.getIsPublicLoaded)
  const isUserLoaded = useSelector(dispatch.user.getIsUserLoaded)
  const hasUserLocation = !!useSelector(dispatch.user.getUserLocation)
  const loaded = isDirectOrderLoaded && isPublicLoaded && isUserLoaded
  const ready = loaded && hasUserLocation

  const {restaurantId, locationId} = useParams()

  useEffect(() => {
    dispatch.user.subscribeUser()
    return dispatch.public.subscribePublic()
  }, [dispatch.public, dispatch.user])

  useEffect(() => {
    if (locationId && restaurantId) {
      return dispatch.restaurants.subscribeDirectOrderLocation({restaurantId, locationId})
    }
  }, [dispatch.restaurants, restaurantId, locationId])

  useEffect(() => {
    if (isUserLoaded) {
      locationResolver(dispatch)
    }
  }, [dispatch, isUserLoaded])
  return <Route {...rest} element={ready ? element : <pages.Loading.Loading />} />
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <AuthModal />
      <ScrollToTop />
      <Analytics />
      <Routes>
        <UserLoadingRoute path="/" element={<pages.Home.Home />} />
        <UserLoadingRoute path="/restaurants" element={<pages.Restaurants.Restaurants />} />
        <UserLoadingRoute path="/orders" element={<pages.Orders.Orders />} />
        <UserLoadingRoute path="/orders/:orderId" element={<pages.Orders.Orders />} />
        <UserLoadingRoute path="/account" element={<pages.Account.Account />} />
        <UserLoadingRoute path="/promotions" element={<pages.Promotions.Promotions />} />
        <UserLoadingRoute path="/map" element={<pages.Map.Map />} />
        <UserLoadingRoute path="/deliverWithUs" element={<pages.DeliverWithUs.DeliverWithUs />} />
        <UserLoadingRoute
          screen="location"
          path="/:restaurantName/restaurant/:restaurantId/location/:locationId"
          element={<pages.Location.Location />}
        />
        <UserLoadingRoute path="/restaurant/:restaurantId/location/:locationId" element={<pages.Location.Location />} />
        <UserLoadingRoute path="/checkout" element={<pages.Checkout.Checkout />} />
        <UserLoadingRoute path="/all-restaurants" element={<pages.AllRestaurants.AllRestaurants />} />

        <Route path="/restaurant-owners" element={<pages.RestaurantOwners.RestaurantOwners />} />
        <Route path="/about-us" element={<pages.AboutUs.AboutUs />} />
        <Route path="/privacy" element={<pages.Privacy.Privacy />} />
        <Route path="/terms" element={<pages.Terms.Terms />} />
        <DirectOrderLoadingRoute
          path="/direct/account/:restaurantName/:restaurantId/:locationId"
          element={<pages.Account.Account directOrder />}
        />

        <DirectOrderLoadingRoute
          screen="location"
          path="/direct/order/:restaurantName/:restaurantId/:locationId"
          element={<pages.Location.Location directOrder />}
        />

        <DirectOrderLoadingRoute
          path="/direct/checkout/:restaurantName/:restaurantId/:locationId"
          element={<pages.Checkout.Checkout directOrder />}
        />

        <DirectOrderLoadingRoute
          path="/direct/orders/:restaurantName/:restaurantId/:locationId"
          element={<pages.Orders.Orders directOrder />}
        />
        <DirectOrderLoadingRoute
          path="/direct/orders/:restaurantName/:restaurantId/:locationId/:orderId"
          element={<pages.Orders.Orders directOrder />}
        />
        <DirectOrderLoadingRoute
          path="/direct/account/:restaurantName/:restaurantId/:locationId/"
          element={<pages.Account.Account directOrder />}
        />
        {/* QR code scan direct dine in orders */}
        {/* <DirectOrderLoadingRoute
          path="/direct/order/:restaurantName/:restaurantId/:locationId/:myOrderType/:myTableNumber"
          element={<pages.Location.DirectOrder />}
        />
        <DirectOrderLoadingRoute
          path="/direct/payment/:restaurantName/:restaurantId/:locationId/:myOrderType/:myTableNumber"
          element={<pages.Payment.Payment directOrder />}
        />
        <DirectOrderLoadingRoute
          path="/direct/orders/:restaurantName/:restaurantId/:locationId/:myOrderType/:myTableNumber"
          element={<pages.Orders.Orders directOrder />}
        />
        <DirectOrderLoadingRoute
          path="/direct/orders/:restaurantName/:restaurantId/:locationId/:orderId/:myOrderType/:myTableNumber"
          element={<pages.Orders.Orders directOrder />}
        />
        <DirectOrderLoadingRoute
          path="/direct/rewards/:restaurantName/:restaurantId/:locationId/:myOrderType/:myTableNumber"
          element={<pages.Rewards.Rewards directOrder />}
        /> */}

        <Route path="/*" element={<pages.Page404.Page404 />} />
      </Routes>
    </BrowserRouter>
  )
}
