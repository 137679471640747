import React, {useState} from 'react'
import {TextField, IconButton, InputAdornment} from '@material-ui/core'
import {Visibility, VisibilityOff} from '@material-ui/icons'

const FTextFieldPassword = React.forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="normal"
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              onMouseDown={handleMouseDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      ref={ref}
      {...props}
    />
  )
})

export default FTextFieldPassword
