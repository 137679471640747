import React, {useState, useRef} from 'react'
import * as utils from 'f-utils'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {auth, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider} from 'f-core/src/config/firebase'
import {OAuthProvider} from 'f-core/src/config/firebase'
import {DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core'
import {FButton, FView, FText, ButtonFillView, FTextField, FTextFieldPassword} from 'components'
import FDialog from './FDialog'
import FacebookButton from './FacebookButton'
import GoogleButton from './GoogleButton'
import AppleButton from './AppleButton'

const getProviderName = (providerId) => {
  switch (providerId) {
    case 'google.com':
      return 'Google'
    case 'facebook.com':
      return 'Facebook'
    case 'password':
      return 'Email and Password'
    default:
      return false
  }
}

const getProvider = (provider) => {
  let AuthProvider
  if (provider === 'google.com') {
    AuthProvider = new GoogleAuthProvider()
  } else if (provider === 'facebook.com') {
    AuthProvider = new FacebookAuthProvider()
  } else if (provider === 'password') {
    AuthProvider = new EmailAuthProvider()
  } else if (provider === 'apple.com') {
    AuthProvider = new OAuthProvider('apple.com')
    AuthProvider.addScope('email')
  }
  return AuthProvider
}

const LinkProviderDialog = ({authCredential, onComplete}) => {
  const dispatch = useDispatch()
  const providerName = getProviderName(authCredential.providerId)
  const providerIsPassword = authCredential.providerId === 'password'
  const passwordRef = useRef(null)

  return (
    <FDialog open={!!authCredential}>
      <DialogTitle>
        <FText h4 bold>
          Add Sign In Method
        </FText>
      </DialogTitle>
      <DialogContent>
        {providerIsPassword ? (
          <FView>
            <FText>
              To sign in with your email and password in the future enter a password and click yes. Otherwise click no
              to skip.
            </FText>
            <FView h={16} />
            <TextField
              type="password"
              variant="outlined"
              inputRef={passwordRef}
              inputProps={{minLength: 6}}
              required
              label="password"
            />
          </FView>
        ) : (
          <>
            <FText>We noticed that you tried to sign in with {providerName}.</FText>
            <FText>Would you like to be able to sign in with {providerName} in the future?</FText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <FButton
          onClick={async () => {
            onComplete()
            dispatch.settings.setAuthModalType(null)
          }}>
          <FView>
            <FText>No</FText>
          </FView>
        </FButton>
        <FButton
          onClick={async () => {
            if (providerIsPassword) {
              if (!passwordRef.current.reportValidity()) {
                return
              }
              auth.currentUser.updatePassword(passwordRef.current.value)
            } else {
              auth.currentUser.linkWithCredential(authCredential)
            }

            onComplete()
            dispatch.settings.setAuthModalType(null)
          }}>
          <FView>
            <FText>Yes</FText>
          </FView>
        </FButton>
      </DialogActions>
    </FDialog>
  )
}

export default function AuthModal() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const authModalType = useSelector(dispatch.settings.getAuthModalType)
  const signInEmailInputRef = useRef(null)
  const signInPasswordInputRef = useRef(null)
  const signUpEmailInputRef = useRef(null)
  const signUpPasswordInputRef = useRef(null)
  const signUpNameInputRef = useRef(null)
  const signUpPhoneInputRef = useRef(null)
  const resetPasswordEmailInputRef = useRef(null)
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [signUpPhoneNumberValue, setSignUpPhoneNumberValue] = useState('')

  const [message, setMessage] = useState('')
  const savedCredentialRef = useRef(null)
  const savedEmailRef = useRef(null)

  const signInWithOAuth = async (provider) => {
    const AuthProvider = getProvider(provider)

    try {
      return await auth.currentUser.linkWithPopup(AuthProvider)
    } catch (e) {
      if (e.code === 'auth/credential-already-in-use') {
        try {
          console.warn('credential already in use')
          return await auth.signInWithCredential(e.credential)
        } catch (e) {
          console.warn(e.code)
          throw e
        }
      } else if (e.code === 'auth/email-already-in-use') {
        console.warn('email in use')
        const providerIds = await auth.fetchSignInMethodsForEmail(e.email)
        let message = e.email + ' is associated with another sign in method. \n Please use '

        message += providerIds.reduce((prev, curr, i) => {
          const providerName = getProviderName(curr) + ' Sign In'
          return i === providerIds.length - 1 ? prev + providerName + ' instead.' : prev + providerName + ' or '
        }, '')
        setMessage(message)
        savedCredentialRef.current = e.credential
        savedEmailRef.current = e.email
        throw e
      } else {
        console.warn(e.code)
        throw e
      }
    }
  }

  const handleSignIn = () => {
    if (isSigningIn) {
      return
    }
    setIsSigningIn(true)
    const email = signInEmailInputRef.current.value
    const password = signInPasswordInputRef.current.value
    dispatch.user
      .signInWithEmailAndPassword(email, password)
      .then(async () => {
        dispatch.settings.setAuthModalType(
          savedCredentialRef.current && email === savedEmailRef.current ? 'linkProvider' : null,
        )
      })
      .catch(async (e) => {
        if (e.code === 'auth/wrong-password') {
          try {
            const providerIds = await auth.fetchSignInMethodsForEmail(email)
            if (!providerIds.includes('password') && providerIds.length > 0) {
              let message = email + ' is associated with another sign in method. \n Please use '

              message += providerIds.reduce((prev, curr, i) => {
                const providerName = getProviderName(curr) + ' Sign In'
                return i === providerIds.length - 1 ? prev + providerName + ' .' : prev + providerName + ' or '
              }, '')
              setMessage(message)
              savedCredentialRef.current = {providerId: 'password'}
              savedEmailRef.current = email
            } else {
              alert(e.message)
            }
          } catch (_) {}
        } else {
          alert(e.message)
        }
      })
      .finally(() => {
        setIsSigningIn(false)
      })
  }

  const handleCreateUser = () => {
    if (isSigningIn) {
      return
    }
    setIsSigningIn(true)
    const email = signUpEmailInputRef.current.value
    const password = signUpPasswordInputRef.current.value
    const name = signUpNameInputRef.current.value
    const phoneNumber = signUpPhoneNumberValue
    dispatch.user
      .createUserWithEmailAndPassword({email, password, name, phoneNumber})
      .then(() => {
        dispatch.settings.setAuthModalType(null)
      })
      .catch((e) => {
        alert(e.message)
      })
      .finally(() => {
        setIsSigningIn(false)
      })
  }

  const handleResetPassword = () => {
    if (isSigningIn) {
      return
    }
    setIsSigningIn(true)
    const email = resetPasswordEmailInputRef.current.value
    dispatch.user
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch.settings.setAuthModalType('signin')
        alert(`Check ${email} for a reset link!`)
      })
      .catch((e) => {
        alert(e.message)
      })
      .finally(() => {
        setIsSigningIn(false)
      })
  }

  return authModalType === 'linkProvider' ? (
    <LinkProviderDialog
      authCredential={savedCredentialRef.current}
      onComplete={() => {
        savedCredentialRef.current = null
        savedEmailRef.current = null
        setMessage('')
      }}
    />
  ) : (
    <FDialog
      aria-labelledby="auth-modal-title"
      open={!!authModalType}
      onClose={() => {
        if (isSigningIn) {
          return
        }

        dispatch.settings.setAuthModalType(null)
      }}
      onExit={() => {
        savedCredentialRef.current = null
        savedEmailRef.current = null
        setMessage('')
      }}>
      {['signin', 'signup', 'resetpassword'].includes(authModalType) && (
        <FView
          maxWidth="100vw"
          maxHeight="100vh"
          bg={theme.palette.common.white}
          outline={0}
          round
          p={50}
          relative
          overflowY="auto">
          <FView block>
            {authModalType === 'signin' ? (
              <FView>
                <FText id="auth-modal-title" bold h4 alignCenter>
                  Sign In
                </FText>
                <FView h={16} />
                {message && <FText style={{whiteSpace: 'pre-line'}}>{message}</FText>}
                <FTextField
                  inputRef={signInEmailInputRef}
                  type="email"
                  autoComplete="email"
                  label="Email"
                  required
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      signInPasswordInputRef.current.focus()
                    }
                  }}
                />
                <FTextFieldPassword
                  inputRef={signInPasswordInputRef}
                  autoComplete="current-password"
                  label="Password"
                  required
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleSignIn()
                    }
                  }}
                />
                <FView size={16} />
                <FButton
                  disabled={isSigningIn}
                  onClick={() => {
                    handleSignIn()
                  }}>
                  <ButtonFillView round disabled={isSigningIn}>
                    <FText white semiBold>
                      {isSigningIn ? 'Signing In...' : 'Sign In'}
                    </FText>
                  </ButtonFillView>
                </FButton>
                <FView h={8} />
                <FView center>
                  <FButton onClick={() => dispatch.settings.setAuthModalType('resetpassword')}>
                    <FText primary semiBold>
                      Forgot your password?
                    </FText>
                  </FButton>
                  <FButton
                    onClick={() => {
                      if (isSigningIn) {
                        return
                      }
                      dispatch.settings.setAuthModalType('signup')
                    }}>
                    <FText primary semiBold>
                      Create an account
                    </FText>
                  </FButton>
                </FView>
                <FView h={16} />
                <FView row alignCenter>
                  <FView fill>
                    <FView h={1} bg={theme.palette.grey[300]} />
                  </FView>
                  <FView mh={8}>
                    <FText>OR</FText>
                  </FView>
                  <FView fill>
                    <FView h={1} bg={theme.palette.grey[300]} />
                  </FView>
                </FView>
                <FView h={32} />
                <FView>
                  <GoogleButton
                    onClick={async () => {
                      try {
                        const userCredential = await signInWithOAuth('google.com')

                        dispatch.settings.setAuthModalType(
                          userCredential.user.email === savedEmailRef.current ? 'linkProvider' : null,
                        )
                      } catch (e) {}
                    }}
                    text="Continue with Google"
                  />
                  <FView h={16} />
                  <AppleButton
                    onClick={async () => {
                      try {
                        const userCredential = await signInWithOAuth('apple.com')
                        dispatch.settings.setAuthModalType(
                          userCredential.user.email === savedEmailRef.current ? 'linkProvider' : null,
                        )
                      } catch (e) {}
                    }}
                    text="Continue with Apple"
                  />
                  <FView h={16} />
                  <FacebookButton
                    onClick={async () => {
                      try {
                        const userCredential = await signInWithOAuth('facebook.com')
                        dispatch.settings.setAuthModalType(
                          userCredential.user.email === savedEmailRef.current ? 'linkProvider' : null,
                        )
                      } catch (e) {}
                    }}
                    text="Continue with Facebook"
                  />
                </FView>
              </FView>
            ) : authModalType === 'signup' ? (
              <FView>
                <FText id="auth-modal-title" bold h4 alignCenter>
                  Create Account
                </FText>
                <FView row center>
                  <FText body1>Already have an account?</FText>
                  <FView size={5} />
                  <FButton
                    onClick={() => {
                      if (isSigningIn) {
                        return
                      }
                      dispatch.settings.setAuthModalType('signin')
                    }}>
                    <FText body1 semiBold>
                      Sign In
                    </FText>
                  </FButton>
                </FView>
                <FView size={32} />
                <FTextField
                  inputRef={signUpEmailInputRef}
                  type="email"
                  required
                  autoComplete="email"
                  label="Email"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      signUpPasswordInputRef.current.focus()
                    }
                  }}
                />
                <FTextFieldPassword
                  inputRef={signUpPasswordInputRef}
                  autoComplete="new-password"
                  label="Password"
                  required
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      signUpNameInputRef.current.focus()
                    }
                  }}
                />
                <FTextField
                  inputRef={signUpNameInputRef}
                  type="name"
                  autoComplete="name"
                  label="Name"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      signUpPhoneInputRef.current.focus()
                    }
                  }}
                />
                <FTextField
                  inputRef={signUpPhoneInputRef}
                  type="tel"
                  autoComplete="tel"
                  label="Phone"
                  inputProps={{maxLength: 16}}
                  value={utils.formatPhoneNumber(signUpPhoneNumberValue)}
                  onChange={(e) => setSignUpPhoneNumberValue(utils.removeNonNumericString(e.target.value))}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleCreateUser()
                    }
                  }}
                />
                <FView size={16} />
                <FButton
                  disabled={isSigningIn}
                  onClick={() => {
                    handleCreateUser()
                  }}>
                  <ButtonFillView round disabled={isSigningIn}>
                    <FText bold primaryContrast button>
                      {isSigningIn ? 'Creating Account...' : 'Create Account'}
                    </FText>
                  </ButtonFillView>
                </FButton>
              </FView>
            ) : authModalType === 'resetpassword' ? (
              <FView>
                <FText id="auth-modal-title" bold h4 alignCenter>
                  Reset Password
                </FText>
                <FView size={10} />
                <FTextField
                  inputRef={resetPasswordEmailInputRef}
                  type="email"
                  required
                  autoComplete="email"
                  label="Email"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleResetPassword()
                    }
                  }}
                />
                <FView size={16} />
                <FButton disabled={isSigningIn} onClick={handleResetPassword}>
                  <ButtonFillView round disabled={isSigningIn}>
                    <FText bold primaryContrast button>
                      {isSigningIn ? 'Sending...' : 'Send Password Reset Link'}
                    </FText>
                  </ButtonFillView>
                </FButton>
                <FView alignCenter>
                  <FButton
                    onClick={() => {
                      if (isSigningIn) {
                        return
                      }
                      dispatch.settings.setAuthModalType('signin')
                    }}>
                    <FText body1 primary>
                      Back to Sign In
                    </FText>
                  </FButton>
                </FView>
              </FView>
            ) : null}
          </FView>
        </FView>
      )}
    </FDialog>
  )
}
