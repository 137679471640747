import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'

export default function OrderItemView({
  isRefund = false,
  count,
  name,
  priceWithModifiers,
  selectedModifierGroupsWithDetails,
  SubtractButtonElement,
  notes,
}) {
  return (
    <FView relative row justifyBetween minHeight={50}>
      {isRefund && <FView absolute top={'50%'} left={'0%'} h={1} w={'100%'} zIndex={999} bg="black" />}
      <FView fill selfCenter>
        <FView row justifyBetween alignCenter>
          <FView alignCenter row fill>
            <FView size={25} bg={colors.grey} center rounded>
              <FText bold caption white>
                x{count}
              </FText>
            </FView>
            <FView fill mh={8}>
              <FView row alignCenter>
                {isRefund && (
                  <FText body2 bold color="red">
                    Refunded
                  </FText>
                )}
                <FView w={8} />
                <FText bold body2>
                  {` ${name}`}
                </FText>
              </FView>
            </FView>
          </FView>
          <FText bold body2>
            ${priceWithModifiers?.toFixed(2) ?? 'loading...'}
          </FText>
        </FView>
        {!!selectedModifierGroupsWithDetails &&
          selectedModifierGroupsWithDetails.map((modifierGroupDetails) =>
            Object.entries(modifierGroupDetails.selectedModifierItemsObj).map(([itemId, isSelected]) => {
              if (!modifierGroupDetails.modifierItems[itemId]) {
                return null
              }
              const {name: optionName, price: optionPrice, defaultValue} = modifierGroupDetails.modifierItems[itemId]

              if (defaultValue === isSelected) {
                return null
              }
              let isNegative = optionPrice < 0
              if (!isSelected) {
                isNegative = !isNegative
              }
              return (
                <FView fill key={itemId} row>
                  <FView ml={30} mr={4}>
                    <FText bold letterSpacing={-0.5} grey800 body2>
                      {`• ${!isSelected ? 'No ' : ''}${optionName}`}
                    </FText>
                  </FView>
                  {optionPrice !== 0 && (
                    <FText bold body2 grey800>
                      ({isNegative ? '-' : ''}${Math.abs(optionPrice * count).toFixed(2)})
                    </FText>
                  )}
                </FView>
              )
            }),
          )}
        {!!notes && (
          <FView pl={32}>
            <FText grey800 body2 bold>
              {'Notes: ' + notes}
            </FText>
          </FView>
        )}
      </FView>
      <FView>{SubtractButtonElement}</FView>
    </FView>
  )
}
