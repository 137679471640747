import React, {useState, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {DialogTitle, DialogContent, DialogActions} from '@material-ui/core'
import {FText, FView, FButton, FTextField, ButtonFillView, FDialog} from 'components'
// TODO: handle back button

export default function AddPaymentDialog({open, handleClose}) {
  const dispatch = useDispatch()

  const expRef = useRef()
  const cvvRef = useRef()
  const postalRef = useRef()

  const [cc, setCC] = useState('')
  const [exp, setExp] = useState('')
  const [cvv, setCvv] = useState('')
  const [postal, setPostal] = useState('')
  const [updatingCard, setUpdatingCard] = useState(false)
  const payments = useSelector(dispatch.user.getPayments)

  const clearForm = () => {
    setCC('')
    setExp('')
    setCvv('')
    setPostal('')
  }

  async function handleAddCreditCard() {
    if (!postal) {
      alert('Missing Postal Code. Please fill out postal code registered under the card')
      return
    }
    setUpdatingCard(true)
    try {
      const newCardIndex = payments.length
      await dispatch.user.addCreditCard({
        number: cc,
        cvc: cvv,
        exp_month: exp.slice(0, 2),
        exp_year: `20${exp.slice(2, 4)}`,
        address_zip: postal,
      })
      dispatch.user.setSelectedPaymentIndex(newCardIndex)
      dispatch.user.setPaymentMethod({paymentMethod: 'online-creditcard'})
      handleClose()
      clearForm()
    } catch (e) {
      if (e.message === 'The zip code you supplied failed validation.') {
        alert('Failed to update credit/debit card, the postal code does not match with what is registered on the card.')
      } else {
        alert('Updating Card Failed. ' + e.message)
      }
    } finally {
      setUpdatingCard(false)
    }
  }
  return (
    <FDialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <FText bold h4>
          Add A New Card
        </FText>
      </DialogTitle>
      <DialogContent>
        <FTextField
          autoFocus
          placeholder="Card Number"
          margin="none"
          size="small"
          value={cc.replace(/(.{4})/g, '$1 ').trim()}
          disabled={updatingCard}
          onChange={(e) => {
            const text = e.target.value
            setCC(text.replace(/\D/g, ''))
            if (text.length === 19) {
              expRef.current.focus()
            }
          }}
        />
        <FView row mv={16}>
          <FView fill>
            <FView h={4} />
            <FTextField
              margin="none"
              size="small"
              placeholder="MM/YY"
              inputRef={expRef}
              disabled={updatingCard}
              onChange={(e) => {
                const text = e.target.value
                setExp(text.replace(/\D/g, ''))
                if (text.length === 5) {
                  cvvRef.current.focus()
                }
              }}
              value={exp.length < 3 ? exp : exp.substr(0, 2) + '/' + exp.substr(2, 2)}
            />
          </FView>
          <FView w={8} />
          <FView fill>
            <FView h={4} />
            <FTextField
              placeholder="CVV"
              margin="none"
              size="small"
              inputRef={cvvRef}
              disabled={updatingCard}
              onChange={(e) => {
                const text = e.target.value
                setCvv(text.replace(/\D/g, ''))
                if (text.length === 3) {
                  postalRef.current.focus()
                }
              }}
            />
          </FView>
        </FView>
        <FView size={4} />
        <FTextField
          placeholder="Zip Code"
          margin="none"
          size="small"
          inputRef={postalRef}
          disabled={updatingCard}
          onChange={(e) => setPostal(e.target.value)}
          value={postal}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleAddCreditCard()
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <FButton fill disabled={!(cc || cvv || exp || postal) || updatingCard} onClick={() => handleAddCreditCard()}>
          <ButtonFillView round disabled={!(cc || cvv || exp || postal) || updatingCard}>
            <FText medium white>
              {updatingCard ? 'Saving...' : 'Save'}
            </FText>
          </ButtonFillView>
        </FButton>
      </DialogActions>
    </FDialog>
  )
}
