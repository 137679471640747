import React from 'react'
import {css} from 'emotion'
import {FText, FView, FButton} from 'components'
import * as images from 'images'
import {useNavigate} from 'react-router-dom'
import {useTheme} from '@material-ui/core/styles'

const resTypeToImages = {
  Korean: images.korean,
  Japanese: images.japanese,
  Chinese: images.chinese,
  Western: images.western,
  Asian: images.asian,
  Chicken: images.chicken,
  Vegan: images.vegan,
  Dessert: images.dessert,
  Grocery: images.grocery,
}

export default function TagSelector({selectedTags}) {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <FView row alignCenter width={1440} maxWidth={'100vw'} overflow="auto">
      {Object.entries(resTypeToImages).map(([resType, image]) => {
        return (
          <FView round ph={16} pv={16} key={resType} bg={selectedTags?.[resType] ? theme.palette.grey[200] : 'white'}>
            <FButton
              onClick={() =>
                selectedTags?.[resType]
                  ? navigate('/all-restaurants', {state: {selectedTags: {}}})
                  : navigate('/all-restaurants', {state: {selectedTags: {[resType]: true}}})
              }>
              <FView alignCenter>
                <img src={image} alt={resType} className={css(classes.tags, classes.notDraggable)} />
                <FText>{resType}</FText>
              </FView>
            </FButton>
          </FView>
        )
      })}
    </FView>
  )
}
const classes = {
  notDraggable: css({userSelect: 'none', WebkitUserDrag: 'none'}),
  tags: css({objectFit: 'contain', height: 64}),
}
