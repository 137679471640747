export default function getFeaturedDetails({orderType, locationData}) {
  let rewardName = null
  let rewardType = null
  let deliveryFreeName = null
  let condition = ''

  // 1. Check if rewards contain any of the given reward type
  // Free Item Over X Amount
  // X% OFF on orders > $5
  // Free Item First Order
  for (const reward of Object.values(locationData.rewards || {})) {
    const [rewardWith, rewardFor, rewardCondition] = reward.type.split('/')

    if (
      reward.rewardName &&
      (rewardWith === 'Free' || rewardWith === 'FreeFirstOrderOrPoints') &&
      (rewardFor === 'Product' || rewardFor === 'Discount' || rewardFor === 'DiscountPercent')
    ) {
      if (
        rewardCondition === 'Pickup' ||
        rewardCondition === 'Delivery' ||
        (rewardCondition === 'DineIn' && rewardCondition !== orderType)
      ) {
        continue
      }
      if (rewardWith?.startsWith('FreeFirstOrder')) {
        condition += 'Free on First Order'
      }

      rewardName = reward.rewardName
      rewardType = rewardFor
      break
    }
  }

  if (orderType === 'Delivery') {
    if (locationData.deliveryFreeMinimumSubTotal < 1000 || locationData.deliveryFee === 0) {
      if (locationData.deliveryFee === 0) {
        deliveryFreeName = 'Free Delivery'
      } else {
        deliveryFreeName = `Free Delivery over $${locationData.deliveryFreeMinimumSubTotal}`
      }
    }
  }
  if (!rewardType && deliveryFreeName) {
    rewardType = 'FreeDelivery'
  }

  return {condition, rewardName, deliveryFreeName, rewardType}
}
