import React, {useState, useRef} from 'react'
import {FButton, FText, FView, FInput, ButtonFillView} from 'components'
import {colors} from 'styles'
import {css} from 'emotion'
import {useSelector, useDispatch} from 'react-redux'
import * as utils from 'f-utils'

export default function PaymentInformation() {
  const phoneRef = useRef()

  const dispatch = useDispatch()
  const [updating, setUpdating] = useState(false)

  const name = useSelector(dispatch.user.getName)
  const email = useSelector(dispatch.user.getEmail)
  const phoneNumber = useSelector(dispatch.user.getPhoneNumber)

  const [nameVal, setNameVal] = useState(name)
  const [phoneNumberVal, setPhoneNumberVal] = useState(phoneNumber)
  const isUserLoggedIn = useSelector(dispatch.user.getIsUserLoggedIn)

  // This allows customers to unlink with their existing social logins
  // const [signInMethods, setSignInMethods] = useState([])

  const updateAccountDetails = () => {
    if (phoneNumberVal && phoneNumberVal.length > 0 && phoneNumberVal.length < 10) {
      alert('Please enter exactly 10 digit phone number')
      return
    }
    setUpdating(true)
    Promise.all([
      dispatch.user.setNameOnline({name: nameVal}),
      dispatch.user.setPhoneNumberOnline({phoneNumber: phoneNumberVal}),
    ])
      .catch((e) => {
        alert('Update Failed ' + e.message)
        return
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  // This allows customers to unlink with their existing social logins
  // useEffect(() => {
  //   auth.fetchSignInMethodsForEmail(email).then((methods) => setSignInMethods(methods))
  // }, [email])

  return (
    <FView pt={15} ph={50} pb={25}>
      {isUserLoggedIn && (
        <>
          <AccountDetailsItemView label="Email">
            <FInput className={classes.textInputStyle} disabled value={email} />
          </AccountDetailsItemView>
          <FView size={15} />
        </>
      )}
      <AccountDetailsItemView label="Phone">
        <FInput
          ref={phoneRef}
          type="tel"
          autoComplete="tel"
          disabled={updating}
          className={classes.textInputStyle}
          maxLength={16}
          value={utils.formatPhoneNumber(phoneNumberVal)}
          onChange={(e) => setPhoneNumberVal(utils.removeNonNumericString(e.target.value))}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              updateAccountDetails()
            }
          }}
        />
      </AccountDetailsItemView>
      <AccountDetailsItemView label="Name">
        <FInput
          autoComplete="name"
          className={classes.textInputStyle}
          disabled={updating}
          value={nameVal}
          onChange={(e) => setNameVal(e.target.value)}
          maxLength={25}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              phoneRef.current.focus()
            }
          }}
        />
      </AccountDetailsItemView>
      <FView size={15} />

      <FView size={15} />

      <FButton disabled={updating} onClick={() => updateAccountDetails()}>
        <ButtonFillView
          rounded
          disabled={updating || (phoneNumberVal && phoneNumberVal.length > 0 && phoneNumberVal.length < 10)}>
          <FText body1 bold primaryContrast>
            {updating ? 'Updating...' : 'Update'}
          </FText>
        </ButtonFillView>
      </FButton>
      {/* Uncomment to allow users to unlink with their social logins */}
      {/* {signInMethods.includes('google.com') && (
        <FButton onClick={() => auth.currentUser.unlink('google.com')}>
          <FView>
            <FText>Unlink Google</FText>
          </FView>
        </FButton>
      )}
      {signInMethods.includes('facebook.com') && (
        <FButton onClick={() => auth.currentUser.unlink('facebook.com')}>
          <FView>
            <FText>Unlink Facebook</FText>
          </FView>
        </FButton>
      )} */}
    </FView>
  )
}

function AccountDetailsItemView({label, children}) {
  return (
    <>
      <FText caption bold gutterBottom>
        {label}
      </FText>
      <FView bc={colors.grey50} bw={1} rounded>
        {children}
      </FView>
    </>
  )
}

const classes = {
  textInputStyle: css({height: 50}),
}
