import React from 'react'
import {useDispatch} from 'react-redux'
import {FView} from 'components'
import {Tabs, Tab, makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
  scrollButtons: {
    backgroundColor: 'white',
    padding: '0px 20px',
  },

  flexContainer: {
    backgroundColor: 'white',
  },
})

const CategoryHorizontalList = ({
  activeCategoryOrder,
  selectedCategory,
  setSelectedCategory,
  locationId,
  categoryItemRefs,
  jumpRef,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  return (
    <FView sticky top={0} zIndex={3}>
      <Tabs
        classes={classes}
        variant="scrollable"
        scrollButtons="auto"
        value={selectedCategory}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, newValue) => {
          jumpRef.current = true
          setTimeout(() => {
            jumpRef.current = false
          }, 700)
          const categoryId = activeCategoryOrder[newValue]
          window.scrollTo({
            top: (categoryItemRefs?.current[categoryId]?.offsetTop ?? 0) - 32,
            left: 0,
            behavior: 'smooth',
          })
          setSelectedCategory(newValue)
        }}>
        {activeCategoryOrder.map((categoryId) => {
          const categoryData = dispatch.restaurants.getCategory({locationId, categoryId})
          return <Tab key={categoryId} disableRipple label={categoryData.name} style={styles.tab} />
        })}
      </Tabs>
    </FView>
  )
}

const styles = {
  tab: {
    fontWeight: 600,
    textTransform: 'capitalize',
    fontSize: 16,
    backgroundColor: 'white',
    margin: '0px',
    padding: '16px 16px',
    whiteSpace: 'nowrap',
  },
}

export default CategoryHorizontalList
