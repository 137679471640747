import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import {css} from 'emotion'
import {useTheme} from '@material-ui/core/styles'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {FButton, FText, FView, NavBarV2, Footer, TagSelector} from 'components'
import {useFoodlyRestaurants} from 'f-app-models/hooks'
import {useMediaMinSM, useMediaMinMD} from 'f-web/src/hooks'
import LocationPreview from 'components/LocationPreview'
import OrderTypeDialog from '../Location/componentsV2/OrderTypeDialog'

const responsive = {
  desktop: {
    breakpoint: {max: 9999999, min: 1024},
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: {max: 1023, min: 768},
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: {max: 767, min: 0},
    items: 1,
    slidesToSlide: 1,
  },
}

const Home = () => {
  const navigate = useNavigate()
  const {sortedLocationList: dealsLocations} = useFoodlyRestaurants({initialFilterType: 'Deals'})
  const {sortedLocationList: fastestLocations} = useFoodlyRestaurants({initialFilterType: 'Fastest'})
  const {sortedLocationList: nearestLocations} = useFoodlyRestaurants({initialFilterType: 'Nearest'})

  const theme = useTheme()
  const dispatch = useDispatch()

  const promotions = useSelector(dispatch.public.getPublicPromotionsV2)
  const [promotion, setPromotion] = useState({})
  useEffect(() => {
    setPromotion(_.sample(promotions))
  }, [promotions])

  const isMinMD = useMediaMinMD()

  return (
    <FView alignCenter>
      <OrderTypeDialog />
      <NavBarV2 />
      <FView h={8} />
      <FView alignCenter w={1440} maxWidth={'100vw'}>
        <FView w={1440} maxWidth={'100vw'}>
          <FView bg={theme.palette.grey[50]} row justifyCenter>
            <FButton
              onClick={() => {
                if (promotion.targetUrl) {
                  window.location.href = promotion.targetUrl
                } else if (promotion.restaurantId && promotion.locationId) {
                  if (promotion.couponCode) {
                    navigate(
                      `/restaurant/${promotion.restaurantId}/location/${promotion.locationId}?couponCode=${promotion.couponCode}`,
                    )
                  } else {
                    navigate(`/restaurant/${promotion.restaurantId}/location/${promotion.locationId}`)
                  }
                }
              }}>
              <img
                draggable={false}
                src={isMinMD ? promotion.imageUrlWide || promotion.imageUrl : promotion.imageUrl}
                alt="promotion banner"
                className={classes.promoBanner}
              />
            </FButton>
          </FView>
        </FView>

        <FView h={32} />
        <TagSelector />
      </FView>
      <FView mv={8} h={1} bg={theme.palette.grey[200]} />
      {fastestLocations.length > 0 || dealsLocations.length > 0 || nearestLocations.length > 0 ? (
        <FView alignCenter>
          {dealsLocations.length > 0 && (
            <RestaurantsPreview name="Today's Offer" filterType="Deals" locations={dealsLocations} />
          )}
          {/* {popularLocations.length > 0 && (
              <RestaurantsPreview name="Popular" filterType="Nearest" locations={popularLocations} />
            )} */}
          {fastestLocations.length > 0 && (
            <RestaurantsPreview name="In A Hurry? " filterType="Fastest" locations={fastestLocations} />
          )}
          {nearestLocations.length > 0 && (
            <RestaurantsPreview name="Places Close By" filterType="Nearest" locations={nearestLocations} />
          )}
        </FView>
      ) : (
        <FView alignCenter p={64}>
          <FText h5 bold color={theme.palette.primary.main}>
            No restaurants in your area, please change your address or select pickup instead.
          </FText>
        </FView>
      )}
      <FView h={64} />
      <Footer />
    </FView>
  )
}

const ButtonGroup = ({next, previous, carouselState: {currentSlide, slidesToShow, totalItems}}) => {
  const theme = useTheme()
  return (
    <FView row absolute zIndex={10} right={0} top={0}>
      <FButton onPress={() => previous()} disabled={currentSlide === 0}>
        <FView mh={8} br={20} size={40} opacity={currentSlide === 0 ? 0.3 : 0.7} bg={theme.palette.grey[100]} center>
          <ChevronLeft fontSize="large" />
        </FView>
      </FButton>
      <FButton onPress={() => next()} disabled={currentSlide + slidesToShow >= totalItems}>
        <FView
          ml={8}
          br={20}
          size={40}
          opacity={currentSlide + slidesToShow >= totalItems ? 0.3 : 0.7}
          bg={theme.palette.grey[100]}
          center>
          <ChevronRight fontSize="large" />
        </FView>
      </FButton>
    </FView>
  )
}

const RestaurantsPreview = ({name, filterType, locations, numberToLoad = 10}) => {
  const navigate = useNavigate()
  const isMinSM = useMediaMinSM()
  const swipeSpeed = isMinSM ? 300 : 100

  //mock
  const theme = useTheme()

  return (
    <FView pv={10} w={1440} maxWidth={'90vw'}>
      <FView relative>
        <FView row justifyBetween alignCenter mr={120}>
          <FText h5 bold>
            {name}
          </FText>
          <FButton onClick={() => navigate('/all-restaurants', {state: {selectedFilterType: filterType}})}>
            <FText className={classes.viewAll} semiBold>
              View all
            </FText>
          </FButton>
        </FView>
        <Carousel
          minimumTouchDrag={5}
          arrows={false}
          partialVisible={false}
          renderButtonGroupOutside
          customButtonGroup={<ButtonGroup />}
          draggable={false}
          responsive={responsive}
          customTransition={`transform ${swipeSpeed}ms linear`}
          transitionDuration={swipeSpeed}
          itemClass={classes.itemClass}>
          {locations.slice(0, numberToLoad).map((location, i) => (
            <LocationPreview key={location.locationId} location={location} />
          ))}
          <FView mv={16} w={'100%'} maxWidth={'100%'}>
            <FButton onClick={() => navigate('/all-restaurants', {state: {selectedFilterType: filterType}})}>
              <FView>
                <FView>
                  <FView relative>
                    <FView alt="preview" h={230} width={'100%'} />
                    <FView zIndex={6}>
                      <FView bg={theme.palette.primary.main} absoluteFill />
                      <FView absoluteFill center mh={'10%'}>
                        <FText h6 bold white>
                          View More
                        </FText>
                      </FView>
                    </FView>
                  </FView>
                  <FView h={4} />
                  <FText bold> </FText>
                  <FText> </FText>
                </FView>
              </FView>
            </FButton>
          </FView>
        </Carousel>
      </FView>
    </FView>
  )
}

const classes = {
  promoBanner: css({objectFit: 'cover', height: '50vw', maxHeight: 275, maxWidth: '100%', borderRadius: 16}),
  viewAll: css({textDecoration: 'underline', whiteSpace: 'nowrap'}),
  itemClass: css({padding: '0px 8px'}),
}

export default Home
