export {default as becomeAPartner} from './becomeAPartner.svg'
export {default as deliverWithUs} from './deliverWithUs.svg'
export {default as foodlyIcon} from './foodlyIcon.svg'
export {default as foodlyLogo} from './foodlyLogo.svg'
export {default as heroFood1} from './heroFood1.svg'
export {default as heroFood2} from './heroFood2.svg'
export {default as heroFood1Mobile} from './heroFood1Mobile.svg'
export {default as heroFood2Mobile} from './heroFood2Mobile.svg'
export {default as fbIcon} from './fbIcon.svg'
export {default as igIcon} from './igIcon.svg'
export {default as googleIcon} from './googleIcon.png'

export const phoneMockup = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/foodly/web/phoneMockup.png'
export const promoImage4 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/foodly/web/promoImage4.png'

export const restaurantsHeader =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto/foodly/web/restaurantsHeader.png'
export const ownerHeroBG =
  'https://res.cloudinary.com/foodlytech/image/upload/c_scale,f_auto,w_1440/v1573936852/foodly/web/ownerHeroBG.png'
export const courierHeroBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/foodly/web/courierHeroBG.png'
export {default as whyFoodly1} from './whyFoodly1.svg'
export {default as whyFoodly2} from './whyFoodly2.svg'
export {default as whyFoodly3} from './whyFoodly3.svg'
export {default as restaurantReception} from './restaurantReception.svg'
export {default as howItWorks1} from './howItWorks1.svg'
export {default as howItWorks2} from './howItWorks2.svg'
export {default as howItWorks3} from './howItWorks3.svg'
export {default as pricingCityScape} from './pricingCityScape.svg'
export {default as specialOfferLetters} from './specialOfferLetters.svg'
export {default as partners} from './partners.svg'
export {default as mapPin} from './map/mapPin.svg'
export {default as mapPinSelected} from './map/mapPinSelected.svg'
export {default as mapPinClosed} from './map/mapPinClosed.svg'
export {default as mapPinClosedSelected} from './map/mapPinClosedSelected.svg'

export {default as mapPinKorean} from './map/open/mapPinKorean.svg'
export {default as mapPinChinese} from './map/open/mapPinChinese.svg'
export {default as mapPinJapanese} from './map/open/mapPinJapanese.svg'
export {default as mapPinChicken} from './map/open/mapPinChicken.svg'
export {default as mapPinCafe} from './map/open/mapPinCafe.svg'
export {default as mapPinItalian} from './map/open/mapPinItalian.svg'
export {default as mapPinIndian} from './map/open//mapPinIndian.svg'
export {default as mapPinThai} from './map/open/mapPinThai.svg'
export {default as mapPinPub} from './map/open/mapPinPub.svg'
export {default as mapPinBreakfast} from './map/open/mapPinBreakfast.svg'

export {default as mapPinKoreanSelected} from './map/selected/mapPinKoreanSelected.svg'
export {default as mapPinChineseSelected} from './map/selected/mapPinChineseSelected.svg'
export {default as mapPinJapaneseSelected} from './map/selected/mapPinJapaneseSelected.svg'
export {default as mapPinChickenSelected} from './map/selected/mapPinChickenSelected.svg'
export {default as mapPinCafeSelected} from './map/selected/mapPinCafeSelected.svg'
export {default as mapPinItalianSelected} from './map/selected/mapPinItalianSelected.svg'
export {default as mapPinIndianSelected} from './map/selected/mapPinIndianSelected.svg'
export {default as mapPinThaiSelected} from './map/selected/mapPinThaiSelected.svg'
export {default as mapPinPubSelected} from './map/selected/mapPinPubSelected.svg'
export {default as mapPinBreakfastSelected} from './map/selected/mapPinBreakfastSelected.svg'

export const aboutUsHero = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/foodly/web/aboutUsHero.png'
export const aboutUsHeroMobile =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto/v1/foodly/web/aboutUsHeroMobile.png'
export const aboutUsPromo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/foodly/web/aboutUsPromo.png'
export {default as aboutUsHeroBox} from './aboutUsHeroBox.svg'
export {default as foodlyLogoWhite} from './foodlyLogoWhite.svg'

export {default as giftIcon} from './giftIcon.svg'
export {default as orderHistoryIcon} from './orderHistoryIcon.svg'
export {default as userIcon} from './userIcon.svg'

export {default as addProductModalBG} from './addProductModalBG.svg'

export {default as noItems} from './noItems.png'
export {default as noItemsCafe} from './noItemsCafe.png'

export {default as colouredPedestrian} from './colouredPedestrian.svg'
export {default as pedestrian} from './pedestrian.svg'
export {default as scooter} from './scooter.svg'
export {default as colouredScooter} from './colouredScooter.svg'
export {default as slideBump} from './slideBump.svg'

export {default as homePin} from './homePin.svg'
export {default as restaurantPin} from './restaurantPin.svg'
export {default as personPin} from './personPin.svg'

export {default as korean} from './Korean.svg'
export {default as asian} from './Asian.svg'
export {default as chicken} from './Chicken.svg'
export {default as chinese} from './Chinese.svg'
export {default as dessert} from './Dessert.svg'
export {default as grocery} from './Grocery.svg'
export {default as japanese} from './Japanese.svg'
export {default as vegan} from './Vegan.svg'
export {default as western} from './Western.svg'
