import React, {Component} from 'react'
import {css} from 'emotion'
import {FText} from 'f-web/src/components'
import {colors} from 'f-web/src/styles'

class FInput extends Component {
  render() {
    const {className, disabled, multiline, error, forwardedRef, style, ...props} = this.props
    const classNames = css(multiline ? styles.multiline : styles.input, className, style)
    return (
      <>
        {multiline ? (
          <textarea
            style={disabled ? {backgroundColor: colors.grey25} : {}}
            ref={forwardedRef}
            className={classNames}
            disabled={disabled}
            {...props}
          />
        ) : (
          <input
            style={disabled ? {backgroundColor: colors.grey25} : {}}
            ref={forwardedRef}
            className={classNames}
            disabled={disabled}
            {...props}
          />
        )}
        {error && (
          <FText content h4 red>
            {error}
          </FText>
        )}
      </>
    )
  }
}

const styles = {
  input: css({
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: '4.5rem',
    width: '100%',
    display: 'inline-block',
    borderRadius: 4,
    boxShadow: 'none',
    outline: '0',
    border: 'none',
    color: colors.black,
    fontSize: '1.6rem',
    fontFamily: 'Open Sans',
    '&::placeholder': {
      color: colors.mediumGrey,
    },
  }),
  multiline: css({
    padding: '1rem',
    width: '100%',
    height: '10rem',
    display: 'inline-block',
    borderRadius: 4,
    resize: 'vertical',
    boxShadow: 'none',
    outline: '0',
    border: 'none',
    color: colors.black,
    fontSize: '1.6rem',
    fontFamily: 'roboto',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    '&::placeholder': {
      color: 'rgba(0, 0, 0, .5)',
    },
  }),
}

export default React.forwardRef((props, ref) => {
  return <FInput forwardedRef={ref} {...props} />
})
