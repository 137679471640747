import React, {useEffect} from 'react'
import {FText, FView, NavBarV2, Footer} from 'components'
import * as images from 'images'
import {useMediaMinMD, useMediaMinLG} from 'f-web/src/hooks'
import {Divider} from '@material-ui/core'

export default function AboutUs() {
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <FView alignCenter>
      <NavBarV2 />
      <FView block>
        <FView size={'calc(-15px + 4vw)'} maxHeight={15} />
        <FView h={'calc(200px + 14.1vw)'} maxHeight={400} minHeight={250} row justifyBetween relative>
          <img
            src={isMinMD ? images.aboutUsHero : images.aboutUsHeroMobile}
            style={{objectFit: 'fit', width: '100%', height: '100%'}}
            alt="about us hero"
          />
          <img
            src={images.aboutUsHeroBox}
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              height: 'calc(80px + 18.7vw)',
              maxHeight: 350,
              objectFit: 'contain',
            }}
            alt="about us hero box"
          />
          <FView absoluteFill center zIndex={1}>
            <FText bold h3>
              About Us
            </FText>
          </FView>
        </FView>

        <FView size={50} />

        <FView>
          <FView selfCenter w={1024} maxWidth={'100%'} row={isMinLG} alignCenter ph={!isMinLG && 15}>
            <FView maxWidth={550}>
              <FText bold h6 gutterBottom>
                Foodly empowers restaurants with innovative technologies
              </FText>
              <FText body1>
                Foodly wants to empower restaurants with innovative technologies that is both affordable and easy to
                use, so they can focus on providing good values to their customers.
              </FText>
            </FView>
            <FView size={25} />

            <img
              src={images.aboutUsPromo}
              alt="about us promo"
              style={{objectFit: 'contain', width: 450, height: '100%'}}
            />
          </FView>
        </FView>

        <FView size={50} />
        <Divider />
        <FView size={50} />
      </FView>
      <Footer />
    </FView>
  )
}
