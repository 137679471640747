import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from 'emotion'
import {useTheme} from '@material-ui/core/styles'
import {Info} from '@material-ui/icons'
import {FText, FView, FButton} from 'components'
import * as utils from 'f-utils'
import InfoDialog from '../InfoDialog'
import OrderTypeSelector from 'components/OrderTypeSelector'

function getDistance(restaurantLatLng, userLocation) {
  try {
    const {latitude: lat1, longitude: lng1} = userLocation
    const {lat: lat2, lng: lng2} = restaurantLatLng
    return utils.latlngDistance(lat1, lng1, lat2, lng2)
  } catch (e) {
    return 0
  }
}

export default React.memo(function RestaurantHeader({
  promoUrl,
  restaurantName,
  locationData,
  tags,
  address,
  waitTime,
  directOrder,
}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const orderType = useSelector(dispatch.user.getOrderType)
  const locationId = locationData.locationId
  const deliveryFee = useSelector(() => dispatch.restaurants.getEstimatedDeliveryFee({locationId}))
  const currentOrderOpenDetails = useSelector(() => {
    let orderOpenDetails = dispatch.restaurants.getOrderOpenDetails({locationId})
    if (orderOpenDetails.isOpen && orderType === 'Delivery') {
      orderOpenDetails = dispatch.restaurants.getDeliveryOrderOpenDetails({locationId})
    }
    return orderOpenDetails
  }, utils.isOrderOpenDetailsEqual)
  const [infoDialogOpen, setInfoDialogOpen] = useState(false)
  const distance = getDistance(locationData.restaurantLatLng, dispatch.user.getUserLocation())

  const isWithinDeliveryDistance = useSelector(() => dispatch.user.getIsLocationWithinDeliveryDistance({locationId}))
  const isDeliveryAvailable = useSelector(() => dispatch.restaurants.getIsDeliveryAvailable({locationId}))
  const isDeliveryAddressEmpty = useSelector(dispatch.user.getIsDeliveryAddressEmpty)
  return (
    <FView mb={24} relative>
      {directOrder && (
        <FView absolute top={8} zIndex={1} left={8}>
          <OrderTypeSelector locationId={locationId} />
        </FView>
      )}
      <FView relative h={256}>
        <img src={promoUrl} className={classes.promoImage} alt="restaurant banner" />
        {!currentOrderOpenDetails?.isOpen ? (
          <>
            <FView bg={'rgba(0,0,0,0.5)'} absoluteFill />
            <FView absoluteFill center mh={'10%'}>
              <FText h4 bold white>
                Ordering Closed
              </FText>
              {currentOrderOpenDetails?.openMoment && (
                <FText h6 bold white>
                  {(orderType ?? '') + ' Opens ' + currentOrderOpenDetails.openMoment.calendar()}
                </FText>
              )}
            </FView>
          </>
        ) : (
          orderType === 'Delivery' &&
          (!isDeliveryAvailable || !isWithinDeliveryDistance) && (
            <>
              <FView bg={'rgba(0,0,0,0.5)'} absoluteFill />
              <FView absoluteFill center mh={'10%'}>
                <FText h4 bold white>
                  {!isDeliveryAvailable
                    ? orderType + ' not available'
                    : isDeliveryAddressEmpty
                    ? 'Add an address'
                    : 'Too far to deliver'}
                </FText>
              </FView>
            </>
          )
        )}
      </FView>
      <FView mt={16} mb={8}>
        <FText semiBold h4>
          {restaurantName}
        </FText>
        <FText body2 color={theme.palette.grey[700]}>
          {tags?.join(', ') ?? ''}
        </FText>
        <FView h={4} />
        <FText body2 color={theme.palette.grey[700]}>
          {address}
        </FText>
        <FView row>
          <FButton onClick={() => setInfoDialogOpen(true)}>
            <FView alignCenter row>
              <FText body2 style={{textDecoration: 'underline', color: theme.palette.grey[700]}}>
                More info
              </FText>
              <FView w={4} />
              <Info style={{color: theme.palette.grey[700]}} fontSize="small" />
            </FView>
          </FButton>
          <InfoDialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)} locationData={locationData} />
        </FView>
      </FView>
      <FView row>
        {orderType === 'Delivery' && isDeliveryAvailable && (
          <>
            <OutlineView
              value={isWithinDeliveryDistance ? `$${deliveryFee > 10 ? '10+' : deliveryFee.toFixed(2)}` : 'N/A'}
              unit="delivery fee"
            />
            <FView w={16} />
          </>
        )}
        {orderType === 'Pickup' && distance > 0 && (
          <>
            <OutlineView
              value={
                (distance > 10 ? '10+' : distance < 1 ? (distance * 1000).toFixed(0) : distance.toFixed(1)) +
                (distance < 1 ? ' m' : ' km')
              }
              unit={'distance'}
            />
            <FView w={16} />
          </>
        )}
        <OutlineView value={waitTime + ' - ' + (waitTime + 10)} unit="mins" />
        <FView w={16} />
        {/* <OutlineView value={locationData.ratings ? `${locationData.ratings} / 5` : 'N/A'} unit="Ratings" />
        <FView w={16} /> */}
      </FView>
    </FView>
  )
})

const OutlineView = ({value, unit}) => {
  const theme = useTheme()
  return (
    <FView br={12} bc={theme.palette.grey[300]} bw={1} p={16} center minWidth={100}>
      <FText semiBold>{value}</FText>
      <FView w={8} />
      <FText color={theme.palette.grey[700]}>{unit}</FText>
    </FView>
  )
}

const classes = {
  promoImage: css({
    objectFit: 'cover',
    maxHeight: '100%',
    width: 1440,
    maxWidth: '100%',
    userSelect: 'none',
    WebkitUserDrag: 'none',
  }),
  orderTypeButton: css({
    transition: 'background-color 0.2s ease',
  }),
  orderTypeButtonText: css({
    transition: 'color 0.2s ease',
  }),
}
