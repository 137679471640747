import React from 'react'
import {FText, FView} from 'components'
import {colors} from 'styles'
import {FaGift} from 'react-icons/fa'

export default function RewardItemView({
  count,
  name,
  rewardName,
  productId,
  totalDiscountSubtotal,
  SubtractButtonElement,
}) {
  return (
    <FView row alignCenter minHeight={30} mb={5}>
      <FView size={25} bg={colors.grey} center rounded>
        <FText bold caption primaryContrast>
          x{count}
        </FText>
      </FView>
      <FView size={6} />
      <FaGift size={16} color={colors.grey} />
      <FView size={6} />
      <FView fill>
        <FText bold body2>
          {rewardName || name}
        </FText>
      </FView>
      <FView w={60}>
        <FText bold body2 alignRight>
          {productId || !totalDiscountSubtotal ? 'FREE' : `-$${totalDiscountSubtotal.toFixed(2)}`}
        </FText>
      </FView>
      {SubtractButtonElement}
    </FView>
  )
}
