import React from 'react'
import {FView, FText, FButton} from 'components'
import {useNavigate} from 'react-router-dom'

export default function Page404() {
  const navigate = useNavigate()
  return (
    <FView selfCenter p={50}>
      <FText bold h4>
        Page Not Found
      </FText>
      <FButton onClick={() => navigate('/', {replace: true})} selfStart>
        <FText bold h5 primary>
          {'< Go To Home'}
        </FText>
      </FButton>
    </FView>
  )
}
