import React, {useState} from 'react'
import {filter} from 'lodash'
import momentTZ from 'moment-timezone'
import {useParams, useNavigate} from 'react-router-dom'
import {useTheme} from '@material-ui/core/styles'
import {useDispatch, useSelector} from 'react-redux'
import {Divider} from '@material-ui/core'
import {Receipt} from '@material-ui/icons'
import {FView, FText, FButton, NavBarV2} from 'components'
import OrderDetailsItem from './OrderDetailsItem'

export default function Orders({directOrder}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {restaurantName, restaurantId, locationId, orderId} = useParams()
  let orderIds = useSelector(dispatch.user.getOrderIds)
  const orders = useSelector(dispatch.user.getOrders)
  if (directOrder && locationId) {
    orderIds = filter(orderIds, (orderId) => {
      const order = orders[orderId]
      return order?.locationId === locationId
    })
  }
  const [orderDetailsId, setOrderDetailsId] = useState(orderId)
  const ordersLink = directOrder ? `/direct/orders/${restaurantName}/${restaurantId}/${locationId}` : '/orders'

  return (
    <>
      <FView w={'100vw'} minHeight={'100vh'}>
        <NavBarV2 hasSearchBar={false} directOrder={directOrder} locationId={locationId} />

        <FView selfCenter w={768} minHeight={540} maxWidth={'100vw'} bg={theme.palette.common.white} rounded>
          {orderIds.length === 0 && (
            <FView fill center>
              <FText h1 noLineHeight>
                <Receipt fontSize="inherit" color="disabled" />
              </FText>
              <FView size={25} />
              <FText h5 grey500>
                You have no orders
              </FText>
            </FView>
          )}
          {orderIds.map((orderId) => {
            const order = orders[orderId]
            // In-case order is missing restaurantName
            const locationDetails = dispatch.restaurants.getLocation({locationId: order.locationId}) || {}
            const timezone = order?.timezone ?? 'America/Vancouver'
            return (
              <FView key={orderId}>
                <FButton
                  hoverHighlight
                  onClick={() => {
                    navigate(ordersLink + '/' + orderId, {replace: true})
                    setOrderDetailsId(orderId)
                  }}>
                  <FView fill p={15}>
                    <FView row justifyBetween>
                      <FText bold h6>
                        {order.restaurantName || locationDetails.restaurantName || order.locationId}
                      </FText>
                      <FText h6 bold primary>
                        {order.status}
                      </FText>
                    </FView>
                    <FText body1 alignLeft gutterBottom>
                      {momentTZ.tz(order.createdAt, timezone).calendar(null, {sameElse: 'L LT'})}
                    </FText>
                    <FView size={10} />
                    <FView row justifyBetween>
                      <FText body1># {order.orderNumber}</FText>
                      <FText body1 bold>
                        ${(order.total + (order.inpersonTipAmount || 0)).toFixed(2)}
                      </FText>
                    </FView>
                  </FView>
                </FButton>
                <Divider />
              </FView>
            )
          })}
        </FView>
      </FView>
      {/* <Modal
        aria-labelledby="auth-modal-title"
        open={!!orderDetailsId && !!orders[orderDetailsId]}
        onClose={() => {
          navigate(ordersLink, {replace: true})
          setOrderDetailsId(null)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.orderDetailsModal}>
        <Fade in={!!orderDetailsId}>
          <FView
            w={600}
            maxWidth="100vw"
            maxHeight={'90vh'}
            overflowY="auto"
            overflowX="hidden"
            bg={theme.palette.common.white}
            outline={0}
            rounded>
            <FView block w={600} maxWidth="100vw" p={25} pt={15} relative>
              <OrderDetailsItem orderId={orderDetailsId} />
              <FButton
                absolute
                right={0}
                top={0}
                zIndex={2}
                w={50}
                onClick={() => {
                  navigate(ordersLink, {replace: true})
                  setOrderDetailsId(null)
                }}>
                <Close />
              </FButton>
            </FView>
          </FView>
        </Fade>
      </Modal> */}
      <OrderDetailsItem
        orderId={orderDetailsId}
        open={!!orderDetailsId}
        handleClose={() => {
          navigate(ordersLink, {replace: true})
          setOrderDetailsId(null)
        }}
        directOrder={directOrder}
      />
    </>
  )
}
