import {db, getCollection, getLocationsGroup, Timestamp} from 'f-core/src/config/firebase'
import momentTZ from 'moment-timezone'
import {get, reduce} from 'lodash'
import * as utils from 'f-utils'
// import * as geofire from 'geofire-common'

// const DEFAULT_LOCATION = {
//   isCategoriesLoading: true,
//   isProductsLoading: true,
//   isModifierGroupsLoading: true,
//   isReviewsLoading: true,
//   Categories: {},
//   Products: {},
//   ModifierGroups: {},
//   Reviews: [],

//   // LocalOnly
//   activeCategoryOrder: [],
//   activeProducts: {},

//   restaurantId: null,
//   restaurantName: null,
//   locationName: null,
//   address: null,
//   availableTables: {},
//   phoneNumber: null,
//   email: null,
//   hours: {},
//   menus: {},
//   orderOpen: null,
//   rewards: {},
//   waitTime: 0,
//   printingEnabled: false,
//   deliveryFee: 0,
//   deliveryEnabled: false, // Deprecated
//   deliveryFreeMinimumSubTotal: 40,
//   deliveryMinimumSubTotal: 0,
//   deliveryDistance: 0,
//   deliveryTime: 0,
//   paymentMethods: {
//     'online-creditcard': true,
//     'inperson-cash': true,
//   },
//   promoUrl: null,
//   restaurantLatLng: { lat: 0, lng: 0 },
//   restaurantIconUrl: null,
//   waitTimeIncrementSubTotalStart: 0,
//   waitTimeIncrementSubTotalInterval: 10,
//   waitTimeIncrementAmount: 0,
// }

const DEFAULT_STATE = {
  // Restaurant data
  locationsLoaded: false,
  Locations: {},
  LocationIdToRewardName: {},

  // Locations SubCollection
  LocationsCategories: {},
  LocationsProducts: {},
  LocationsModifierGroups: {},

  locationsIsCategoriesLoading: {},
  locationsIsProductsLoading: {},
  locationsIsModifierGroupsLoading: {},
  locationsActiveCategoryOrder: {},
  locationsActiveProducts: {},
  Rewards: {},
  directOrderLoaded: false,
}

let unsubDirectOrderLocation = null
let unsubDirectOrderCategories = null
let unsubDirectOrderProducts = null
let unsubDirectOrderProductModifiers = null
let unsubRewardsWithCode = []

const restaurantsModel = {
  state: DEFAULT_STATE,
  reducers: {
    setLocationsLoaded: (state, locationsLoaded) => {
      return {...state, locationsLoaded}
    },
    setLocations: (state, Locations) => {
      return {
        ...state,
        locationsLoading: false,
        Locations,
      }
    },
    setLocation: (state, {locationId, Location}) => {
      return {
        ...state,
        Locations: {...state.Locations, [locationId]: Location},
      }
    },
    setDirectOrderLoaded: (state, directOrderLoaded) => {
      return {...state, directOrderLoaded}
    },
    setLocationCategoriesLoading: (state, {locationId, isCategoriesLoading}) => {
      return {
        ...state,
        locationsIsCategoriesLoading: {
          ...state.locationsIsCategoriesLoading,
          [locationId]: isCategoriesLoading,
        },
      }
    },
    setCategories: (state, {locationId, Categories}) => {
      return {
        ...state,
        locationsIsCategoriesLoading: {
          ...state.locationsIsCategoriesLoading,
          [locationId]: false,
        },
        LocationsCategories: {
          ...state.LocationsCategories,
          [locationId]: Categories,
        },
      }
    },
    setLocationProductsLoading: (state, {locationId, isProductsLoading}) => {
      return {
        ...state,
        locationsIsProductsLoading: {
          ...state.locationsIsProductsLoading,
          [locationId]: isProductsLoading,
        },
      }
    },
    setProducts: (state, {locationId, Products}) => {
      return {
        ...state,
        locationsIsProductsLoading: {
          ...state.locationsIsProductsLoading,
          [locationId]: false,
        },
        LocationsProducts: {
          ...state.LocationsProducts,
          [locationId]: Products,
        },
      }
    },
    setLocationModifierGroupsLoading: (state, {locationId, isModifierGroupsLoading}) => {
      return {
        ...state,
        locationsIsModifierGroupsLoading: {
          ...state.locationsIsModifierGroupsLoading,
          [locationId]: isModifierGroupsLoading,
        },
      }
    },
    setModifierGroups: (state, {locationId, ModifierGroups}) => {
      return {
        ...state,
        locationsIsModifierGroupsLoading: {
          ...state.locationsIsModifierGroupsLoading,
          [locationId]: false,
        },
        LocationsModifierGroups: {
          ...state.LocationsModifierGroups,
          [locationId]: ModifierGroups,
        },
      }
    },

    setRewards: (state, {locationId, Rewards}) => {
      return {
        ...state,
        Rewards: {
          ...state.Rewards,
          [locationId]: Rewards,
        },
      }
    },
    setAllRewards: (state, {Rewards}) => {
      return {
        ...state,
        Rewards,
      }
    },
    setLocationIdToRewardName: (state, {locationId, rewardName}) => {
      return {
        ...state,
        LocationIdToRewardName: {
          ...state.LocationIdToRewardName,
          [locationId]: rewardName,
        },
      }
    },
    // setLocationReviewsLoading: (state, {locationId, isReviewsLoading}) => {
    //   return {
    //     ...state,
    //     Locations: {...state.Locations, [locationId]: {...state.Locations[locationId], isReviewsLoading}},
    //   }
    // },
    // setReviews: (state, {locationId, Reviews}) => {
    //   return {
    //     ...state,
    //     Locations: {
    //       ...state.Locations,
    //       [locationId]: {...state.Locations[locationId], isReviewsLoading: false, Reviews},
    //     },
    //   }
    // },
    setActiveCategoryOrder: (state, {locationId, activeCategoryOrder}) => {
      return {
        ...state,
        locationsActiveCategoryOrder: {
          ...state.locationsActiveCategoryOrder,
          [locationId]: activeCategoryOrder,
        },
      }
    },
    setActiveProducts: (state, {locationId, activeProducts}) => {
      return {
        ...state,
        locationsActiveProducts: {
          ...state.locationsActiveProducts,
          [locationId]: activeProducts,
        },
      }
    },
  },
  actions: ({dispatch, getState}) => ({
    getDeliveryFeeTaxType({locationId}) {
      return dispatch.restaurants.getLocation({locationId})?.deliveryFeeTaxType ?? 'prorated'
    },
    getAnnouncementData({locationId}) {
      return dispatch.restaurants.getLocation({locationId})?.announcement ?? null
    },
    getIsAnnouncementActive({locationId}) {
      const announcementData = dispatch.restaurants.getAnnouncementData({locationId})
      if (announcementData) {
        const now = new Date().valueOf()
        if (announcementData.activeAfter?.toMillis() > now) {
          return false
        }
        if (announcementData.activeUntil?.toMillis() < now) {
          return false
        }
        return true
      }
      return false
    },
    getTimezone({locationId}) {
      return dispatch.restaurants.getLocation({locationId})?.timezone ?? 'America/Vancouver'
    },
    getMoment({locationId}) {
      const timezone = dispatch.restaurants.getTimezone({locationId})
      return momentTZ().tz(timezone)
    },
    getIsLocationsLoaded() {
      return getState().restaurants.locationsLoaded
    },
    getLocations() {
      return getState().restaurants.Locations
    },
    getLocationIdToRewardName() {
      return getState().restaurants.LocationIdToRewardName
    },

    getLocation({locationId}) {
      if (!locationId) {
        return null
      }
      return getState().restaurants.Locations[locationId]
    },
    getRestaurantType({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.restaurantType
    },
    getIsDirectOrderLoaded() {
      return getState().restaurants.directOrderLoaded
    },
    getMenus({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.menus ?? {}
    },
    getRestaurantLatLng({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.restaurantLatLng ?? {lat: 0, lng: 0}
    },
    getCategories({locationId}) {
      return getState().restaurants.LocationsCategories[locationId] ?? {}
    },
    getCategory({locationId, categoryId}) {
      const categories = dispatch.restaurants.getCategories({locationId})
      return categories[categoryId] ?? {}
    },
    getProducts({locationId}) {
      return getState().restaurants.LocationsProducts[locationId] ?? {}
    },
    getModifierGroups({locationId}) {
      return getState().restaurants.LocationsModifierGroups[locationId] ?? {}
    },
    subscribeRewards() {
      return db
        .collectionGroup('Rewards')
        .where('isPublic', '==', true)
        .onSnapshot((rewardQuerySnap) => {
          const Rewards = {}
          rewardQuerySnap?.forEach((doc) => {
            const locationId = doc?.ref?.parent?.parent?.id
            if (!locationId) {
              return
            }
            Rewards[locationId] = {}
            Rewards[locationId][doc.id] = doc.data()
          })
          dispatch.restaurants.setAllRewards({Rewards})
        })
    },
    getAllRewards() {
      return getState().restaurants.Rewards
    },
    getRewards({locationId}) {
      const fieldRewards = dispatch.restaurants.getFieldRewards({locationId})
      const collectionRewards = getState().restaurants.Rewards[locationId] ?? {}
      return {...fieldRewards, ...collectionRewards}
    },
    getModifierGroupDetails({locationId, modifierGroupId}) {
      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId})
      return modifierGroups == null || !modifierGroups[modifierGroupId] ? {} : modifierGroups[modifierGroupId]
    },
    // getReviews({locationId}) {
    //   const locationData = dispatch.restaurants.getLocation({locationId})
    //   return locationData?.Reviews ?? []
    // },
    getActiveCategoryOrder({locationId}) {
      return getState().restaurants.locationsActiveCategoryOrder[locationId] ?? []
    },
    getAvailableTables({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.availableTables ?? {}
    },
    getProductDetails({locationId, productId}) {
      const Products = dispatch.restaurants.getProducts({locationId})
      return Products[productId] ?? {}
    },
    getProductDetailsFieldValue({locationId, productId, key}) {
      const productDetails = dispatch.restaurants.getProductDetails({locationId, productId})
      return productDetails?.[key]
    },
    getOrderOpen({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.orderOpen ?? false
    },
    getOrderOpenAfter({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.orderOpenAfter ?? (dispatch.restaurants.getOrderOpen({locationId}) ? 0 : 1e15)
    },
    getHours({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.hours ?? {}
    },
    getHoursLT({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.hoursLT ?? null
    },
    getWaitTime({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.waitTime ?? 120
    },
    getIsDeliveryEnabled({locationId}) {
      const locations = dispatch.restaurants.getLocations()
      return locations[locationId] ? locations[locationId].deliveryEnabled : false
    },
    getEstimatedDeliveryFee({locationId}) {
      const deliveryFee = dispatch.restaurants.getDeliveryFee({locationId})
      const deliveryFeeMinimum = dispatch.restaurants.getDeliveryFeeMinimum({locationId})
      const isDeliveryFeeVariable = dispatch.restaurants.getIsDeliveryFeeVariable({locationId})
      const deliveryFreeMinimumSubTotal = dispatch.restaurants.getDeliveryFreeMinimumSubTotal({
        locationId,
      })
      const deliveryFeePerDistance = dispatch.restaurants.getDeliveryFeePerDistance({locationId})
      const deliveryFeeDiscountMinimumSubTotal = dispatch.restaurants.getDeliveryFeeDiscountMinimumSubTotal({
        locationId,
      })
      const deliveryFeeDiscount = dispatch.restaurants.getDeliveryFeeDiscount({
        locationId,
      })
      const userLocation = dispatch.user.getUserLocation()
      if (!userLocation) {
        return null
      }
      const {latitude, longitude} = userLocation
      const locationData = dispatch.restaurants.getLocation({locationId})
      if (!locationData) {
        return null
      }

      return utils.calculateDeliveryFee({
        customerCoords: {lat: latitude, lng: longitude},
        deliveryFee,
        deliveryFeeDiscount,
        deliveryFeeDiscountMinimumSubTotal,
        deliveryFeeMinimum,
        deliveryFeePerDistance,
        deliveryFreeMinimumSubTotal,
        isDeliveryFeeVariable,
        orderType: 'Delivery',
        restaurantCoords: locationData.restaurantLatLng,
        subTotalBeforeDiscount: 0,
      })
    },
    getDeliveryFee({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryFee ?? 0
    },
    getDeliveryFeeMinimum({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryFeeMinimum ?? 0
    },
    getIsDeliveryFeeVariable({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.isDeliveryFeeVariable ?? true
    },
    getDeliveryFeePerDistance({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryFeePerDistance ?? 0
    },
    getDeliveryMinimumSubTotal({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryMinimumSubTotal ?? 0
    },
    getDeliveryFreeMinimumSubTotal({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryFreeMinimumSubTotal
    },
    getDeliveryFeeDiscount({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryFeeDiscount ?? 0
    },
    getDeliveryFeeDiscountMinimumSubTotal({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryFeeDiscountMinimumSubTotal ?? 0
    },
    getDeliveryDistance({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryDistance ?? 0
    },
    getDeliveryTime({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryTime ?? 15
    },
    getDeliveryHoursLT({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.deliveryHoursLT ?? locationData?.hoursLT ?? null
    },
    getPaymentMethods({locationId}) {
      const locations = dispatch.restaurants.getLocations()
      return locations[locationId] && locations[locationId].paymentMethods
        ? locations[locationId].paymentMethods
        : {
            'online-creditcard': true,
            'inperson-cash': true,
          }
    },
    getIsOnlineCCEnabled({locationId}) {
      const paymentMethods = dispatch.restaurants.getPaymentMethods({locationId})
      return get(paymentMethods, 'online-creditcard', true)
    },
    getIsInpersonCashEnabled({locationId}) {
      const paymentMethods = dispatch.restaurants.getPaymentMethods({locationId})
      return get(paymentMethods, 'inperson-cash', true)
    },
    getIsCashDeliveryEnabled({locationId}) {
      const location = dispatch.restaurants.getLocation({locationId})
      return location?.isCashDeliveryEnabled ?? false
    },
    getOrderOpenDetails({locationId, targetTimeMoment}) {
      const myTargetTimeMoment = targetTimeMoment ?? dispatch.restaurants.getMoment({locationId})
      const timezone = dispatch.restaurants.getTimezone({locationId})

      const hoursLT = dispatch.restaurants.getHoursLT({locationId})
      if (!hoursLT) {
        return {
          isOpen: dispatch.restaurants.getIsStoreOpen({locationId, targetTime: myTargetTimeMoment}),
        }
      }
      const orderOpenAfter = dispatch.restaurants.getOrderOpenAfter({locationId})

      return utils.getOrderStatusDetailsTimestamp({
        hoursLT,
        targetTimestamp: myTargetTimeMoment.valueOf(),
        orderOpenAfter,
        timezone,
      })
    },
    getDeliveryOrderOpenDetails({locationId, targetTimeMoment}) {
      const myTargetTimeMoment = targetTimeMoment ?? dispatch.restaurants.getMoment({locationId})
      const timezone = dispatch.restaurants.getTimezone({locationId})

      const deliveryHoursLT = dispatch.restaurants.getDeliveryHoursLT({locationId})
      if (!deliveryHoursLT) {
        return dispatch.restaurants.getOrderOpenDetails({locationId, targetTimeMoment: myTargetTimeMoment})
      }
      const orderOpenAfter = dispatch.restaurants.getOrderOpenAfter({locationId})
      return utils.getOrderStatusDetailsTimestamp({
        hoursLT: deliveryHoursLT,
        targetTimestamp: myTargetTimeMoment.valueOf(),
        orderOpenAfter,
        timezone,
      })
    },
    getOrderOpenPeriods({locationId, targetTimestamp}) {
      const myTargetTimestamp = targetTimestamp ?? new Date().valueOf()
      const timezone = dispatch.restaurants.getTimezone({locationId})
      const locations = dispatch.restaurants.getLocations()
      const locationData = locations[locationId]
      const orderOpenAfter = locationData?.orderOpenAfter ?? null
      if (orderOpenAfter !== null && myTargetTimestamp < orderOpenAfter) {
        return []
      }
      const hoursLT = locationData?.hoursLT
      return utils.getOrderOpenPeriods({hoursLT, targetTimestamp: myTargetTimestamp, timezone, fullRange: true})
    },
    getDeliveryOpenPeriods({locationId, targetTimestamp}) {
      const myTargetTimestamp = targetTimestamp ?? new Date().valueOf()
      const timezone = dispatch.restaurants.getTimezone({locationId})
      const locations = dispatch.restaurants.getLocations()
      const locationData = locations[locationId]
      const orderOpenAfter = locationData?.orderOpenAfter ?? null
      if (orderOpenAfter !== null && myTargetTimestamp < orderOpenAfter) {
        return []
      }

      const deliveryHoursLT = locationData?.deliveryHoursLT
      if (deliveryHoursLT) {
        return utils.getOrderOpenPeriods({
          hoursLT: deliveryHoursLT,
          targetTimestamp: myTargetTimestamp,
          timezone,
          fullRange: true,
        })
      }
      const hoursLT = locationData?.hoursLT
      return utils.getOrderOpenPeriods({hoursLT, targetTimestamp: myTargetTimestamp, timezone, fullRange: true})
    },
    getIsStoreOpen({locationId, targetTime}) {
      const myTargetTimeMoment = targetTime ?? dispatch.restaurants.getMoment({locationId})
      const timezone = dispatch.restaurants.getTimezone({locationId})
      const targetTimestamp = myTargetTimeMoment.valueOf()
      const locations = dispatch.restaurants.getLocations()
      const locationData = locations[locationId]
      const orderOpenAfter = locationData?.orderOpenAfter ?? null
      if (orderOpenAfter !== null && targetTimestamp < orderOpenAfter) {
        return false
      }
      if (locationData?.hoursLT) {
        // Greatly simplified starting version 2, only open if current time falls within one or more timeLTs in today's weekday
        const hoursLT = locationData?.hoursLT
        return utils.getIsOrderOpenHoursLT({hoursLT, targetTimeMoment: myTargetTimeMoment, timezone})
      }

      // ==================== Deprecated =====================================
      const orderOpen = locationData?.orderOpen ?? null

      // return null if we don't know if order is open (ie. still loading)
      if (orderOpen === null) {
        return null
      }
      if (orderOpen === false) {
        return false
      }

      // To properly check if currentTime is between store hours,
      // Check both today's weekday and yesterday's weekday to see if currentTime is between open and close.

      const todayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurants.getHours({locationId}),
        targetDate: utils.moment(),
        time: utils.moment(),
        waitTime: dispatch.restaurants.getWaitTime({locationId}),
      })
      if (todayOpen) {
        return true
      }

      const yesterdayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurants.getHours({locationId}),
        targetDate: utils.moment().subtract(1, 'days'),
        time: utils.moment(),
        waitTime: dispatch.restaurants.getWaitTime({locationId}),
      })
      return yesterdayOpen
      // ======================================================================
    },
    getIsPickupAvailable({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.availableOrderTypes?.Pickup ?? true
    },
    getIsDeliveryAvailable({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.availableOrderTypes?.Delivery ?? locationData?.deliveryEnabled ?? false
    },
    getIsDineInAvailable({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData?.availableOrderTypes?.DineIn ?? false
    },
    getCategoryProductOrder({categoryId, locationId}) {
      const Categories = dispatch.restaurants.getCategories({locationId})
      return Categories[categoryId]?.productOrder || []
    },
    getActiveProducts({locationId}) {
      return getState().restaurants.locationsActiveProducts[locationId] ?? {}
    },
    getIsProductActive({locationId, productId}) {
      const productDetails = dispatch.restaurants.getProductDetails({locationId, productId})
      return utils.getIsProductActive(productDetails)
    },
    getIsMenuActive({menu}) {
      const activeFrom = menu.activeFrom ?? Timestamp.fromMillis(0)
      const activeUntil = menu.activeUntil ?? Timestamp.fromMillis(utils.moment().year(3000).valueOf())
      const currMillis = utils.moment().valueOf()
      return menu.active && activeFrom.toMillis() < currMillis && currMillis < activeUntil.toMillis()
    },
    updateActiveCategoryOrder({locationId, targetDateTime}) {
      const menus = dispatch.restaurants.getMenus({locationId})
      const categoryOrder = Object.entries(menus)
        .sort((a, b) => {
          if (a[0] === 'default') {
            return 1
          }
          if (b[0] === 'default') {
            return -1
          }
          return 0
        })
        .reduce((prev, [key, menu]) => {
          const isActive = dispatch.restaurants.getIsMenuActive({menu})
          const {hours} = menu
          if (isActive) {
            const isOpen = utils.getIsOpenForGivenTime({
              hours,
              targetDate: utils.moment(targetDateTime),
              time: utils.moment(targetDateTime),
              waitTime: 0,
            })
            if (isOpen) {
              prev = prev.concat(get(menu, 'categoryOrder'))
            } else {
              const isYesterdayOpen = utils.getIsOpenForGivenTime({
                hours,
                targetDate: utils.moment(targetDateTime).subtract(1, 'days'),
                time: utils.moment(targetDateTime),
                waitTime: 0,
              })
              if (isYesterdayOpen) {
                prev = prev.concat(get(menu, 'categoryOrder'))
              }
            }
          }
          return prev
        }, [])
      if (categoryOrder.length === 0 && menus.default) {
        dispatch.restaurants.setActiveCategoryOrder({locationId, activeCategoryOrder: menus.default.categoryOrder})
      } else {
        dispatch.restaurants.setActiveCategoryOrder({locationId, activeCategoryOrder: categoryOrder})
      }
    },
    updateActiveProducts({locationId}) {
      const activeProducts = {}
      const activeCategoryOrder = dispatch.restaurants.getActiveCategoryOrder({locationId})
      const Categories = dispatch.restaurants.getCategories({locationId})
      const Products = dispatch.restaurants.getProducts({locationId})

      for (const categoryId of activeCategoryOrder) {
        if (!Categories[categoryId]) {
          continue
        }
        const productOrder = Categories[categoryId].productOrder || []
        for (const productId of productOrder) {
          // Check if product exists
          const productDetails = Products[productId]
          if (!productDetails) {
            continue
          }
          // Check if product is active
          if (!utils.getIsProductActive(productDetails)) {
            continue
          }

          activeProducts[productId] = productDetails
        }
      }
      dispatch.restaurants.setActiveProducts({locationId, activeProducts})
    },
    getDeliveryInfoDoc({restaurantId, orderId}) {
      return getCollection('DeliveryInfo', {restaurantId}).doc(orderId)
    },
    subscribeLocations() {
      dispatch.restaurants.setLocationsLoaded(false)
      const unsubLocations = getLocationsGroup({isVisible: true}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const Locations = {}
          for (const doc of snapshot.docs) {
            const Location = doc.data()
            Location.restaurantId = doc.ref.parent.parent.id
            Location.locationId = doc.id
            Locations[doc.id] = Location
          }
          dispatch.restaurants.setLocations(Locations)
          dispatch.restaurants.setLocationsLoaded(true)
        },
      )
      const unsubRewards = dispatch.restaurants.subscribeRewards()

      return () => {
        unsubRewards()
        unsubLocations()
      }
    },
    // async subscribeLocations(radius = 15) {
    //   dispatch.restaurants.setLocationsLoaded(false)
    //   try {
    //     dispatch.restaurants.setLocations({})
    //     const userLocation = dispatch.user.getUserLocation()
    //     const latlng = [userLocation.latitude, userLocation.longitude]
    //     // Find cities within 50km of center
    //     const center = latlng
    //     const radiusInM = radius * 1000

    //     // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    //     // a separate query for each pair. There can be up to 9 pairs of bounds
    //     // depending on overlap, but in most cases there are 4.
    //     const bounds = geofire.geohashQueryBounds(center, radiusInM)
    //     const promises = []
    //     for (const b of bounds) {
    //       let query = db
    //         .collectionGroup('Locations')
    //         // .where('isVisible', '==', 'true')
    //         .orderBy('geohash')
    //         .startAt(b[0])
    //         .endAt(b[1])

    //       promises.push(query.get())
    //     }

    //     // Collect all the query results together into a single list
    //     const snapshots = await Promise.all(promises)
    //     for (const snap of snapshots) {
    //       for (const doc of snap.docs) {
    //         const latlng = doc.get('restaurantLatLng')
    //         const {lat, lng} = latlng
    //         // We have to filter out a few false positives due to GeoHash
    //         // accuracy, but most will match
    //         const distanceInKm = geofire.distanceBetween([lat, lng], center)
    //         const distanceInM = distanceInKm * 1000
    //         if (distanceInM > radiusInM) {
    //           continue
    //         }
    //         const locationId = doc.id
    //         const Location = doc.data()
    //         Location.restaurantId = doc.ref.parent.parent.id
    //         Location.locationId = doc.id
    //         dispatch.restaurants.setLocation({locationId, Location})
    //       }
    //     }
    //   } finally {
    //     dispatch.restaurants.setLocationsLoaded(true)
    //   }
    // },
    getIsFoodLoading({locationId}) {
      const isCategoriesLoading = getState().restaurants.locationsIsCategoriesLoading[locationId] ?? true
      const isProductsLoading = getState().restaurants.locationsIsProductsLoading[locationId] ?? true
      const isModifierGroupsLoading = getState().restaurants.locationsIsModifierGroupsLoading[locationId] ?? true

      return isCategoriesLoading || isProductsLoading || isModifierGroupsLoading
    },
    // getIsReviewsLoading({locationId}) {
    //   const locationData = dispatch.restaurants.getLocation({locationId})
    //   if (!locationData) {
    //     return true
    //   }
    //   return locationData.isReviewsLoading
    // },
    //TODO:  Remove after reward migration
    getFieldRewards({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      return locationData && locationData.rewards ? locationData.rewards : {}
    },
    getLocationsWithPromotions() {
      const locations = dispatch.restaurants.getLocations()
      return reduce(
        locations,
        (prev, curr) => {
          if (curr.rewards) {
            for (const reward of Object.values(curr.rewards)) {
              if (
                reward.type === 'FreeFirstOrderOrPoints/Product' ||
                reward.type === 'Free/Product/MinSubTotal' ||
                reward.type === 'Free/DiscountPercent/Pickup' ||
                reward.type === 'Free/DiscountPercent/MinSubTotal'
              ) {
                if (reward.type === 'FreeFirstOrderOrPoints/Product') {
                  prev.push({
                    locationId: curr.locationId,
                    restaurantId: curr.restaurantId,
                    restaurantName: curr.restaurantName,
                    restaurantType: curr.restaurantType,
                    ...reward,
                    name: `Free ${reward.rewardName} on first order`,
                  })
                } else if (reward.type === 'Free/Product/MinSubTotal') {
                  prev.push({
                    locationId: curr.locationId,
                    restaurantId: curr.restaurantId,
                    restaurantName: curr.restaurantName,
                    restaurantType: curr.restaurantType,
                    ...reward,
                    name: `Free ${reward.rewardName} on orders > $${reward.minimumSubTotal}`,
                  })
                } else if (reward.type === 'Free/DiscountPercent/MinSubTotal') {
                  prev.push({
                    locationId: curr.locationId,
                    restaurantId: curr.restaurantId,
                    restaurantName: curr.restaurantName,
                    restaurantType: curr.restaurantType,
                    ...reward,
                    name: `${reward.value * 100}% off on orders > $${reward.minimumSubTotal}`,
                  })
                } else if (reward.type === 'Free/DiscountPercent/Pickup') {
                  prev.push({
                    locationId: curr.locationId,
                    restaurantId: curr.restaurantId,
                    restaurantName: curr.restaurantName,
                    restaurantType: curr.restaurantType,
                    ...reward,
                    name: `${reward.value * 100}% off on pickup`,
                  })
                }
                return prev
              }
            }
          }
          return prev
        },
        [],
      )
    },
    subscribeDirectOrderLocation({restaurantId, locationId}) {
      dispatch.restaurants.setDirectOrderLoaded(false)
      let isLocationLoading = true
      let isCategoriesLoading = true
      let isProductsLoading = true
      let isProductModifiersLoading = true

      function updateActiveAndLoadingIfAllLoaded() {
        if (!isLocationLoading && !isCategoriesLoading && !isProductsLoading && !isProductModifiersLoading) {
          dispatch.restaurants.updateActiveCategoryOrder({locationId})
          dispatch.restaurants.updateActiveProducts({locationId})
          dispatch.restaurants.setDirectOrderLoaded(true)
        }
      }

      function unsubAll() {
        unsubDirectOrderLocation && unsubDirectOrderLocation()
        unsubDirectOrderCategories && unsubDirectOrderCategories()
        unsubDirectOrderProducts && unsubDirectOrderProducts()
        unsubDirectOrderProductModifiers && unsubDirectOrderProductModifiers()
      }
      unsubAll()

      // 1. Load Location
      unsubDirectOrderLocation = getCollection('Locations', {restaurantId})
        .doc(locationId)
        .onSnapshot({includeMetadataChanges: true}, (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const Location = snapshot.data()
          if (Location) {
            Location.restaurantId = restaurantId
            Location.locationId = locationId
            dispatch.restaurants.setLocation({locationId, Location})
          }
          isLocationLoading = false
          updateActiveAndLoadingIfAllLoaded()
        })

      // 2. Load Categories
      unsubDirectOrderCategories = getCollection('Categories', {restaurantId, locationId}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const Categories = {}
          for (const doc of snapshot.docs) {
            Categories[doc.id] = doc.data()
          }
          dispatch.restaurants.setCategories({locationId, Categories})
          isCategoriesLoading = false
          updateActiveAndLoadingIfAllLoaded()
        },
      )

      // 3. Load Products
      unsubDirectOrderProducts = getCollection('Products', {restaurantId, locationId}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const Products = {}
          for (const doc of snapshot.docs) {
            Products[doc.id] = doc.data()
          }
          dispatch.restaurants.setProducts({locationId, Products})
          isProductsLoading = false
          updateActiveAndLoadingIfAllLoaded()
        },
      )

      // 4. Load ProductModifiers
      unsubDirectOrderProductModifiers = getCollection('ModifierGroups', {restaurantId, locationId}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const ModifierGroups = {}
          for (const doc of snapshot.docs) {
            ModifierGroups[doc.id] = doc.data()
          }
          dispatch.restaurants.setModifierGroups({locationId, ModifierGroups})
          isProductModifiersLoading = false
          updateActiveAndLoadingIfAllLoaded()
        },
      )

      return unsubAll
    },
    subscribeReward({rewardId, restaurantId, locationId}) {
      unsubRewardsWithCode.push(
        getCollection('Rewards', {restaurantId, locationId})
          .doc(rewardId)
          .onSnapshot({includeMetadataChanges: true}, (snapshot) => {
            if (!snapshot || snapshot.metadata.fromCache) {
              return
            }
            const Rewards = dispatch.restaurants.getRewards({locationId})
            Rewards[snapshot.id] = snapshot.data()
            dispatch.restaurants.setRewards({locationId, Rewards})
          }),
      )
    },

    subscribeLocation({restaurantId, locationId, onLoadComplete}) {
      dispatch.restaurants.setLocationCategoriesLoading({locationId, isCategoriesLoading: true})
      dispatch.restaurants.setLocationProductsLoading({locationId, isProductsLoading: true})
      dispatch.restaurants.setLocationModifierGroupsLoading({locationId, isModifierGroupsLoading: true})
      unsubRewardsWithCode.forEach((unsub) => unsub())

      let hasCalledOnLoadComplete = false
      const isLoadComplete = {
        Categories: false,
        Products: false,
        ModifierGroups: false,
      }

      function handleLoadComplete() {
        if (hasCalledOnLoadComplete) {
          return
        }
        for (const isLoaded of Object.values(isLoadComplete)) {
          if (!isLoaded) {
            return false
          }
        }
        if (onLoadComplete) {
          hasCalledOnLoadComplete = true
          onLoadComplete()
        }
      }

      const scheduledOrderTimestamp = dispatch.user.getScheduledOrderTimestamp()
      const unsubCategories = getCollection('Categories', {restaurantId, locationId}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const Categories = {}
          for (const doc of snapshot.docs) {
            Categories[doc.id] = doc.data()
          }
          dispatch.restaurants.setCategories({locationId, Categories})
          dispatch.restaurants.updateActiveCategoryOrder({
            locationId,
            targetDateTime: scheduledOrderTimestamp ? scheduledOrderTimestamp : undefined,
          })
          dispatch.restaurants.updateActiveProducts({locationId})
          isLoadComplete.Categories = true
          handleLoadComplete()
        },
      )
      const unsubProducts = getCollection('Products', {restaurantId, locationId}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const Products = {}
          for (const doc of snapshot.docs) {
            Products[doc.id] = doc.data()
          }
          dispatch.restaurants.setProducts({locationId, Products})
          dispatch.restaurants.updateActiveCategoryOrder({
            locationId,
            targetDateTime: scheduledOrderTimestamp ? scheduledOrderTimestamp : undefined,
          })
          dispatch.restaurants.updateActiveProducts({locationId})
          isLoadComplete.Products = true
          handleLoadComplete()
        },
      )
      const unsubModifierGroups = getCollection('ModifierGroups', {restaurantId, locationId}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!snapshot || snapshot.metadata.fromCache) {
            return
          }
          const ModifierGroups = {}
          for (const doc of snapshot.docs) {
            ModifierGroups[doc.id] = doc.data()
          }
          dispatch.restaurants.setModifierGroups({locationId, ModifierGroups})
          isLoadComplete.ModifierGroups = true
          handleLoadComplete()
        },
      )

      // const unsubReviews = getReviewsCreatedAtDesc({restaurantId, locationId}).onSnapshot(
      //   {includeMetadataChanges: true},
      //   snapshot => {
      //     if (!snapshot || snapshot.metadata.fromCache) {
      //       return
      //     }
      //     const Reviews = []
      //     for (const doc of snapshot.docs) {
      //       const reviewData = doc.data()
      //       reviewData.reviewId = doc.id
      //       Reviews.push(reviewData)
      //     }
      //     dispatch.restaurants.setReviews({locationId, Reviews})
      //   },
      // )
      return () => {
        unsubCategories()
        unsubProducts()
        unsubModifierGroups()
        // unsubReviews()
      }
    },
  }),
}

export default restaurantsModel
