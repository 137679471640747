import React from 'react'
import * as utils from 'f-utils'
import {FView, FText} from 'f-web/src/components'
import {colors} from 'styles'
import {css} from 'emotion'
import {useDispatch, useSelector} from 'react-redux'

export default function RestaurantListItemView({item, locationId}) {
  const dispatch = useDispatch()
  const orderOpenDetails = useSelector(
    () => dispatch.restaurants.getOrderOpenDetails({locationId}),
    utils.isOrderOpenDetailsEqual,
  )
  const deliveryAvailable = useSelector(() => dispatch.restaurants.getIsDeliveryAvailable({locationId}))

  return (
    <FView row p={15} minWidth={320}>
      <FView relative>
        <img src={item.restaurantIconUrl} className={classes.restaurantIconStyle} alt="" />
        {!orderOpenDetails?.isOpen && (
          <FView absolute left={0} w={85} h={85} bg={colors.black50} center>
            <FText body2 bold alignCenter primaryContrast>
              Ordering Closed
            </FText>
          </FView>
        )}
      </FView>
      <FView size={10} />
      <FView fill>
        <FText body1 bold gutterBottom alignLeft>
          {item.restaurantName}
        </FText>
        <FText body1 gutterBottom alignLeft>{`${item.waitTime} - ${item.waitTime + 10} mins`}</FText>
        <FText body1 gutterBottom alignLeft>
          {deliveryAvailable
            ? item.deliveryFee <= 0
              ? ''
              : item.deliveryFreeMinimumSubTotal <= 0
              ? ''
              : `$${(item.deliveryFee || 0).toFixed(2)}` +
                (item.deliveryFee > 0 && item.deliveryFreeMinimumSubTotal > 0 && item.deliveryFreeMinimumSubTotal < 1000
                  ? ' | '
                  : '')
            : 'Pickup Only'}
          {deliveryAvailable && (
            <FText inline primary>
              {item.deliveryFreeMinimumSubTotal && item.deliveryFreeMinimumSubTotal !== 1000 && (
                <FText> Free Delivery over ${item.deliveryFreeMinimumSubTotal.toFixed(2)}</FText>
              )}
              {!item.deliveryFreeMinimumSubTotal && item.deliveryFeeDiscount && (
                <FText>
                  {' '}
                  ${item.deliveryFeeDiscount} off Delivery over ${item.deliveryFeeDiscountMinimumSubTotal.toFixed(2)}
                </FText>
              )}
            </FText>
          )}
        </FText>
      </FView>
    </FView>
  )
}

const classes = {
  restaurantIconStyle: css({width: 85, height: 85, objectFit: 'contain'}),
}
