import React, {useState, useRef} from 'react'
import {FView, FText, FInput, ButtonFillView, FButton} from 'components'
import {colors} from 'styles'
import {MdPlace} from 'react-icons/md'
import {IoIosCloseCircle} from 'react-icons/io'
import {useMediaMinMD} from 'f-web/src/hooks'
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete'
import {css} from 'emotion'
import {CircularProgress} from '@material-ui/core'
import {useDispatch, useSelector} from 'react-redux'

export default function FindRestaurantsInput() {
  const dispatch = useDispatch()
  const isMinMD = useMediaMinMD()

  const addresses = useSelector(dispatch.user.getAddresses)
  const [updating, setUpdating] = useState(false)
  const [address, setAddress] = useState('')
  const [isAddressSelected, setIsAddressSelected] = useState(false)
  const enterAddressInputRef = useRef(null)
  const [formattedAddress, setFormattedAddress] = useState('')
  const [deliveryAddressLatLng, setDeliveryAddressLatLng] = useState({
    lat: 0,
    lng: 0,
  })
  const addressData = useRef(null)

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(address) => {
        setAddress(address)
        setIsAddressSelected(false)
      }}
      onSelect={(address) => {
        if (address) {
          geocodeByAddress(address).then(async (results) => {
            const {formatted_address, types, address_components} = results[0]

            if (types.includes('route')) {
              alert(
                'Invalid Address, we can only deliver to a specific street address. For example: 1234 Friendly Street, Coquitlam.',
              )
              return
            }
            const addressComponents = {
              street_number: null,
              route: null, // Street Name
              locality: null, // City
              administrative_area_level_1: null, // Province
              country: null,
              postal_code: null,
            }

            for (const component of address_components) {
              for (const type of component.types) {
                if (addressComponents.hasOwnProperty(type)) {
                  addressComponents[type] = component.short_name
                  break
                }
              }
            }
            if (addressComponents.postal_code?.length < 4) {
              alert(
                'Invalid Address, we can only deliver to a specific street address. For example: 1234 Friendly Street, Coquitlam.',
              )
              return
            }

            const name = `${addressComponents.street_number} ${addressComponents.route}`

            addressData.current = {
              ...(addressData.current ?? {}),
              city: addressComponents.locality,
              country: addressComponents.country,
              name,
              postalCode: addressComponents.postal_code,
              region: addressComponents.administrative_area_level_1,
              streetName: addressComponents.route,
              streetNumber: addressComponents.street_number,
            }

            const {lat, lng} = await getLatLng(results[0])

            setAddress(name)
            setIsAddressSelected(true)
            setFormattedAddress(formatted_address)
            setDeliveryAddressLatLng({lat, lng})
          })
        }
      }}
      searchOptions={{
        componentRestrictions: {country: ['ca']},
        types: ['address'],
      }}>
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <>
          <FView row={isMinMD} w={635} maxWidth="100%">
            <FView fill>
              <FView block>
                <FView fill bc={colors.grey50} blr={4} br={!isMinMD && 4} bw={1} bg={colors.white} row>
                  {/* <FButton
                    Button
                    disabled={isLocatingUser}
                    onClick={() => {
                      setIsLocatingUser(true)
                      const prevPlaceholder = enterAddressInputRef.current.placeholder
                      enterAddressInputRef.current.placeholder = 'Loading...'
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          // reverse geocode
                          dispatch.user
                            .reverseGeocode(position.coords)
                            .then((address) => {
                              enterAddressInputRef.current.value = address
                            })
                            .finally(() => {
                              enterAddressInputRef.current.placeholder = prevPlaceholder
                            })
                          setIsLocatingUser(false)
                          dispatch.user.setUserLocation(position.coords)
                          setIsAddressSelected(true)
                        },
                        (error) => {
                          setIsLocatingUser(false)
                          alert('Failed to locate the user')
                          enterAddressInputRef.current.placeholder = prevPlaceholder
                        },
                        {
                          timeout: 20000,
                          enableHighAccuracy: false,
                        },
                      )
                    }}>
                    <MdMyLocation size={24} color={colors.grey} />
                  </FButton> */}
                  {/* <FView w={1} bg={colors.xLightGrey} /> */}
                  <FView fill>
                    <FInput
                      ref={enterAddressInputRef}
                      autoComplete="street-address"
                      placeholder="Enter Your Address"
                      style={{height: 48, background: 'transparent'}}
                      {...getInputProps()}
                      onKeyDown={(e) => {
                        e.keyCode === 13 && e.preventDefault()
                      }}
                    />
                  </FView>
                  {!!address && (
                    <FButton
                      size={48}
                      onClick={() => {
                        setAddress('')
                        setIsAddressSelected(false)
                        setFormattedAddress('')
                        setDeliveryAddressLatLng({lat: 0, lng: 0})
                      }}>
                      <FView size={48} center>
                        <IoIosCloseCircle color={colors.lightGrey} size={30} />
                      </FView>
                    </FButton>
                  )}
                </FView>
              </FView>
              {suggestions.length > 0 && (
                <FView relative fill>
                  <FView
                    absolute
                    zIndex={10}
                    bg={colors.white}
                    left={0}
                    right={0}
                    rounded
                    bc={colors.grey50}
                    bw={1}
                    btw={0}>
                    {loading && (
                      <FView h={'5.3rem'} justifyCenter pl={'6rem'}>
                        <CircularProgress />
                      </FView>
                    )}
                    {suggestions.map((suggestion) => (
                      <div
                        className={classes.placeItem}
                        {...getSuggestionItemProps(suggestion)}
                        key={suggestion.placeId}>
                        <FView size={'5.4rem'} center>
                          <MdPlace color={colors.lightGrey} size={30} />
                        </FView>
                        <FText body1 grey700 bold>
                          {suggestion.description}
                        </FText>
                      </div>
                    ))}
                  </FView>
                </FView>
              )}
            </FView>
            <FView h={15} />
            <FButton
              onClick={() => {
                if (addresses.length > 4) {
                  setAddress('')
                  setIsAddressSelected(false)
                  setFormattedAddress('')
                  setDeliveryAddressLatLng({lat: 0, lng: 0})
                  alert('Please delete existing addresses to add more.')
                  return
                }
                if (updating) {
                  return
                }
                if (isAddressSelected) {
                  setUpdating(true)
                  dispatch.user.setUserLocation({
                    latitude: deliveryAddressLatLng.lat,
                    longitude: deliveryAddressLatLng.lng,
                    city: null,
                    region: null,
                    country: null,
                  })
                  const {streetNumber, streetName, city, region, country, postalCode, name} = addressData.current
                  dispatch.user
                    .addAddress({
                      name,
                      streetNumber,
                      streetName,
                      city,
                      region,
                      country,
                      postalCode,
                      containerDeliveryAddress: formattedAddress,
                      containerDeliveryAddressLatLng: deliveryAddressLatLng,
                      containerDeliveryInstructions: '',
                      containerDeliveryUnit: '',
                      contactlessDeliveryType: '',
                      contactlessDeliveryLocation: '',
                    })
                    .then(async (addressIndex) => {
                      dispatch.user.setSelectedAddressIndex(addressIndex)
                      await dispatch.user.setOrderType({orderType: 'Delivery'})
                    })
                    .catch((e) => {
                      alert(e.message)
                    })
                    .finally(() => {
                      setUpdating(false)
                    })
                } else {
                  alert('Enter an address and select it from the dropdown!')
                }
              }}>
              <ButtonFillView w={isMinMD && 185} brr={4} br={!isMinMD && 4} disabled={!isAddressSelected || updating}>
                <FText body1 bold primaryContrast>
                  Find Restaurants
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </>
      )}
    </PlacesAutocomplete>
  )
}

const classes = {
  placeItem: css({
    height: '5.3rem',
    padding: '1rem',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    paddingHorizontal: '1rem',
    flexDirection: 'row',
  }),
}
