import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router'

import {useTheme} from '@material-ui/core/styles'
import {Fade, Snackbar} from '@material-ui/core'
import {ShoppingCart, AccountBox, Receipt, Map} from '@material-ui/icons'

import {FText, FView, FButton} from 'components'
import {useMediaMinMD} from 'f-web/src/hooks'

export default function NavBarMenuButtonGroup({directOrder}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isUserLoggedIn = useSelector(dispatch.user.getIsUserLoggedIn)
  const isMinMD = useMediaMinMD()
  const [open, setOpen] = useState(false)

  const {restaurantName, restaurantId, locationId} = useParams()

  return (
    <FView order={!isMinMD ? (directOrder ? 0 : -1) : 0} row alignCenter>
      {!directOrder && (
        <FButton to="/map" hoverHighlight alignCenter>
          <Map />
        </FButton>
      )}
      <FButton
        hoverHighlight
        alignCenter
        to={directOrder ? `/direct/orders/${restaurantName}/${restaurantId}/${locationId}` : '/orders'}>
        <Receipt />
      </FButton>
      <FButton
        hoverHighlight
        alignCenter
        onClick={() => {
          if (!dispatch.user.getIsCartEmpty()) {
            navigate(directOrder ? `/direct/checkout/${restaurantName}/${restaurantId}/${locationId}` : `/checkout`)
          } else {
            setOpen(true)
          }
        }}>
        <ShoppingCart fontSize="default" />
      </FButton>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={1000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        TransitionComponent={Fade}>
        <FView bg={theme.palette.primary.main} opacity={0.9} br={4} p={16} w="50vw">
          <FText alignCenter h6 bold color="white">
            Cart is Empty!
          </FText>
        </FView>
      </Snackbar>

      {isUserLoggedIn ? (
        <FButton
          hoverHighlight
          alignCenter
          onClick={() => {
            navigate(directOrder ? `/direct/account/${restaurantName}/${restaurantId}/${locationId}` : '/account')
          }}>
          <AccountBox />
        </FButton>
      ) : (
        <FButton onClick={() => dispatch.settings.setAuthModalType('signin')} hoverHighlight>
          <FView pv={4} ph={8}>
            <FText bold>Sign In</FText>
          </FView>
        </FButton>
      )}
    </FView>
  )
}
