import React from 'react'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'

const FText = React.forwardRef(function FText(props, ref) {
  const classes = useStyles()
  const passedProps = {}
  const classNames = []
  if (props.className) {
    classNames.push(props.className)
  }
  const styles = {}
  const keys = Object.keys(props)
  for (const key of keys) {
    if (key === 'h7' || key === 'h8') {
      console.error(key + ' is currently not supported in FText.')
    }
    if (key === 'inline') {
      passedProps.component = 'span'
    }
    if (props[key] === true && classes[key]) {
      classNames.push(classes[key])
    } else {
      switch (key) {
        case 'className':
          break
        case 'span':
          passedProps.component = 'span'
          break
        case 'w':
          styles.width = props[key]
          break
        case 'h':
          styles.height = props[key]
          break
        case 'color':
        case 'fontSize':
        case 'textDecoration':
        case 'letterSpacing':
        case 'overflow':
        case 'textOverflow':
        case 'lineHeight':
        case 'maxWidth':
          styles[key] = props[key]
          break
        default:
          if (!classes[key]) {
            passedProps[key] = props[key]
          }
          break
      }
    }
  }

  return <Typography ref={ref} className={clsx(classNames)} style={styles} {...passedProps} />
})

const useStyles = makeStyles((theme) => {
  const styles = {
    h1: theme.typography.h1,
    h2: theme.typography.h2,
    h3: theme.typography.h3,
    h4: theme.typography.h4,
    h5: theme.typography.h5,
    h6: theme.typography.h6,
    h7: theme.typography.h7,
    subtitle1: theme.typography.subtitle1,
    subtitle2: theme.typography.subtitle2,
    body1: theme.typography.body1,
    body2: theme.typography.body2,
    button: theme.typography.button,
    caption: theme.typography.caption,
    overline: theme.typography.overline,
    srcOnly: theme.typography.srcOnly, // Only accessible to screen readers
    light: {fontWeight: theme.typography.fontWeightLight},
    regular: {fontWeight: theme.typography.fontWeightRegular},
    medium: {fontWeight: theme.typography.fontWeightMedium},
    semiBold: {fontWeight: 600},
    bold: {fontWeight: theme.typography.fontWeightBold},
    primaryLight: {color: theme.palette.primary.light},
    primary: {color: theme.palette.primary.main},
    primaryDark: {color: theme.palette.primary.dark},
    primaryContrast: {color: theme.palette.primary.contrastText},
    secondaryLight: {color: theme.palette.secondary.light},
    secondary: {color: theme.palette.secondary.main},
    secondaryDark: {color: theme.palette.secondary.dark},
    secondaryContrast: {color: theme.palette.secondary.contrastText},
    errorLight: {color: theme.palette.error.light},
    error: {color: theme.palette.error.main},
    errorDark: {color: theme.palette.error.dark},
    errorContrast: {color: theme.palette.error.contrastText},
    warningLight: {color: theme.palette.warning.light},
    warning: {color: theme.palette.warning.main},
    warningDark: {color: theme.palette.warning.dark},
    warningContrast: {color: theme.palette.warning.contrastText},
    infoLight: {color: theme.palette.info.light},
    info: {color: theme.palette.info.main},
    infoDark: {color: theme.palette.info.dark},
    infoContrast: {color: theme.palette.info.contrastText},
    successLight: {color: theme.palette.success.light},
    success: {color: theme.palette.success.main},
    successDark: {color: theme.palette.success.dark},
    successContrast: {color: theme.palette.success.contrastText},
    grey50: {color: theme.palette.grey['50']},
    grey100: {color: theme.palette.grey['100']},
    grey200: {color: theme.palette.grey['200']},
    grey300: {color: theme.palette.grey['300']},
    grey400: {color: theme.palette.grey['400']},
    grey500: {color: theme.palette.grey['500']},
    grey600: {color: theme.palette.grey['600']},
    grey700: {color: theme.palette.grey['700']},
    grey800: {color: theme.palette.grey['800']},
    grey900: {color: theme.palette.grey['900']},
    greyA100: {color: theme.palette.grey['A100']},
    greyA200: {color: theme.palette.grey['A200']},
    greyA400: {color: theme.palette.grey['A400']},
    greyA700: {color: theme.palette.grey['A700']},
    textPrimary: {color: theme.palette.text.primary},
    textSecondary: {color: theme.palette.text.secondary},
    textDisabled: {color: theme.palette.text.disabled},
    textHint: {color: theme.palette.text.hint},
    alignLeft: {textAlign: 'left'},
    alignCenter: {textAlign: 'center'},
    alignRight: {textAlign: 'right'},
    alignJustify: {textAlign: 'justify'},
    displayBlock: {display: 'block'},
    displayInline: {display: 'inline'},
    block: {display: 'block'},
    inline: {display: 'inline'},
    roboto: {fontFamily: 'roboto'},
    textShadowLight: {textShadow: `0px 0px 2px ${theme.palette.grey['500']}`},
    textShadow: {textShadow: `0px 0px 2px ${theme.palette.grey['700']}`},
    textShadowDark: {textShadow: `0px 0px 2px ${theme.palette.grey['900']}`},
    textShadowPrimaryContrast: {textShadow: `0px 0px 2px ${theme.palette.primary.main.contrastText}`},
    textShadowSecondaryContrast: {textShadow: `0px 0px 2px ${theme.palette.secondary.main.contrastText}`},
    noSelect: {userSelect: 'none'},
    noWrap: {whiteSpace: 'nowrap'},
    noLineHeight: {lineHeight: 0},
    lineHeight1: {lineHeight: 1},
  }

  for (const commonColor in theme.palette.common) {
    styles[commonColor] = {color: theme.palette.common[commonColor]}
  }

  return styles
})

export default FText
