import React from 'react'
import {FText, FButton, ButtonFillView, FDialog} from 'components'
import {DialogTitle, DialogActions, DialogContent} from '@material-ui/core'
import PaymentInformation from 'pages/Payment/PaymentInformation'
import {useDispatch, useSelector} from 'react-redux'

export default function PaymentInfoDialog({open, onClose, hasNext, onNext}) {
  const dispatch = useDispatch()

  const paymentMethod = useSelector(dispatch.user.getPaymentMethod)
  const payments = useSelector(dispatch.user.getPayments)
  const selectedPaymentIndex = useSelector(dispatch.user.getSelectedPaymentIndex)
  const paymentComplete = paymentMethod === 'inperson-cash' || payments[selectedPaymentIndex]
  return (
    <FDialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>
        <FText h4 bold>
          Payment Info
        </FText>
      </DialogTitle>
      <DialogContent>
        <PaymentInformation />
      </DialogContent>
      <DialogActions>
        <FButton fill disabled={!paymentComplete} onClick={!hasNext ? onClose : onNext}>
          <ButtonFillView disabled={!paymentComplete} round primary>
            <FText white bold>
              {!hasNext ? 'Done' : 'Next'}
            </FText>
          </ButtonFillView>
        </FButton>
      </DialogActions>
    </FDialog>
  )
}
