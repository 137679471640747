import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DialogTitle, Divider, DialogContent, DialogActions} from '@material-ui/core'
import {FButton, FText, ButtonFillView} from 'components'
import FDialog from './FDialog'
import InvalidItemsList from './InvalidItemsList'

export default function RemoveInvalidCartItemsModal({open, onClose, locationId}) {
  const dispatch = useDispatch()
  const products = useSelector(() => dispatch.restaurants.getProducts({locationId}))
  const invalidCart = {}
  const cartItems = dispatch.user.getCartItems()
  for (const [itemId, itemData] of Object.entries(cartItems)) {
    if (!itemData.isValid) {
      invalidCart[itemId] = itemData
    }
  }
  return (
    <FDialog open={open} onClose={onClose}>
      <DialogTitle>
        <FText bold h5>
          Invalid Items in Cart
        </FText>
        <FText>The following items are not available at the scheduled order time</FText>
      </DialogTitle>
      <Divider />
      <DialogContent>{invalidCart && <InvalidItemsList invalidCart={invalidCart} products={products} />}</DialogContent>
      <Divider />
      <DialogActions>
        <FButton
          fill
          onClick={() => {
            dispatch.user.removeInvalidCartItems(cartItems)
            onClose()
          }}>
          <ButtonFillView round>
            <FText white bold>
              Remove All Invalid Items
            </FText>
          </ButtonFillView>
        </FButton>
      </DialogActions>
    </FDialog>
  )
}
