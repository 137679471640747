import React, {useRef} from 'react'
import {useDispatch} from 'react-redux'
import {DialogActions, DialogContent, Dialog, Divider} from '@material-ui/core'
import {FText, FView, FButton, ButtonFillView, FTextField} from 'components'
import {useMediaMinSM} from 'f-web/src/hooks'
import {Close} from '@material-ui/icons'

export default function EditNotesDialog({cartItemId, onClose, products, cartItems}) {
  const specialRequestRef = useRef()
  const dispatch = useDispatch()
  const isMinSM = useMediaMinSM()
  const cartProduct = products[cartItems[cartItemId].productId]
  return (
    <Dialog
      transitionDuration={0}
      fullWidth
      maxWidth={'sm'}
      fullScreen={!isMinSM}
      disableScrollLock={isMinSM}
      open={!!cartItemId}
      onClose={onClose}>
      <FView absolute right={4} top={4} zIndex={2}>
        <FButton alignCenter onClick={onClose}>
          <FView p={4} br="50%" bg="rgba(255,255,255, 0.75)">
            <Close />
          </FView>
        </FButton>
      </FView>

      <DialogContent>
        <FView pv={16} ph={24}>
          <FText h5 bold>
            {cartProduct.name}
          </FText>
          <FView h={16} />
          <Divider />
          <FText>Edit Special Request</FText>
        </FView>

        <FView ph={16}>
          <FTextField
            inputRef={specialRequestRef}
            label="Special Requests"
            inputProps={{maxLength: 128}}
            multiline
            defaultValue={cartItems[cartItemId].notes}
          />
        </FView>
      </DialogContent>
      <DialogActions>
        <FButton
          fill
          onClick={() => {
            dispatch.user.editCartItemNote(cartItemId, specialRequestRef.current.value)
            onClose()
          }}>
          <ButtonFillView round>
            <FText white bold>
              Edit
            </FText>
          </ButtonFillView>
        </FButton>
      </DialogActions>
    </Dialog>
  )
}
