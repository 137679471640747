import React from 'react'
import {FView, FText} from 'components'

const WorkWithUsItemView = ({imgSrc, title, description, LearnMoreElement}) => {
  return (
    <FView w={345} maxWidth="100%">
      <img src={imgSrc} style={{width: 345, height: 230, objectFit: 'contain', maxWidth: '100%'}} alt="work with us" />
      <FView size={15} />
      <FText bold h4 gutterBottom>
        {title}
      </FText>
      <FText body1 gutterBottom>
        {description}
      </FText>
      <FView>{LearnMoreElement}</FView>
    </FView>
  )
}

export default WorkWithUsItemView
