import React, {useRef} from 'react'
import {FButton, FView, FText, FInput} from 'components'
import {useTheme} from '@material-ui/core/styles'
import {Search} from '@material-ui/icons'
import {useFoodlyRestaurants} from 'f-app-models/hooks'
import {Popper, ClickAwayListener, Backdrop} from '@material-ui/core'
import {useNavigate} from 'react-router'
import _ from 'lodash'

const LocationsSearch = ({override}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  let {sortedLocationList, restaurantFilterKeyword, setRestaurantFilterKeyword, orderType} = useFoodlyRestaurants()
  if (
    override &&
    ['sortedLocationList', 'restaurantFilterKeyword', 'setRestaurantFilterKeyword'].every((k) => k in override)
  ) {
    sortedLocationList = override.sortedLocationList
    restaurantFilterKeyword = override.restaurantFilterKeyword
    setRestaurantFilterKeyword = override.setRestaurantFilterKeyword
  }
  const anchorEl = useRef(null)

  return (
    <>
      {!override && <Backdrop open={!!restaurantFilterKeyword} style={{zIndex: 429}} />}
      <ClickAwayListener onClickAway={() => !override && setRestaurantFilterKeyword('')}>
        <FView zIndex={505} ref={anchorEl} fill br={8} bg={theme.palette.grey[100]} row alignCenter>
          <FView w={16} />
          <Search fontSize="default" />
          <FView minWidth={100} fill>
            <FInput
              style={{backgroundColor: theme.palette.grey[100]}}
              value={restaurantFilterKeyword ?? ''}
              onChange={(e) => {
                setRestaurantFilterKeyword(e.target.value)
              }}
              placeholder={'What are you craving?'}
            />
          </FView>
          {!override && (
            <Popper
              disablePortal
              anchorEl={anchorEl.current}
              open={!!restaurantFilterKeyword}
              placement={'bottom-start'}>
              <FView bg="white" pl={40} w={anchorEl.current?.clientWidth ?? 500} rounded mt={8}>
                {sortedLocationList.slice(0, 3).map((location) => (
                  <FButton
                    key={location.locationId}
                    onClick={() => {
                      setRestaurantFilterKeyword('')
                      navigate(
                        `/${_.kebabCase(location.restaurantName)}/restaurant/${location.restaurantId}/location/${
                          location.locationId
                        }`,
                      )
                    }}>
                    <FView pl={8} alignStart>
                      <FText alignLeft> {location.restaurantName}</FText>
                    </FView>
                  </FButton>
                ))}

                {!orderType ? (
                  <FButton
                    key={'error'}
                    onClick={() => {
                      setRestaurantFilterKeyword('')
                    }}>
                    <FView pl={8} alignStart>
                      <FText>Chose Pickup Or Delivery First</FText>
                    </FView>
                  </FButton>
                ) : (
                  <FButton
                    key={'error'}
                    onClick={() => {
                      navigate('/all-restaurants')
                    }}>
                    <FView pl={8} alignStart>
                      <FText alignLeft semiBold>
                        View All Restaurants
                      </FText>
                    </FView>
                  </FButton>
                )}
              </FView>
            </Popper>
          )}
        </FView>
      </ClickAwayListener>
    </>
  )
}

export default LocationsSearch
