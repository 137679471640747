async function stripeFetchUrlEncoded({authToken, url, method, formBody}) {
  const response = await fetch(url, {
    method: method || 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formBody,
  })
  const text = await response.text()
  let json
  try {
    json = JSON.parse(text)
  } catch (e) {
    if (response.ok) {
      return text
    } else {
      throw new Error(text)
    }
  }
  if (response.ok) {
    return json
  } else {
    throw new Error(json.error.message)
  }
}

export async function createCardWithStripe(cardInfo, stripeKey) {
  const {
    name,
    number,
    cvc,
    exp_month,
    exp_year,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
  } = cardInfo

  const card = {
    'card[number]': number,
    'card[cvc]': cvc,
    'card[exp_month]': exp_month,
    'card[exp_year]': exp_year,
    'card[address_zip]': address_zip,
  }
  if (name) {
    card['card[name]'] = name
  }
  if (address_line1) {
    card['card[address_line1]'] = address_line1
  }
  if (address_line2) {
    card['card[address_line2]'] = address_line2
  }
  if (address_city) {
    card['card[address_city]'] = address_city
  }
  if (address_state) {
    card['card[address_state]'] = address_state
  }
  if (address_country) {
    card['card[address_country]'] = address_country
  }

  const url = 'https://api.stripe.com/v1/tokens'
  let formBody = []
  for (let property in card) {
    let encodedKey = encodeURIComponent(property)
    let encodedValue = encodeURIComponent(card[property])
    formBody.push(encodedKey + '=' + encodedValue)
  }
  formBody = formBody.join('&')
  const authToken = 'Bearer ' + stripeKey
  return await stripeFetchUrlEncoded({
    authToken,
    url,
    formBody,
  })
}
