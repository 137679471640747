import React from 'react'
import {FText, FDialog} from 'components'
import {DialogTitle, DialogContent} from '@material-ui/core'
import ContactInformation from 'pages/Payment/ContactInformation'

export default function ContactInfoDialog({open, onClose, onNext, hasNext}) {
  return (
    <FDialog open={open} onClose={onClose}>
      <DialogTitle>
        <FText h4 bold>
          Contact Info
        </FText>
      </DialogTitle>
      <DialogContent>
        <ContactInformation onClose={onClose} hasNext={hasNext} onNext={onNext} />
      </DialogContent>
    </FDialog>
  )
}
