import React, {useState, useEffect} from 'react'
import * as utils from 'f-utils'
import {kebabCase} from 'lodash'
import momentTZ from 'moment-timezone'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Divider, Dialog, DialogTitle, DialogActions, DialogContent, makeStyles} from '@material-ui/core'
import {CreditCard, Cancel, Close, AttachMoney, Restaurant} from '@material-ui/icons'
import {FDialog, FView, FText, RewardItemView, OrderItemView, FButton, ButtonFillView} from 'components'
import OrderStatusView from './OrderStatusView'
import OrderItemAddedCharges from './OrderItemAddedCharges'

export default function OrderDetailsItem({orderId, open, handleClose, directOrder}) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const orderDetails = useSelector(() => dispatch.user.getOrderDetails({orderId}))
  const navigate = useNavigate()
  const [deliveryOrderDetails, setDeliveryOrderDetails] = useState({})
  const restaurantId = orderDetails?.restaurantId ?? null
  const orderType = orderDetails?.orderType ?? null

  const [openDiffLocationBox, setOpenDiffLocationBox] = useState(false)
  const [openClearBox, setOpenClearBox] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isClearAdd, setIsClearAdd] = useState(false)

  useEffect(() => {
    if (restaurantId && orderId && orderType === 'Delivery') {
      return dispatch.restaurants.getDeliveryInfoDoc({restaurantId, orderId}).onSnapshot(
        {includeMetadataChanges: true},
        (snapshot) => {
          if (snapshot) {
            if (snapshot && snapshot.exists && !snapshot.metadata.fromCache) {
              setDeliveryOrderDetails(snapshot.data() ?? {})
            }
          }
        },
        (e) => {
          console.warn(e.message)
        },
      )
    }
  }, [restaurantId, dispatch.restaurants, orderId, orderType])

  if (!orderDetails) {
    return null
  }

  const {
    orderNumber,
    status,
    restaurantName,
    restaurantAddress,
    restaurantPhoneNumber,
    createdAt,
    completionTime,
    deliveryTime,
    subTotal,
    taxAmount,
    tipAmount,
    inpersonTipAmount,
    total,
    fPointsEarned,
    fPointsUsed = 0,
    paymentMethod,
    deliveryFee,
    rewards = {},
    tableNumber,
    timezone = 'America/Vancouver',
    isFoodReady,
    addedCharges,
    includeUtensils,
    locationId,
    fPointsDiscount = 0,
  } = orderDetails

  const orderAgain = async () => {
    for (const cartItemValue of Object.values(orderDetails.orderCartItems)) {
      const {count, selectedModifierGroupsWithDetails, productId, notes = ''} = cartItemValue
      const selectedModifiers = {}
      for (const selectedModifierGroupWithDetail of selectedModifierGroupsWithDetails) {
        const groupId = selectedModifierGroupWithDetail.id
        const selectedModifierItemsObj = selectedModifierGroupWithDetail.selectedModifierItemsObj
        selectedModifiers[groupId] = selectedModifierItemsObj
      }
      await dispatch.user.addCartItem({
        restaurantId,
        locationId,
        productId,
        qty: count,
        selectedModifiers,
        notes,
      })
    }
    const directOrdersLink = `/direct/order/${kebabCase(restaurantName)}/${restaurantId}/${locationId}`
    if (directOrder) {
      navigate(directOrdersLink)
    } else {
      navigate(`/${kebabCase(restaurantName)}/restaurant/${restaurantId}/location/${locationId}`)
    }
    setIsAdding(false)
    setIsClearAdd(false)
  }

  return (
    <FDialog open={open} onClose={handleClose} classes={{paper: classes.paper}}>
      <DialogTitle>
        <FText id="auth-modal-title" bold h4>
          Order #{orderNumber}
        </FText>
      </DialogTitle>
      <FView size={15} />
      <DialogContent>
        <FView alignCenter>
          <OrderStatusView
            status={status}
            orderType={orderType}
            deliveryStatus={deliveryOrderDetails.deliveryStatus}
            isFoodReady={isFoodReady}
          />
        </FView>
        <FView size={15} />
        {orderDetails.status === 'Cancelled' && (
          <>
            <FView row alignCenter>
              <FText error>
                <Cancel />
              </FText>
              <FView size={8} />
              <FView fill>
                <FText body1 bold error>
                  This order has been cancelled. Reason: {orderDetails.cancelReason}
                </FText>
              </FView>
            </FView>
            <FView size={15} />
          </>
        )}
        <FText bold h4>
          {restaurantName}
        </FText>
        <FView size={5} />
        <FText body1>{restaurantAddress}</FText>
        <FText body1>{utils.formatPhoneNumber(restaurantPhoneNumber)}</FText>
        <FView size={15} />
        <FView row justifyBetween>
          <FText body1>{momentTZ.tz(createdAt, timezone).calendar(null, {sameElse: 'L LT'})}</FText>
          <FText body1>Order #{orderNumber}</FText>
        </FView>
        <FView size={15} />
        {!(
          (orderType === 'Delivery' && deliveryOrderDetails.deliveryStatus === 'Delivered') ||
          (orderType === 'Pickup' && status === 'Done') ||
          orderType === 'DineIn'
        ) && (
          <>
            <Divider />
            <FView size={10} />
            <FView row justifyBetween>
              <FText bold body1>
                {orderType} Time:
              </FText>
              <FText bold body1 primary>
                {completionTime
                  ? orderType === 'Delivery'
                    ? momentTZ
                        .tz(completionTime, timezone)
                        .add(deliveryTime, 'minutes')
                        .calendar(null, {sameElse: 'L LT'})
                    : momentTZ.tz(completionTime, timezone).calendar(null, {sameElse: 'L LT'})
                  : 'Confirming Order...'}
              </FText>
            </FView>
          </>
        )}
        {orderType === 'DineIn' && (
          <>
            <Divider />
            <FView size={10} />
            <FText bold body1>
              Dine-in Table Number: {tableNumber}
            </FText>
          </>
        )}
        {orderDetails.orderType === 'Delivery' && (
          <>
            <FView size={10} />
            <Divider />
            <FView size={10} />
            <FText bold body1>
              Delivery Address:
            </FText>
            <FView size={4} />
            <FText bold body1 grey700>
              {orderDetails.deliveryAddress}
            </FText>
            {!!orderDetails.deliveryUnit && (
              <>
                <FView size={4} />
                <FText bold body1>
                  Unit:{' '}
                  <FText inline grey700>
                    {orderDetails.deliveryUnit}
                  </FText>
                </FText>
              </>
            )}
            {!!orderDetails.deliveryInstructions && (
              <>
                <FView size={4} />
                <FText bold body1>
                  {'Instructions: '}
                  <FText inline grey700>
                    {orderDetails.deliveryInstructions}
                  </FText>
                </FText>
              </>
            )}
          </>
        )}

        {!!orderDetails.notes && (
          <>
            <FView size={10} />
            <Divider />
            <FView size={10} />
            <FText bold body1>
              Special Requests:
            </FText>
            <FView size={10} />
            <FText bold body1 grey800 overflow={'scroll'}>
              {orderDetails.notes}
            </FText>
          </>
        )}

        <FView size={10} />
        <Divider />
        <FView size={10} />
        <FView>
          {addedCharges && <OrderItemAddedCharges addedCharges={addedCharges} />}
          {Object.keys(orderDetails.orderCartItems)
            .sort()
            .map((cartItemId) => {
              const {name, count, price, selectedModifierGroupsWithDetails} = orderDetails.orderCartItems[cartItemId]
              const refundCount = orderDetails.orderCartItems[cartItemId].refundCount ?? 0
              const priceWithModifiers = utils.getCartItemTotal({
                count: count - refundCount,
                price,
                selectedModifierGroupsWithDetails,
              })

              const refundPriceWithModifiers = utils.getCartItemTotal({
                count: refundCount,
                price,
                selectedModifierGroupsWithDetails,
              })

              return (
                <FView key={cartItemId} fill>
                  {count - refundCount > 0 && (
                    <OrderItemView
                      count={count - refundCount}
                      name={name}
                      priceWithModifiers={priceWithModifiers}
                      selectedModifierGroupsWithDetails={selectedModifierGroupsWithDetails}
                      notes={orderDetails.orderCartItems[cartItemId]?.notes}
                    />
                  )}
                  {refundCount > 0 && (
                    <OrderItemView
                      isRefund={true}
                      count={refundCount}
                      name={name}
                      priceWithModifiers={refundPriceWithModifiers}
                      selectedModifierGroupsWithDetails={selectedModifierGroupsWithDetails}
                      notes={orderDetails.orderCartItems[cartItemId]?.notes}
                    />
                  )}
                </FView>
              )
            })}
          {Object.keys(rewards).map((id) => {
            return <RewardItemView key={id} productId={id} {...rewards[id]} />
          })}
          {orderType !== 'DineIn' && (
            <FView row justifyBetween>
              <FView row alignCenter>
                <Restaurant />
                <FView size={8} />
                <FText bold body2>
                  Utensils
                </FText>
              </FView>
              <FText bold body2>
                {includeUtensils ? 'Include' : 'Do NOT Include'}
              </FText>
            </FView>
          )}
          <FView size={10} />
          <Divider />
          <FView size={15} />
          <FView row justifyBetween>
            <FText bold body1 grey800>
              Subtotal:
            </FText>
            <FText bold body1 grey800>
              ${subTotal.toFixed(2)}
            </FText>
          </FView>
          <FView size={10} />
          {orderType === 'Delivery' && (
            <>
              <FView row justifyBetween>
                <FText bold body1 grey800>
                  Delivery Fee:
                </FText>
                <FText bold body1 grey800>
                  {deliveryFee > 0 ? `$${deliveryFee.toFixed(2)}` : 'FREE'}
                </FText>
              </FView>
              <FView size={10} />
            </>
          )}
          {orderDetails.taxes ? (
            Object.entries(orderDetails.taxes).map(([taxId, taxData]) => {
              return (
                <FView row justifyBetween key={taxId}>
                  <FText bold body1 grey800>
                    {taxData.name}
                  </FText>
                  <FText bold body1 grey800>
                    ${taxData.taxAmount.toFixed(2)}
                  </FText>
                </FView>
              )
            })
          ) : (
            <FView row justifyBetween>
              <FText bold body1 grey800>
                GST (5%):
              </FText>
              <FText bold body1 grey800>
                ${taxAmount.toFixed(2)}
              </FText>
            </FView>
          )}
          {tipAmount > 0 && (
            <>
              <FView size={10} />
              <FView row justifyBetween>
                <FText bold body1 grey800>
                  Online Tip:
                </FText>
                <FText bold body1 grey800>
                  ${tipAmount.toFixed(2)}
                </FText>
              </FView>
            </>
          )}
          {inpersonTipAmount > 0 && (
            <>
              <FView size={10} />
              <FView row justifyBetween>
                <FText bold body1 grey800>
                  Stated Cash Tip:
                </FText>
                <FText bold body1 grey800>
                  ${inpersonTipAmount.toFixed(2)}
                </FText>
              </FView>
            </>
          )}
          {fPointsDiscount > 0 && (
            <FView row justifyBetween>
              <FText bold body1 grey800>
                Discount:
              </FText>
              <FText bold body1 grey800>
                -${fPointsDiscount.toFixed(2)}
              </FText>
            </FView>
          )}
          <FView size={10} />
          <FView selfEnd w={'20rem'} maxWidth={'100%'}>
            <Divider />
          </FView>
          <FView size={10} />
          <FView row justifyBetween>
            <FText bold h6 black>
              Total:
            </FText>
            <FText bold primary h6>
              ${(total + (inpersonTipAmount || 0)).toFixed(2)}
            </FText>
          </FView>
          <FView size={10} />
          <FView selfEnd w={'20rem'} maxWidth={'100%'}>
            <Divider />
          </FView>
          <FView size={10} />
          {(fPointsEarned > 0 || fPointsUsed > 0) && (
            <>
              <FView>
                {fPointsEarned > 0 && (
                  <FView row alignCenter justifyBetween>
                    <FText bold body1 grey500>
                      Earned Rewards:
                    </FText>
                    <FText bold body1 grey500>
                      {fPointsEarned} points
                    </FText>
                  </FView>
                )}
                {fPointsUsed > 0 && (
                  <FView row alignCenter justifyBetween>
                    <FText bold body1 grey500>
                      Redeemed Rewards:
                    </FText>
                    <FText bold body1 grey500>
                      {-fPointsUsed} points
                    </FText>
                  </FView>
                )}
              </FView>
              <FView size={10} />
              <FView selfEnd w={'20rem'} maxWidth={'100%'}>
                <Divider />
              </FView>
              <FView size={10} />
            </>
          )}
          <FView row justifyBetween alignCenter>
            <FText bold body1 grey800>
              Payment:
            </FText>
            <FView row alignCenter>
              <FText grey800 noLineHeight>
                {paymentMethod === 'inperson-cash' ? <AttachMoney /> : <CreditCard />}
              </FText>
              <FView size={5} />
              <FText bold body1 grey800>
                {paymentMethod === 'online-creditcard'
                  ? 'Online Card'
                  : paymentMethod === 'inperson-cash'
                  ? 'Cash'
                  : paymentMethod === 'inperson-creditcard'
                  ? 'In-person Card'
                  : 'Unknown'}
              </FText>
            </FView>
          </FView>
        </FView>
      </DialogContent>
      <DialogActions>
        {status === 'Done' && (
          <>
            <FButton
              fill
              onClick={() => {
                if (isAdding || isClearAdd) {
                  return
                }
                dispatch.restaurants.subscribeLocation({
                  restaurantId,
                  locationId,
                  onLoadComplete: async () => {
                    const isCartEmpty = dispatch.user.getIsCartEmpty()
                    const cartLocationId = dispatch.user.getCartLocationId()
                    if (isCartEmpty) {
                      setIsAdding(true)
                      orderAgain()
                    } else if (cartLocationId !== locationId) {
                      setOpenDiffLocationBox(true)
                    } else {
                      setOpenClearBox(true)
                    }
                  },
                })
              }}>
              <ButtonFillView round disabled={!orderType || isAdding || isClearAdd}>
                <FText bold primaryContrast>
                  {isAdding ? 'Adding...' : isClearAdd ? 'Clearing and Adding...' : 'Order Again'}
                </FText>
              </ButtonFillView>
            </FButton>
            {/* Popup for asking users to clear cart or clear and proceed */}
            <Dialog fullWidth onClose={() => setOpenClearBox(false)} open={openClearBox}>
              <DialogTitle>
                <FView row alignCenter>
                  <FView fill>
                    <FText bold h5 alignCenter>
                      Cart is not empty
                    </FText>
                  </FView>
                  <FButton onClick={() => setOpenClearBox(false)}>
                    <Close />
                  </FButton>
                </FView>
              </DialogTitle>
              <DialogContent>
                <FView mv={15}>
                  <FText body1 grey800>
                    Would you like to clear your cart before proceeding?
                  </FText>
                </FView>
              </DialogContent>
              <Divider />
              <DialogActions>
                <FView fill center row>
                  <FButton
                    onClick={() => {
                      setOpenClearBox(false)
                      setIsAdding(true)
                      orderAgain()
                    }}>
                    <ButtonFillView w={150} rounded>
                      <FText bold primaryContrast>
                        Proceed
                      </FText>
                    </ButtonFillView>
                  </FButton>
                  <FView size={10} />
                  <FButton
                    onClick={async () => {
                      setOpenClearBox(false)
                      setIsClearAdd(true)
                      await dispatch.user.clearCart()
                      orderAgain()
                    }}>
                    <ButtonFillView w={150} rounded>
                      <FText bold primaryContrast>
                        Clear and Proceed
                      </FText>
                    </ButtonFillView>
                  </FButton>
                </FView>
              </DialogActions>
            </Dialog>
            {/* Popup for clearing cart when locationId is different from cartLocationId */}
            <Dialog fullWidth onClose={() => setOpenDiffLocationBox(false)} open={openDiffLocationBox}>
              <DialogTitle>
                <FView row alignCenter>
                  <FView fill>
                    <FText bold h5 alignCenter>
                      Cart is not empty
                    </FText>
                  </FView>
                  <FButton onClick={() => setOpenDiffLocationBox(false)}>
                    <Close />
                  </FButton>
                </FView>
              </DialogTitle>
              <DialogContent>
                <FView mv={15}>
                  <FText body1 grey800>
                    You can only order items from one restaurant at a time
                    <FText />
                    Clear your cart if you'd still like to add items.
                  </FText>
                </FView>
              </DialogContent>
              <Divider />
              <DialogActions>
                <FView fill center row>
                  <FButton
                    onClick={async () => {
                      setOpenDiffLocationBox(false)
                    }}>
                    <ButtonFillView w={150} rounded>
                      <FText bold primaryContrast>
                        Cancel
                      </FText>
                    </ButtonFillView>
                  </FButton>
                  <FView size={10} />
                  <FButton
                    onClick={async () => {
                      setOpenDiffLocationBox(false)
                      setIsClearAdd(true)
                      orderAgain()
                    }}>
                    <ButtonFillView w={150} rounded>
                      <FText bold primaryContrast>
                        Clear Cart and Add
                      </FText>
                    </ButtonFillView>
                  </FButton>
                </FView>
              </DialogActions>
            </Dialog>
          </>
        )}
      </DialogActions>
    </FDialog>
  )
}

const useStyles = makeStyles(() => ({
  paper: {maxWidth: 500},
}))
