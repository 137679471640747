import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {Dialog, DialogContent, makeStyles} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import RemoveIcon from '@material-ui/icons/Remove'
import {FText, FView, FButton, FTextField} from 'components'
import * as utils from 'f-utils'
import {useMediaMinSM} from 'f-web/src/hooks'
import * as images from 'images'
import ModifierGroupView from './ModifierGroupView'
import useQueryDialog from 'components/useQueryDialog'

const ProductDialog = ({locationId, restaurantId}) => {
  const classes = useStyles()
  const theme = useTheme()
  const {dialogOpen, closeDialog} = useQueryDialog()
  const dispatch = useDispatch()
  const isMinSM = useMediaMinSM()
  const orderType = useSelector(dispatch.user.getOrderType)
  const cartItems = useSelector(dispatch.user.getCartItems)
  const firstOpen = useRef(true)
  const {editCartDialog: cartItemId} = useQueryDialog()
  const productId =
    dialogOpen === 'orderType' ||
    dialogOpen === 'deliveryInfo' ||
    dialogOpen === 'contact' ||
    dialogOpen === 'payment' ||
    dialogOpen === 'delivery'
      ? null
      : dialogOpen
  const open = !!productId

  const locationOpen = useSelector(() => {
    let orderOpenDetails = dispatch.restaurants.getOrderOpenDetails({locationId})
    if (orderOpenDetails.isOpen && orderType === 'Delivery') {
      orderOpenDetails = dispatch.restaurants.getDeliveryOrderOpenDetails({locationId})
    }
    return orderOpenDetails.isOpen
  }, utils.isOrderOpenDetailsEqual)
  const productDetails = useSelector(() => dispatch.restaurants.getProductDetails({locationId, productId}))
  const modifierGroups = useSelector(() => dispatch.restaurants.getModifierGroups({locationId}))

  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const [quantity, setQuantity] = useState(1)
  const productNotes = useRef()
  const [selectedModifiers, setSelectedModifiers] = useState({})
  const cartItemTotal = useSelector(() =>
    dispatch.user.getCartItemTotal({locationId, selectedModifiers, price: productDetails.price, count: quantity}),
  )

  const [error, setError] = useState({})
  const modifierGroupRefs = useRef({})

  function handleAddCartItem() {
    setIsAdding(true)
    dispatch.user
      .addCartItem({
        restaurantId,
        locationId,
        productId,
        selectedModifiers,
        qty: quantity,
        notes: productNotes.current.value,
      })
      .then(() => onClose())
      .catch((e) => {
        if (e?.ids?.length > 0) {
          setError(e)
          modifierGroupRefs.current[e.ids[0]].scrollIntoView()
        } else {
          alert('Failed to add. ' + e.message)
        }
      })
      .finally(() => setIsAdding(false))
  }
  function handleEditCartItem() {
    setIsEditing(true)
    dispatch.user
      .editCartItem({
        selectedModifiers,
        qty: quantity,
        cartItemId,
        notes: productNotes.current.value,
      })
      .then(() => onClose())
      .catch((e) => alert('Failed to edit. ' + e.message))
      .finally(() => setIsEditing(false))
  }

  useEffect(() => {
    if (!open || !firstOpen.current) {
      return
    }

    firstOpen.current = false

    setError(null)

    const selectedModifiers = {}

    productDetails.modifierGroups?.forEach((modifierGroupId) => {
      const groupDetails = dispatch.restaurants.getModifierGroupDetails({locationId, modifierGroupId})
      if (groupDetails && groupDetails.modifierItems) {
        Object.entries(groupDetails.modifierItems).forEach(([itemId, modifierItem]) => {
          if (!selectedModifiers[modifierGroupId]) {
            selectedModifiers[modifierGroupId] = {}
          }
          const {activeAfter} = modifierItem
          const inStock = !activeAfter || activeAfter.toDate() < new Date()
          selectedModifiers[modifierGroupId][itemId] = inStock && modifierItem.defaultValue
        })
      }
    })
    if (cartItemId && cartItems[cartItemId]) {
      setQuantity(cartItems[cartItemId].count)
      Object.entries(cartItems[cartItemId].selectedModifiers).forEach(([modifierGroupId, modifierItems]) => {
        Object.entries(modifierItems).forEach(([modifierItemId, modifierValue]) => {
          if (!!selectedModifiers[modifierGroupId]) {
            selectedModifiers[modifierGroupId][modifierItemId] = modifierValue
          }
        })
      })
    }
    setSelectedModifiers(selectedModifiers)
  }, [
    cartItemId,
    cartItems,
    dispatch.restaurants,
    locationId,
    open,
    productDetails,
    productDetails.modifierGroups,
    productId,
  ])

  const onClose = () => {
    if (!isAdding && !isEditing) {
      setQuantity(1)
      closeDialog()
    }
    firstOpen.current = true
  }

  return (
    <Dialog
      transitionDuration={0}
      fullWidth
      classes={{paper: classes.paper}}
      fullScreen={!isMinSM}
      disableScrollLock={isMinSM}
      open={open}
      // style={styles.dialog}
      onClose={onClose}>
      <FView absolute right={4} top={4} zIndex={2}>
        <FButton alignCenter onClick={onClose}>
          <FView p={4} br="50%" bg="rgba(255,255,255, 0.75)">
            <CloseIcon />
          </FView>
        </FButton>
      </FView>
      {productDetails.imageUrl ? (
        <img src={productDetails.imageUrl} style={styles.image} alt="product" />
      ) : (
        <>
          <FView h={32} />
          <img src={images.addProductModalBG} style={styles.defaultImage} alt="defaultProduct" />
        </>
      )}
      <DialogContent style={styles.dialogContent}>
        <FView pv={16} ph={24}>
          <FText h4 bold>
            {productDetails.name}
          </FText>
          <FView h={16} />
          <FText grey600>{productDetails.description}</FText>
        </FView>

        {productDetails?.modifierGroups?.map((modifierGroupId) => {
          const modifierGroupDetails = modifierGroups[modifierGroupId] ?? {}
          const {title, isRequired, maxSelection, modifierItems} = modifierGroupDetails
          if (!modifierItems) {
            return null
          }
          return (
            <FView mb={8} key={modifierGroupId} ref={(r) => (modifierGroupRefs.current[modifierGroupId] = r)}>
              <FView h={4} bg={theme.palette.grey[100]} />
              <ModifierGroupView
                isRequired={isRequired}
                maxSelection={maxSelection}
                modifierGroupDetails={modifierGroupDetails}
                modifierGroupId={modifierGroupId}
                modifierItems={modifierItems}
                selectedModifiers={selectedModifiers}
                setSelectedModifiers={setSelectedModifiers}
                title={title}
                error={error?.ids?.includes(modifierGroupId) ? error : null}
                onClickModifier={
                  error?.ids?.includes(modifierGroupId)
                    ? () => {
                        error.ids = error.ids.filter((id) => id !== modifierGroupId)
                        setError({...error, message: error.message})
                      }
                    : () => {}
                }
              />
            </FView>
          )
        })}
        <FView ph={16}>
          <FTextField
            inputRef={productNotes}
            label="Special Requests"
            inputProps={{maxLength: 128}}
            defaultValue={!!cartItemId && cartItems && cartItems[cartItemId] ? cartItems[cartItemId].notes : ''}
            multiline
          />
        </FView>
      </DialogContent>
      <FView flexDirection={isMinSM ? 'row' : 'column'} pv={16} ph={24} alignCenter>
        <FView br={8} bc={theme.palette.grey[300]} bw={0.7} row alignCenter>
          <FButton disabled={quantity <= 1} onClick={() => setQuantity(quantity - 1)} alignCenter>
            <RemoveIcon style={{color: quantity <= 1 ? theme.palette.grey[400] : theme.palette.primary.main}} />
          </FButton>
          <FView mh={16}>
            <FText grey600 bold>
              {quantity}
            </FText>
          </FView>
          <FButton onClick={() => setQuantity(quantity + 1)} alignCenter>
            <AddIcon color="primary" />
          </FButton>
        </FView>
        <FView w={16} h={16} />
        <FView fill selfStretch>
          <FButton
            disabled={isAdding || isEditing || !locationOpen}
            onClick={() => {
              if (!!cartItemId) {
                handleEditCartItem()
              } else {
                handleAddCartItem()
              }
            }}>
            <FView
              bg={isAdding || !locationOpen ? theme.palette.grey[400] : theme.palette.primary.main}
              br={8}
              ph={24}
              pv={isMinSM ? 9 : 20}>
              <FText white bold>
                {locationOpen
                  ? !!cartItemId
                    ? isEditing
                      ? 'Updating...'
                      : `Update Item - $${cartItemTotal?.toFixed(2)}`
                    : isAdding
                    ? 'Adding...'
                    : `Add to Order - $${cartItemTotal?.toFixed(2)}`
                  : 'Ordering Closed'}
              </FText>
            </FView>
          </FButton>
        </FView>
      </FView>
    </Dialog>
  )
}

const styles = {
  dialog: {
    width: 500,
  },
  image: {
    objectFit: 'cover',
    height: 272,
    maxHeight: '30vh',
  },
  dialogContent: {
    padding: 0,
    margin: 0,
  },
  defaultImage: {
    objectFit: 'contain',
    height: 272,
    maxHeight: '30vh',
  },
}

const useStyles = makeStyles(() => ({
  paper: {maxWidth: '600px'},
}))

export default React.memo(ProductDialog)
