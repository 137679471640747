import React from 'react'
import {FButton, FText, FView} from 'components'
import {Add, Remove, NoteOutlined} from '@material-ui/icons'
import {useTheme} from '@material-ui/core/styles'
import * as utils from 'f-utils'
import {useDispatch} from 'react-redux'
import SelectedModifierItems from 'pages/Location/componentsV2/SelectedModifierItems'
import {isEmpty} from 'lodash'

export default function CartItems({
  cartItems,
  promoItems,
  products,
  modifierGroups,
  restaurantId,
  locationId,
  setProductDialogId,
}) {
  const theme = useTheme()
  const dispatch = useDispatch()

  const appliedPromoItemsData = []
  Object.entries(promoItems ?? {})
    .sort((a, b) => {
      if (a[1].valid === b[1].valid) {
        return a[0] > b[0] ? 1 : -1
      } else {
        return a[1].valid ? -1 : 1
      }
    })
    .forEach(([promoId, promo]) => {
      if (
        (!promo.valid && !promo.appliedDefault && promo.version > 1) ||
        (promo.valid && promo.redeemType !== 'ProductDiscount' && promo.redeemType !== 'ProductDiscountPercent')
      ) {
        appliedPromoItemsData.push({
          promoId,
          name: promo.name,
          valid: promo.valid,
          price: promo.valid
            ? !promo.totalDiscountSubtotal
              ? 'FREE'
              : `-$${promo.totalDiscountSubtotal.toFixed(2)}`
            : null,
          message: promo.message,
        })
      }
    })
  return (
    <>
      {Object.entries(cartItems)
        .sort((firstId, secondId) => {
          return firstId[1].createdAt < secondId[1].createdAt ? -1 : 1
        })
        .map(([cartItemId, cartItem]) => {
          const productDetails = products[cartItem.productId]
          const selectedModifierGroupsWithDetails = utils.getSelectedModifierGroupsWithDetails({
            selectedModifiers: cartItem.selectedModifiers,
            modifierGroups,
          })

          let discount = 0
          let applies = 0
          let isDiscounted = false
          Object.values(promoItems ?? {}).forEach((rewardItem) => {
            if (!rewardItem.productIds[cartItem.productId] || rewardItem.discountType === 'free' || !rewardItem.valid) {
              return
            }
            isDiscounted = true
            discount += rewardItem.cartItemIdToDiscount[cartItemId] ?? 0

            applies = Math.max(rewardItem.applyTo[cartItemId] ?? 0, applies)
          })

          return (
            <FView key={cartItemId} pb={8}>
              <FView row justifyBetween alignStart>
                <FView rounded bc={theme.palette.grey[300]} bw={1} row alignCenter zIndex={777}>
                  <FButton onClick={() => dispatch.user.subtractCartItem({cartItemId})} alignCenter>
                    <Remove color="primary" fontSize={'small'} />
                  </FButton>
                  <FView mh={4}>
                    <FText grey800 bold body2>
                      {cartItem.count}
                    </FText>
                  </FView>
                  <FButton
                    onClick={() => {
                      dispatch.user
                        .addCartItem({
                          restaurantId,
                          locationId,
                          productId: cartItem.productId,
                          qty: 1,
                          selectedModifiers: cartItem.selectedModifiers,
                          notes: cartItems[cartItemId]?.notes,
                        })
                        .catch((e) => alert(e.message))
                    }}
                    alignCenter>
                    <Add color="primary" fontSize={'small'} />
                  </FButton>
                </FView>
                <FView w={16} />
                <FButton
                  fill
                  hoverHighlight
                  onClick={() => {
                    setProductDialogId(cartItems[cartItemId].productId, cartItemId)
                  }}>
                  <FView row alignStart justifyBetween>
                    <FView>
                      <FText alignLeft semiBold body1 color={cartItem.isValid ? '' : theme.palette.error.main}>
                        {productDetails.name}
                      </FText>
                      <CartItemInfo
                        product={cartItem}
                        productDetails={productDetails}
                        selectedModifierGroupsWithDetails={selectedModifierGroupsWithDetails}
                        cartItemId={cartItemId}
                      />
                    </FView>
                    <PriceItem
                      product={cartItem}
                      discount={discount}
                      productDetails={productDetails}
                      selectedModifierGroupsWithDetails={selectedModifierGroupsWithDetails}
                      isDiscounted={isDiscounted}
                      count={cartItem.count}
                    />
                  </FView>
                </FButton>
              </FView>
            </FView>
          )
        })}
      {!isEmpty(appliedPromoItemsData) && (
        <FView mt={16}>
          <FText bold>Applied Rewards</FText>
        </FView>
      )}
      {appliedPromoItemsData.map((data) => {
        return (
          <FView key={data.promoId} pl={16} pt={8}>
            <FView row alignStart justifyBetween>
              <FView row>
                <FText semiBold color={data.valid ? 'black' : 'grey'}>
                  {data.name}
                </FText>
              </FView>
              <FView>
                {data.valid && (
                  <FText semiBold color={data.valid ? 'black' : 'grey'}>
                    {data.price}
                  </FText>
                )}
              </FView>
            </FView>
            <FText color={data.valid ? 'black' : 'grey'}>{data.message}</FText>
          </FView>
        )
      })}
    </>
  )
}

function CartItemInfo({product, selectedModifierGroupsWithDetails, cartItemId}) {
  const theme = useTheme()
  return (
    <FView maxWidth={220} pr={16}>
      <FView pl={8} pt={4}>
        <SelectedModifierItems
          selectedModifierGroupsWithDetails={selectedModifierGroupsWithDetails}
          product={product}
          cartItemId={cartItemId}
        />
      </FView>
      {!!product.notes && (
        <FView w={240} row alignCenter>
          <NoteOutlined color="primary" fontSize={'small'} />
          <FView w={4} />
          <FText inline color={theme.palette.common.black} body2>
            {product.notes}
          </FText>
        </FView>
      )}
    </FView>
  )
}

function PriceItem({product, discount, productDetails, selectedModifierGroupsWithDetails, isDiscounted, count}) {
  const theme = useTheme()
  const priceWithModifiers = utils.getCartItemTotal({
    price: productDetails.price,
    count: product.count,
    selectedModifierGroupsWithDetails,
  })
  return (
    <FView>
      {product.isValid ? (
        discount ? (
          isDiscounted ? (
            <FView fill alignEnd>
              <FView relative>
                <FView absolute h={2} w={'100%'} top={'50%'} zIndex={3} bg="black" />
                <FText semiBold color={theme.palette.error.main}>
                  $
                  {utils
                    .getCartItemTotal({
                      price: productDetails.price,
                      count: count,
                      selectedModifierGroupsWithDetails,
                    })
                    ?.toFixed(2)}
                </FText>
              </FView>
              <FText semiBold>
                $
                {Math.max(
                  utils.getCartItemTotal({
                    price: productDetails.price,
                    count: count,
                    selectedModifierGroupsWithDetails,
                  }) - discount,
                  0,
                )?.toFixed(2)}
              </FText>
            </FView>
          ) : (
            <FText semiBold>
              {utils
                .getCartItemTotal({
                  price: productDetails.price,
                  count: count,
                  selectedModifierGroupsWithDetails,
                })
                ?.toFixed(2)}
            </FText>
          )
        ) : (
          <FText semiBold>${priceWithModifiers?.toFixed(2)}</FText>
        )
      ) : (
        <FText bold error>
          Invalid
        </FText>
      )}
    </FView>
  )
}
