import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '@material-ui/core/styles'
import {DialogActions, DialogTitle, DialogContent} from '@material-ui/core'
import {FText, FView, FButton, ButtonFillView, FDialog} from 'components'
import * as images from 'images'
import AddressPicker from 'components/AddressPicker'
import useQueryDialog from 'components/useQueryDialog'
import {useLocation} from 'react-router-dom'

export default function OrderTypeDialog({locationId}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const orderType = useSelector(dispatch.user.getOrderType)
  const hasAddress = !!useSelector(dispatch.user.getAddressData)
  const isDeliveryAvailable = useSelector(
    () => !locationId || dispatch.restaurants.getIsDeliveryAvailable({locationId}),
  )
  const isPickupAvailable = useSelector(() => !locationId || dispatch.restaurants.getIsPickupAvailable({locationId}))

  const {dialogOpen, openDialog, closeDialog} = useQueryDialog()
  const {pathname} = useLocation()

  useEffect(() => {
    if (pathname.includes('checkout')) {
      if (
        !orderType ||
        (orderType === 'Pickup' && !isPickupAvailable) ||
        (orderType === 'Delivery' && !isDeliveryAvailable)
      ) {
        openDialog('orderType')
      } else {
        closeDialog()
      }
    } else if (!orderType || (orderType === 'Delivery' && !hasAddress)) {
      openDialog('orderType')
    }
  }, [closeDialog, hasAddress, isDeliveryAvailable, isPickupAvailable, openDialog, orderType, pathname])

  return (
    <>
      <FDialog
        cancelable={false}
        open={dialogOpen === 'orderType'}
        onClose={() => {}}
        onEnter={() => {}}
        onExit={() => {}}>
        <DialogTitle>
          <FText h4 bold>
            Pick Your Order Type
          </FText>
        </DialogTitle>
        <FView overflowY="auto">
          <FView row alignCenter>
            <FView mh={16}>
              <FButton
                disabled={!isDeliveryAvailable}
                onClick={() => dispatch.user.setOrderType({orderType: 'Delivery'})}>
                <FView opacity={isDeliveryAvailable ? 1 : 0.25} center>
                  <FView
                    size={'100%'}
                    br={'50%'}
                    bc={orderType === 'Delivery' ? theme.palette.primary.main : 'black'}
                    bw={4}
                    center
                    p={32}>
                    <img
                      src={orderType === 'Delivery' ? images.colouredScooter : images.scooter}
                      alt="delivery"
                      style={{objectFit: 'contain', width: '100%'}}
                    />
                  </FView>
                  <FView h={16} />

                  <FText bold color={orderType === 'Delivery' ? theme.palette.primary.main : 'black'}>
                    {'DELIVERY' + (!isDeliveryAvailable ? ' NOT AVAILABLE' : '')}
                  </FText>

                  <FView h={16} />

                  {orderType === 'Delivery' ? (
                    <img
                      src={images.slideBump}
                      alt="right"
                      style={{padding: 0, marginBottom: -4, height: 16, draggable: false}}
                    />
                  ) : (
                    <FView h={12} />
                  )}
                </FView>
              </FButton>
            </FView>

            <FView mr={16}>
              <FButton disabled={!isPickupAvailable} onClick={() => dispatch.user.setOrderType({orderType: 'Pickup'})}>
                <FView opacity={isPickupAvailable ? 1 : 0.25} center>
                  <FView
                    size={'100%'}
                    br={'50%'}
                    bc={orderType === 'Pickup' ? theme.palette.primary.main : 'black'}
                    bw={4}
                    center
                    p={32}>
                    <img
                      src={orderType === 'Pickup' ? images.colouredPedestrian : images.pedestrian}
                      alt="pickup"
                      style={{objectFit: 'contain', width: '100%'}}
                    />
                  </FView>
                  <FView h={16} />
                  <FText bold color={orderType === 'Pickup' ? theme.palette.primary.main : 'black'}>
                    {'PICKUP' + (!isPickupAvailable ? ' NOT AVAILABLE' : '')}
                  </FText>
                  <FView h={16} />

                  {orderType === 'Pickup' ? (
                    <img
                      src={images.slideBump}
                      draggable={false}
                      alt="right"
                      style={{padding: 0, marginBottom: -4, height: 16}}
                    />
                  ) : (
                    <FView h={12} />
                  )}
                </FView>
              </FButton>
            </FView>
          </FView>
          <FView h={4} bg={theme.palette.primary.main} />

          <FView pt={16}>
            {orderType === 'Delivery' && (
              <DialogContent>
                <AddressPicker />
              </DialogContent>
            )}

            {hasAddress || orderType !== 'Delivery' ? (
              <DialogActions>
                <FButton
                  fill
                  onClick={() => {
                    closeDialog()
                  }}>
                  <ButtonFillView round>
                    <FText bold white>
                      Confirm
                    </FText>
                  </ButtonFillView>
                </FButton>
              </DialogActions>
            ) : (
              <FView h={32} />
            )}
          </FView>
        </FView>
      </FDialog>
    </>
  )
}
