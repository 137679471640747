import React from 'react'
import {useDispatch} from 'react-redux'
import {colors} from 'styles'
import {FButton, FText, FView} from 'components'
import {Check, Payment, Delete} from '@material-ui/icons'
import {Collapse, useTheme} from '@material-ui/core'

export default function PaymentItem({index, last4, expdate, onPress, expandedIndex, setExpandedIndex, isSelected}) {
  const dispatch = useDispatch()

  const theme = useTheme()
  return (
    <>
      <FButton
        onClick={() => {
          onPress()
          setExpandedIndex(expandedIndex === index ? null : index)
        }}>
        <FView row alignCenter justifyBetween p={15}>
          <FView row alignCenter>
            <Payment style={{color: isSelected ? colors.primary : colors.grey700}} />
            <FView w={10} />
            <FView>
              <FText body1 primary={isSelected}>
                ****{last4}
              </FText>
            </FView>
          </FView>
          <FView row>
            <FText caption primary={isSelected}>
              exp
            </FText>
            <FView w={5} />
            <FText caption primary={isSelected}>{`${expdate.toString().slice(2, 4)}/${expdate
              .toString()
              .slice(0, 2)}`}</FText>
          </FView>
          <FView row justifyRight alignCenter size={24} mr={16}>
            {isSelected && <Check color="primary" />}
          </FView>
        </FView>
      </FButton>
      <Collapse in={expandedIndex === index}>
        <FView pl={15}>
          <FView justifyCenter>
            <FButton
              onClick={() => {
                dispatch.user.setSelectedPaymentIndex(index)
                setExpandedIndex(null)
              }}>
              <FView row>
                <Check color="primary" />
                <FView w={10} />
                <FText color={theme.palette.primary.main}>Set as default payment method</FText>
              </FView>
            </FButton>
            <FButton
              onClick={() => {
                dispatch.user.deleteCreditCardAtIndex(index)
                setExpandedIndex(null)
              }}>
              <FView row>
                <Delete style={{color: colors.red}} />
                <FView w={10} />
                <FText color={colors.red}>Remove payment method</FText>
              </FView>
            </FButton>
          </FView>
        </FView>
      </Collapse>
    </>
  )
}
