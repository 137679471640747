import axios from 'axios'

const getReverseGeocodeUrl = ({latitude, longitude}) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCxp65jbkg8YA6RjLzgEUwaF3fAflTdNyg`
}

export function reverseGeocode({latitude, longitude}) {
  const url = getReverseGeocodeUrl({latitude, longitude})
  return axios({
    url,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

export function locationByIP() {
  const url = 'https://ipapi.co/json'
  return axios({
    url,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}
