import React from 'react'
import {FView, FText, FButton} from 'components'
import * as images from 'images'
import {useMediaMinMD} from 'f-web/src/hooks'
import FooterItemView from './FooterItemView'

export default function Footer() {
  const isMinMD = useMediaMinMD()
  return (
    <>
      <FView selfCenter={isMinMD} p={10} w={1440} maxWidth={'90vw'} row={isMinMD} justifyBetween>
        <FView alignStart={!isMinMD}>
          <FButton to="/">
            <img src={images.foodlyLogo} style={{objectFit: 'contain', height: 50}} alt="foodly logo" />
          </FButton>
        </FView>
        <FView w={175} alignStart={isMinMD}>
          <FButton to="/about-us">
            <FooterItemView label="About us" />
          </FButton>
          <FButton to="/privacy">
            <FooterItemView label="Privacy Policy" />
          </FButton>
          <FButton to="/terms">
            <FooterItemView label="Terms of Use" />
          </FButton>
        </FView>
        <FView w={175} alignStart={isMinMD}>
          <FButton to="/restaurant-owners">
            <FooterItemView label="Restaurant Owners" />
          </FButton>
          <FButton to="/deliverWithUs">
            <FooterItemView label="Deliver With Us" />
          </FButton>
          <FView row>
            <FButton IconButton href="https://www.instagram.com/foodly.ca/" target="_blank">
              <img src={images.igIcon} alt="foodly instagram" />
            </FButton>
            <FButton IconButton href="https://www.facebook.com/foodlytech/" target="_blank">
              <img src={images.fbIcon} alt="foodly facebook" />
            </FButton>
          </FView>
        </FView>
        {/* <FView w={175} alignStart={isMinMD}>
          <FButton>
            <FooterItemView label="Contact Us" />
          </FButton>
        </FView> */}
      </FView>
      <FView size={45} />
      <FView selfCenter>
        <FText body2>© 2021 Foodly Technologies Inc.</FText>
      </FView>
      <FView size={25} />
    </>
  )
}
