import React, {useEffect} from 'react'
import {css} from 'emotion'
import {FText, FView, FButton, ButtonFillView, NavBarV2, Footer} from 'components'
import {useMediaMinMD, useMediaMinLG} from 'f-web/src/hooks'
import * as images from 'images'
import {useTheme} from '@material-ui/core/styles'
import {Divider} from '@material-ui/core'
import HubspotForm from 'react-hubspot-form'

export default function RestaurantOwners() {
  const theme = useTheme()
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <FView alignCenter>
      <NavBarV2 />
      <FView center h={isMinMD ? 'calc(100vh - 80px)' : 250}>
        <img
          src={images.ownerHeroBG}
          style={{position: 'fixed', width: '100vw', objectFit: 'cover', zIndex: -1}}
          alt={'ownerHeroBG'}
        />
        <FView row justifyCenter>
          <FView column center w={isMinMD ? '40%' : '80%'}>
            <FView>
              <FText bold h3 gutterBottom alignCenter={!isMinMD}>
                Interested in becoming a Partner Restaurant?
              </FText>
              <FText h6 alignCenter={!isMinMD}>
                Foodly is known to support its local restaurants for its competitive pricing and zero onboarding fees!
              </FText>
              <FView size={8} />
              <FText h6 alignCenter={!isMinMD}>
                Sign up with us today!
              </FText>
              <FView h={isMinMD ? 100 : 0} />
            </FView>
          </FView>
          {isMinMD && <SignUpFormView />}
        </FView>
      </FView>
      <FView zIndex={1} bg={theme.palette.common.white}>
        {!isMinMD && <SignUpFormView />}
        <FView mv={50} column center alignCenter>
          <FText bold h3>
            Why Foodly?
          </FText>
          <FView size={25} />
          <FView column row={isMinLG}>
            <FView column alignCenter w={325}>
              <img src={images.whyFoodly1} alt="increase your sales" className={styles.imageItems} />
              <FText bold h5>
                Increase your sales
              </FText>
              <FText body1 alignCenter>
                New customers will find your restaurant through our platform, which results in an increase in sales.
              </FText>
            </FView>
            <FView size={25} />
            <FView column alignCenter w={325}>
              <img src={images.whyFoodly2} alt="increase your sales" className={styles.imageItems} />
              <FText bold h5>
                Save Time
              </FText>
              <FText body1 alignCenter>
                Avoid wastes of time by automating taking orders, so you can focus on making good food.{' '}
              </FText>
            </FView>
            <FView size={25} />
            <FView column alignCenter w={325}>
              <img src={images.whyFoodly3} alt="increase your sales" className={styles.imageItems} />
              <FText bold h5>
                In-person Experience
              </FText>
              <FText body1 alignCenter>
                Since customers pick up their orders at your restaurant, you always have control on their experience.{' '}
              </FText>
            </FView>
          </FView>
        </FView>
        <FView bg={theme.palette.primary.main} pv={isMinMD ? 50 : 25} alignCenter>
          <FView row={isMinMD} maxWidth={1000} alignCenter ph={24}>
            <FView>
              <FText h4 bold primaryContrast gutterBottom alignCenter={!isMinMD}>
                Foodly let your customers order ahead of time.
              </FText>
              <FText h6 primaryContrast alignCenter={!isMinMD}>
                Once you sign up to become a partner, we provide everything so you’re ready to take orders on day one.
                You will get notified whenever an order comes in, and your customers will be notified once their order
                is ready for pick up.
              </FText>
            </FView>
            <FView size={25} />
            <img src={images.restaurantReception} alt="restaurant reception" />
          </FView>
        </FView>
        <FView mv={50} column center alignCenter>
          <FText bold h4 h3={isMinMD}>
            How It Works
          </FText>
          <FView size={25} />
          <FView column row={isMinLG}>
            <FView column alignCenter w={325}>
              <img src={images.howItWorks1} alt="receive orders" className={styles.imageItems} />
              <FText bold h5>
                Receive Orders
              </FText>
              <FText body1 alignCenter>
                Your restaurant gets notified through a tablet provided by us whenever a customer places an order.{' '}
              </FText>
            </FView>
            <FView size={25} />
            <FView column alignCenter w={325}>
              <img src={images.howItWorks2} alt="confirm orders" className={styles.imageItems} />
              <FText bold h5>
                Confirm Orders
              </FText>
              <FText body1 alignCenter>
                You can accept new orders and adjust their estimated completion time so customers know when to pick them
                up.
              </FText>
            </FView>
            <FView size={25} />
            <FView column alignCenter w={325}>
              <img src={images.howItWorks3} alt="increase your sales" className={styles.imageItems} />
              <FText bold h5>
                Prepare Orders
              </FText>
              <FText body1 alignCenter>
                Customers get notified when their order is ready to be picked up at your restaurant.{' '}
              </FText>
            </FView>
          </FView>
        </FView>

        <Divider />

        <FView
          mv={isMinMD ? 25 : 0}
          mh={isMinMD ? 0 : 13}
          column
          center
          alignCenter
          selfCenter
          className={styles.section}>
          <FText bold h3>
            Pricing
          </FText>
          <FView row={isMinLG} mt={isMinMD ? 50 : 25} p={13}>
            <img
              src={images.pricingCityScape}
              alt="limited offer"
              style={{objectFit: 'contain', width: 578, maxWidth: '100%'}}
            />
            <FView size={25} />
            <FView selfEnd={isMinMD} alignCenter>
              <img
                src={images.specialOfferLetters}
                alt="special offer"
                style={{objectFit: 'contain', width: 396, maxWidth: '100%'}}
              />
              <FText bold h1>
                15%
              </FText>
              <FText h6 alignCenter gutterBottom>
                Commision Rate*
              </FText>
              <FText body1 alignCenter>
                *Includes online payment processing fees
              </FText>
            </FView>
          </FView>
        </FView>
        <FView size={isMinMD ? 50 : 25} />
        <Divider />
        <FView size={isMinMD ? 50 : 25} />
        <FView>
          <FView column center alignCenter selfCenter className={styles.section} pb={isMinMD ? 50 : 25} w={'100vw'}>
            <FText bold h4>
              Our Partners:
            </FText>
            <FView w={'100vw'} style={{overflowX: 'auto'}}>
              <FView style={{flexWrap: 'nowrap'}}>
                <img
                  src={images.partners}
                  alt="our partners"
                  className={styles.imageItems}
                  style={{marginTop: 25, minWidth: isMinMD ? '100%' : '200%'}}
                />
              </FView>
            </FView>
          </FView>
          <Divider />

          <FView column center alignCenter selfCenter className={styles.section} pt={isMinMD ? 50 : 25}>
            <FText bold h3>
              Interested?
            </FText>
            <FView alignCenter mt={25}>
              <FButton
                onClick={() => {
                  window.scrollTo(0, 0)
                }}>
                <ButtonFillView w={250} rounded>
                  <FText bold body1 primaryContrast>
                    Apply Now
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
        </FView>
        <FView size={isMinMD ? 50 : 25} />
        <Divider />
        <FView size={isMinMD ? 50 : 25} />

        <Footer />
      </FView>
    </FView>
  )
}

function SignUpFormView() {
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  return (
    <FView
      rounded
      bg={'rgba(255,255,255,0.75)'}
      w={isMinMD ? '38%' : '100%'}
      pt={25}
      ph={25}
      maxWidth={450}
      ml={isMinMD ? 25 : 15}
      mt={isMinMD ? 50 : 25}
      mr={isMinMD ? 0 : 15}
      relative
      selfCenter={!isMinLG}>
      <HubspotForm
        region="na1"
        portalId="9318101"
        formId="b605fc19-0f12-4a9e-910f-7219b8706351"
        // onSubmit={() => console.log('Submit!')}
        // onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading Form...</div>}
      />
    </FView>
  )
}

const styles = {
  section: css({
    paddingTop: 25,
  }),
  imageItems: css({
    objectFit: 'contain',
    marginTop: 15,
    marginBottom: 25,
  }),
}
