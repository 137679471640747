import React, {useState, useEffect, useRef} from 'react'
import * as utils from 'f-utils'
import {useSelector, useDispatch} from 'react-redux'
import {colors} from 'styles'
import {Collapse, Divider} from '@material-ui/core'
import {CheckBox, CheckBoxOutlineBlank, InfoOutlined, CheckCircle} from '@material-ui/icons'
import {FView, FText, FButton, ButtonFillView, FInput} from 'components'
import {useTheme} from '@material-ui/core/styles'
import {auth, PhoneAuthProvider} from 'f-core/src/config/firebase'
import firebase from 'firebase/app'
import {trim} from 'lodash'

const window = {
  recaptchaVerifier: undefined,
  confirmationResult: {},
}

let currVerifyingPhoneNumber = ''

export default function ContactInformation({onClose, onNext, hasNext}) {
  const dispatch = useDispatch()
  const theme = useTheme()

  const cartLocationId = useSelector(dispatch.user.getCartLocationId)
  const orderType = useSelector(dispatch.user.getOrderType)
  const currentOrderOpenDetails = useSelector(() => {
    let orderOpenDetails = dispatch.restaurants.getOrderOpenDetails({locationId: cartLocationId})
    if (orderOpenDetails.isOpen && orderType === 'Delivery') {
      orderOpenDetails = dispatch.restaurants.getDeliveryOrderOpenDetails({locationId: cartLocationId})
    }
    return orderOpenDetails
  }, utils.isOrderOpenDetailsEqual)

  const isLoggedIn = useSelector(dispatch.user.getIsUserLoggedIn)
  const createAccountChecked = useSelector(dispatch.user.getCreateAccountChecked)

  const inputNameRef = useRef(null)
  const inputPhoneRef = useRef(null)
  const inputEmailRef = useRef(null)
  const inputPasswordRef = useRef(null)

  const [nameInput, setNameInput] = useState('')
  const [phoneNumberInput, setPhoneNumberInput] = useState('')
  const [password, setPassword] = useState('')
  const email = useSelector(dispatch.user.getEmail)
  const initialName = useSelector(dispatch.user.getName)
  const initialPhoneNumber = useSelector(dispatch.user.getPhoneNumber)
  useEffect(() => {
    setNameInput(initialName)
  }, [isLoggedIn, initialName])
  useEffect(() => {
    setPhoneNumberInput(initialPhoneNumber)
  }, [isLoggedIn, initialPhoneNumber])

  const [code, setCode] = useState('')
  const isPhoneNumberVerified = useSelector(() =>
    dispatch.user.getIsPhoneNumberVerified({phoneNumber: phoneNumberInput}),
  )
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)
  const [isVerifyingCode, setIsVerifyingCode] = useState(false)

  const [isCreatingAccount, setIsCreatingAccount] = useState(false)
  const [updatingContact, setUpdatingContact] = useState(false)

  const cartSubTotal = useSelector(dispatch.user.getCartSubTotal)
  const fPointsEarned = utils.calculateFoodlyPointsEarnedSubTotal({subTotal: cartSubTotal})
  const firstFreeRewardName = useSelector(dispatch.user.getFreeFirstOrderRewardName)

  let text = 'Done'

  if (hasNext) {
    text = 'Next'
  }
  if (createAccountChecked) {
    text = 'Sign Up'
  }
  if (!currentOrderOpenDetails?.isOpen) {
    text = 'Ordering Closed'
  }
  if (isCreatingAccount) {
    text = 'Creating Account...'
  }

  // phone verification functions
  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber
      },
      'expired-callback': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
      },
      'error-callback': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
      },
    })
  }, [])

  const createAccount = async () => {
    setIsCreatingAccount(true)
    try {
      await dispatch.user.createUserWithEmailAndPassword({
        email,
        password,
        name: trim(nameInput),
        phoneNumber: utils.removeNonNumericString(phoneNumberInput),
      })
      dispatch.user.setCreateAccountChecked({createAccountChecked: !createAccountChecked})
    } catch (e) {
      alert('Create Account Failed. ' + e.message)
      return
    } finally {
      setIsCreatingAccount(false)
    }
  }

  const updateContact = async ({onSuccess}) => {
    setUpdatingContact(true)
    try {
      await dispatch.user.setNameOnline({name: trim(nameInput)})
      await dispatch.user.setPhoneNumberOnline({phoneNumber: utils.removeNonNumericString(phoneNumberInput)})
      onSuccess()
    } catch (e) {
      alert('Update Contact Failed. ' + e.message)
      return
    } finally {
      setUpdatingContact(false)
    }
  }

  const sendCode = async () => {
    if (!inputPhoneRef.current.reportValidity()) {
      return
    }
    setIsCodeSent(false)
    setIsSendingCode(true)
    const appVerifier = window.recaptchaVerifier

    try {
      currVerifyingPhoneNumber = '+1' + utils.removeNonNumericString(phoneNumberInput)
      const confirmationResult = await auth.signInWithPhoneNumber(currVerifyingPhoneNumber, appVerifier)
      window.confirmationResult = confirmationResult
      setIsCodeSent(true)
    } catch (e) {
      setIsCodeSent(false)
      alert(e.message)
    } finally {
      setIsSendingCode(false)
    }
  }

  const verifyCode = async () => {
    setIsVerifyingCode(true)
    try {
      try {
        // attempt to unlink phone number to prevent logging into existing account
        await dispatch.user.unlinkPhoneNumberFromAuth({phoneNumber: currVerifyingPhoneNumber})
      } catch (e) {}
      const credential = await PhoneAuthProvider.credential(window.confirmationResult?.verificationId, code)
      // If Current User Auth already has phone number, remove it
      if (auth.currentUser?.providerData?.some(({providerId}) => providerId === 'phone')) {
        try {
          await auth.currentUser.unlink('phone')
        } catch (e) {
          console.warn(e.message)
        }
      }
      try {
        const userCredentials = await auth.currentUser.linkWithCredential(credential)
        const userData = {
          uid: userCredentials.user.uid,
          phoneNumber: userCredentials.user.phoneNumber,
        }
        dispatch.user.updateUserWithAuthUser(userData)
      } catch (e) {
        if (e.code === 'auth/credential-already-in-use') {
          await dispatch.user.unlinkPhoneNumberFromAuth({phoneNumber: currVerifyingPhoneNumber})
          const userCredentials = await auth.currentUser.linkWithCredential(credential)
          const userData = {
            uid: userCredentials.user.uid,
            phoneNumber: userCredentials.user.phoneNumber,
          }
          dispatch.user.updateUserWithAuthUser(userData)
        } else if (e.code === 'auth/invalid-verification-code') {
          alert('You have entered the wrong code')
        } else {
          throw e
        }
      }
    } catch (e) {
      alert(e.message)
    } finally {
      setIsVerifyingCode(false)
    }
  }
  return (
    <FView>
      <FView>
        {!isLoggedIn && (
          <FView bg={colors.white} row justifyBetween alignCenter mb={16}>
            <FView row justifyBetween alignCenter>
              <InfoOutlined style={{color: '#EB8A30'}} />
              <FView w={8} />
              <FText color="#EB8A30" body1>
                Already have an account?
              </FText>
            </FView>
            <FButton onClick={() => dispatch.settings.setAuthModalType('signin')}>
              <ButtonFillView ph={8} h={40} rounded>
                <FText primaryContrast bold body2>
                  Sign In
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        )}
        <FView>
          <InputLabelView label="Name">
            <FInput
              placeholder="John Smith"
              value={nameInput}
              ref={inputNameRef}
              maxLength={24}
              onChange={(e) => setNameInput(e.target.value)}
              required
            />
          </InputLabelView>
          <InputLabelView label="Phone">
            <FView row alignCenter>
              <FView ml={12}>
                <FText body2>+1</FText>
              </FView>
              <FView fill>
                <FInput
                  ref={inputPhoneRef}
                  type="tel"
                  required
                  pattern="\([0-9]{3}\) [0-9]{3} - [0-9]{4}"
                  autoComplete="tel"
                  placeholder="(555) 555 - 5555"
                  minLength={10}
                  maxLength={16}
                  value={utils.formatPhoneNumber(phoneNumberInput)}
                  onChange={(e) => setPhoneNumberInput(utils.removeNonNumericString(e.target.value))}
                />
              </FView>
              <FText body2 error={!isPhoneNumberVerified}>
                {isPhoneNumberVerified ? 'verified' : 'not verified'}
              </FText>
              {isPhoneNumberVerified && (
                <>
                  <FView w={4} />
                  <CheckCircle style={{color: 'green'}} fontSize="small" />
                </>
              )}
            </FView>
          </InputLabelView>
          {!isPhoneNumberVerified && (
            <>
              <FView pv={16}>
                <FButton fill onClick={sendCode} disabled={isSendingCode}>
                  <FView bg={isSendingCode ? colors.lightGrey : '#EB8A30'} br={theme.shape.borderRadius} h={40} center>
                    <FText white bold body2>
                      {isSendingCode
                        ? 'Sending...'
                        : isCodeSent
                        ? 'Resend Verification Code'
                        : 'Send Verification Code'}
                    </FText>
                  </FView>
                </FButton>
              </FView>
            </>
          )}
          {isCodeSent && !isPhoneNumberVerified && (
            <>
              <InputLabelView label="SMS Code">
                <FView row alignCenter>
                  <FView fill>
                    <FInput required placeholder="555555" value={code} onChange={(e) => setCode(e.target.value)} />
                  </FView>
                  <FButton onPress={verifyCode} disabled={isVerifyingCode}>
                    <FView
                      bg={isVerifyingCode ? theme.palette.grey[500] : '#EB8A30'}
                      br={theme.shape.borderRadius}
                      ph={8}
                      h={40}
                      center>
                      <FText white bold body2>
                        {isVerifyingCode ? 'Verifying...' : 'Verify'}
                      </FText>
                    </FView>
                  </FButton>
                </FView>
              </InputLabelView>
              <Divider />
            </>
          )}

          <Collapse in={!isLoggedIn && createAccountChecked}>
            <InputLabelView label="Email">
              <FInput
                ref={inputEmailRef}
                required
                type="email"
                autoComplete="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => dispatch.user.setEmail({email: e.target.value})}
              />
            </InputLabelView>
            <Divider />
            <InputLabelView label="Password">
              <FInput
                ref={inputPasswordRef}
                required
                type="password"
                minLength={6}
                autoComplete="new-password"
                placeholder="******"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputLabelView>
            <Divider />
          </Collapse>
          {!isLoggedIn && (
            <FView>
              <FButton
                onClick={() => dispatch.user.setCreateAccountChecked({createAccountChecked: !createAccountChecked})}>
                <FView row pv={8}>
                  {createAccountChecked ? (
                    <CheckBox style={{color: '#EB8A30'}} />
                  ) : (
                    <CheckBoxOutlineBlank color="action" />
                  )}
                  <FView fill row>
                    <FView w={16} />
                    {firstFreeRewardName ? (
                      <FView row>
                        <FText alignLeft body1 bold>
                          Limited Time Offer! Sign up now and get a free
                          <FText inline bold primary>{` ${firstFreeRewardName} `}</FText>
                          and {fPointsEarned.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} points!
                        </FText>
                      </FView>
                    ) : (
                      <FText body1 bold>
                        Sign up now and receive {fPointsEarned.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} points!
                      </FText>
                    )}
                  </FView>
                </FView>
              </FButton>
            </FView>
          )}
        </FView>
      </FView>
      <FView fill />
      <FView mv={32} mb={32}>
        <FButton
          fill
          disabled={!currentOrderOpenDetails?.isOpen || isCreatingAccount || updatingContact}
          onClick={async () => {
            if (!(inputNameRef.current.reportValidity() && inputPhoneRef.current.reportValidity())) {
              return
            }
            if (!isPhoneNumberVerified) {
              alert('Phone Not Verified! Phone number needs to be verified to continue.')
              return
            }
            if (createAccountChecked) {
              if (isLoggedIn || !inputEmailRef.current.reportValidity() || !inputPasswordRef.current.reportValidity()) {
                return
              }
              await createAccount()
            }

            updateContact({onSuccess: () => (hasNext ? onNext() : onClose())})
          }}>
          <ButtonFillView round disabled={!currentOrderOpenDetails?.isOpen || isCreatingAccount || updatingContact}>
            <FText body1 bold primaryContrast>
              {text}
            </FText>
            {!currentOrderOpenDetails?.isOpen && currentOrderOpenDetails?.openMoment && (
              <FText white>{(orderType ?? '') + ' Opens ' + currentOrderOpenDetails.openMoment.calendar()}</FText>
            )}
          </ButtonFillView>
        </FButton>
      </FView>
      <div id="sign-in-button"></div>
    </FView>
  )
}

const InputLabelView = ({label, children}) => (
  <FView row h={56} alignCenter bg="white">
    <FView pr={8}>
      <FText bold>{label}</FText>
    </FView>
    <FView fill pr={8}>
      {children}
    </FView>
  </FView>
)
