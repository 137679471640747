import {auth, isMobile, FieldValue, firebaseAnalytics} from 'f-core/src/config/firebase'
import {getCollection, getDocument, getOrdersGroupCreatedAtDesc} from 'f-core/src/config/firebase'
import {get, isEmpty, omit, orderBy, pickBy, memoize} from 'lodash'
import * as utils from 'f-utils'
import * as api from 'f-app-models/api'
import momentTZ from 'moment-timezone'
import hash from 'object-hash'

utils.getPromosWithDetails = memoize(utils.getPromosWithDetails)
const DEFAULT_READONLY_STATE = {
  fPoints: 0,
  RewardHistory: [],
}

const DEFAULT_READWRITE_STATE = {
  addresses: [],
  // Shape of Address
  // city: '',
  // contactlessDeliveryLocation: '',
  // contactlessDeliveryType: null, // null, MEET_COURIER_OUTSIDE, LEAVE_AT_LOCATION    // null means Deliver to my door
  // containerDeliveryAddress: '',
  // containerDeliveryAddressLatLng: {
  //   lat: 0,
  //   lng: 0,
  // },
  // containerDeliveryInstructions: '',
  // containerDeliveryUnit: '',
  // country: '',
  // name: '',
  // postalCode: '',
  // region: '',
  // streetName: '',
  // streetNumber: '',
  selectedFRewardId: null,
  selectedAddressIndex: 0,
  selectedPaymentIndex: 0,
  cartRestaurantId: null,
  cartLocationId: null,
  cartItems: {},
  promos: {},
  includeUtensils: true,
  orderType: null,
}

const DEFAULT_STATE = {
  // Local Only
  defaultNotes: '',
  isUserLoading: false,
  hasUser: false,
  hasReadWrite: false,

  paymentMethod: null,
  createAccountChecked: false,
  lastUserOrder: {},
  orderTimeType: 'ASAP', // ASAP or Scheduled
  scheduledOrderTimestamp: 0, // Scheduled Order Timestamp
  tableNumber: null,
  isTipsDefault: true,

  // User/{userId}
  email: '',
  id: '',
  name: '',
  phoneNumber: '',
  payments: [],

  rewardsUsed: {},
  // User/{userId}/UserData/readonly
  ...DEFAULT_READONLY_STATE,

  // User/{userId}/UserData/readwrite
  ...DEFAULT_READWRITE_STATE,

  // Restaurants/{restaurantId}/Orders
  Orders: {},
  OrderIds: [],
}

let unsubLocation = null
let unsubLocationLastOrder = null

const createUserModel = (stripeKey, serverUrl) => ({
  state: DEFAULT_STATE,
  reducers: {
    _setUser: (state, User) => ({
      ...state,
      ...User,
    }),
    setIsUserLoading: (state, isUserLoading) => ({
      ...state,
      isUserLoading,
    }),
  },
  actions: ({dispatch, getState}) => ({
    getProductCountInCart({productId}) {
      const cartItems = dispatch.user.getCartItems()
      let cartItemsCount = 0
      for (const cartItem of Object.values(cartItems)) {
        if (cartItem.productId === productId) {
          cartItemsCount += cartItem.count
        }
      }
      return cartItemsCount
    },
    getCartLocationTimezone() {
      const locationId = dispatch.user.getCartLocationId()
      return dispatch.restaurants.getTimezone({locationId})
    },
    getCartLocationMoment(...params) {
      const timezone = dispatch.user.getCartLocationTimezone()
      return momentTZ.tz(...params, timezone)
    },
    setUser(User) {
      // Check if user's cart is not empty and cartLocationId is set
      if (!isEmpty(User.cartItems) && User.cartLocationId) {
        const locationId = dispatch.user.getCartLocationId()
        if (locationId !== User.cartLocationId) {
          unsubLocation && unsubLocation()
          unsubLocation = dispatch.restaurants.subscribeLocation({
            restaurantId: User.cartRestaurantId,
            locationId: User.cartLocationId,
          })
          dispatch.user.subscribeLocationLastOrder({locationId: User.cartLocationId})
        }
      }
      dispatch.user._setUser(User)
    },
    async subscribeRewardsWithCode({restaurantId, locationId}) {
      const rewardCodes = (await getDocument('readwrite', {userId: dispatch.user.getUserId()}).get()).get('rewardCodes')
      for (const code of rewardCodes ?? []) {
        dispatch.restaurants.subscribeReward({
          rewardId: code,
          restaurantId,
          locationId,
        })
      }
    },

    setReadWriteUser(userData) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return getDocument('readwrite', {userId}).set(userData, {merge: true})
      }
      return Promise.reject(new Error('User Not Logged In'))
    },
    updateReadWriteUser(userData) {
      const userId = dispatch.user.getUserId()
      return getDocument('readwrite', {userId})
        .update(userData)
        .catch((e) => {
          return dispatch.user.setReadWriteUser(userData)
        })
    },
    setTableNumber(tableNumber) {
      return dispatch.user._setUser({tableNumber})
    },
    setRewardIdToTimesUsed(rewardsUsed) {
      return dispatch.user._setUser({rewardsUsed})
    },
    getTableNumber() {
      return getState().user.tableNumber
    },
    getRewardIdToTimesUsed() {
      return getState().user.rewardsUsed
    },
    getAddresses() {
      return getState().user.addresses ?? []
    },
    getIsDeliveryAddressEmpty() {
      return isEmpty(dispatch.user.getAddresses())
    },
    updateAddress(address, index = 0) {
      const addresses = dispatch.user.getAddresses()
      addresses[index] = {...addresses[index], ...address}
      return dispatch.user.updateReadWriteUser({addresses})
    },
    addAddress(address) {
      const addresses = dispatch.user.getAddresses()
      addresses.unshift(address)
      return dispatch.user.updateReadWriteUser({addresses}).then(() => {
        return 0
      })
    },
    deleteAdddressAtIndex(index) {
      const addresses = dispatch.user.getAddresses()
      addresses.splice(index, 1)
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex()
      if (selectedAddressIndex === index) {
        return dispatch.user.updateReadWriteUser({addresses, selectedAddressIndex: 0})
      } else if (selectedAddressIndex > index) {
        return dispatch.user.updateReadWriteUser({addresses, selectedAddressIndex: selectedAddressIndex - 1})
      }
      return dispatch.user.updateReadWriteUser({addresses})
    },
    getSelectedAddressIndex() {
      return getState().user.selectedAddressIndex
    },
    setSelectedAddressIndex(selectedAddressIndex) {
      return dispatch.user.updateReadWriteUser({selectedAddressIndex})
    },
    async getUserLocationByIP() {
      const response = await api.geocode.locationByIP()
      const {latitude, longitude, city, region_code, country} = response.data
      return {latitude, longitude, city, region: region_code, country}
    },
    getOrderTimeType() {
      return getState().user.orderTimeType ?? 'ASAP'
    },
    setOrderTimeType(orderTimeType) {
      return dispatch.user._setUser({orderTimeType})
    },
    getScheduledOrderTimestamp() {
      return getState().user.scheduledOrderTimestamp
    },
    setScheduledOrderTimestamp(scheduledOrderTimestamp) {
      return dispatch.user._setUser({scheduledOrderTimestamp})
    },
    getLocationsByDistance() {
      const locationsData = dispatch.restaurants.getLocations()
      const userLocation = dispatch.user.getUserLocation() ?? {latitude: 0, longitude: 0}
      return orderBy(
        Object.entries(locationsData).map(([locationId, locationData]) => {
          const {latitude: lat1, longitude: lng1} = userLocation
          const {lat: lat2, lng: lng2} = locationData.restaurantLatLng
          const distance = utils.latlngDistance(lat1, lng1, lat2, lng2)
          const isStoreOpen = dispatch.restaurants.getOrderOpenDetails({locationId}).isOpen
          return {...locationData, isStoreOpen, distance}
        }),
        ['isStoreOpen', 'distance'],
        ['desc', 'asc'],
      )
    },
    getLocationByDistance({locationId}) {
      const locationData = dispatch.restaurants.getLocation({locationId})
      const userLocation = dispatch.user.getUserLocation() ?? {latitude: 0, longitude: 0}
      const {latitude: lat1, longitude: lng1} = userLocation
      const {lat: lat2, lng: lng2} = locationData.restaurantLatLng
      const distance = utils.latlngDistance(lat1, lng1, lat2, lng2)
      return distance
    },
    getIsLocationWithinDeliveryDistance({locationId}) {
      const {latitude: lat1, longitude: lng1} = dispatch.user.getUserLocation() ?? {latitude: 0, longitude: 0}
      const {lat: lat2, lng: lng2} = dispatch.restaurants.getRestaurantLatLng({locationId})
      const distance = utils.latlngDistance(lat1, lng1, lat2, lng2)
      const deliveryDistance = dispatch.restaurants.getDeliveryDistance({locationId})
      return deliveryDistance >= distance
    },
    getUserLocation() {
      return getState().user.userLocation
    },
    async setUserLocation({latitude, longitude, city, region, country, method = 'previouslyStored'}) {
      try {
        await dispatch.user.setReadWriteUser({
          userLocation: {latitude, longitude, city, region, country, method},
        })
      } catch (e) {
        throw e
      }
    },
    getIsUserLoading() {
      return getState().user.isUserLoading
    },
    getIsUserLoaded() {
      return getState().user.hasUser && getState().user.hasReadWrite
    },
    getIsUserLoggedIn() {
      return !!(
        auth.currentUser &&
        !auth.currentUser.isAnonymous &&
        !auth.currentUser.providerData?.every(({providerId}) => providerId === 'phone')
      )
    },
    getIsPhoneNumberVerified({phoneNumber}) {
      return auth.currentUser?.providerData?.some(({providerId, phoneNumber: providerPhoneNumber}) => {
        return providerId === 'phone' && providerPhoneNumber.substr(-10) === phoneNumber
      })
    },
    getUserToken() {
      return auth?.currentUser?.getIdToken() ?? null
    },
    getUserId() {
      return auth?.currentUser?.uid ?? null
    },
    getIncludeUtensils() {
      return getState().user.includeUtensils ?? true
    },
    setIncludeUtensils(includeUtensils) {
      return dispatch.user.setReadWriteUser({includeUtensils})
    },
    getCartCount() {
      const cartItems = dispatch.user.getCartItems()
      let count = 0
      for (const cartItem of Object.values(cartItems)) {
        count += cartItem.count
      }
      return count
    },
    getCartItems() {
      let cartItems = getState().user.cartItems
      if (isEmpty(cartItems)) {
        return {}
      }
      const cartLocationId = dispatch.user.getCartLocationId()
      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId: cartLocationId})
      const activeProducts = dispatch.restaurants.getActiveProducts({locationId: cartLocationId})
      const products = dispatch.restaurants.getProducts({locationId: cartLocationId})

      const productIdToCount = {}
      return Object.entries(cartItems).reduce((prev, [cartItemId, cartItem]) => {
        if (activeProducts[cartItem.productId]) {
          // Check if cartItem meets all required modifier group requirements
          const productDetails = products[cartItem.productId]
          if (productDetails?.modifierGroups?.length > 0) {
            const requiredGroupIds = productDetails.modifierGroups.reduce((prev, groupId) => {
              const groupDetails = dispatch.restaurants.getModifierGroupDetails({
                locationId: cartLocationId,
                modifierGroupId: groupId,
              })
              if (groupDetails && groupDetails.isRequired) {
                prev.push(groupId)
              }
              return prev
            }, [])
            const selectedGroupIds = Object.entries(cartItem.selectedModifiers).reduce(
              (prev, [groupId, modifierItemObj]) => {
                if (!modifierGroups[groupId]) {
                  return prev
                }
                for (const isSelected of Object.values(modifierItemObj)) {
                  if (isSelected) {
                    prev.push(groupId)
                    break
                  }
                }
                return prev
              },
              [],
            )
            const containsAll = requiredGroupIds.every((groupId) => selectedGroupIds.includes(groupId))

            if (containsAll) {
              prev[cartItemId] = cartItem
            }
          } else {
            prev[cartItemId] = cartItem
          }

          if (!prev[cartItemId]) {
            prev[cartItemId] = cartItem
          }
          if (productIdToCount[cartItem.productId]) {
            productIdToCount[cartItem.productId] += cartItem.count
          } else {
            productIdToCount[cartItem.productId] = cartItem.count
          }
          if (
            (typeof productDetails.qty === 'number' && productIdToCount[cartItem.productId] > productDetails.qty) ||
            (typeof productDetails.qtyLimit === 'number' &&
              productIdToCount[cartItem.productId] > productDetails.qtyLimit)
          ) {
            prev[cartItemId].isValid = false
          } else {
            prev[cartItemId].isValid = true
          }
        } else {
          prev[cartItemId] = cartItem
          prev[cartItemId].isValid = false
        }
        return prev
      }, {})
    },
    getIsCartEmpty() {
      return isEmpty(dispatch.user.getCartItems())
    },
    getIsCartValid() {
      const cartItems = dispatch.user.getCartItems()
      for (const itemData of Object.values(cartItems)) {
        if (!itemData.isValid) {
          return false
        }
      }
      return true
    },
    getContactlessDeliveryType() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return addresses?.[selectedAddressIndex]?.contactlessDeliveryType ?? getState().user.contactlessDeliveryType
    },
    getContactlessDeliveryLocation() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return (
        addresses?.[selectedAddressIndex]?.contactlessDeliveryLocation ?? getState().user.contactlessDeliveryLocation
      )
    },
    getName() {
      return getState().user.name || ''
    },
    setName({name}) {
      return dispatch.user.setUser({
        name,
      })
    },
    async setNameOnline({name}) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return getCollection('Users').doc(userId).update({name})
      }
    },
    getEmail() {
      return getState().user.email || ''
    },
    setEmail({email}) {
      return dispatch.user.setUser({
        email,
      })
    },
    getPhoneNumber() {
      return getState().user.phoneNumber || ''
    },
    setPhoneNumber({phoneNumber}) {
      return dispatch.user.setUser({
        phoneNumber,
      })
    },
    async setPhoneNumberOnline({phoneNumber}) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return getCollection('Users').doc(userId).update({phoneNumber})
      }
    },
    getAddressData() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return addresses?.[selectedAddressIndex] ?? null
    },
    getDeliveryAddress() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return addresses?.[selectedAddressIndex]?.containerDeliveryAddress ?? getState().user.containerDeliveryAddress
    },
    getDeliveryAddressShort() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return addresses?.[selectedAddressIndex]?.name ?? addresses?.[selectedAddressIndex]?.containerDeliveryAddress
    },
    getDeliveryAddressLatLng() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return (
        addresses?.[selectedAddressIndex]?.containerDeliveryAddressLatLng ??
        getState().user.containerDeliveryAddressLatLng
      )
    },
    getDeliveryUnit() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return addresses?.[selectedAddressIndex]?.containerDeliveryUnit ?? getState().user.containerDeliveryUnit
    },
    getDeliveryInstructions() {
      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex() ?? 0
      const addresses = dispatch.user.getAddresses()

      return (
        addresses?.[selectedAddressIndex]?.containerDeliveryInstructions ??
        getState().user.containerDeliveryInstructions
      )
    },
    getPayments() {
      return getState().user.payments || []
    },
    getPaymentMethod() {
      return getState().user.paymentMethod
    },
    setPaymentMethod({paymentMethod}) {
      return dispatch.user.setUser({
        paymentMethod,
      })
    },
    getFPoints() {
      return getState().user.fPoints
    },
    getSelectedFRewardId() {
      const isLoggedIn = dispatch.user.getIsUserLoggedIn()
      if (!isLoggedIn) {
        return null
      }
      return getState().user.selectedFRewardId
    },
    setSelectedFRewardId(selectedFRewardId) {
      return dispatch.user._setUser({selectedFRewardId})
    },
    getRewardHistory() {
      return getState().user.RewardHistory
    },
    getCartWaitTime() {
      const orderType = dispatch.user.getOrderType()
      const cartLocationId = dispatch.user.getCartLocationId()
      const waitTime = dispatch.restaurants.getWaitTime({locationId: cartLocationId})
      if (orderType === 'Delivery') {
        return waitTime + dispatch.restaurants.getDeliveryTime({locationId: cartLocationId})
      }
      return waitTime
    },
    setSelectedPaymentIndex(selectedPaymentIndex) {
      return dispatch.user.updateReadWriteUser({selectedPaymentIndex})
    },
    getSelectedPaymentIndex() {
      return getState().user.selectedPaymentIndex
    },
    deleteCreditCardAtIndex(index) {
      const payments = dispatch.user.getPayments()
      const userId = dispatch.user.getUserId()
      payments.splice(index, 1)
      const selectedPaymentIndex = dispatch.user.getSelectedPaymentIndex()
      if (selectedPaymentIndex === index) {
        dispatch.user.updateReadWriteUser({selectedPaymentIndex: 0})
      } else if (selectedPaymentIndex > index) {
        dispatch.user.updateReadWriteUser({selectedPaymentIndex: selectedPaymentIndex - 1})
      }
      return getCollection('Users').doc(userId).update({payments})
    },
    async addCreditCard(card) {
      const userToken = await dispatch.user.getUserToken()
      if (!userToken) {
        throw new Error('User Token not valid')
      }

      // TODO:
      // 1. If you know Moneris will be used as payment processor, then ignore Stripe error messages and
      // only show Moneris error messages
      // 2. If you don't know, then default to ignoring Moneris error messages and only showing Stripe error messages.
      // 3. Regardless of which payment processor, add card to both.

      // Create a new card
      const response = await api.stripe.createCardWithStripe(card, stripeKey)
      const {brand} = response.card

      // Create a new customer on server side
      const res = await api.server.createCustomerWithStripe(serverUrl, userToken, response.id)
      // Don't let the failure of generating Moneris Data Token stop us from adding a credit card
      try {
        // Server will add Moneris Data Token to the User document, so we don't have to
        await api.server.addCardWithCC({card, serverUrl, authToken: userToken, brand, customerId: res.id})
      } catch (e) {}
    },
    async placeOrder(
      {directOrder = false, appVersion = 'unknown', revision = 'unknown'} = {
        directOrder: false,
        appVersion: 'unknown',
        revision: 'unknown',
      },
    ) {
      const paymentMethod = dispatch.user.getPaymentMethod()
      if (!paymentMethod) {
        throw new Error('You must select a payment method.')
      }

      const cartLocationId = dispatch.user.getCartLocationId()
      let orderOpenDetails = dispatch.restaurants.getOrderOpenDetails({locationId: cartLocationId})
      const orderType = dispatch.user.getOrderType()
      if (orderOpenDetails.isOpen && orderType === 'Delivery') {
        orderOpenDetails = dispatch.restaurants.getDeliveryOrderOpenDetails({locationId: cartLocationId})
      }
      if (!orderOpenDetails.isOpen) {
        throw new Error(
          'Sorry Ordering is currently closed' +
            (orderOpenDetails.openMoment ? ' until ' + orderOpenDetails.openMoment.calendar() : '.'),
        )
      }

      const cartRestaurantId = dispatch.user.getCartRestaurantId()
      const cartItems = dispatch.user.getCartItems()
      const includeUtensils = dispatch.user.getIncludeUtensils()
      const notes = dispatch.user.getDefaultNotes()

      const isPickupAvailable = dispatch.restaurants.getIsPickupAvailable({locationId: cartLocationId})
      const isDeliveryAvailable = dispatch.restaurants.getIsDeliveryAvailable({locationId: cartLocationId})
      const isDineInAvailable = dispatch.restaurants.getIsDineInAvailable({locationId: cartLocationId})
      if (orderType === 'Pickup' && !isPickupAvailable) {
        throw new Error('Pickup is not available for this restaurant')
      } else if (orderType === 'Delivery' && !isDeliveryAvailable) {
        throw new Error('Delivery is not available for this restaurant')
      } else if (orderType === 'DineIn' && !isDineInAvailable) {
        throw new Error('Dine-in is not available for this restaurant')
      }
      const tableNumber = dispatch.user.getTableNumber()

      const restaurantLatLng = dispatch.restaurants.getRestaurantLatLng({locationId: cartLocationId})
      const deliveryAddressLatLng = dispatch.user.getDeliveryAddressLatLng() || {lat: 0, lng: 0}
      const deliveryDistance = dispatch.restaurants.getDeliveryDistance({locationId: cartLocationId})
      const cartSubTotalBeforeDiscount = dispatch.user.getCartSubTotalBeforeDiscount()
      const deliveryMinimumSubTotal = dispatch.restaurants.getDeliveryMinimumSubTotal({locationId: cartLocationId})

      const deliveryAddressDistance = utils.latlngDistance(
        restaurantLatLng.lat,
        restaurantLatLng.lng,
        deliveryAddressLatLng.lat,
        deliveryAddressLatLng.lng,
      )

      if (orderType == null) {
        throw new Error('Please select Pickup, Delivery or Dine-In to continue.')
      }
      if (orderType === 'Delivery') {
        if (deliveryAddressDistance > deliveryDistance) {
          throw new Error(`We can only deliver within ${deliveryDistance}km radius from the restaurant.`)
        }
        if (cartSubTotalBeforeDiscount < deliveryMinimumSubTotal) {
          throw new Error(`Subtotal must be at least $${deliveryMinimumSubTotal} for delivery`)
        }
      }
      if (orderType === 'DineIn' && tableNumber == null) {
        throw new Error('You must put a Table Number at your current table to place a Dine-In order.')
      }

      const orderTimeType = dispatch.user.getOrderTimeType()
      const scheduledOrderTimestamp = dispatch.user.getScheduledOrderTimestamp()

      const deliveryAddress = dispatch.user.getDeliveryAddress()
      const deliveryUnit = dispatch.user.getDeliveryUnit()
      const deliveryInstructions = dispatch.user.getDeliveryInstructions()

      const contactlessDeliveryType = dispatch.user.getContactlessDeliveryType()
      const contactlessDeliveryLocation = dispatch.user.getContactlessDeliveryLocation()

      const validPromosWithDetails = dispatch.user.getValidPromosWithDetails()
      const name = dispatch.user.getName()
      const phoneNumber = dispatch.user.getPhoneNumber()
      // Remove everything from promos except id and count before sending to server
      const promos = Object.keys(validPromosWithDetails).reduce((prev, promoId) => {
        prev[promoId] = {
          id: promoId,
          count: validPromosWithDetails[promoId].count,
        }
        return prev
      }, {})
      const selectedFRewardId = dispatch.user.getSelectedFRewardId()
      const tipAmount = dispatch.user.getTipAmount()
      const userToken = await dispatch.user.getUserToken()
      const selectedPaymentIndex = dispatch.user.getSelectedPaymentIndex()
      const {
        order: {orderId},
      } = await api.server.createOrderAndCharge(serverUrl, userToken, cartRestaurantId, cartLocationId, {
        appVersion,
        revision,
        cartItems,
        contactlessDeliveryLocation,
        contactlessDeliveryType,
        deliveryAddress,
        deliveryInstructions,
        deliveryUnit,
        directOrder,
        includeUtensils,
        name,
        notes,
        orderTimeType,
        orderType,
        paymentMethod,
        phoneNumber,
        promos,
        scheduledOrderTimestamp,
        fRewardId: selectedFRewardId,
        selectedPaymentIndex,
        tableNumber,
        tipAmount,
        sourceClient: isMobile
          ? 'container-mobile'
          : utils.isMobileBrowser()
          ? directOrder
            ? 'directorder-mobile-web'
            : 'container-mobile-web'
          : directOrder
          ? 'directorder-web'
          : 'container-web',
      })

      const total = dispatch.user.getCartTotal()
      const cartWithProductDetails = dispatch.user.getCartWithProductDetails()
      if (firebaseAnalytics) {
        firebaseAnalytics.logEvent('purchase', {
          items: Object.values(cartWithProductDetails).map((product) => ({
            item_id: product.productId,
            item_name: product.name,
            price: product.price,
            currency: 'CAD',
          })),
          value: total,
        })
      }
      return orderId
    },
    getCreateAccountChecked() {
      return getState().user.createAccountChecked
    },
    setCreateAccountChecked({createAccountChecked}) {
      return dispatch.user.setUser({
        createAccountChecked,
      })
    },
    getCartRestaurantId() {
      return getState().user.cartRestaurantId
    },
    getCartLocationId() {
      return getState().user.cartLocationId
    },
    getCartLocationDetails() {
      const locationId = dispatch.user.getCartLocationId()
      return dispatch.restaurants.getLocation({locationId})
    },
    getCartWithProductDetails() {
      const locationId = dispatch.user.getCartLocationId()
      const restaurantProducts = dispatch.restaurants.getProducts({locationId})
      const cartItems = dispatch.user.getCartItems()
      return Object.entries(cartItems).reduce((prev, [cartItemId, cartItem]) => {
        prev[cartItemId] = {
          ...cartItems[cartItemId],
          ...restaurantProducts[cartItem.productId],
        }
        return prev
      }, {})
    },
    async reverseGeocode({latitude, longitude}) {
      const response = await api.geocode.reverseGeocode({latitude, longitude})
      if (response && response.data && response.data.results && response.data.results.length > 0) {
        return response.data.results[0].formatted_address
      }
      return ''
    },
    getCartItemUpdatedTimestamp() {
      return getState().user.cartItemUpdatedTimestamp
    },
    async addCartItem({restaurantId, locationId, productId, qty, selectedModifiers, notes}) {
      const userId = dispatch.user.getUserId()
      const cartItems = dispatch.user.getCartItems()

      const isPickupAvailable = dispatch.restaurants.getIsPickupAvailable({locationId})
      const isDeliveryAvailable = dispatch.restaurants.getIsDeliveryAvailable({locationId})
      const isDineInAvailable = dispatch.restaurants.getIsDineInAvailable({locationId})

      const orderType = dispatch.user.getOrderType()

      const isCartEmpty = dispatch.user.getIsCartEmpty()

      const productDetails = dispatch.restaurants.getProductDetails({locationId, productId})
      let numAlreadyInCart = 0
      for (const cartItem of Object.values(cartItems)) {
        if (cartItem.productId === productId) {
          numAlreadyInCart += cartItem.count
        }
      }
      if (typeof productDetails.qty === 'number' && productDetails.qty < numAlreadyInCart + qty) {
        throw new Error(`Only ${productDetails.qty} remaining`)
      }
      if (typeof productDetails.qtyLimit === 'number' && productDetails.qtyLimit < numAlreadyInCart + qty) {
        throw new Error(`Limit ${productDetails.qtyLimit} per Order`)
      }

      if (isCartEmpty) {
        dispatch.user.setIsTipsDefault(true)
      }

      if (
        (orderType === 'Pickup' && !isPickupAvailable) ||
        (orderType === 'Delivery' && !isDeliveryAvailable) ||
        (orderType === 'DineIn' && !isDineInAvailable)
      ) {
        dispatch.user.setOrderType({orderType: null})
      }

      const newUserData = {
        cartRestaurantId: restaurantId,
        cartLocationId: locationId,
      }
      // 1. If QR Code scan/direct & dine in order
      // orderType stays 'DineIn' to prevent users from ordering with wrong orderType (ex. dine in instead of delivery/pickup)
      // if (myOrderType?.toLowerCase() === 'dinein' && myTableNumber) {
      //   newUserData.orderType = 'DineIn'
      //   dispatch.user.setTableNumber(myTableNumber)
      // }

      // Else if cartLocationId is different or cart is empty
      // Reset orderType to prevent users from ordering with wrong orderType (ex. pickup instead of delivery)
      // else if (isEmpty(cartItems) || dispatch.user.getCartLocationId() !== locationId) {
      //   newUserData.orderType = null
      // }

      let newCartItems = {}
      // 2. We only support ordering from a single location at a time.
      // Keep existing cart items only if cartLocationId is same as the adding item's locationId
      if (!isEmpty(cartItems) && dispatch.user.getCartLocationId() === locationId) {
        newCartItems = {...cartItems}
      }

      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId})

      // 3. Throw error if any required modifier is not selected
      const error = new Error('You must select all the required options.')
      error.ids = []
      for (const [groupId, modifierItems] of Object.entries(selectedModifiers)) {
        if (modifierGroups[groupId] && modifierGroups[groupId].isRequired) {
          const hasSelection = Object.values(modifierItems).reduce((prev, selected) => selected || prev, false)
          if (!hasSelection) {
            error.ids.push(groupId)
          }
        }
      }

      if (error.ids.length > 0) {
        throw error
      }

      // 4. Calculate cartItemHash
      const newCartItem = {
        productId,
        selectedModifiers,
        notes,
      }
      const cartItemId = hash(newCartItem, {unorderedObjects: true})
      newCartItem.createdAt = new Date().valueOf()

      // 5. Add New cart item (increment count if already in cart)
      if (newCartItems[cartItemId]) {
        newCartItems[cartItemId].count += qty
      } else {
        newCartItem.count = qty
        newCartItems[cartItemId] = newCartItem
      }

      //6. add notes to car item
      if (notes) {
        newCartItems[cartItemId].notes = notes
      }

      if (firebaseAnalytics) {
        firebaseAnalytics.logEvent('add_to_cart', {
          items: [
            {
              item_name: productDetails.name,
              item_id: productDetails.productId,
              price: productDetails.price,
              currency: 'CAD',
            },
          ],
        })
      }

      // 6. Update User's readwrite (use set if update fails since readwrite document may not exist yet)
      newUserData.cartItems = newCartItems
      newUserData.cartItemUpdatedTimestamp = FieldValue.serverTimestamp()
      return getDocument('readwrite', {userId})
        .update(newUserData)
        .catch(() => getDocument('readwrite', {userId}).set(newUserData))
    },

    async clearCart() {
      const userId = dispatch.user.getUserId()
      return getDocument('readwrite', {userId}).update({
        cartItems: {},
        cartLocationId: null,
      })
    },

    removeInvalidCartItems(cartItems) {
      const userReadWriteUpdate = {}
      for (const [cartItemId, cartItemData] of Object.entries(cartItems)) {
        if (!cartItemData.isValid) {
          userReadWriteUpdate[`cartItems.${cartItemId}`] = FieldValue.delete()
        }
      }

      const userId = dispatch.user.getUserId()
      if (!isEmpty(userReadWriteUpdate)) {
        return getDocument('readwrite', {userId}).update(userReadWriteUpdate)
      }
      return Promise.resolve(true)
    },
    editCartItem({qty, selectedModifiers, cartItemId, notes}) {
      const userId = dispatch.user.getUserId()
      const cartItems = dispatch.user.getCartItems()
      const productId = cartItems[cartItemId].productId
      const newCartItemId = hash({
        notes,
        selectedModifiers,
        productId,
      })

      cartItems[newCartItemId] = cartItems[cartItemId]
      cartItems[newCartItemId].notes = notes
      cartItems[newCartItemId].selectedModifiers = selectedModifiers
      cartItems[newCartItemId].count = qty

      if (newCartItemId !== cartItemId) {
        delete cartItems[cartItemId]
      }
      return getDocument('readwrite', {userId}).update({cartItems})
    },
    subtractCartItem({cartItemId, count = 1}) {
      const cartItems = dispatch.user.getCartItems()
      if (!cartItems[cartItemId]) {
        return
      }
      const newCartItems =
        cartItems[cartItemId].count === 1 || cartItems[cartItemId].count - count <= 0
          ? omit(cartItems, cartItemId)
          : {
              ...cartItems,
              [cartItemId]: {
                ...cartItems[cartItemId],
                count: cartItems[cartItemId].count - count,
              },
            }

      if (isEmpty(newCartItems)) {
        unsubLocation && unsubLocation()
        unsubLocationLastOrder && unsubLocationLastOrder()
      }

      const userId = dispatch.user.getUserId()
      return getDocument('readwrite', {userId}).update({
        cartItems: newCartItems,
      })
    },
    getCartItemTotal({locationId, selectedModifiers, price, count}) {
      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId})

      const selectedModifierGroupsWithDetails = utils.getSelectedModifierGroupsWithDetails({
        selectedModifiers,
        modifierGroups,
      })

      return utils.getCartItemTotal({price, count, selectedModifierGroupsWithDetails})
    },
    getOrderType() {
      return getState().user.orderType
    },
    async setOrderType({orderType}) {
      return dispatch.user.setReadWriteUser({orderType})
    },
    getBeforeTaxDiscount() {
      const promosWithDetails = dispatch.user.getValidPromosWithDetails()
      const subtotalBeforeDiscount = dispatch.user.getCartSubTotalBeforeDiscount()
      let totalDiscount = utils.getBeforeTaxDiscount({
        promosWithDetails,
        subtotalBeforeDiscount,
      })

      return totalDiscount
    },
    getAfterTaxDiscount() {
      const subtotal = dispatch.user.getCartSubTotal()
      const selectedFRewardId = dispatch.user.getSelectedFRewardId()
      return utils.parseFReward({
        subtotal,
        fRewardId: selectedFRewardId,
      }).totalDiscountAmount
    },
    getFRewardDetails(fRewardId) {
      const subtotal = dispatch.user.getCartSubTotal()
      return utils.parseFReward({
        subtotal,
        fRewardId,
      })
    },
    getCartTaxes() {
      const locationId = dispatch.user.getCartLocationId()
      const products = dispatch.restaurants.getProducts({locationId})
      const cartItems = dispatch.user.getCartItems()
      const beforeTaxDiscountAmount = dispatch.user.getBeforeTaxDiscount()
      const orderType = dispatch.user.getOrderType()
      const deliveryFee = dispatch.user.getDeliveryFee()
      const deliveryFeeTaxType = dispatch.restaurants.getDeliveryFeeTaxType({locationId})
      const publicTaxes = dispatch.public.getPublicTaxes()
      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId})

      return utils.getCartTaxes({
        products,
        cartItems,
        beforeTaxDiscountAmount,
        orderType,
        deliveryFee,
        deliveryFeeTaxType,
        publicTaxes,
        modifierGroups,
      })
    },
    getCartTax() {
      const taxes = dispatch.user.getCartTaxes()
      return utils.getCartTax({taxes})
    },
    getCartSubTotalBeforeDiscount() {
      const locationId = dispatch.user.getCartLocationId()
      const products = dispatch.restaurants.getProducts({locationId})
      const cartItems = dispatch.user.getCartItems()
      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId})

      const orderCartItems = utils.getOrderCartItems({
        cartItems,
        products,
        modifierGroups,
      })

      return utils.getSubtotalBeforeDiscount({
        orderCartItems,
      })
    },
    getCartSubTotal() {
      return utils.currencyRounding(
        Math.max(dispatch.user.getCartSubTotalBeforeDiscount() - dispatch.user.getBeforeTaxDiscount(), 0),
      )
    },
    getIsTipsDefault() {
      return getState().user.isTipsDefault
    },
    getTipSelectionIndex() {
      const orderType = dispatch.user.getOrderType()
      const isTipsDefault = dispatch.user.getIsTipsDefault()
      if (isTipsDefault) {
        if (orderType === 'Pickup') {
          return 3
        } else if (orderType === 'Delivery') {
          return 1
        }
      }
      return getState().user.tipSelectionIndex ?? 1
    },
    getCustomTipAmount() {
      return getState().user.customTipAmount ?? 0
    },
    getDeliveryFee() {
      const orderType = dispatch.user.getOrderType()
      const cartLocationId = dispatch.user.getCartLocationId()
      const deliveryFee = dispatch.restaurants.getDeliveryFee({locationId: cartLocationId})
      const deliveryFeeMinimum = dispatch.restaurants.getDeliveryFeeMinimum({locationId: cartLocationId})
      const isDeliveryFeeVariable = dispatch.restaurants.getIsDeliveryFeeVariable({locationId: cartLocationId})
      const deliveryFreeMinimumSubTotal = dispatch.restaurants.getDeliveryFreeMinimumSubTotal({
        locationId: cartLocationId,
      })
      const deliveryFeePerDistance = dispatch.restaurants.getDeliveryFeePerDistance({locationId: cartLocationId})

      const deliveryFeeDiscountMinimumSubTotal = dispatch.restaurants.getDeliveryFeeDiscountMinimumSubTotal({
        locationId: cartLocationId,
      })
      const deliveryFeeDiscount = dispatch.restaurants.getDeliveryFeeDiscount({
        locationId: cartLocationId,
      })
      const subTotalBeforeDiscount = dispatch.user.getCartSubTotalBeforeDiscount()
      const {latitude, longitude} = dispatch.user.getUserLocation() ?? {latitude: 0, longitude: 0}
      const locationData = dispatch.restaurants.getLocation({locationId: cartLocationId})
      if (!locationData) {
        return 0
      }

      return utils.calculateDeliveryFee({
        customerCoords: {lat: latitude, lng: longitude},
        deliveryFee,
        deliveryFeeDiscount,
        deliveryFeeDiscountMinimumSubTotal,
        deliveryFeeMinimum,
        deliveryFeePerDistance,
        deliveryFreeMinimumSubTotal,
        isDeliveryFeeVariable,
        orderType,
        restaurantCoords: locationData.restaurantLatLng,
        subTotalBeforeDiscount,
      })
    },
    getTipAmountForTipSelectionIndex(tipSelectionIndex) {
      if (tipSelectionIndex === null) {
        return 0
      }

      const total = dispatch.user.getCartSubTotal() + dispatch.user.getCartTax() + dispatch.user.getDeliveryFee()

      switch (tipSelectionIndex) {
        case 0:
          if (total < 5) {
            return 1
          } else {
            return utils.currencyRounding(total * 0.12)
          }
        case 1:
          if (total < 5) {
            return 2
          } else {
            return utils.currencyRounding(total * 0.15)
          }
        case 2:
          if (total < 5) {
            return 3
          } else {
            return utils.currencyRounding(total * 0.18)
          }
        case 3:
          return dispatch.user.getCustomTipAmount() || 0
        default:
          return 0
      }
    },
    getMinimumTipAmount() {
      const locationId = dispatch.user.getCartLocationId()
      const orderType = dispatch.user.getOrderType()
      if (locationId && orderType) {
        const locationData = dispatch.restaurants.getLocation({locationId})
        const minimumTipPercent = locationData?.minimumTip?.[orderType] ?? 0
        const total = dispatch.user.getCartSubTotal() + dispatch.user.getCartTax() + dispatch.user.getDeliveryFee()
        return utils.currencyRounding(total * minimumTipPercent)
      }
      return 0
    },
    getTipAmount() {
      const tipSelectionIndex = dispatch.user.getTipSelectionIndex()
      const tipAmount = dispatch.user.getTipAmountForTipSelectionIndex(tipSelectionIndex)
      const minimumTipAmount = dispatch.user.getMinimumTipAmount()
      return Math.max(tipAmount, minimumTipAmount)
    },
    getCartTotal() {
      const orderType = dispatch.user.getOrderType()
      const cartLocationId = dispatch.user.getCartLocationId()
      return utils.currencyRounding(
        dispatch.user.getCartSubTotal() +
          dispatch.user.getCartTax() -
          dispatch.user.getAfterTaxDiscount() +
          dispatch.user.getTipAmount() +
          (orderType === 'Delivery' ? dispatch.user.getDeliveryFee({locationId: cartLocationId}) : 0),
      )
    },
    getDefaultNotes() {
      return getState().user.defaultNotes || ''
    },
    setDefaultNotes(defaultNotes) {
      return dispatch.user._setUser({defaultNotes})
    },
    setIsTipsDefault(isTipsDefault) {
      return dispatch.user._setUser({isTipsDefault})
    },
    setTipSelectionIndex(tipSelectionIndex) {
      dispatch.user.setIsTipsDefault(false)
      return dispatch.user._setUser({tipSelectionIndex})
    },
    setCustomTipAmount(customTipAmount) {
      return dispatch.user._setUser({customTipAmount})
    },
    getOrders() {
      return getState().user.Orders
    },
    getOrderIds() {
      return getState().user.OrderIds
    },
    getOrderDetails({orderId}) {
      const Orders = dispatch.user.getOrders()
      return Orders[orderId]
    },
    getLastUserOrder() {
      return getState().user.lastUserOrder
    },
    getIsFirstOrder({locationId}) {
      const isUserLoggedIn = dispatch.user.getIsUserLoggedIn()
      if (!isUserLoggedIn) {
        return true
      }
      const lastUserOrder = dispatch.user.getLastUserOrder()
      if (lastUserOrder && lastUserOrder.locationId && lastUserOrder.locationId !== locationId) {
        return false
      }
      return !get(lastUserOrder, 'id')
    },
    // >>>>>>>>>>>>>>>>>>>>>> AUTHENTICATION START >>>>>>>>>>>>>>>>>>>>>>>>>>
    async signInWithEmailAndPassword(email, password) {
      // Cart state before logging in
      const addresses = dispatch.user.getAddresses()
      const cartItems = dispatch.user.getCartItems()
      const cartLocationId = dispatch.user.getCartLocationId()
      const cartRestaurantId = dispatch.user.getCartRestaurantId()
      const customTipAmount = dispatch.user.getCustomTipAmount()
      const includeUtensils = dispatch.user.getIncludeUtensils()
      const orderType = dispatch.user.getOrderType()
      const promos = dispatch.user.getPromos()
      const tipSelectionIndex = dispatch.user.getTipSelectionIndex()

      const selectedAddressIndex = dispatch.user.getSelectedAddressIndex()

      const res = await auth.signInWithEmailAndPassword(email.toLowerCase(), password)
      dispatch.user.setUser({lastUserOrder: {}, customTipAmount, tipSelectionIndex})

      const uid = res.uid || get(res, 'user.uid')
      const readwriteSnapshot = await getDocument('readwrite', {userId: uid}).get()
      const signedInReadwriteData = readwriteSnapshot.data() || {}

      const newReadwriteData = {
        ...signedInReadwriteData,

        ...(!isEmpty(addresses) ? {addresses, selectedAddressIndex} : {}),
        ...(!isEmpty(cartItems) || !isEmpty(promos)
          ? {cartItems, cartLocationId, cartRestaurantId, includeUtensils, orderType, promos}
          : {}),
      }

      return getDocument('readwrite', {userId: uid}).set(newReadwriteData)
    },
    sendPasswordResetEmail(email) {
      return auth.sendPasswordResetEmail(email)
    },
    signOut() {
      return auth.signOut()
    },
    async createUserWithEmailAndPassword({email, password, name, phoneNumber}) {
      if (!email || email.length === 0) {
        throw new Error('Email cannot be empty')
      }
      if (!password || password.length === 0) {
        throw new Error('Password cannot be empty')
      }
      const numberOnlyPhoneNumber = utils.removeNonNumericString(phoneNumber)
      if (numberOnlyPhoneNumber.length > 0 && numberOnlyPhoneNumber.length !== 10) {
        throw new Error('Incorrect Phone Number. Please enter format of 7783334444')
      }
      const authToken = await dispatch.user.getUserToken()
      const emailLowercase = email.toLowerCase()
      const res = await api.server.linkAccountToUser({authToken, serverUrl, email: emailLowercase, password})
      const userData = {
        uid: res.uid,
        phoneNumber: res.phoneNumber ?? numberOnlyPhoneNumber ?? '',
        email: res.email,
        name,
      }
      await dispatch.user.updateUserWithAuthUser(userData)
      return dispatch.user.signInWithEmailAndPassword(res.email, password)
    },
    async unlinkPhoneNumberFromAuth({phoneNumber}) {
      const authToken = await dispatch.user.getUserToken()
      return api.server.unlinkPhoneNumberFromAuth({authToken, serverUrl, phoneNumber})
    },
    async updateUserWithAuthUser(authUserData) {
      const userData = {
        ...(authUserData.phoneNumber ? {phoneNumber: authUserData.phoneNumber?.substr(-10)} : {}),
        ...(authUserData.email ? {email: authUserData.email} : {}),
        ...(authUserData.name ? {name: authUserData.name} : {}),
      }
      if (!isEmpty(userData)) {
        return getCollection('Users').doc(authUserData.uid).set(userData, {merge: true})
      }
    },
    // <<<<<<<<<<<<<<<<<<< AUTHENTICATION END <<<<<<<<<<<<<<<<<<<<<<<<
    // >>>>>>>>>>>>>>>>>>> PAYMENT ACTIONS START >>>>>>>>>>>>>>>>>>>>>
    getHasPayment() {
      const payments = dispatch.user.getPayments()
      return !isEmpty(payments)
    },
    // <<<<<<<<<<<<<<<<<<< PAYMENT ACTIONS END <<<<<<<<<<<<<<<<<
    // >>>>>>>>>>>>>>>>>>> REWARDS START >>>>>>>>>>>>>>>>>>>>>>>
    getRewards(locationId) {
      const myLocationId = locationId || dispatch.user.getCartLocationId()
      const rewards = dispatch.restaurants.getRewards({locationId: myLocationId})
      for (const [rewardId, rewardInfo] of Object.entries(rewards)) {
        if (rewardInfo == null) {
          delete rewards[rewardId]
        }
      }
      return rewards
    },
    getPromos() {
      const rewards = dispatch.user.getRewards()
      const rewardsObj = Object.keys(rewards).reduce((prev, rewardId) => {
        prev[rewardId] = {
          id: rewardId,
          count: 1,
        }
        return prev
      }, {})
      const promos = getState().user.promos

      return {...promos, ...rewardsObj}
    },
    //TODO: rename
    async addPromo({code, restaurantId, locationId}) {
      const rewardDoc = await getCollection('Rewards', {restaurantId, locationId}).doc(code).get()
      if (!rewardDoc.exists) {
        throw new Error('Invalid Promo code')
      }

      await dispatch.restaurants.subscribeReward({
        rewardId: code,
        restaurantId,
        locationId,
      })

      const userId = dispatch.user.getUserId()

      await getDocument('readwrite', {userId}).set({rewardCodes: FieldValue.arrayUnion(code)}, {merge: true})
    },
    removePromo(code) {
      //deprecated
    },
    async clearPromos() {
      const userId = dispatch.user.getUserId()
      await getDocument('readwrite', {userId}).set({rewardCodes: []}, {merge: true})
    },
    getValidPromosWithDetails() {
      return pickBy(dispatch.user.getAllPromosWithDetails(), (promoDetails) => promoDetails.valid)
    },
    getAllPromosWithDetails(locationId) {
      const myLocationId = locationId || dispatch.user.getCartLocationId()
      const rewards = dispatch.user.getRewards(myLocationId)
      const cartItems = dispatch.user.getCartItems()
      const products = dispatch.restaurants.getProducts({locationId: myLocationId})
      const orderType = dispatch.user.getOrderType()
      const subtotalBeforeDiscount = dispatch.user.getCartSubTotalBeforeDiscount()
      const isLoggedIn = dispatch.user.getIsUserLoggedIn()
      const isFirstOrder = dispatch.user.getIsFirstOrder({locationId: myLocationId})
      const modifierGroups = dispatch.restaurants.getModifierGroups({locationId: myLocationId})
      const rewardIdToTimesUsed = dispatch.user.getRewardIdToTimesUsed()

      return utils.getPromosWithDetails({
        rewardIdToTimesUsed,
        rewards,
        products,
        orderType,
        subtotalBeforeDiscount,
        isLoggedIn,
        isFirstOrder,
        cartItems,
        modifierGroups,
      })
    },
    getFreeFirstOrderRewardName() {
      const cartLocationId = dispatch.user.getCartLocationId()
      if (!cartLocationId) {
        return null
      }
      const rewards = dispatch.restaurants.getFieldRewards({locationId: cartLocationId}) //remove after migration
      const rewardsArr = Object.entries(rewards)
      for (const [rewardId, rewardDetails] of rewardsArr) {
        if (rewardDetails && rewardDetails.type && rewardDetails.type.startsWith('FreeFirstOrder')) {
          const rewardDetail = dispatch.user.getRewardDetails({rewardId, locationId: cartLocationId})
          return rewardDetail.name
        }
      }
      return null
    },
    // <<<<<<<<<<<<<<<<< REWARDS END <<<<<<<<<<<<<<<<<<<<<<<
    // >>>>>>>>>>>>>>>>> SUBSCRIPTIONS START >>>>>>>>>>>>>>>>>>>>>>
    subscribeOrders() {
      const userId = dispatch.user.getUserId()
      return getOrdersGroupCreatedAtDesc({userId, limit: 20}).onSnapshot((querySnapshot) => {
        if (querySnapshot != null) {
          const Orders = {}
          const OrderIds = []
          for (const snapshot of querySnapshot.docs) {
            Orders[snapshot.id] = snapshot.data()
            Orders[snapshot.id].orderId = snapshot.id
            OrderIds.push(snapshot.id)
          }
          dispatch.user.setUser({
            Orders,
            OrderIds,
          })
        }
      })
    },
    subscribeUser() {
      let subsUserId = null
      let unsubUser = null
      let unsubUserDataReadWrite = null
      let unsubUserUsedRewards = null
      let unsubUserDataReadOnly = null
      let unsubRewardHistory = null
      let unsubOrders = null
      // let unsubUserDataPromos = null

      const unsubAll = () => {
        unsubUser && unsubUser()
        unsubUserDataReadWrite && unsubUserDataReadWrite()
        unsubUserDataReadOnly && unsubUserDataReadOnly()
        unsubRewardHistory && unsubRewardHistory()
        unsubOrders && unsubOrders()
        unsubUserUsedRewards && unsubUserUsedRewards()
      }

      let isSigningInAnonymously = false
      return auth.onAuthStateChanged((user) => {
        if (user && user.uid) {
          isSigningInAnonymously = false
          dispatch.user.updateUserWithAuthUser(user)
          if (subsUserId == null || subsUserId !== user.uid) {
            subsUserId = user.uid
            unsubAll()
            dispatch.user.setIsUserLoading(true)

            // Subscribes User's Last Order of a cartLocationId
            // This happens when switching from annonymous to logged in User with both cases having items from same restaurant.
            const cartLocationId = dispatch.user.getCartLocationId()
            if (cartLocationId) {
              dispatch.user.subscribeLocationLastOrder({locationId: cartLocationId})
            }

            // Update user's lastLogin timestamp
            getCollection('Users').doc(user.uid).set({lastLogin: new Date().valueOf()}, {merge: true})

            unsubUser = getCollection('Users')
              .doc(user.uid)
              .onSnapshot(
                (snapshot) => {
                  if (snapshot == null) {
                    if (!auth.currentUser.isAnonymous) {
                      subsUserId = null
                      unsubAll()
                      auth.signOut()
                    }
                  } else {
                    if (snapshot.exists) {
                      const User = snapshot.data()
                      User.id = snapshot.id
                      User.hasUser = true
                      dispatch.user.setUser(User)
                    }
                  }
                  if (dispatch.user.getIsUserLoading() === true) {
                    dispatch.user.setIsUserLoading(false)
                  }
                },
                (e) => {
                  if (dispatch.user.getIsUserLoading() === true) {
                    dispatch.user.setIsUserLoading(false)
                  }
                },
              )

            unsubUserUsedRewards = getCollection('Users')
              .doc(user.uid)
              .collection('RewardsUsed')
              .onSnapshot(async (snapshot) => {
                if (snapshot == null) {
                  return
                } else {
                  const rewardsUsed = {}
                  for (const doc of snapshot.docs) {
                    rewardsUsed[doc.id] = doc.get('timesUsed')
                  }
                  dispatch.user.setRewardIdToTimesUsed(rewardsUsed)
                }
              })
            unsubUserDataReadWrite = getDocument('readwrite', {userId: user.uid}).onSnapshot(async (snapshot) => {
              if (snapshot == null) {
                if (!auth.currentUser.isAnonymous) {
                  subsUserId = null
                  unsubAll()
                  auth.signOut()
                }
              } else {
                if (snapshot.exists) {
                  const readwriteData = snapshot.data()
                  delete readwriteData.defaultNotes
                  delete readwriteData.tipSelectionIndex
                  delete readwriteData.customTipAmount
                  delete readwriteData.payments
                  readwriteData.hasReadWrite = true

                  return dispatch.user.setUser(readwriteData)
                } else {
                  // Create readwrite with default state
                  return getDocument('readwrite', {userId: user.uid}).set(DEFAULT_READWRITE_STATE)
                }
              }
            })

            unsubUserDataReadOnly = getDocument('readonly', {userId: user.uid}).onSnapshot((snapshot) => {
              if (snapshot == null) {
                if (!auth.currentUser.isAnonymous) {
                  subsUserId = null
                  unsubAll()
                  auth.signOut()
                }
              } else {
                if (snapshot.exists) {
                  return dispatch.user.setUser(snapshot.data())
                }
              }
            })

            unsubRewardHistory = getCollection('RewardHistory', {userId: user.uid})
              .orderBy('createdAt', 'desc')
              .limit(30)
              .onSnapshot((snapshot) => {
                if (snapshot) {
                  const RewardHistory = []
                  for (const doc of snapshot.docs) {
                    const data = doc.data()
                    data.rewardHistoryId = doc.id
                    RewardHistory.push(data)
                  }
                  return dispatch.user.setUser({RewardHistory})
                }
              })

            unsubOrders = dispatch.user.subscribeOrders()
          } else {
            // User is logged in and already subscribed
          }
        } else {
          subsUserId = null
          unsubAll()
          dispatch.user.setUser(DEFAULT_STATE)
          if (!isSigningInAnonymously) {
            isSigningInAnonymously = true
            auth.signInAnonymously()
          }
        }
      })
    },
    subscribeLocationLastOrder({locationId}) {
      const userId = dispatch.user.getUserId()
      unsubLocationLastOrder && unsubLocationLastOrder()
      unsubLocationLastOrder = getOrdersGroupCreatedAtDesc({locationId, userId, limit: 1}).onSnapshot((snapshot) => {
        if (snapshot) {
          for (const doc of snapshot.docs) {
            const lastUserOrder = doc.data()
            lastUserOrder.id = doc.id
            return dispatch.user.setUser({lastUserOrder})
          }
        }
      })
      return unsubLocationLastOrder
    },
    // <<<<<<<<<<<<<<<<<<< SUBSCRIPTIONS END <<<<<<<<<<<<<<<<<<
  }),
})

export default createUserModel
