import React from 'react'
import {FView, FText} from 'components'

export default function InvalidItemsList({invalidCart, products}) {
  return (
    <FView pt={16} minHeight={100} maxHeight={250} overflowY="scroll">
      {Object.values(invalidCart).map((item) => {
        const productDetails = products[item.productId]
        return (
          <FView key={item.productId} row justifyBetween>
            <FText bold h6>
              {productDetails.name}
            </FText>
            <FText h6>x{item.count}</FText>
          </FView>
        )
      })}
    </FView>
  )
}
