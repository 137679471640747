import React, {useState, useEffect} from 'react'
import {colors} from 'styles'
import {useSelector, useDispatch} from 'react-redux'
import {DialogTitle, Divider} from '@material-ui/core'
import {FButton, FView, FText} from 'components'
import FDialog from './FDialog'

export default function SelectOrderTimeModal({isOpen, setIsOpen}) {
  const dispatch = useDispatch()
  const [availableDaysTimestamps, setAvailableDaysTimestamps] = useState([[]])
  const [selectedDayIndex, setSelectedDayIndex] = useState(0)
  const orderType = useSelector(dispatch.user.getOrderType)

  useEffect(() => {
    if (isOpen) {
      const locationId = dispatch.user.getCartLocationId()
      const waitTime = dispatch.restaurants.getWaitTime({locationId})
      let minutes = dispatch.user.getCartLocationMoment().minutes() + waitTime
      if (minutes < 15) {
        minutes = 15
      } else if (minutes < 30) {
        minutes = 30
      } else if (minutes < 45) {
        minutes = 45
      } else {
        minutes = 60
      }
      const targetTimeMoment = dispatch.user
        .getCartLocationMoment()
        .startOf('hours')
        .add(minutes + (orderType === 'Delivery' ? 30 : 0), 'minutes')

      const orderOpenPeriods = dispatch.restaurants.getOrderOpenPeriods({
        locationId,
        targetTimestamp: targetTimeMoment.valueOf(),
      })
      const deliveryOrderOpenPeriods =
        orderType === 'Delivery'
          ? dispatch.restaurants.getDeliveryOpenPeriods({
              locationId,
              targetTimestamp: targetTimeMoment.valueOf(),
            })
          : []
      const newAvailableDaysTimestamps = [[]]
      let dayIndex = 0
      let dayOfYear = targetTimeMoment.dayOfYear()
      for (let i = 0; i < 384; i++) {
        if (targetTimeMoment.dayOfYear() !== dayOfYear) {
          dayIndex++
          dayOfYear = targetTimeMoment.dayOfYear()
        }
        const targetTimestamp = targetTimeMoment.valueOf()
        let isOpen = false
        // eslint-disable-next-line no-unused-vars
        for (const [openTimestamp, closeTimestamp] of orderOpenPeriods) {
          if (targetTimestamp < openTimestamp) {
            break
          }

          if (targetTimestamp >= openTimestamp && targetTimestamp < closeTimestamp) {
            isOpen = true
            break
          }
        }
        let isDeliveryOpen = false
        if (orderType === 'Delivery') {
          // eslint-disable-next-line no-unused-vars
          for (const [openTimestamp, closeTimestamp] of deliveryOrderOpenPeriods) {
            if (targetTimestamp < openTimestamp) {
              break
            }

            if (targetTimestamp >= openTimestamp && targetTimestamp < closeTimestamp) {
              isDeliveryOpen = true
              break
            }
          }
        }
        if (orderType === 'Delivery' ? isDeliveryOpen && isOpen : isOpen) {
          newAvailableDaysTimestamps[dayIndex]
            ? newAvailableDaysTimestamps[dayIndex].push(targetTimeMoment.valueOf())
            : (newAvailableDaysTimestamps[dayIndex] = [targetTimeMoment.valueOf()])
        }
        targetTimeMoment.add(15, 'minutes')
      }
      setAvailableDaysTimestamps(newAvailableDaysTimestamps)
    }
  }, [dispatch.user, dispatch.restaurants, isOpen, orderType])
  return (
    <FDialog aria-labelledby="auth-modal-title" open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>
        <FText bold h4>
          Schedule {orderType} Time
        </FText>
      </DialogTitle>

      <FView overflowX="hidden" bg={colors.background} outline={0} rounded>
        <Divider />
        <FView h={540} maxHeight={'calc(95vh - 80px)'} overflowY="auto">
          <FView>
            <FView row ph={8}>
              {availableDaysTimestamps.map((dayTimestamp, index) => {
                const orderDayMoment = dispatch.user.getCartLocationMoment(dayTimestamp[0])
                return (
                  <FButton IconButton key={index} onPress={() => setSelectedDayIndex(index)}>
                    <FView center w={50}>
                      <FText alignCenter body1>
                        {orderDayMoment.format('ddd')}
                      </FText>
                      <FView bg={selectedDayIndex === index && colors.primary} br={15} size={30} center>
                        <FText alignCenter bold body1 white={selectedDayIndex === index}>
                          {orderDayMoment.format('D')}
                        </FText>
                      </FView>
                    </FView>
                  </FButton>
                )
              })}
            </FView>
          </FView>
          <Divider />

          {(availableDaysTimestamps[selectedDayIndex] ?? []).map((dayTimestamp) => {
            return (
              <FView key={String(dayTimestamp)} bg={colors.white}>
                <FButton
                  hoverHighlight
                  onPress={() => {
                    setIsOpen(false)
                    dispatch.user.setOrderTimeType('Scheduled')
                    dispatch.user.setScheduledOrderTimestamp(dayTimestamp)
                    const locationId = dispatch.user.getCartLocationId()
                    dispatch.restaurants.updateActiveCategoryOrder({locationId, targetDateTime: dayTimestamp})
                    dispatch.restaurants.updateActiveProducts({locationId})
                  }}>
                  <FView h={60} row alignCenter justifyBetween ph={15}>
                    <FText body1>{dispatch.user.getCartLocationMoment(dayTimestamp).format('LT')}</FText>
                  </FView>
                </FButton>
                <Divider />
              </FView>
            )
          })}
        </FView>
        {/* <Divider />

          <FView center h={100} bg={colors.white}>
            <FButton
              onPress={() => {
                setIsOpen(false)
              }}>
              <ButtonFillView row h={50} w={250} rounded>
                <FText body1 bold primaryContrast>
                  Done
                </FText>
              </ButtonFillView>
            </FButton>
          </FView> */}
      </FView>
    </FDialog>
  )
}
