import React, {useState, useEffect} from 'react'
import {colors} from 'styles'
import {css} from 'emotion'
import {useSelector, useDispatch} from 'react-redux'
import {InfoOutlined, Close, RadioButtonChecked, RadioButtonUncheckedOutlined, EventSeat} from '@material-ui/icons'
import {Modal, Backdrop, Fade, Divider} from '@material-ui/core'
import {FButton, FView, FText, ButtonFillView} from 'components'

export default function SelectTableNumberModal({isOpen, setIsOpen}) {
  const dispatch = useDispatch()
  const locationId = useSelector(dispatch.user.getCartLocationId)
  const availableTables = useSelector(() => dispatch.restaurants.getAvailableTables({locationId}))
  const [selectedTableNumber, setSelectedTableNumber] = useState(dispatch.user.getTableNumber())

  useEffect(() => {
    if (selectedTableNumber && availableTables?.[selectedTableNumber]?.isAvailable === false) {
      setSelectedTableNumber(null)
    }
  }, [availableTables, selectedTableNumber])

  return (
    <Modal
      aria-labelledby="auth-modal-title"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={isOpen}>
        <FView
          w="100vw"
          maxWidth={600}
          h={600}
          maxHeight={'100%'}
          overflowX="hidden"
          bg={colors.background}
          outline={0}
          rounded>
          <FView row alignCenter justifyEnd relative h={80} bg={colors.white}>
            <FView absoluteFill center pointerEvents="none">
              <FText bold h5>
                {'Select Table'}
              </FText>
            </FView>
            <FButton IconButton onClick={() => setIsOpen(false)}>
              <FText black noLineHeight>
                <Close />
              </FText>
            </FButton>
          </FView>
          <Divider />
          <FView h={540} maxHeight={'calc(95vh - 80px)'} overflowY="auto">
            <FView block>
              <FView row p={15} alignCenter bg={colors.white}>
                <InfoOutlined color="action" />
                <FView w={10} />
                <FView fill>
                  <FText body1 grey700>
                    Select the number found at your table.
                  </FText>
                </FView>
              </FView>

              <FView mt={10} h={60} justifyCenter ph={15}>
                <FText body1 grey700>
                  Table Number
                </FText>
              </FView>
              <Divider />
              <FView>
                {Object.values(availableTables).map((table) => {
                  return (
                    <TableNumberButton
                      key={String(table.tableNumber)}
                      item={table}
                      selectedTableNumber={selectedTableNumber}
                      setSelectedTableNumber={setSelectedTableNumber}
                    />
                  )
                })}
              </FView>
            </FView>
          </FView>
          <Divider />

          <FView center h={100} bg={colors.white}>
            <FButton
              onPress={() => {
                if (selectedTableNumber != null) {
                  dispatch.user.setTableNumber(selectedTableNumber)
                }
                setIsOpen(false)
              }}>
              <ButtonFillView row h={50} w={250} rounded>
                <FText body1 bold primaryContrast>
                  {selectedTableNumber != null ? 'Done' : 'Close'}
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </Fade>
    </Modal>
  )
}

function TableNumberButton({item, selectedTableNumber, setSelectedTableNumber}) {
  const isSelected = selectedTableNumber === item.tableNumber
  return (
    <FView bg={colors.white}>
      <FButton
        hoverHighlight
        onPress={() => {
          if (item.isAvailable) {
            setSelectedTableNumber(item.tableNumber)
          }
        }}>
        <FView row h={60} key={item.tableNumber} alignCenter ph={15}>
          <EventSeat color={item.isAvailable ? (isSelected ? 'primary' : 'inherit') : 'action'} />
          <FView row alignCenter justifyBetween fill pl={10}>
            <FText
              body1
              bold
              color={item.isAvailable ? (isSelected ? colors.primary : colors.black) : colors.lightGrey}>
              Table {item.tableNumber} {!item.isAvailable ? ' (Unavailable)' : ''}
            </FText>
            {isSelected && item.isAvailable ? (
              <RadioButtonChecked color="primary" />
            ) : (
              <RadioButtonUncheckedOutlined color={isSelected ? 'action' : 'inherit'} />
            )}
          </FView>
        </FView>
      </FButton>
      <Divider />
    </FView>
  )
}

const classes = {
  modal: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}
