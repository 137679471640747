import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Add, Create} from '@material-ui/icons'
import {useTheme} from '@material-ui/core'
import {FButton, FView, FText} from 'components'
import DeliveryModal from './DeliveryModal'
import {isEqual} from 'lodash'

export default function AddressPicker() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const addresses = useSelector(dispatch.user.getAddresses, isEqual)
  const selectedAddressIndex = useSelector(dispatch.user.getSelectedAddressIndex)
  const [addressInfoOpen, setAddressInfoOpen] = useState(false)
  const [editIndex, setEditIndex] = useState(null)

  return (
    <>
      <FView>
        <FView justifyBetween>
          {addresses.map((address, i) => {
            const isSelected = selectedAddressIndex === i
            const color = isSelected ? theme.palette.primary.main : theme.palette.grey[500]
            return (
              <FView key={i} mv={8} bc={color} bw={2} br={10} row justifyBetween alignCenter>
                <FButton
                  fill
                  onClick={() => {
                    dispatch.user.setSelectedAddressIndex(i)
                    dispatch.user.setUserLocation({
                      latitude: address.containerDeliveryAddressLatLng.lat,
                      longitude: address.containerDeliveryAddressLatLng.lng,
                      city: null,
                      region: null,
                      country: null,
                    })
                  }}>
                  <FView p={16}>
                    <FText color={color} alignLeft>
                      {address.name}
                    </FText>
                    <FText color={color} alignLeft>
                      {address.city}, {address.region}, {address.postalCode}
                    </FText>
                  </FView>
                </FButton>
                <FButton
                  selfStretch
                  hoverHighlight
                  onClick={() => {
                    setEditIndex(i)
                    setAddressInfoOpen(true)
                  }}>
                  <FView w={70} center>
                    <Create color={isSelected ? 'primary' : 'disabled'} />
                  </FView>
                </FButton>
              </FView>
            )
          })}

          <FButton
            fill
            hoverHighlight
            onClick={() => {
              setEditIndex(null)
              setAddressInfoOpen(true)
            }}>
            <FView row justifyBetween alignCenter>
              <FView p={16}>
                <FText alignLeft semiBold>
                  Add new address
                </FText>
              </FView>
              <FView w={70} center>
                <Add />
              </FView>
            </FView>
          </FButton>
        </FView>
      </FView>
      <DeliveryModal
        isOpen={addressInfoOpen}
        editMode={editIndex !== null}
        onRequestClose={() => setAddressInfoOpen(false)}
        selectedAddressIndex={editIndex}
        layered
      />
    </>
  )
}
