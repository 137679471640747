import React, {Component} from 'react'
import {css} from 'emotion'

class FView extends Component {
  state = {}
  render() {
    const {
      children,
      forwardedRef,
      style: customStyle,
      className: customClassName,
      onClick,
      'data-aos': dataAOS,
      tabIndex,
      component,
      ...props
    } = this.props
    // 1. Apply base style
    const style = [styles.baseStyle]

    // 2. Apply customStyle passed down as this.props.style
    style.push(customStyle)
    style.push(customClassName)

    // 3. Apply prop styles
    const keys = Object.keys(props)
    for (const key of keys) {
      if (props[key] === true && styles[key]) {
        style.push(styles[key])
      }
      // Check if key is any of the following
      // ph - padding horizontal
      // pv - padding vertical
      // pl - padding left
      // pr - padding right
      // pt - padding top
      // pb - padding bottom
      // p - padding
      // same for margin but starts with m instead of p
      // w - width
      // h - height
      // bg - backgroundColor
      // br - borderRadius
      // if so then the value is the corresponding style value
      // ex. ph={20} -> style={{paddingHorizontal: 20}}
      // ex. w='100%' -> style={{width: '100%'}}

      switch (key) {
        case 'mh':
          style.push(css({marginLeft: props[key], marginRight: props[key]}))
          break
        case 'mv':
          style.push(css({marginTop: props[key], marginBottom: props[key]}))
          break
        case 'mt':
          style.push(css({marginTop: props[key]}))
          break
        case 'mb':
          style.push(css({marginBottom: props[key]}))
          break
        case 'ml':
          style.push(css({marginLeft: props[key]}))
          break
        case 'mr':
          style.push(css({marginRight: props[key]}))
          break
        case 'm':
          style.push(css({margin: props[key]}))
          break
        case 'ph':
          style.push(css({paddingLeft: props[key], paddingRight: props[key]}))
          break
        case 'pv':
          style.push(css({paddingTop: props[key], paddingBottom: props[key]}))
          break
        case 'pt':
          style.push(css({paddingTop: props[key]}))
          break
        case 'pb':
          style.push(css({paddingBottom: props[key]}))
          break
        case 'pl':
          style.push(css({paddingLeft: props[key]}))
          break
        case 'pr':
          style.push(css({paddingRight: props[key]}))
          break
        case 'p':
          style.push(css({padding: props[key]}))
          break
        case 'w':
          style.push(css({width: props[key]}))
          break
        case 'h':
          style.push(css({height: props[key]}))
          break
        case 'size':
          style.push(css({width: props[key], height: props[key]}))
          break
        case 'maxSize':
          style.push(css({maxWidth: props[key], maxHeight: props[key]}))
          break
        case 'bg':
          style.push(css({backgroundColor: props[key]}))
          break
        case 'br':
          style.push(css({borderRadius: props[key]}))
          break
        case 'blr':
          style.push(css({borderTopLeftRadius: props[key], borderBottomLeftRadius: props[key]}))
          break
        case 'brr':
          style.push(css({borderTopRightRadius: props[key], borderBottomRightRadius: props[key]}))
          break
        case 'btr':
          style.push(css({borderTopLeftRadius: props[key], borderTopRightRadius: props[key]}))
          break
        case 'bbr':
          style.push(css({borderBottomLeftRadius: props[key], borderBottomRightRadius: props[key]}))
          break
        case 'bc':
          style.push(css({border: 'solid', borderColor: props[key]}))
          break
        case 'bw':
          style.push(css({borderWidth: props[key]}))
          break
        case 'bbw':
          style.push(css({borderBottomWidth: props[key]}))
          break
        case 'btw':
          style.push(css({borderTopWidth: props[key]}))
          break
        case 'blw':
          style.push(css({borderLeftWidth: props[key]}))
          break
        case 'brw':
          style.push(css({borderRightWidth: props[key]}))
          break
        case 'cursor':
        case 'top':
        case 'bottom':
        case 'left':
        case 'right':
        case 'maxWidth':
        case 'minWidth':
        case 'minHeight':
        case 'maxHeight':
        case 'backgroundImage':
        case 'backgroundSize':
        case 'zIndex':
        case 'order':
        case 'overflow':
        case 'overflowX':
        case 'overflowY':
        case 'flexDirection':
        case 'pointerEvents':
        case 'opacity':
        case 'transition':
        case 'transform':
        case 'flexWrap':
        case 'outline':
        case 'gridTemplateRows':
        case 'gridTemplateColumns':
        case 'columnGap':
        case 'rowGap':
        case 'gap':
        case 'breakInside':
        case 'columnCount':
        case 'flexGrow':
        case 'flexShrink':
        case 'flexBasis':
          style.push(css({[key]: props[key]}))
          break
        case 'fill':
          if (typeof props[key] === 'number') {
            style.push(css({flex: props[key]}))
          }
          break
        default:
          break
      }
    }

    const Component = component ?? 'div'

    return (
      <Component ref={forwardedRef} className={css(style)} onClick={onClick} data-aos={dataAOS} tabIndex={tabIndex}>
        {children}
      </Component>
    )
  }
}

const styles = {
  baseStyle: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  shadow: css({
    boxShadow: '0 8px 32px rgba(7, 18, 37, 0.2)',
  }),
  shadow75: css({
    boxShadow: '0 6px 24px rgba(7, 18, 37, 0.2)',
  }),
  shadow50: css({
    boxShadow: '0 4px 16px rgba(7, 18, 37, 0.2)',
  }),
  shadow25: css({
    boxShadow: '0 2px 8px rgba(7, 18, 37, 0.2)',
  }),
  shadow10: css({
    boxShadow: '0 1px 4px rgba(7, 18, 37, 0.2)',
  }),
  shadowHover: css({
    boxShadow: '0 2px 8px rgba(7, 18, 37, 0.2)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    ':hover': {
      transform: 'translateX(2px)',
      boxShadow: '0 6px 24px rgba(7, 18, 37, 0.2)',
    },
  }),
  round: css({borderRadius: 8}),

  rounded: css({borderRadius: 4}),
  varelaRound: css({
    fontFamily: 'Varela Round',
  }),
  muliLight: css({
    fontFamily: 'Muli',
    fontWeight: '300',
  }),
  muliBlack: css({
    fontFamily: 'Muli',
    fontWeight: '900',
  }),
  flex: css({
    display: 'flex',
  }),
  none: css({
    display: 'none',
  }),
  block: css({
    display: 'block',
  }),
  grid: css({
    display: 'grid',
  }),
  inline: css({
    display: 'inline',
  }),
  inlineBlock: css({
    display: 'inline-block',
  }),
  row: css({
    flexDirection: 'row',
  }),
  rowReverse: css({
    flexDirection: 'row-reverse',
  }),
  column: css({
    flexDirection: 'column',
  }),
  columnReverse: css({
    flexDirection: 'column-reverse',
  }),
  fill: css({
    flex: 1,
  }),
  flexShrink: css({
    flexShrink: 1,
  }),
  flexGrow: css({
    flexGrow: 1,
  }),
  wrap: css({
    flexWrap: 'wrap',
  }),
  absoluteFill: css({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }),
  relative: css({
    position: 'relative',
  }),
  absolute: css({
    position: 'absolute',
  }),
  fixed: css({
    position: 'fixed',
  }),
  sticky: css({
    position: 'sticky',
  }),
  selfCenter: css({
    alignSelf: 'center',
  }),
  selfStart: css({
    alignSelf: 'flex-start',
  }),
  selfEnd: css({
    alignSelf: 'flex-end',
  }),
  selfStretch: css({
    alignSelf: 'stretch',
  }),
  center: css({
    alignItems: 'center',
    justifyContent: 'center',
  }),
  alignCenter: css({
    alignItems: 'center',
  }),
  alignStart: css({
    alignItems: 'flex-start',
  }),
  alignEnd: css({
    alignItems: 'flex-end',
  }),
  alignStretch: css({
    alignItems: 'stretch',
  }),
  justifyCenter: css({
    justifyContent: 'center',
  }),
  justifyBetween: css({
    justifyContent: 'space-between',
  }),
  justifyAround: css({
    justifyContent: 'space-around',
  }),
  justifyEvenly: css({
    justifyContent: 'space-evenly',
  }),
  justifyEnd: css({
    justifyContent: 'flex-end',
  }),
  pointer: css({
    cursor: 'pointer',
  }),
  heading: css({
    fontFamily: 'futura',
  }),
  heading2: css({
    fontFamily: 'antonio',
  }),
  content: css({
    fontFamily: 'roboto',
  }),
  dafoe: css({
    fontFamily: 'dafoe',
  }),
  din: css({
    fontFamily: 'din',
  }),
  tech: css({
    fontFamily: 'tech',
  }),
  animateOnHover: css({
    ':hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
}

export default React.forwardRef((props, ref) => <FView forwardedRef={ref} {...props} />)
