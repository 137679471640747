import React from 'react'
import {Provider} from 'react-redux'
import {store} from 'store'
import {createMuiTheme, responsiveFontSizes, ThemeProvider} from '@material-ui/core/styles'
import AppRouter from './AppRouter'
import {foodlyTheme} from 'f-web'
import {css} from 'emotion'
import {Helmet} from 'react-helmet'

const theme = responsiveFontSizes(createMuiTheme(foodlyTheme))

export default function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>Food-Friendly | Foodly</title>
            <meta
              name="description"
              content="Foodly - Foodly is the best online food ordering app in Metro Vancouver. Feeling hungry? Then order from Foodly! Earn Points on every order and get FREE FOOD!."
              data-react-Helmet="true"
            />
          </Helmet>
          <AppRouter />
        </ThemeProvider>
      </Provider>
      {process.env.REACT_APP_ENVIRONMENT === 'dev' && <div className={classes.divDev}>Development Build</div>}
    </>
  )
}

const classes = {
  divDev: css({
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 0,
    right: 0,
    height: 10,
    backgroundColor: 'pink',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}
