/*
  Please read Firestore Guide before making changes to firestore related queries
  https://bitbucket.org/foodlytech/foodly-guide/src/master/firestore.md
*/

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'

const isMobile = false

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(config)

// process.env.REACT_APP_ENVIRONMENT === 'dev' && firebase.firestore.setLogLevel('debug')

const firebaseAnalytics = firebase.analytics()
const firestore = firebase.firestore()
const firebaseAuth = firebase.auth()
const functions = firebase.functions()

export const FacebookAuthProvider = firebase.auth.FacebookAuthProvider
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier
export const OAuthProvider = firebase.auth.OAuthProvider
export const Timestamp = firebase.firestore.Timestamp
export const EmailAuthProvider = firebase.auth.EmailAuthProvider
export const PhoneAuthProvider = firebase.auth.PhoneAuthProvider

const auth = firebaseAuth
const db = firestore

const sendReport = functions.httpsCallable('sendReport')
const cancelVopayTransaction = functions.httpsCallable('cancelVopayTransaction')
const getSquareTokens = functions.httpsCallable('getSquareTokens')
const revokeSquareTokens = functions.httpsCallable('revokeSquareTokens')
const getSquareLocations = functions.httpsCallable('getSquareLocations')

const getCollection = (collectionName, params) => {
  switch (collectionName) {
    case 'DeliveryInfo':
    case 'Locations':
    case 'Orders':
    case 'Private':
      return db.collection('Restaurants').doc(params.restaurantId).collection(collectionName)
    case 'Accounts':
    case 'Email':
    case 'Analytics':
    case 'Payments':
    case 'Payouts':
    case 'PointOfSales':
    case 'Public':
    case 'Refunds':
    case 'Regions':
    case 'Restaurants':
    case 'Schedules':
    case 'Stats':
    case 'TransferReversals':
    case 'Transfers':
    case 'Translations':
    case 'Users':
      return db.collection(collectionName)
    case 'Categories':
    case 'LocationPrivate':
    case 'ModifierGroups':
    case 'MonthlyRevenues':
    case 'Products':
    case 'Rewards':
    case 'Reviews':
      return db
        .collection('Restaurants')
        .doc(params.restaurantId)
        .collection('Locations')
        .doc(params.locationId)
        .collection(collectionName)
    case 'UserData':
      return db.collection('Users').doc(params.userId).collection(collectionName)
    case 'RewardHistory':
      return getDocument('readonly', params).collection(collectionName)
    default:
      throw new Error('Unsupported collectionName: ' + collectionName)
  }
}

function getDocument(docId, params) {
  switch (docId) {
    case 'public':
      return getCollection('Public').doc(docId)
    case 'private':
    case 'admin-readwrite':
      return getCollection('Private', params).doc(docId)
    case 'readwrite':
    case 'readonly':
      return getCollection('UserData', params).doc(docId)
    case 'locationPrivate':
      return getCollection('LocationPrivate', params).doc(docId)
    default:
      throw new Error('Unsupported docId: ' + docId)
  }
}

const getCollectionGroup = (collectionName) => db.collectionGroup(collectionName)

function addArrayContainsChecks(query, arrayFields) {
  let newQuery = query
  for (const [key, value] of Object.entries(arrayFields)) {
    if (Array.isArray(value) && value.length > 0) {
      newQuery = newQuery.where(key, 'array-contains-any', value)
    } else if (value !== undefined) {
      newQuery = newQuery.where(key, 'array-contains', value)
    }
  }
  return newQuery
}

function addEqualityComparisons(query, fields) {
  let newQuery = query
  for (const [key, value] of Object.entries(fields)) {
    if (Array.isArray(value) && value.length > 0) {
      newQuery = newQuery.where(key, 'in', value)
    } else if (value !== undefined) {
      newQuery = newQuery.where(key, '==', value)
    }
  }
  return newQuery
}

function addRangeComparison(query, name, min, max) {
  if (min != null && max != null && min > max) {
    console.warn('min cannot be greater than max: ' + name)
    return query
  }
  let newQuery = query
  if (min != null) {
    newQuery = newQuery.where(name, '>=', min)
  }
  if (max != null) {
    newQuery = newQuery.where(name, '<=', max)
  }
  return newQuery
}

function addLimit(query, limit) {
  if (limit >= 0) {
    return query.limit(limit)
  }
  return query
}

const getReviewsCreatedAtDesc = ({ restaurantId, locationId, createdAt, createdAtMin, createdAtMax, limit }) => {
  let query = getCollection('Reviews', { restaurantId, locationId })
  query = addEqualityComparisons(query, { createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'desc')
}

// CollectionGroup: Locations
// Index: isVisible
const getLocationsGroup = (params) => {
  const { isVisible, limit } = params
  let query = getCollectionGroup('Locations')
  query = addEqualityComparisons(query, { isVisible })
  query = addLimit(query, limit)
  return query
}

// CollectionGroup: DeliveryInfo
// Index: deliveryStatus, riderUserId, createdAt
const getDeliveryInfoGroup = (params) => {
  const { deliveryStatus, riderUserId, createdAt, createdAtMin, createdAtMax, limit } = params
  let query = getCollectionGroup('DeliveryInfo')
  query = addEqualityComparisons(query, { deliveryStatus, riderUserId, createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query
}

// CollectionGroup: DeliveryInfo
// Index: riderUserId, createdAt
// OrderBy: createdAt desc
const getDeliveryInfoGroupCreatedAtDesc = ({ riderUserId, createdAt, createdAtMin, createdAtMax, limit }) => {
  let query = getCollectionGroup('DeliveryInfo')
  query = addEqualityComparisons(query, { riderUserId, createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'desc')
}

// Collection: Schedules
const getSchedules = ({ startAtMin, startAtMax, limit }) => {
  let query = getCollection('Schedules')
  query = addRangeComparison(query, 'start', startAtMin, startAtMax)
  query = addLimit(query, limit)
  return query
}
// Collection: Translations
// OrderBy: createdAt desc
// const getTranslations = ({limit}) => {
//   let query = getCollection('Translations')
//   query =
// }

// Collection: Orders
// Index: locationId, createdAt
// OrderBy: createdAt asc
const getOrdersCreatedAtAsc = ({ restaurantId, locationId, createdAt, createdAtMin, createdAtMax, limit }) => {
  let query = getCollection('Orders', { restaurantId })
  query = addEqualityComparisons(query, { locationId, createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'asc')
}

const getReports = ({ locationId, limit = 50 }) => {
  let query = getCollection('Email')
  query = addEqualityComparisons(query, { locationId, 'template.name': 'report' })
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'desc')
}

const resendReport = ({ emailId }) => {
  return getCollection('Email').doc(emailId).update({ 'delivery.state': 'RETRY' })
}

// Collection: Orders
// Index: locationId, userId, createdAt, status
// OrderBy: createdAt desc
const getOrdersCreatedAtDesc = (params) => {
  const { restaurantId, locationId, userId, createdAt, status, createdAtMin, createdAtMax, limit } = params
  let query = getCollection('Orders', { restaurantId })
  query = addEqualityComparisons(query, { locationId, userId, status, createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'desc')
}

// Collection: Transfers
// Index: createdAt, destinationAccountId, sourceAccountId
// OrderBy: createdAt desc
const getTransfersCreatedAtDesc = (params) => {
  const { destinationAccountId, sourceAccountId, createdAt, createdAtMin, createdAtMax, limit } = params
  let query = getCollection('Transfers')
  query = addEqualityComparisons(query, { destinationAccountId, sourceAccountId, createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'desc')
}

// Collection: Payouts
// Index: locationId, restaurantId, createdAt
// OrderBy: createdAt desc
const getPayoutsCreatedAtDesc = (params) => {
  const { accountId, createdAt, createdAtMin, createdAtMax, limit } = params
  let query = getCollection('Payouts')
  query = addEqualityComparisons(query, { accountId, createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'desc')
}

// CollectionGroup: Orders
// Index: locationId, userId, status, createdAt
const getOrdersGroupCreatedAtDesc = ({ locationId, userId, status, createdAt, createdAtMin, createdAtMax, limit }) => {
  if (!locationId && !userId && !status) {
    console.warn('You must create index "Orders, createdAt Descending, Collection Group"')
  }

  let query = getCollectionGroup('Orders')
  query = addEqualityComparisons(query, { locationId, userId, status, createdAt })
  query = addRangeComparison(query, 'createdAt', createdAtMin, createdAtMax)
  query = addLimit(query, limit)
  return query.orderBy('createdAt', 'desc')
}

// CollectionGroup: Private
// Index: deposit
// OrderBy: deposit desc
const getPrivateGroupDepositDesc = ({ deposit, depositMin, depositMax, limit } = {}) => {
  let query = getCollectionGroup('Private')
  query = addEqualityComparisons(query, { deposit })
  query = addRangeComparison(query, 'deposit', depositMin, depositMax)
  query = addLimit(query, limit)
  return query.orderBy('deposit', 'desc')
}

const DELETE_FIELD_VALUE = firebase.firestore.FieldValue.delete()
const FieldValue = firebase.firestore.FieldValue

export function subscribeCollection(collectionRef, setData, filter) {
  return collectionRef.onSnapshot((snap) => {
    const collectionData = {}
    snap.forEach((doc) => {
      const docData = doc.data()
      if (filter && !filter(docData)) {
        return
      }
      collectionData[doc.id] = docData
      collectionData[doc.id].id = doc.id
    })
    setData(collectionData)
  })
}

export {
  auth,
  isMobile,
  FieldValue,
  DELETE_FIELD_VALUE,
  getCollection,
  getCollectionGroup,
  getDeliveryInfoGroup,
  getDeliveryInfoGroupCreatedAtDesc,
  getDocument,
  getLocationsGroup,
  getOrdersCreatedAtAsc,
  getOrdersCreatedAtDesc,
  getOrdersGroupCreatedAtDesc,
  getPayoutsCreatedAtDesc,
  getPrivateGroupDepositDesc,
  getReports,
  getReviewsCreatedAtDesc,
  getSchedules,
  getTransfersCreatedAtDesc,
  resendReport,
  sendReport,
  cancelVopayTransaction,
  getSquareTokens,
  getSquareLocations,
}

// ==================== Deprecated ===================================================================
const getRestaurantsCollection = () => firestore.collection('Restaurants')
const getRestaurantDoc = ({ restaurantId }) => firestore.collection('Restaurants').doc(restaurantId)
const getRestaurantLocationsCollection = ({ restaurantId }) =>
  firestore.collection('Restaurants').doc(restaurantId).collection('Locations')
const getRestaurantLocationDoc = ({ restaurantId, locationId }) =>
  firestore.collection('Restaurants').doc(restaurantId).collection('Locations').doc(locationId)
const getRestaurantOrdersCollection = ({ restaurantId }) =>
  firestore.collection('Restaurants').doc(restaurantId).collection('Orders')
const getRestaurantOrderDoc = ({ restaurantId, orderId }) =>
  firestore.collection('Restaurants').doc(restaurantId).collection('Orders').doc(orderId)
const getUsersCollection = () => firestore.collection('Users')
const getUserDoc = ({ userId }) => firestore.collection('Users').doc(userId)
const getUserRestaurantDoc = ({ userId, restaurantId }) =>
  firestore.collection('Users').doc(userId).collection('Restaurants').doc(restaurantId)
export {
  firebaseAuth,
  firebaseAnalytics,
  db,
  getRestaurantDoc,
  getRestaurantLocationDoc,
  getRestaurantLocationsCollection,
  getRestaurantOrderDoc,
  getRestaurantOrdersCollection,
  getRestaurantsCollection,
  getUserDoc,
  getUserRestaurantDoc,
  getUsersCollection,
  revokeSquareTokens,
}
// ====================================================================================================
