async function authFetch({authToken, url, method, ...restParams}) {
  const response = await fetch(url, {
    method: method || 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(restParams),
  })
  const text = await response.text()
  let json
  try {
    json = JSON.parse(text)
  } catch (e) {
    if (response.ok) {
      return text
    } else {
      throw new Error(text)
    }
  }
  if (response.ok) {
    return json
  } else {
    const err = new Error(json.message)
    err.statusCode = response.status
    err.statusText = response.statusText
    throw err
  }
}

export async function createOrderAndCharge(serverUrl, authToken, restaurantId, locationId, order) {
  return await authFetch({
    authToken,
    url: `${serverUrl}/api/create_order`,
    ...order,
    restaurantId,
    locationId,
  })
}

export async function createCustomerWithStripe(serverUrl, authToken, cardToken) {
  return await authFetch({
    authToken,
    url: `${serverUrl}/api/create_customer`,
    cardToken,
  })
}

export async function addCardWithCC({card, serverUrl, authToken, brand, customerId}) {
  const {number, cvc, exp_month, exp_year, address_zip} = card

  return await authFetch({
    authToken,
    method: 'POST',
    url: `${serverUrl}/_api/v2/add_card_with_cc_v2`,
    pan: number,
    expdate: String(exp_year).slice(2, 4) + ('0' + exp_month).slice(-2),
    cvd_value: cvc,
    avs_zipcode: address_zip,
    brand,
    customerId,
  })
}

export async function addCardToVaultWithCC({card, serverUrl, authToken}) {
  const {number, cvc, exp_month, exp_year, address_zip} = card

  return await authFetch({
    authToken,
    method: 'POST',
    url: `${serverUrl}/_api/v2/add_card_with_cc`,
    pan: number,
    expdate: String(exp_year).slice(2, 4) + ('0' + exp_month).slice(-2),
    cvd_value: cvc,
    avs_zipcode: address_zip,
  })
}

export async function linkAccountToUser({authToken, serverUrl, email, password}) {
  return await authFetch({
    authToken,
    method: 'POST',
    url: `${serverUrl}/api/link_account_to_user`,
    email,
    password,
  })
}

export async function unlinkPhoneNumberFromAuth({authToken, serverUrl, phoneNumber}) {
  return await authFetch({
    authToken,
    method: 'POST',
    url: `${serverUrl}/api/unlink_phone_number_from_auth`,
    phoneNumber,
  })
}
