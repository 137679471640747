import React from 'react'
import {useTheme} from '@material-ui/core/styles'

import {FText, FView} from 'components'

export default function SelectModifierItems({selectedModifierGroupsWithDetails, product, cartItemId}) {
  const theme = useTheme()

  return selectedModifierGroupsWithDetails?.map((modifierGroupDetails) => {
    const ModifierItemsElement = []
    Object.entries(modifierGroupDetails.selectedModifierItemsObj)
      .sort((firstModifier, secondModifier) => {
        return firstModifier[0] < secondModifier[0] ? -1 : 1
      })
      .forEach(([itemId, isSelected]) => {
        if (!modifierGroupDetails.modifierItems[itemId]) {
          return
        }
        const {name: optionName, price: optionPrice, defaultValue} = modifierGroupDetails.modifierItems[itemId]
        if (defaultValue === isSelected) {
          return
        }
        let isNegative = optionPrice < 0
        if (!isSelected) {
          isNegative = !isNegative
        }
        ModifierItemsElement.push(
          <FView key={itemId} row fill>
            <FView mr={4}>
              <FText color={theme.palette.grey[700]} body2>{`• ${!isSelected ? 'No ' : ''}${optionName}`}</FText>
            </FView>
            {optionPrice !== 0 && (
              <FText color={theme.palette.grey[700]} body2>
                ({isNegative ? '-' : ''}${Math.abs(optionPrice * product.count).toFixed(2)})
              </FText>
            )}
          </FView>,
        )
      })
    return (
      <FView key={cartItemId + modifierGroupDetails.id} alignStart>
        {ModifierItemsElement.length > 0 && (
          <FText color={theme.palette.grey[700]} bold body2>
            {modifierGroupDetails.title}
          </FText>
        )}
        {ModifierItemsElement}
      </FView>
    )
  })
}
