import React from 'react'
import {FView, FText} from 'components'
import {colors} from 'styles'

const ItemView = ({imageUrl, restaurantName, restaurantType, name}) => {
  return (
    <FView alignCenter bg={colors.white} w={315}>
      {imageUrl ? (
        <img
          src={imageUrl}
          style={{objectFit: 'cover', width: 315, height: 210, backgroundColor: colors.xLightGrey}}
          alt={restaurantName}
        />
      ) : (
        <FView w={315} h={210} bg={colors.xLightGrey} />
      )}
      <FView size={10} />
      <FText bold h6>
        {restaurantName}
      </FText>
      <FView size={5} />
      <FText body1 grey700>
        {restaurantType}
      </FText>
      <FView size={15} />
      <FView ph={10}>
        <FText body1 alignCenter>
          {name}
        </FText>
      </FView>
      <FView size={25} />
    </FView>
  )
}

export default ItemView
