import React, {useState} from 'react'
import {FButton, FText, FView, ExpandMoreRotate, NavBarV2} from 'components'
import {colors} from 'styles'
import PaymentInformation from './PaymentInformation'
import AccountDetails from './AccountDetails'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {Divider, Collapse, CircularProgress} from '@material-ui/core'
import {ExitToApp, Person, CreditCard, Room, Toll} from '@material-ui/icons'
import {kebabCase} from 'lodash'
import AddressPicker from 'components/AddressPicker'

export default function Account({directOrder}) {
  const [isPaymentOpen, setIsPaymentOpen] = useState(false)
  const [isAccountDetailsOpen, setIsAccountDetailsOpen] = useState(false)
  const [isDeliveryAddressOpen, setIsDeliveryAddressOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isUserLoggedIn = useSelector(dispatch.user.getIsUserLoggedIn)
  const isUserLoading = useSelector(dispatch.user.getIsUserLoading)
  const {restaurantId, locationId} = useParams()
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId}))
  const fPoints = useSelector(dispatch.user.getFPoints)
  return (
    <FView overflowY="auto" overflowX="hidden" minHeight={'100vh'}>
      <NavBarV2 directOrder={directOrder} />
      <FView size={25} />
      <FView bg={colors.white} w={425} maxWidth={'100%'} selfCenter mb={120}>
        <FView h={80} justifyCenter p={15}>
          <FText h5 bold>
            Account
          </FText>
        </FView>
        <Divider />
        <FView pv={16} fill row alignCenter justifyBetween ph={15}>
          <FView row alignCenter>
            <Toll /> <FView size={10} />
            <FText body1 bold>
              My Foodly Points
            </FText>
          </FView>
          <FView>
            <FText primary bold>
              {fPoints.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
            </FText>
          </FView>
        </FView>
        <Divider />

        <FButton
          h={60}
          hoverHighlight
          onClick={() => {
            setIsPaymentOpen(!isPaymentOpen)
          }}>
          <AccountItemView IconElement={<CreditCard />} label="Payment Information" isOpen={isPaymentOpen} />
        </FButton>
        <Collapse in={isPaymentOpen}>{isUserLoading ? <CircularProgress /> : <PaymentInformation />}</Collapse>
        <Divider />
        <FButton
          h={60}
          hoverHighlight
          onClick={() => {
            setIsAccountDetailsOpen(!isAccountDetailsOpen)
          }}>
          <AccountItemView IconElement={<Person />} label="Account Details" isOpen={isAccountDetailsOpen} />
        </FButton>
        <Collapse in={isAccountDetailsOpen}>{isUserLoading ? <CircularProgress /> : <AccountDetails />}</Collapse>
        <Divider />
        <FButton
          h={60}
          hoverHighlight
          onClick={() => {
            setIsDeliveryAddressOpen(!isDeliveryAddressOpen)
          }}>
          <AccountItemView IconElement={<Room />} label="Delivery Address" isOpen={isDeliveryAddressOpen} />
        </FButton>
        <Collapse in={isDeliveryAddressOpen}>
          {isUserLoading ? (
            <CircularProgress />
          ) : (
            <FView ph={32}>
              <AddressPicker />
            </FView>
          )}
        </Collapse>
        <Divider />

        <FView h={20} />
        {isUserLoggedIn && (
          <FButton
            h={60}
            hoverShadow
            onClick={async () => {
              await dispatch.user.signOut()
              if (directOrder) {
                navigate(
                  `/direct/order/${
                    kebabCase(locationData?.restaurantName) || 'restaurant'
                  }/${restaurantId}/${locationId}`,
                  {replace: true},
                )
              } else {
                navigate('/', {replace: true})
              }
            }}>
            <FView fill row alignCenter ph={10}>
              <ExitToApp />
              <FView size={10} />
              <FText body1 bold>
                Sign Out
              </FText>
            </FView>
          </FButton>
        )}
      </FView>
    </FView>
  )
}

const AccountItemView = ({IconElement, label, isOpen}) => {
  return (
    <FView fill row alignCenter justifyBetween ph={15}>
      <FView row alignCenter>
        {IconElement}
        <FView size={10} />
        <FText body1 bold>
          {label}
        </FText>
      </FView>
      <ExpandMoreRotate expanded={isOpen} />
    </FView>
  )
}
