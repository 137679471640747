import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {FButton, FText, FView} from 'components'
import {Add} from '@material-ui/icons'
import {Divider} from '@material-ui/core'
import PaymentItem from './PaymentItem'
import AddPaymentDialog from './AddPaymentDialog'

export default function PaymentInformation() {
  const dispatch = useDispatch()
  const payments = useSelector(dispatch.user.getPayments)
  const selectedPaymentIndex = useSelector(dispatch.user.getSelectedPaymentIndex)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState(null)

  return (
    <>
      <FView pt={0} ph={16} pb={0}>
        <FView pv={10}>
          <FText body1 grey800>
            Credit/Debit Cards
          </FText>
        </FView>
        <Divider />
        {payments.map((payment, index) => {
          return (
            <FView key={index}>
              <PaymentItem
                index={index}
                isSelected={selectedPaymentIndex === index}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                last4={payment.maskedPan.slice(-4)}
                expdate={payment.expdate}
                onPress={() => {}}
              />
              <Divider />
            </FView>
          )
        })}
        <FButton onClick={() => setAddModalOpen(true)}>
          <FView row pv={15}>
            <Add />
            <FView size={5} />
            <FText bold>Add Credit/Debit Card</FText>
          </FView>
        </FButton>
      </FView>
      <AddPaymentDialog open={addModalOpen} handleClose={() => setAddModalOpen(false)} />
    </>
  )
}
