import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Place, Alarm, CreditCard, Phone} from '@material-ui/icons'
import {useTheme, Divider, Checkbox, TextField, RadioGroup, Radio, FormControlLabel} from '@material-ui/core'
import {FButton, FView, FText, SelectOrderTimeModal} from 'components'
import * as utils from 'f-utils'
import CheckoutMap from './CheckoutMap'
import {useFoodlyAppCart} from 'f-app-models/hooks'
import {useMediaMinMD, useMediaMinSM} from 'f-web/src/hooks'
import {kebabCase} from 'lodash'
import {css} from 'emotion'

function CheckoutInfo({directOrder, setDialogOpen}) {
  const theme = useTheme()
  const isMinMD = useMediaMinMD()
  const isMinSM = useMediaMinSM()
  const dispatch = useDispatch()

  // GENERAL
  const cartLocationId = useSelector(dispatch.user.getCartLocationId)
  const locationData = useSelector(() => dispatch.restaurants.getLocation({locationId: cartLocationId}))
  const {address: locationAddress, restaurantName, restaurantIconUrl, restaurantId, locationId} = locationData ?? {}
  const userAddressData = useSelector(dispatch.user.getAddressData)

  // Delivery

  // MAP
  const isPickupAvailable = useSelector(() => dispatch.restaurants.getIsPickupAvailable({locationId: cartLocationId}))
  const isDeliveryAvailable = useSelector(() =>
    dispatch.restaurants.getIsDeliveryAvailable({locationId: cartLocationId}),
  )

  // Time
  const orderType = useSelector(dispatch.user.getOrderType)
  const orderTimeType = useSelector(dispatch.user.getOrderTimeType)
  const scheduledOrderTimestamp = useSelector(dispatch.user.getScheduledOrderTimestamp)
  const {cartWaitTime} = useFoodlyAppCart()
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false)

  // Payment
  const payments = useSelector(dispatch.user.getPayments)
  const paymentMethod = useSelector(dispatch.user.getPaymentMethod)
  const selectedPaymentIndex = useSelector(dispatch.user.getSelectedPaymentIndex)
  const payment = payments?.[selectedPaymentIndex ?? 0]

  // Contact
  const customerName = useSelector(dispatch.user.getName)
  const customerPhoneNumber = useSelector(dispatch.user.getPhoneNumber)

  // Additional
  const notes = useSelector(dispatch.user.getDefaultNotes)

  return (
    <FView w={isMinMD ? '50%' : '100%'}>
      <SelectOrderTimeModal isOpen={scheduleDialogOpen} setIsOpen={setScheduleDialogOpen} />

      <FButton
        selfStart
        hoverHighlight
        to={
          directOrder
            ? `/direct/order/${kebabCase(restaurantName) || 'restaurant'}/${restaurantId}/${locationId}`
            : `/${kebabCase(restaurantName) || 'restaurant'}/restaurant/${restaurantId}/location/${locationId}`
        }>
        <FView row alignCenter mh={isMinMD ? 0 : 16}>
          <img className={classes.restaurantIcon} alt={restaurantName} src={restaurantIconUrl} />
          <FView ml={16}>
            <FText h4 bold>
              {restaurantName}
            </FText>
            <FText color={theme.palette.grey[500]} bold>
              {locationAddress}
            </FText>
          </FView>
        </FView>
      </FButton>

      <FView mt={isMinMD ? 32 : 16} h={256} relative>
        <CheckoutMap />
        <FView zIndex={3} absolute left={10} top={10} row ph={4} pv={4} br={24} bg="white">
          {isDeliveryAvailable && (
            <FButton onClick={() => orderType !== 'Delivery' && dispatch.user.setOrderType({orderType: 'Delivery'})}>
              <FView center br={24} w={92} h={40} bg={orderType === 'Delivery' ? theme.palette.primary.main : 'white'}>
                <FText body1 color={orderType === 'Delivery' ? 'white' : theme.palette.grey[500]} bold>
                  Delivery
                </FText>
              </FView>
            </FButton>
          )}
          {isPickupAvailable && (
            <FButton onClick={() => orderType !== 'Pickup' && dispatch.user.setOrderType({orderType: 'Pickup'})}>
              <FView center br={24} w={92} h={40} bg={orderType === 'Pickup' ? theme.palette.primary.main : 'white'}>
                <FText body1 color={orderType === 'Pickup' ? 'white' : theme.palette.grey[500]} bold>
                  Pickup
                </FText>
              </FView>
            </FButton>
          )}
        </FView>
      </FView>
      <FView mh={isMinMD ? 0 : 16}>
        <InfoView
          onEditPress={orderType === 'Delivery' ? () => setDialogOpen('delivery') : null}
          Icon={<Place />}
          name={orderType === 'Delivery' ? 'Delivery Address' : 'Store Address'}>
          {orderType === 'Delivery' ? (
            <FView>
              {userAddressData?.containerDeliveryAddress && <FText>{userAddressData?.containerDeliveryAddress}</FText>}
              {!!userAddressData?.containerDeliveryUnit && (
                <FText grey600>Unit: {userAddressData?.containerDeliveryUnit}</FText>
              )}
              {(!!userAddressData?.contactlessDeliveryType || !!userAddressData?.containerDeliveryInstructions) && (
                <FText grey600>
                  {userAddressData?.contactlessDeliveryType === 'MEET_COURIER_OUTSIDE'
                    ? 'Meet Courier Outisde. '
                    : userAddressData?.contactlessDeliveryType === 'LEAVE_AT_LOCATION'
                    ? 'Leave at ' + userAddressData?.contactlessDeliveryLocation + '. '
                    : ''}
                  {userAddressData?.containerDeliveryInstructions}
                </FText>
              )}
            </FView>
          ) : (
            <FText>{locationAddress}</FText>
          )}
        </InfoView>
        <InfoView Icon={<Alarm />} name={orderType + ' Time'}>
          <RadioGroup
            name="Order Time"
            value={orderTimeType}
            onChange={(e) => {
              dispatch.user.setOrderTimeType(e.target.value)
              dispatch.restaurants.updateActiveCategoryOrder({locationId})
              dispatch.restaurants.updateActiveProducts({locationId})
            }}>
            <FormControlLabel
              value="ASAP"
              control={<Radio color="primary" />}
              label={`ASAP (${cartWaitTime} - ${cartWaitTime + 10} min)`}
            />
            <FormControlLabel
              value="Scheduled"
              control={<CustomRadio setScheduleDialogOpen={setScheduleDialogOpen} />}
              label={
                `Scheduled` +
                (scheduledOrderTimestamp && orderTimeType === 'Scheduled'
                  ? ` (${utils.moment(scheduledOrderTimestamp).format('ddd, MMM D, LT')})`
                  : '')
              }
            />
          </RadioGroup>
        </InfoView>

        <InfoView Icon={<Phone />} name={'Contact Info'} onEditPress={() => setDialogOpen('contact')}>
          <FView>
            <FText>{customerName}</FText>
            <FText color={theme.palette.grey[500]}>{utils.formatPhoneNumber(customerPhoneNumber)}</FText>
          </FView>
        </InfoView>
        <InfoView Icon={<CreditCard />} name={'Payment'} onEditPress={() => setDialogOpen('payment')}>
          {paymentMethod === 'inperson-cash' ? (
            <FView>
              <FText primary>Paying With Cash </FText>
            </FView>
          ) : (
            payment && (
              <FView row alignCenter>
                <CreditCard color="primary" />
                <FView w={8} />
                <FView>
                  <FText primary>
                    Ending in{' '}
                    {payment.maskedPan.slice(payment.maskedPan.lastIndexOf('*') + 1, payment.maskedPan.length)}
                  </FText>
                  <FText color={theme.palette.grey[500]} subtitle2>
                    Expires {payment.expdate.slice(2, 4) + '/' + payment.expdate.slice(0, 2)}
                  </FText>
                </FView>
              </FView>
            )
          )}
        </InfoView>
      </FView>

      <FView h={32} />
      <FView mh={isMinMD ? 0 : 16}>
        <FView flexDirection={isMinSM ? 'row' : 'column'} justifyBetween>
          <IncludeUtensils />
          <FView size={16} />
          <AddPromoCode locationId={locationId} restaurantId={restaurantId} />
        </FView>
        <FView h={24} />
        <FView>
          <TextField
            defaultValue={notes}
            onBlur={(e) => dispatch.user.setDefaultNotes(e.target.value)}
            multiline
            label="Add a special request"
            inputProps={{maxLength: 256}}
            variant="filled"
          />
        </FView>
      </FView>
      {isMinMD && <FView h={64} />}
    </FView>
  )
}

const EditButton = ({onPress}) => {
  const theme = useTheme()

  return (
    <FButton onPress={onPress}>
      <FView bg={theme.palette.grey[100]} ph={16} pv={12} br={8}>
        <FText grey500 bold>
          Edit
        </FText>
      </FView>
    </FButton>
  )
}

const InfoView = ({Icon, name, children, onEditPress}) => {
  return (
    <FView mt={32}>
      <FView row alignCenter justifyBetween mb={8}>
        <FView row alignCenter>
          {Icon}
          <FView w={8} />
          <FText bold h5>
            {name}
          </FText>
        </FView>
        {onEditPress && <EditButton onPress={onEditPress} />}
      </FView>
      <FView row justifyBetween alignEnd>
        {children}
      </FView>
      <FView h={16} />
      <Divider />
    </FView>
  )
}

const CustomRadio = (props) => {
  const {setScheduleDialogOpen, ...other} = props

  return (
    <FButton onClick={() => setScheduleDialogOpen(true)}>
      <Radio {...other} color="primary" />
    </FButton>
  )
}

const IncludeUtensils = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const includeUtensils = useSelector(dispatch.user.getIncludeUtensils)

  return (
    <FButton fill onClick={() => dispatch.user.setIncludeUtensils(!includeUtensils)}>
      <FView row bg={theme.palette.grey[100]} justifyBetween alignCenter pv={7} pl={16} pr={8}>
        <FText semiBold>Request utensils</FText>
        <Checkbox checked={includeUtensils} color="primary" />
      </FView>
    </FButton>
  )
}
const AddPromoCode = ({locationId, restaurantId}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [promoCode, setPromoCode] = useState('')

  return (
    <FView row>
      <TextField
        value={promoCode}
        onChange={(e) => setPromoCode(e.target.value)}
        label="Add promo code"
        inputProps={{maxLength: 30}}
        variant="filled"
      />
      <FView ph={8} justifyCenter>
        <FButton
          disabled={!promoCode}
          onClick={async () => {
            try {
              const promoItems = dispatch.user.getAllPromosWithDetails(locationId)
              if (promoItems[promoCode]) {
                throw new Error('Promo has already been applied')
              }
              await dispatch.user.addPromo({code: promoCode, locationId, restaurantId})

              setPromoCode('')
            } catch (e) {
              alert(e.message)
            }
          }}>
          <FView center round fill bg={promoCode ? theme.palette.primary.main : theme.palette.grey[400]} ph={8}>
            <FText bold white body2>
              Apply
            </FText>
          </FView>
        </FButton>
      </FView>
    </FView>
  )
}

const classes = {
  restaurantIcon: css({
    width: 56,
    height: 56,
    borderRadius: 28,
    objectFit: 'cover',
  }),
}

export default CheckoutInfo
