import {useEffect} from 'react'
import * as utils from 'f-utils'
import {useSelector, useDispatch} from 'react-redux'

export default function useFoodlyAppCart() {
  const dispatch = useDispatch()

  const locationId = useSelector(dispatch.user.getCartLocationId)
  const deliveryMinimumSubTotal = useSelector(() => dispatch.restaurants.getDeliveryMinimumSubTotal({locationId}))
  const deliveryFreeMinimumSubTotal = useSelector(() =>
    dispatch.restaurants.getDeliveryFreeMinimumSubTotal({locationId}),
  )
  const deliveryFeeDiscountMinimumSubTotal = useSelector(() =>
    dispatch.restaurants.getDeliveryFeeDiscountMinimumSubTotal({locationId}),
  )
  const deliveryFeeDiscount = useSelector(() => dispatch.restaurants.getDeliveryFeeDiscount({locationId}))

  const isCartEmpty = useSelector(dispatch.user.getIsCartEmpty)
  const locationDetails = useSelector(dispatch.user.getCartLocationDetails)
  const cartSubTotal = useSelector(dispatch.user.getCartSubTotal)
  const deliveryFee = useSelector(dispatch.user.getDeliveryFee)
  const cartSubTotalBeforeDiscount = useSelector(dispatch.user.getCartSubTotalBeforeDiscount)
  const cartAfterTaxDiscount = useSelector(dispatch.user.getAfterTaxDiscount)
  const taxes = useSelector(dispatch.user.getCartTaxes)
  const tipAmount = useSelector(dispatch.user.getTipAmount)
  const cartTotal = useSelector(dispatch.user.getCartTotal)
  const tipSelectionIndex = useSelector(dispatch.user.getTipSelectionIndex)
  const defaultNotes = useSelector(dispatch.user.getDefaultNotes)
  const tipAmountForTipSelectionIndexes = [
    dispatch.user.getTipAmountForTipSelectionIndex(0),
    dispatch.user.getTipAmountForTipSelectionIndex(1),
    dispatch.user.getTipAmountForTipSelectionIndex(2),
    dispatch.user.getTipAmountForTipSelectionIndex(3),
  ]
  const orderType = useSelector(dispatch.user.getOrderType)
  const orderTimeType = useSelector(dispatch.user.getOrderTimeType)
  const scheduledOrderTimestamp = useSelector(dispatch.user.getScheduledOrderTimestamp)
  const includeUtensils = useSelector(dispatch.user.getIncludeUtensils)
  const promoItems = useSelector(() => dispatch.user.getAllPromosWithDetails(locationId))
  const cartItems = useSelector(dispatch.user.getCartItems)

  const restaurantLatLng = useSelector(() => dispatch.restaurants.getRestaurantLatLng({locationId}))
  const deliveryAddressLatLng = useSelector(dispatch.user.getDeliveryAddressLatLng) || {lat: 0, lng: 0}
  const contactlessDeliveryType = useSelector(dispatch.user.getContactlessDeliveryType)
  const contactlessDeliveryLocation = useSelector(dispatch.user.getContactlessDeliveryLocation)

  const isDeliveryAddressEmpty = useSelector(dispatch.user.getIsDeliveryAddressEmpty)
  const deliveryAddress = useSelector(dispatch.user.getDeliveryAddress)
  const deliveryAddressShort = useSelector(dispatch.user.getDeliveryAddressShort)
  const deliveryDistance = useSelector(() => dispatch.restaurants.getDeliveryDistance({locationId}))
  const deliveryUnit = useSelector(dispatch.user.getDeliveryUnit)
  const deliveryInstructions = useSelector(dispatch.user.getDeliveryInstructions)
  const cartWaitTime = useSelector(dispatch.user.getCartWaitTime)
  const cartItemIdsArray = Object.keys(cartItems).sort()

  const tableNumber = useSelector(dispatch.user.getTableNumber)
  const availableTables = useSelector(() => dispatch.restaurants.getAvailableTables({locationId}))
  useEffect(() => {
    if (tableNumber != null) {
      if (!availableTables[tableNumber] || availableTables?.[tableNumber]?.isAvailable === false) {
        dispatch.user.setTableNumber(null)
      }
    }
  }, [tableNumber, availableTables, locationId, dispatch.user])

  const pickupAvailable = useSelector(() => dispatch.restaurants.getIsPickupAvailable({locationId}))
  const deliveryAvailable = useSelector(() => dispatch.restaurants.getIsDeliveryAvailable({locationId}))
  const dineInAvailable = useSelector(() => dispatch.restaurants.getIsDineInAvailable({locationId}))

  const currentOrderOpenDetails = useSelector(() => {
    let orderOpenDetails = dispatch.restaurants.getOrderOpenDetails({locationId})
    if (orderOpenDetails.isOpen && orderType === 'Delivery') {
      orderOpenDetails = dispatch.restaurants.getDeliveryOrderOpenDetails({locationId})
    }
    return orderOpenDetails
  }, utils.isOrderOpenDetailsEqual)

  const deliveryAddressDistance = utils.latlngDistance(
    restaurantLatLng.lat,
    restaurantLatLng.lng,
    deliveryAddressLatLng.lat,
    deliveryAddressLatLng.lng,
  )

  let cartTax = 0
  if (taxes) {
    for (const taxData of Object.values(taxes)) {
      cartTax += taxData.taxAmount
    }
  }

  const fPointsEarned = utils.calculateFoodlyPointsEarnedSubTotal({subTotal: cartSubTotal})
  const totalBeforeTip = cartSubTotal + cartTax + deliveryFee

  if (orderTimeType === 'Scheduled') {
    const currentTimestamp = new Date().valueOf()
    if (currentTimestamp + cartWaitTime * 60000 > scheduledOrderTimestamp) {
      // Change to ASAP as scheduledOrderTimestamp is earlier than ASAP.
      dispatch.user.setOrderTimeType('ASAP')
    }
  }

  return {
    availableTables,
    cartAfterTaxDiscount,
    cartItemIdsArray,
    cartItems,
    cartSubTotal,
    cartSubTotalBeforeDiscount,
    cartTotal,
    cartWaitTime,
    contactlessDeliveryLocation,
    contactlessDeliveryType,
    currentOrderOpenDetails,
    defaultNotes,
    deliveryAddress,
    deliveryAddressDistance,
    deliveryAddressShort,
    deliveryAvailable,
    deliveryDistance,
    deliveryFee,
    deliveryFreeMinimumSubTotal,
    deliveryFeeDiscountMinimumSubTotal,
    deliveryFeeDiscount,
    deliveryInstructions,
    deliveryMinimumSubTotal,
    deliveryUnit,
    dineInAvailable,
    fPointsEarned,
    includeUtensils,
    isCartEmpty,
    isDeliveryAddressEmpty,
    locationDetails,
    locationId,
    orderTimeType,
    orderType,
    pickupAvailable,
    promoItems,
    scheduledOrderTimestamp,
    tableNumber,
    taxes,
    tipAmount,
    tipAmountForTipSelectionIndexes,
    tipSelectionIndex,
    totalBeforeTip,
  }
}
